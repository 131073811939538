// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iur-control-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.iur-control-container .switch-container{
  margin-bottom: 0;
}

.iur-control-container label{
  padding: 0 0 5px;
}

.iur-select {
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Auth.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".iur-control-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n\n.iur-control-container .switch-container{\n  margin-bottom: 0;\n}\n\n.iur-control-container label{\n  padding: 0 0 5px;\n}\n\n.iur-select {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

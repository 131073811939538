// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-title_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: var(--sadr-font-size-20) var(--sadr-font-size-20) var(--sadr-font-size-10) var(--sadr-font-size-20);
}

.custom-title_text {
  height: var(--sadr-font-size-34) ;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-28);
  line-height: var(--sadr-font-size-34);
  color: var(--sadr-success);
  margin-bottom: var(--sadr-font-size-30) ;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomTitle.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,2GAA2G;AAC7G;;AAEA;EACE,iCAAiC;EACjC,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,qCAAqC;EACrC,0BAA0B;EAC1B,wCAAwC;AAC1C","sourcesContent":[".custom-title_container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: auto;\n  margin: var(--sadr-font-size-20) var(--sadr-font-size-20) var(--sadr-font-size-10) var(--sadr-font-size-20);\n}\n\n.custom-title_text {\n  height: var(--sadr-font-size-34) ;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-28);\n  line-height: var(--sadr-font-size-34);\n  color: var(--sadr-success);\n  margin-bottom: var(--sadr-font-size-30) ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import '../../styles/UippsMain.css';
import FilterForUips from '../Filter/FilterForUipps';
import TableForUips from '../Table/TableForUipps';
import MultiMarkerMap from '../Maps/MultiMarkerMap';
import HeaderForObject from '../CustomElements/HeaderForObject';

import { clearUippss, initUippsFromServer } from '../../redux/actions/uipps.action';
import { emitGetCoordsStart, emitGetCoordsStop, listenUippsCoords } from '../../redux/actions/uipps.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import { setFilterState } from '../../redux/actions/filterState.action';

function UippsMain(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  const [isViewMap, setIsViewMap] = useState(false);
  const [isViewList, setIsViewList] = useState(true);
  // состояние для строки поиска
  const [filterInput, setFilterInput] = useState('');

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    road_id: '',
    directorate_id: '',
    department_id: '',
    train_type_id: '',
    train_number_id: '',
    responsible: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // функция для записи в стейт значения поиска
  useEffect(() => {
    if (isViewList) {
      dispatch(clearUippss());
      dispatch(clearFilterConfig());
      dispatch(initUippsFromServer(null, filterInput));
    }
  }, [filterInput]);
  // функция для отправки запроса фильтрации
  const onFilterSubmit = (event) => {
    event.preventDefault();
    if (isViewList) {
      dispatch(clearUippss());
      dispatch(clearFilterConfig());
      dispatch(initUippsFromServer(null, filterInput));
    }
  };
  // Стейт для очистки всех значений при переключении между картой и таблицей
  useEffect(() => {
    // Сбрасываем все поля поиска и фильтрации
    dispatch(
      setFilterState({
        search: false,
        filter: false,
        blockFlag: false,
      }))
      if (isViewList) {
        dispatch(clearUippss());
      }
      dispatch(clearFilterConfig());
      dispatch(initUippsFromServer());
  }, [isViewList])

  // слушаем событие получения координат
  useEffect(() => {
    dispatch(listenUippsCoords());
  }, [dispatch]);

  return (
    <div className="uipps">
      <FilterForUips
        setIsViewList={setIsViewList}
        setIsViewMap={setIsViewMap}
        isViewList={isViewList}
        isViewMap={isViewMap}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
      />
      <div className="uipps_content">
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          onFilterSubmit={onFilterSubmit}
          // handleFilterChange}
        />
        {isViewList && <TableForUips formData={formData} />}
        {isViewMap && (
          <MultiMarkerMap
            emitStartFunction={emitGetCoordsStart}
            listenStopFunction={emitGetCoordsStop}
            filterInput={filterInput}
            setSearchInput={setFilterInput}
            formData={formData}
          />
        )}
      </div>
    </div>
  );
}

export default UippsMain;

//функционал для отслеживания маркера
// const [trackerChanged, setTrackerChanged] = useState(false);
// useEffect(() => {
//   setTrackerChanged(true)
// }, [tracker])
// useEffect(() => {
//   if (visibleMarkers.length === 1) {
//     dispatch(addTrackingMarker(visibleMarkers[0].position));
//   } else {
//     dispatch(addTrackingMarker([null, null]));
//   }
// }, [visibleMarkers]);

/**
 * Проверяет поля sim1 и sim2 в объекте formData. Удаляет пробелы и нижние подчеркивания,
 * затем устанавливает значения в null, если они не соответствуют критериям 
 * (длина равна 1 или меньше 11 и больше 1).
 * Обновляет formData с помощью функции setFormData.
 * 
 * @param {Object} formData - Объект с данными, содержащий поля sim1 и sim2.
 * @param {Function} setFormData - Функция для обновления formData.
 * @returns {string|null} Возвращает сообщение об ошибке, если номера некорректны, иначе null.
 */
export function validateSimNumbers(formData, setFormData) {
  let error = false;
  
  // Функция для удаления пробелов и нижних подчеркиваний
  const sanitizeInput = (input) => input.replace(/[\s_]/g, '');

  // Санитизация и проверка SIM1 и SIM2
  let sim1 = formData.sim1 ? sanitizeInput(formData.sim1) : '';
  let sim2 = formData.sim2 ? sanitizeInput(formData.sim2) : '';
  let sim1Invalid = sim1.length === 1 || (sim1.length > 1 && sim1.length < 11);
  let sim2Invalid = sim2.length === 1 || (sim2.length > 1 && sim2.length < 11);
  // Проверяем SIM1 и SIM2 и обновляем formData
  if (sim1Invalid || sim2Invalid) {
      setFormData(prevFormData => ({
          ...prevFormData,
          sim1: sim1Invalid ? null : prevFormData.sim1,
          sim2: sim2Invalid ? null : prevFormData.sim2
      }));

      if (sim1Invalid && sim2Invalid) {
          error = 'Оба номера SIM введены некорректно';
      } else if (sim1Invalid) {
          error = 'Номер SIM1 введен некорректно';
      } else if (sim2Invalid) {
          error = 'Номер SIM2 введен некорректно';
      }
  }
  return error;
}

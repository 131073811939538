import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Компоненты пользовательского интерфейса
import '../../styles/Reports.css';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateRangePicker from '../CustomElements/DateRangePicker';
import CustomTitle from '../CustomElements/CustomTitle';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomContainer from '../CustomElements/CustomContainer';


import { useButtonValidation } from '../../customHook/useButtonValidation';
import useOptionsLoader from '../../customHook/useOptionsLoader';

import { handleUniversalChange } from '../../utils/formUtils';
import { convertDateToMs } from '../../utils/convertToDate';

import { load } from '../../redux/actions/loader.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';

function UniversalFormForReports({ title, action, reportForm, initialReportForm, setReportForm, optionalFields = [] }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const options = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(reportForm, optionalFields);

  //хук для запроса опций для дирекций и контроля селекта
  // в зависимости от типа выбираем функцию для получения дирекций
  const funcForGetDirectorate =
    reportForm.reportType === 'onOff' ? initOptionsDirectoratesFromServer : initOptionsDirectoratesUippsFromServer;
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    reportForm,
    setReportForm,
    ['directorateId', 'departmentId'],
    'roadId',
    funcForGetDirectorate,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    reportForm,
    setReportForm,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Переводим в состояние загрузки
    dispatch(load());

    // Создаем новый объект payload, копируя все свойства из formData
    const payload = { ...reportForm };

    // Если formData содержит startDate, преобразуем его и обновляем в payload
    if (reportForm?.startDate) {
      payload.startDate = convertDateToMs(reportForm.startDate);
    }

    // Если formData содержит endDate, преобразуем его и обновляем в payload
    if (reportForm?.endDate) {
      payload.endDate = convertDateToMs(reportForm.endDate);
    }
    // Отправляем данные на сервер через Redux
    dispatch(action(payload));
  };

  return (
    <>
      <div className="reports-item">
        <div className="reports-title-form">{title}</div>
        <DateRangePicker
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          label="Период *"
          formData={reportForm}
          setFormData={setReportForm}
          startDateField="startDate" // имя поля для даты начала, с дефолтным значением
          endDateField="endDate" // имя поля для даты окончания, с дефолтным значением
          name="date"
          placeholder="Выберите период"
          topPosition="50%"
          maxDate={new Date()}
          disabled={loader?.isLoading}
        />
        <Selector
          options={options?.roads}
          onChange={(selectedOption) => {
            handleUniversalChange(reportForm, setReportForm, null, 'roadId', selectedOption, 'select');
          }}
          placeholder="Выберите дорогу"
          label="Дорога *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          isMulti={false}
          name="roadId"
          isDisabled={loader?.isLoading}
        />
        <Selector
          options={reportForm.reportType === 'onOff' ? options?.directorates : options?.directoratesUipps}
          onChange={(selectedOption) => {
            handleUniversalChange(reportForm, setReportForm, null, 'directorateId', selectedOption, 'select');
          }}
          placeholder="Выберите дирекцию"
          label="Дирекция *"
          isMulti={false}
          name="directorateId"
          isDisabled={directoratesControl?.isSelectorDisabled}
          message={directoratesControl?.message}
          customClick={directoratesControl?.onSelectorChange}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
        />
        <Selector
          options={options?.departments}
          onChange={(selectedOption) => {
            handleUniversalChange(reportForm, setReportForm, null, 'departmentId', selectedOption, 'select');
          }}
          placeholder="Выберите подразделение"
          label={optionalFields?.includes('departmentId') ? 'Подразделение' : 'Подразделение *'}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          isMulti={false}
          name="departmentId"
          isDisabled={departmentsControl?.isSelectorDisabled || loader?.isLoading}
          message={departmentsControl?.message}
          customClick={departmentsControl?.onSelectorChange}
        />
        <CustomButton
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          text="Сформировать отчет"
          disabled={isButtonDisabled || loader?.isLoading}
          onClick={handlerSubmit}
          margin={'auto 0px 0px'}
        />
      </div>
    </>
  );
}

export default UniversalFormForReports;

import '../../styles/FilterForArchive.css';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import { handleUniversalChange } from '../../utils/formUtils';

function FilterForArchiveIUR({ formData, setFormData }) {
  // опции для селектов
  const options = {
    iurGSM: [
      { value: 1, label: '-60 - 0 (dBm)' },
      { value: 2, label: '-70 - -60 (dBm)' },
      { value: 3, label: '-80 - -70 (dBm)' },
      { value: 4, label: ' -90 - -80 (dBm)' },
      { value: 5, label: '< -90 (dBm)' },
    ],
    iurConn: [
      { value: false, label: 'Нет' },
      { value: true, label: 'Да' },
    ],
    iurAlertType: [
      { value: 1, label: 'ОК' },
      { value: 5, label: 'Тревога' },
      { value: 2, label: 'Оповещение 1' },
      { value: 3, label: 'Оповещение 2' },
      { value: 0, label: 'Проследование РЦ' },
      { value: 6, label: 'Ожидание' },
      { value: 4, label: 'Потеря связи' },

    ],
  };

  return (
    <div className="filter-for-archive_select">
      <Selector
        options={options?.iurAlertType}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'iurAlertType', selectedOption, 'select');
        }}
        placeholder="Выберите сигнал"
        value={
          formData.iurAlertType || formData.iurAlertType === 0
            ? options.iurAlertType?.find((option) => option.value === formData.iurAlertType)
            : null
        }
        name="iurAlertType"
        isMulti={false}
        label="Сигнал на ИУР"
      />
      <Input
        placeholder="Введите количество спутников"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData?.iurGPS}
        name="iurGPS"
        label="Количество спутников"
      />
      <Selector
        options={options?.iurGSM}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'iurGSM', selectedOption, 'select');
        }}
        placeholder="Выберите уровень сигнала"
        value={formData.iurGSM ? options?.iurGSM?.find((option) => option.value === formData.iurGSM) : null}
        name="iurGSM"
        isMulti={false}
        label="Уровень сигнала сотовой связи"
      />
      <Selector
        options={options?.iurConn}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'iurConn', selectedOption, 'select');
        }}
        placeholder="Выберите наличие связи"
        value={formData.iurConn !== '' ? options?.iurConn?.find((option) => option.value === formData.iurConn) : null}
        name="iurConn"
        isMulti={false}
        label="Связь с сервером"
      />
    </div>
  );
}

export default FilterForArchiveIUR;

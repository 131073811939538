import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import useUrlNavigate from './useUrlNavigate';

/**
 * меняет значение ключа параметров запроса. Если передать falsey значение, вырезает ключ
 * @param search {URLSearchParams}
 * @param name {string}
 * @param param {string | undefined | null}
 */
export function changeSearchParam(search, name, param) {
  if (!name || !search)
    return;
  if (!param)
    search.delete(name);
  else
    search.set(name, param);
}

/**
 * Хук для оперирования одним ключем в Query param.
 * Возвращает текущее значение ключа и функцию для изменения. Чтобы удалить ключ из параметров запроса, передай
 * любое falsy значение.
 * @param name {string} - Ключ для сохранения состояния
 * @param def {string} - дефолтное значчение
 * @returns {[string, (s: string)=>void]}
 */
export default function useSearchParam(name, def) {
  const navigate = useUrlNavigate();
  const [search] = useSearchParams();
  const actualValue = useMemo(() => search.get(name) || def, [def, name, search]);
  const setActualValue = useCallback((str) => {
    navigate(url=>{
      if (str)
        url.searchParams.set(name, str);
      else
        url.searchParams.delete(name);
    })
  }, [name, navigate]);
  return [actualValue, setActualValue];
}

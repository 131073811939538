/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordeon from '../CustomElements/Accordeon';
import contentData from '../../content/PagesContent/adminDataPannel/admidDataPannelRu.json';
import CustomButton from '../CustomElements/CustomButton';
import { getData, setSelected } from '../../redux/actions/adminChoice.action';

function AdminDataPannelCheck() {
  
  const selectedParam = useSelector((state) => state.adminChoiceData.selectedParam); //---- Selected parameter (pressed button)
  const butttonParamsArray = contentData.buttonsParams;
  const dispatch = useDispatch();
  //---- Set data in store according to button pressed. If data includes dependence - tthen set dependence data too
  const handleCheckData = (param, label) => {
    dispatch(setSelected({ param, label }));
    switch (param) {
      case 'directorates':
        dispatch(getData(param));
        dispatch(getData('roads'));
        break;
      case 'departments':
        dispatch(getData(param));
        dispatch(getData('directorates'));
        break;
      case 'brigades':
        dispatch(getData(param));
        dispatch(getData('departments'));
        break;
      case 'employees':
        dispatch(getData(param));
        dispatch(getData('departments'));
        break;
      default:
        dispatch(getData('roads'));
        break;
    }
  };
  //---- Set default data in store
  useEffect(() => {
    handleCheckData('roads', 'Дороги');
  }, []);
  return (
    <Accordeon header={contentData.title} hideAbove={1200} qParamName="admin-data-panel">
      {contentData.buttonsLabels.map((label, index) => (
        <CustomButton
          key={index}
          width="80%"
          color={butttonParamsArray[index]===selectedParam ? 'var(--sadr-success)' : 'white'}
          border={butttonParamsArray[index]===selectedParam ? '' : true}
          colorText={butttonParamsArray[index]===selectedParam ? true : 'var(--sadr-success)'}
          text={label}
          margin="1rem 1rem"
          onClick={() => handleCheckData(butttonParamsArray[index], label)}
        />
      ))}
    </Accordeon>
  );
}
export default AdminDataPannelCheck;

import React from 'react';
import '../../styles/CustomTextarea.css';

function CustomTextarea({
  placeholder,
  value,
  onChange,
  label,
  name,
  width,
  height,
  message,
  isNotMarginMessage,
  restrictTextInput,
  ...rest
}) {
  const textareaStyle = {
    width: width,
    height: height,
    margin: message ? 0 : 'initial',
  };

  const handleInputChange = (event) => {
    if (restrictTextInput) {
      const newValue = event.target.value;
      const regex = /^[0-9.,;\s-]*$/;
      if (!regex.test(newValue)) {
        event.preventDefault();
        return;
      }
    }
    onChange(event);
  };

  const handleBeforeInput = (event) => {
    if (restrictTextInput) {
      const charStr = event.data;

      // Разрешаем только цифры, запятую, точку с запятой, пробел и дефис
      if (charStr && !/[0-9.,;\s-]/.test(charStr)) {
        event.preventDefault();
      }
    }
  };

  return (
    <>
      <div className="textarea-container" style={textareaStyle}>
        {label && (
          <label className="input-label" htmlFor={name}>
            {label}
          </label>
        )}
        <textarea
          {...rest}
          className={`custom-textarea ${rest?.className}`}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onBeforeInput={handleBeforeInput}
          name={name}
          id={name}
          style={{ ...textareaStyle, ...(rest?.style || {}) }}
        />
        {message && (
          <span className={`message-text-input ${message ? 'visible black-text' : ''}`}>
            {message}
          </span>
        )}
      </div>
    </>
  );
}

export default CustomTextarea;

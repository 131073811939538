import { INIT_STATUSES, CLEAR_STAUSES } from '../type';
import axiosInstance from '../../config/axios.config';
import { addError } from './error.action';
import { remove, success } from './loader.action';
import { getOnlyOneDeviceFromServer } from './devices.action';
import { getOnlyOneUippsFromServer } from './uipps.action';

export const initStatuses = (statuses, path, selectedStatus, selectedDateSort) => ({
  type: INIT_STATUSES,
  payload: statuses,
  statusType: path,
  selectedStatus: selectedStatus,
  selectedDateSort: selectedDateSort,
});

export const clearStatuses = (statuses, path) => ({
  type: CLEAR_STAUSES,
  payload: [],
});

//получение статусов в зависимости от переданного пути, путь так же передается в редьюсер чтобы  определить какие данные обновить
export const initStatusesFromServer =
  (deviceId, path, selectedStatus, selectedDateSort) => async (dispatch, getState) => {
    const loader = getState().loader;
    try {
      if (!['storage', 'usage', 'repair', 'archive'].includes(path)) {
        throw new Error('Некорректный путь');
      }
      const { data } = await axiosInstance(`/api/devices/${path}/${deviceId}`);
      dispatch(initStatuses(data, path, selectedStatus, selectedDateSort));
      if (!loader?.isLoading && loader?.isSuccess) {
        dispatch(remove());
      }
    } catch (error) {      
      // const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
      // dispatch(addError(message));
      console.log(error);
    }
  };

// изменение статуса хранение
export const editStorageStatusFromServer = (payload, deviceId, type) => async (dispatch) => {
  // payload.set('senderId', 100)
  try {
    const response = await axiosInstance.put(`/api/devices/storage`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const typeId = payload.get('typeId');
    if (response.status === 200 && response.data) {
      dispatch(success());
      dispatch(initStatusesFromServer(deviceId, type));
      if (typeId !== 4) {
        dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
      }
      if (type === 4) {
        dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
      }
    }
  } catch (error) {
    // const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    // dispatch(addError(message));
    console.log(error);
  }
};

// изменение статуса направление в ремонт
export const editRepairStatusFromServer = (payload, deviceId, type) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/api/devices/repair`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const typeId = payload.get('typeId');
    if (response.status === 200 && response.data) {
      dispatch(success());
      dispatch(initStatusesFromServer(deviceId, type));
      if (typeId !== 4) {
        dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
      }
      if (type === 4) {
        dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
      }
    }
  } catch (error) {
    // const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    // dispatch(addError(message));
    console.log(error);
  }
};

// изменение статуса "Выдача" Эксплуатация
export const editOperationStatusFromServer = (payload, deviceId, type) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/api/devices/usage`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const typeId = await payload.get('typeId');
    if (response.status === 200 && response.data) {
      dispatch(success());
      await dispatch(initStatusesFromServer(deviceId, type));
      if (Number(typeId) === 4) {
        dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
      } else {
        dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
      }
    }
  } catch (error) {
    // const message = error?.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    // dispatch(addError(message));
    console.log(error);
  }
};

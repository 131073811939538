import {INIT_KUOBZES} from '../type';

export function kuobzReducer(state = [], action) {
  const { type, payload } = action
  
  switch (type) {
    case INIT_KUOBZES : {
      return payload
    }
    default:
      return state
  }
}

import {
  INIT_UIPPS,
  EDIT_UIPPS_STATUS,
  ADD_UIPPS_COORDS,
  CLEAR_UIPPS,
  UPDATE_SINGLE_UIPPS,
  ADD_NEW_UIPPS,
  INIT_UPDATE_UIPPS,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { emitWithTokens } from '../../utils/emitWithTokens';
import { initUippsAndroidId } from '../actions/options.action';
import setRequest from '../../utils/createSearchRequest';
import setFilteredData, { updateCanSendRequest, updateRequestBlocker } from './filterConfig.action';
import { addError } from './error.action';
import { loadRequest } from './requestLoader.action';
import { lookAfterMapArray } from './mapData.action';

export const initUippss = (uipps) => ({
  type: INIT_UIPPS,
  payload: uipps,
});
export const initAndUpdateUippss = (uipps) => ({
  type: INIT_UPDATE_UIPPS,
  payload: uipps,
});

export const updateSingle = (device) => ({
  type: UPDATE_SINGLE_UIPPS,
  payload: device,
});
export const clearUippss = () => ({
  type: CLEAR_UIPPS,
  payload: [],
});

export const addNewUipps = (device) => ({
  type: ADD_NEW_UIPPS,
  payload: device,
});

export const editUippsStatus = (device) => ({
  type: EDIT_UIPPS_STATUS,
  payload: device,
});

// запрос на получение списка uipps, с обработкой фильтрации и поиска
export const initUippsFromServer = (filterCriteria, searchString) => async (dispatch, getState) => {
  const state = getState();
  const lastId = state.filterConfig.lastId; // id последнего полученного устройства
  const canSendRequest = state.filterConfig.canSendRequest; // флаг возможна ли отправка запроса (все ли данные получены)
  const searchFlag = state.filterConfig.searchFlag; // флаг если произведён поиск
  const searchData = state.filterConfig.searchData; // текущее значение поискового запроса
  const blockRequest = state.filterConfig.blockRequest; // флаг блокировки запроса на сервер

  try {
    dispatch(loadRequest(true));
    // вызов утилиты по формированию query-строки для запроса
    if (canSendRequest && !blockRequest) {
    const searchReaquest = setRequest(filterCriteria, searchString, lastId, searchFlag, searchData);
      // получение данных с сервера
      const result = await axiosInstance(`/api/devices/uipps${searchReaquest}${searchReaquest ? '&' : '?'}limit=50`);
      dispatch(updateCanSendRequest(true));
      if (result.data && result.status === 200) {
        // заполняем стейт с uipps
        await dispatch(initUippss(result.data));
        // формируется стейт вспомогательных данных для бесконечной подгрузки и перезапросов
        await dispatch(setFilteredData(result.data, searchString, searchReaquest, searchFlag, searchData));
      dispatch(updateRequestBlocker(false));
      } else {
        //---- if api request fails - show error for user
        dispatch(addError(result.data));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

// запрос на обновление массива uipps после изменения uipps
export const initUippsAfterUpdate =
  ([updatedUipps]) =>
  async (dispatch) => {
    try {
      if (updatedUipps) {
        await dispatch(updateSingle(updatedUipps));
      }
    } catch (error) {
      console.log(error);
    }
  };

// запрос на получение одного uipps после изменения uipps
export const getOnlyOneUippsFromServer = (id) => async (dispatch) => {
  try {
    const uippsData = await axiosInstance(`/api/devices/uipps?id=${id}`);
    if (uippsData.data[0]) {
      dispatch(updateSingle(uippsData.data[0]));
    }
  } catch (error) {
    console.log(error);
  }
};

// запрос на получение массива uipps,  ранее отображённых пользователю после изменения состояния
export const initUippsAfterStateChange = () => async (dispatch, getState) => {
  const state = getState();
  const lastRequest = state.filterConfig.apiRequest;
  try {
    const { data } = await axiosInstance(`/api/devices/uipps${lastRequest}`);
    if (data) {
      await dispatch(initAndUpdateUippss(data));
    }
  } catch (error) {
    console.log(error);
  }
};

// запрос на получение массив android_id [string]
export const initUippsAndroidIdFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/devices/uipps/unauthorized');
    if (data) {
      dispatch(initUippsAndroidId(data));
    }
  } catch (error) {
    console.log(error);
  }
};

// отправляем событие чтобы сервер начал слать координаты уипсов
export const emitGetCoordsStart =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'uipps_listen_start');
  };

// отправляем событие чтобы сервер перестал слать координаты уипсов
export const emitGetCoordsStop =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'uipps_listen_stop');
  };

// обновление стейта
export const addCoords = (coords) => ({
  type: ADD_UIPPS_COORDS,
  payload: coords,
});

// слушаем событие получения координат
export const listenUippsCoords =
  () =>
  (dispatch, getState, { socket }) => {
    socket.on('uipps_coords', (data) => {
      if (!data) return;
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      dispatch(addCoords(data));
      // Запуск экшена для проверки области видимости, фильтров и обновления стейта с для карты
      dispatch(lookAfterMapArray(data, 'УИППС'));
    });

    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('uipps_coords');
    };
  };

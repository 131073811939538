// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-data-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1;
  align-items: stretch;
  /* width: 100%; */
  height: 100vh;
  box-sizing: border-box;
}
.adding-data-block {
  width: 100%;
  padding-right: 30px;  
  padding-left: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.adding-data-block__table {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: var(--sadr-background-secondary);  */
  height: 100%;
  width: 100%;
}

.adding-data-block__table .table_header th{
  white-space: unset;
}

.adding-data-block__button {
    display: flex;
    justify-content: center;
 width: 100%;
  padding: var(--sadr-font-size-40) 0 var(--sadr-font-size-20) 0;
  background-color: var(--sadr-background-secondary);
  border-bottom-left-radius: var(--sadr-font-size-12);
  border-bottom-right-radius: var(--sadr-font-size-12);
}
.data-modal{
    height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;  
  margin:var(--sadr-font-size-40) auto var(--sadr-font-size-20);
}
`, "",{"version":3,"sources":["webpack://./src/styles/AdminDataPanel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAO;EACP,oBAAoB;EACpB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yDAAyD;EACzD,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;CAC1B,WAAW;EACV,8DAA8D;EAC9D,kDAAkD;EAClD,mDAAmD;EACnD,oDAAoD;AACtD;AACA;IACI,YAAY;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,6DAA6D;AAC/D","sourcesContent":[".admin-data-panel {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex: 1;\n  align-items: stretch;\n  /* width: 100%; */\n  height: 100vh;\n  box-sizing: border-box;\n}\n.adding-data-block {\n  width: 100%;\n  padding-right: 30px;  \n  padding-left: 20px;\n  padding-bottom: 30px;\n  box-sizing: border-box;\n}\n.adding-data-block__table {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  /* background-color: var(--sadr-background-secondary);  */\n  height: 100%;\n  width: 100%;\n}\n\n.adding-data-block__table .table_header th{\n  white-space: unset;\n}\n\n.adding-data-block__button {\n    display: flex;\n    justify-content: center;\n width: 100%;\n  padding: var(--sadr-font-size-40) 0 var(--sadr-font-size-20) 0;\n  background-color: var(--sadr-background-secondary);\n  border-bottom-left-radius: var(--sadr-font-size-12);\n  border-bottom-right-radius: var(--sadr-font-size-12);\n}\n.data-modal{\n    height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  box-sizing: border-box;  \n  margin:var(--sadr-font-size-40) auto var(--sadr-font-size-20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

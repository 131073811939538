import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import '../../styles/DeviceOperationStatus.css';
import CustomButton from '../CustomElements/CustomButton';
import DeviceStorageFromOperation from '../Devices/DeviceStorageFromOperation';
import EditOperationSendStatus from '../Statuses/EditOperationSendStatus';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import useDeviceStatus from '../../customHook/useDeviceStatus';
import TableMap from '../Table/TableMap';
import EditOperationSendUippsStatus from '../UIPPS/EditOperationSendUippsStatus';

function DeviceOperationStatus({ currentDevice, setActiveButton, activeButton, uippsType }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  //получакм массив статаусов Хранение из стора
  const { usageStatuses } = useSelector((state) => state?.statuses);
  //получаем статус текущего устройства
  const status = currentDevice?.status;
  // состояние для активации кнопки
  const [disabledBtn, setDisabledBtn] = useState(!(status === 'Эксплуатация'));

  const [statusId, setStatusId] = useState('');

  // хук для получения текущего статуса по id
  const currentStatus = useDeviceStatus(usageStatuses, statusId);

  // Обновляем состояние disabledBtn при изменении статуса
  useEffect(() => {
    setDisabledBtn(!(status === 'Эксплуатация'));
  }, [status]);

  const title = 'ИЗМЕНЕНИЕ ДАННЫХ СТАТУСА';

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus(
    usageStatuses,
    currentDevice,
    setStatusId
  );
  const contentData = {
    Выдача: uippsType ? (
      <EditOperationSendUippsStatus
        key={currentStatus?.id || 'new'}
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
      />
    ) : (
      <EditOperationSendStatus
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
      />
    ),
    Возврат: (
      <DeviceStorageFromOperation
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
      />
    ),
    'Принять на хранение': <DeviceStorageFromOperation currentDevice={currentDevice} closeModal={closeModal} />,
  };
  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'usage'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Таблица со статусами */}
      {usageStatuses && (
        <TableMap
          data={usageStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />
      )}
      {/* Блок с кнопками */}
      <div className="device-operation-status_button-container">
        <CustomButton
          className="device-operation-status_button"
          text="Принять на хранение"
          colorText="#FFFFF"
          onClick={() => handleButtonClick('Принять на хранение')}
          color={disabledBtn ? '#ADADAD' : ''}
          disabled={disabledBtn}
        />
      </div>
      {/* Модальное окно */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {contentData[activeModal]}
      </Modal>
    </>
  );
}

export default DeviceOperationStatus;

import {
  ADD_DEPARTMENT_OPTIONS,
  ADD_EMPLOYEES_OPTIONS,
  ADD_SECTION_OPTIONS,
  ADD_ANDROID_ID_OPTIONS,
  DELETE_SELECT_OPTIONS,
  INIT_OPTIONS,
  ADD_RAILCHAIN_OPTIONS,
  INIT_MRM_IDS,
  ADD_ANDROID_ID_OPTIONS_FOR_IUR,
  ADD_DIRECTORATES_UIPPS_OPTIONS,
  ADD_DIRECTORATES_OPTIONS,
  ADD_BRIGADES_OPTIONS,
  ADD_POSITION
} from '../type';
import axiosInstance from '../../config/axios.config';
import { emitWithTokens } from '../../utils/emitWithTokens';

export const initOptions = (options) => ({
  type: INIT_OPTIONS,
  payload: options,
});

export const deleteOptions = (options) => ({
  type: DELETE_SELECT_OPTIONS,
  payload: options,
});

export const initUippsAndroidId = (options) => ({
  type: ADD_ANDROID_ID_OPTIONS,
  payload: options,
});

export const initIurAndroidId = (options) => ({
  type: ADD_ANDROID_ID_OPTIONS_FOR_IUR,
  payload: options,
});

export const initOptionsFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/data');
    dispatch(initOptions(data));
  } catch (error) {
    console.log(error);
  }
};

// добавление департаментов в стейт опций
export const addDepartmenOptions = (options) => ({
  type: ADD_DEPARTMENT_OPTIONS,
  payload: options,
});

// получение департаментов с сервера по id дирекций
export const initDepartmenOptionsFromServer = (directorateId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/directorates/${directorateId}/departments`);
    dispatch(addDepartmenOptions(data));
  } catch (error) {
    console.log(error);
  }
};

export const addSectionOptions = (options) => ({
  type: ADD_SECTION_OPTIONS,
  payload: options,
});

// получение станций с сервера по id дороги
export const initOptionsSectionFromServer = (roadId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/roads/${roadId}/stations`);
    dispatch(addSectionOptions(data));
  } catch (error) {
    console.log(error);
  }
};

export const addRailChainsOptions = (data, station) => ({
  type: ADD_RAILCHAIN_OPTIONS,
  payload: { data, station },
});

export const initOptionsRailChainsFromServer = (station) => async (dispatch) => {
  try {
    const resp = await axiosInstance(`/api/data/stations/${station}/rail_chains`);
    const data = resp.data;
    dispatch(addRailChainsOptions(data, station));
  } catch (e) {
    console.log(`Error during GET /api/data/railchains/${station} req:`, e);
    throw e;
  }
};

// добавления опций сотрудников
export const addEmployeesOptions = (options) => ({
  type: ADD_EMPLOYEES_OPTIONS,
  payload: options,
});

// получение опций сотрудников с сервера
export const initOptionsEmployeesFromServer = (departmenId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/departments/${departmenId}/workers`);
    dispatch(addEmployeesOptions(data));
  } catch (error) {
    console.log(error);
  }
};

// добавление дирекций в стейт опций
export const addDirectoratesOptions = (options) => ({
  type: ADD_DIRECTORATES_OPTIONS,
  payload: options,
});

// получение дирекций с сервера по id дороги для устройств
export const initOptionsDirectoratesFromServer = (roadId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/roads/${roadId}/directorates`);
    dispatch(addDirectoratesOptions(data));
  } catch (error) {
    console.log(error);
  }
};

// добавление дирекций Uipps в стейт опций
export const addDirectoratesUippsOptions = (options) => ({
  type: ADD_DIRECTORATES_UIPPS_OPTIONS,
  payload: options,
});

// получение дирекций с сервера по id дороги для Uipps
export const initOptionsDirectoratesUippsFromServer = (roadId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/roads/${roadId}/directorates/uipps`);
    dispatch(addDirectoratesUippsOptions(data));
  } catch (error) {
    console.log(error);
  }
};

// добавление бригад в стейт опций
export const addBrigadesOptions = (options) => ({
  type: ADD_BRIGADES_OPTIONS,
  payload: options,
});

// получение бригад с сервера по id подразделения
export const initOptionsBrigadesFromServer = (departmentId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/departments/${departmentId}/brigades`);
    dispatch(addBrigadesOptions(data));
  } catch (error) {
    console.log(error);
  }
};

// добавление позиций в стейт опций
export const addPositions = (options) => ({
  type: ADD_POSITION,
  payload: options,
});


// получение позиций с сервера
export const getPositionsFromServer = (roadId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/data/positions');
    dispatch(addPositions(data));
  } catch (error) {
    console.log(error);
  }
};

/**SOCET */
/** Экшены - генерация событий */
// отправляем событие чтобы сервер начал слать MrmIds
export const emitGetMrmIds =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'get_mrm_data', { skipTokenVerification: true });
  };

// обновление стейта MrmIds
export const initMrmIds = (data) => ({
  type: INIT_MRM_IDS,
  payload: data,
});
// слушаем событие, получаем MrmIds
export const listenGetMrmIds =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('mrm_ids', (data) => {
      if (data) {
        const options = data.map((item) => ({
          value: item.mrmId,
          label: item.mrmId,
        }));
        dispatch(initMrmIds(options));
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('mrm_ids');
    };
  };




import React from 'react';
import '../../styles/CustomTitle.css';

const CustomTitle = ({ titleText, deviceType, deviceId }) => {
  return (
    <div className="custom-title_container">
      <div className="custom-title_text">
        {titleText} {deviceType} {deviceId ? '№' : ''} {deviceId}
      </div>
    </div>
  );
};

export default CustomTitle;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Markers from './Markers';

const redIcon = L.icon({
  iconUrl: '/image/redIcon.svg',
  iconSize: [24, 24],
});

const greenIcon = L.icon({
  iconUrl: '/image/greenIcon.svg',
  iconSize: [15, 15],
});

const blueIcon = L.icon({
  iconUrl: '/image/blueIcon.svg',
  iconSize: [15, 15],
});

function MultiMarkerMap({ emitStartFunction, listenStopFunction, filterInput, formData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  useEffect(() => {
  }, [filterInput])
  // Вычисляем позицию на основе первого элемента markers
  const position = useMemo(() => {
    const startPosition = [53.18442135, 50.12827981];
    return startPosition;
  }, []);

  // Функция для получения иконки
  const getIcon = (type) => {
    switch (type) {
      case 'green':
        return greenIcon;
      case 'red':
        return redIcon;
      case 'blue':
        return blueIcon;
      default:
        return redIcon;
    }
  };

  
  //старт и прекращение прослушивания координат
  useEffect(() => {
    // Проверяем, передана ли функция emitFunctionStart
    if (emitStartFunction) {
      // Отправляем событие чтобы сервер начал слать координаты
      dispatch(emitStartFunction());
    }

    // Функция очистки
    return () => {
      // Проверяем, передана ли функция emitFunctionStop
      if (listenStopFunction) {
        // Отправляем событие чтобы сервер прекратил слать координаты
        dispatch(listenStopFunction());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: '100%', width: '100%', borderRadius: 'var(--sadr-font-size-10)' }}
      attributionControl={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Markers
        getIcon={getIcon}
        filterInput={filterInput}
        formData={formData}
        // setSearchInput={setSearchInput}
      />
    </MapContainer>
  );
}

export default MultiMarkerMap;

// Функция для отслеживания маркера
// const ChangeView =({ tracker }) => {
//   const map = useMap();
//   map.setView(tracker);
//   return null;
// };
// Функция для отслеживания маркера
// const trackingMarker = useSelector((state) => state.mapData.trackingMarker);
// const [tracker, setTracker] = useState(trackingMarker);

// useEffect(() => {
//   if(tracker[0] !== trackingMarker[0] || tracker[1] !==trackingMarker[1]){
//     setTracker(trackingMarker);
//   }
// }, [trackingMarker])

// {tracker[0] && tracker[1] && <ChangeView tracker={tracker}/>}

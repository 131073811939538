import { useState, useEffect } from 'react';

/**
 * Кастомный хук, который отслеживает наличие элементов <span> с классом "visible" на странице.
 * @returns {boolean} - true, если есть хотя бы один элемент <span> с классом "visible", иначе false.
 */
const useVisibleSpans = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    /**
     * Обрабатывает изменения в DOM и обновляет состояние в зависимости от наличия элементов <span> с классом "visible".
     */
    const handleMutation = () => {
      const visibleSpans = document.querySelectorAll('span.visible');
      setIsVisible(visibleSpans.length > 0);
    };

    // Создаем MutationObserver для отслеживания изменений в DOM
    const observer = new MutationObserver(handleMutation);

    // Наблюдаем за изменениями во всем документе
    observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class']
    });

    // Первоначальная проверка на наличие видимых спанов
    handleMutation();

    // Отключаем наблюдателя при размонтировании компонента
    return () => {
      observer.disconnect();
    };
  }, []);

  return isVisible;
};

export default useVisibleSpans;

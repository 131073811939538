import React, { useState } from 'react';
import '../../styles/ExpandableForm.css'; // Импортируем стили

const ExpandableForm = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="sticky-header">
        <div className="div-margin"></div>
        <div className="header expandable-form-container" onClick={toggleOpen}>
          <div className="w-100">
            <span className="expandable-form-text">{title}</span>
          </div>

          <span className={`toggle-icon ${isOpen ? 'open' : ''}`}></span>
        </div>
      </div>
      {isOpen && <div className="form-content">{children}</div>}
    </>
  );
};

export default ExpandableForm;

import { React, useEffect } from 'react';
import { useLoader } from './Common';
import CustomButton from '../CustomElements/CustomButton';
import Preloader from '../CustomElements/Preloader';
import { useParams } from 'react-router-dom';

export default function ModalDelete({
  closeModal,
  confirmText,
  removalText,
  successText,
  goBackText = 'К списку',
  canDelete,
  onDelete,
  setParams,
}) {
  const { isLoading, isSuccess } = useLoader();
  const params = useParams();

  useEffect(() => {
    if (params && setParams) {
      setParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="top-50 position-absolute mx-auto my-auto"
      // see modal-content padding
      style={{ width: 'calc(100% - var(--sadr-font-size-20))' }}
    >
      <div className="d-flex flex-column flex-nowrap align-items-center">
        {!isLoading && !isSuccess && (
          <>
            <h5 className="confirmation-title">{confirmText}</h5>
            <div>
              <CustomButton
                className="confirmation-btn confirm"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                text={'Подтвердить'}
                disabled={!canDelete}
                onClick={onDelete}
              />
              <CustomButton
                className="confirmation-btn cancel"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                color="var(--sadr-background-secondary)"
                colorText="var(--sadr-border)"
                text={'Отменить'}
                onClick={closeModal}
              />
            </div>
          </>
        )}
        {isLoading && (
          <>
            <h5 className="confirmation-title">{removalText}</h5>
            <Preloader isLoading />
          </>
        )}
        {isSuccess && (
          <>
            <h5 className="confirmation-title">{successText}</h5>
            <CustomButton
              className="confirmation-btn confirm"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              text={goBackText}
              onClick={closeModal}
            />
          </>
        )}
      </div>
    </div>
  );
}

import '../../styles/TableMap.css';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import { dateToString } from '../../utils/convertToDate';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import { useSelector } from 'react-redux';

const pathUploads = process.env.REACT_APP_UPLOADS;

const TableMap = ({
  data = [],
  handleButtonClick,
  isBtn = true,
  setActiveButton,
  activeButton,
  archive = false,
  setSelectedStatus,
  setSelectedDateSort,
  selectedStatus,
  selectedDateSort,
}) => {
  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  const btnName = ['Эксплуатация', 'Хранение', 'Ремонт', 'Архив'];

  // записываем в стейт имя кнопки для отрисовки компонента
  const selectComponentButton = (buttonName) => {
    if (buttonName !== activeButton) {
      setActiveButton(buttonName);
    }
  };

  return (
    <div className="table-map-container">
      <div className="shadow"></div>
      {/* Блок с кнопками для выбора статусов */}
      <div className="table-map-button-container">
        {btnName.map((buttonName) => (
          <CustomButton
            className={`device_button ${activeButton === buttonName ? 'activeBtn' : ''}`}
            key={buttonName}
            text={buttonName}
            colorText={activeButton === buttonName ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
            onClick={() => selectComponentButton(buttonName)}
          />
        ))}
      </div>
      {archive && (
        <div className="device-archive-status_select-container">
          <Selector
            column="left"
            options={optionsForSelect.optionsDateSort}
            onChange={(selectedOption) => setSelectedDateSort(selectedOption)}
            placeholder="Сначала новые"
            width="var(--sadr-font-size-320) "
            height="var(--sadr-font-size-60)"
            value={selectedDateSort}
            isMulti={false}
            name="dateSort"
            isView
            defaultValue={selectedDateSort}
          />
          <Selector
            column="left"
            options={optionsForSelect.optionsActions}
            onChange={(selectedOption) => setSelectedStatus(selectedOption)}
            placeholder="Все действия"
            width="var(--sadr-font-size-320) "
            height="var(--sadr-font-size-60)"
            value={selectedStatus}
            isMulti={false}
            name="typeStatus"
            isView
          />
        </div>
      )}
      {/* Если массив пустой выводим запись что данных нет */}
      {data?.length === 0 ? (
        <div className="table-map-no-data">Данные отсутствуют</div>
      ) : (
        data?.map((status, index) => {
          // Составление уникального ключа, объединяя несколько значений
          const uniqueKey = `${status?.id}_${status?.device_id}_${index}`;
          //определяем имя для кнопки чтобы передать в функцию для открытия модального окна по имени
          const btnName = status?.title === 'Хранение' ? 'edit' : status?.title || 'edit';
          //форматируем дату
          const formattedDate = dateToString(status?.date);
          //определяем состояние
          let conditionText;
          if (status?.broken === null) {
            conditionText = 'Не определено';
          } else {
            conditionText = status?.broken ? 'Неисправно' : 'Исправно';
          }

          return (
            // Таблица статусов
            <div key={uniqueKey} className="table-map-row">
              {/* Дата и кнопка */}
              <div className="date-button-container">
                <div className="date">
                  {status?.title} {formattedDate}
                </div>
                {isBtn && <CustomButtonEdit onClick={(event) => handleButtonClick(btnName, status?.id)} />}
              </div>

              {/* Трехколоночная сетка */}
              <div className="grid-columns">
                {/* Колонка 1 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Акт приема-передачи:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Место хранения:</div>
                      <div className="column-value">{status?.storage}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Дата ТО:</div>
                      <div className="column-value">{dateToString(status?.service_date)}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Дата следующего ТО:</div>
                      <div className="column-value">{dateToString(status?.next_date)}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') && (
                  <div className="grid-column">
                    {!status.uipps && (
                      <div className="column-item">
                        <div className="column-title">ФИО выдавшего:</div>
                        <div className="column-value">{status?.sender}</div>
                      </div>
                    )}
                    <div className="column-item">
                      <div className="column-title">Акт приема-передачи:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Таб. № принявшего:</div>
                      <div className="column-value">{status?.responsible_id}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">ФИО принявшего:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Акт приема-передачи:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Место хранения:</div>
                      <div className="column-value">{status?.storage}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Акт приема-передачи:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Сервисный центр:</div>
                      <div className="column-value">{status?.service_center}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Контакты центра:</div>
                      <div className="column-value">{status?.service_center_contact}</div>
                    </div>
                    {/* попросили скрыть это поле */}
                    {/* <div className="column-item">
                      <div className="column-title">Дорога:</div>
                      <div className="column-value">{status?.road}</div>
                    </div> */}
                  </div>
                )}
                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Акт приема-передачи:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">ФИО принявшего:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Дата списания:</div>
                      <div className="column-value">{dateToString(status?.date)}</div>
                    </div>
                  </div>
                )}

                {/* Колонка 2 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">ФИО принявшего:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Состояние:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Выполненные работы:</div>
                      <div className="column-value">{status?.works_done}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  !status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">Дорога:</div>
                        <div className="column-value">{status?.road}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Дирекция:</div>
                        <div className="column-value">{status?.directorate}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Подразделение:</div>
                        <div className="column-value">{status?.department}</div>
                      </div>
                      {/* <div className="column-item">
                        <div className="column-title">Участок:</div>
                        <div className="column-value">{status?.section}</div>
                      </div> */}
                    </div>
                  )}
                {/* Эксплуатация выдача для уиппс*/}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">Дорога:</div>
                        <div className="column-value">{status?.road}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Дирекция:</div>
                        <div className="column-value">{status?.directorate}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Подразделение:</div>
                        <div className="column-value">{status?.department}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Состояние:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Дефектная ведомость:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">ФИО направившего:</div>
                      <div className="column-value">{status?.sender}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Дефектная ведомость:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.defects_file
                          )}&fileName=${encodeURIComponent(status?.defects_file_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.defects_file_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Состояние:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Состояние:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">Акт выполненных работ:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Акт списания:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {/* Колонка 3 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Комментарий:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Комментарий:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  !status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">Контакты:</div>
                        <div className="column-value column-value-note">{status?.responsible_contact}</div>
                      </div>
                    </div>
                  )}

                {/* Эксплуатация выдача для уиппс станционное размещение */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status.uipps &&
                  status?.placement && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">Тип ПС:</div>
                        <div className="column-value">{status?.train_type}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Номер ПС:</div>
                        <div className="column-value">{status?.train_number}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация выдача для уиппс не станционное размещение*/}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status?.uipps &&
                  !status?.placement && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">Табельный № машиниста:</div>
                        <div className="column-value column-value-note">{status?.driver_id}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">Контакты машиниста:</div>
                        <div className="column-value column-value-note">{status?.responsible_contact}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Комментарий:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Комментарий:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}

                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Комментарий:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">Причина списания:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default TableMap;

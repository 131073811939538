import { useState, useEffect } from 'react';
import { formValidation } from '../utils/formUtils';

/**
 * Кастомный хук для определения, должна ли кнопка быть активной на основе валидности данных формы.
 * Использует функцию валидации формы для проверки, все ли поля заполнены.
 * Возвращает состояние, указывающее, должна ли кнопка быть отключена.
 *
 * @param {Object} formData - Объект с данными формы для валидации.
 * @param {string[]} optionalFields - Массив имен необязательных полей формы.
 * @returns {boolean} Состояние, указывающее, должна ли кнопка быть отключена (true - если должна быть отключена).
 */
export const useButtonValidation = (formData, optionalFields) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    // Проверяем валидность формы используя импортированную функцию валидации
    const isInvalid = formValidation(formData, optionalFields);
    setButtonDisabled(isInvalid);
  }, [formData, optionalFields]);

  return isButtonDisabled;
};

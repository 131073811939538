import {ADD_ERROR, CLEAR_ERROR} from '../type'

export const addError = (error) => ({
  type: ADD_ERROR ,
  payload: error
})

export const clearError = () => ({
  type: CLEAR_ERROR ,
  payload: {}
})

import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/CustomButtonEdit.css';

function CustomButtonDelete({ onClick, margin, disabled }) {
  const buttonStyle = {
    margin: margin,
  };
  return (
    <button className="custom-button-edit" style={buttonStyle} onClick={onClick} disabled={disabled}>
      <img className="" src="/image/delete.svg" alt="Иконка" />
    </button>
  );
}

CustomButtonDelete.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomButtonDelete;

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import '../../styles/DateSelector.css';
import moment from 'moment';
import 'moment-timezone';

function DateSelector({
  selectedDate,
  setSelectedDate,
  label,
  placeholder,
  width,
  height,
  name,
  minDate,
  maxDate,
  isDisabled,
  alert,
  customClick,
}) {
  const customStyles = {
    width: width,
    height: height,
  };

  const clearDate = () => {
    setSelectedDate('');
    setIsHovered(false);
  };

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setSelectedDate(date);
    } else {
      setSelectedDate('');
    }
  };

  const inputClassName = `date-selector_input ${selectedDate ? 'clearable' : ''}`;

  const [isHovered, setIsHovered] = useState(false);

  // Стили для наложения, которое блокирует взаимодействие
  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'not-allowed',
    backgroundColor: 'transparent', // Сделать прозрачным
    display: isDisabled ? 'block' : 'none', // Показывать только когда DatePicker отключен
    zIndex: 2,
  };

  return (
    <div className="date-selector-container">
      {label && (
        <div className="date-selector-label" htmlFor={name}>
          {label}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <DatePicker
          id={name}
          customInput={<input style={customStyles} className={alert ? 'red-border' : ''} name={name} id={name} />}
          selected={selectedDate}
          onChange={handleDateChange}
          className={inputClassName}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholder ? placeholder : 'Выберите дату'}
          locale={ru}
          minDate={minDate} // Устанавливаем минимальную дату
          maxDate={maxDate} // Устанавливаем максимальную дату
          disabled={isDisabled}
        />
        {/* Наложение для перехвата кликов когда DatePicker отключен */}
        <div style={overlayStyles} onClick={customClick}></div>
        {selectedDate && (
          <div
            style={{
              position: 'absolute',
              top: '35%',
              right: ' var(--sadr-font-size-1) ',
              transform: 'translateY(-50%)',
              padding: 'var(--sadr-font-size-8)',
            }}
            onClick={clearDate}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                fill={isHovered ? '#D30630' : '#000'}
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default DateSelector;

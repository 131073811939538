import _ from 'lodash';

/**
 * Рекурсивно обходит вложенный массив и возвращает элементы
 * @param arr {T[]}
 * @param child_fn {(T)=>T[] | undefined}
 * @return T[]
 */
export function select_recursive(arr, child_fn) {
  let result = [];
  arr?.forEach(item => {
    result.push(item);
    result.push(...select_recursive(child_fn(item), child_fn));
  });
  return result;
}

export function forEachRecursive(obj, visitFn, parentPath = '') {
  for (let [key, value] of _.entries(obj)) {
    let fullpath = `${parentPath ? parentPath + '.' : ''}${key}`;
    visitFn(fullpath, value);
    if (_.isObject(value)) {
      forEachRecursive(value, visitFn, fullpath);
    }
  }
}

/**
 * Функция для поиска объекта по его id в массиве объектов.
 * @param {number} id - Идентификатор объекта для поиска.
 * @param {Array<Object>} array - Массив объектов, в котором осуществляется поиск.
 * @returns {Object|null} - Найденный объект или null, если объект не найден.
 */
export const findObjectById = (id, array) => {
  if (!Array.isArray(array) || typeof id !== 'number') {
    console.log('не переданы параметры');
    return
  }
  return array.find(obj => obj.id === id) || null;
};


/**
 * Создает глубокую копию массива с помощью метода structuredClone.
 * @param {Array} arr - Массив, который требуется клонировать.
 * @returns {Array} - Глубокая копия исходного массива.
 */
export const cloneArray = (arr) => {
  if (!Array.isArray(arr)) {
    console.log('не переданы параметры');
    return;
  }
  return arr.map(item => structuredClone(item));
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { simulateOnChange } from '../../utils/htmlUtils';
import { useDispatch, useSelector } from 'react-redux';
import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { initOptionsRailChainsFromServer } from '../../redux/actions/options.action';
import Input from '../CustomElements/Input';
import MaskedInput from '../CustomElements/MaskedInput';
import Selector from '../CustomElements/Selector';
import { useLocation, useParams } from 'react-router-dom';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import { useAsyncFetch } from '../../customHook/useAsyncEffect';
import { useWasChanged } from '../../customHook/usePrevious';

const omitCellKeys = 'value getCellProps render'.split(' ');
export const useSelectEdit = ({
  options,
  placeholder,
  name,
  state,
  changeState,
  error,
  width,
  height,
  validate,
  hint,
  message,
  isDisabled,
  customClick,
  bottom,
  ...rest
}) => {
  const value = options?.find?.((x) => x?.value == state);
  const ref = useRef();
  const wasChangedFn = useCallback(
    function (cur, old) {
      return cur == null && !_.isEqual(cur, old) && options?.length;
    },
    [options]
  );
  const shouldResetValue = useWasChanged(value, wasChangedFn);
  useEffect(() => {
    if (shouldResetValue) {
      ref.current?.clearValue?.();
    }
  }, [shouldResetValue]);
  // Подписываюсь на изменение cell.value. Т.к. мы в режиме редактирования, cell.value меняться не будет
  useEffect(
    function initValidationOnTable() {
      if (rest?.hasOwnProperty('value')) {
        let _error = validate?.(state);
        if (_error) {
          changeState(state, _error);
        }
      }
    },
    [rest?.value]
  );
  return renderInput(
    <Selector
      {...rest}
      options={options}
      onChange={(opt) => {
        const _value = opt?.value;
        const err = validate?.(_value);
        changeState(_value, err);
      }}
      placeholder={placeholder}
      width="100%"
      height="100%"
      value={value}
      isMulti={false}
      marginBottom={0}
      inputRef={ref}
      isDisabled={isDisabled}
      message={message}
      customClick={customClick}
      bottom={bottom}
    />,
    {
      hint,
      focused: !!hint,
      error,
      width,
      height,
    }
  );
};

/**
 *
 * @param RControl {JSX.Element}
 * @param width
 * @param height
 * @param focused
 * @param hint
 * @param error
 * @return {Element}
 */
function renderInput(RControl, { width, height, focused, hint, error, key }) {
  const rc_style = {
    height: `calc(0.70 * ${height})`,
    // width: `calc(${width} - var(--sadr-font-size-4))`
    width,
  };
  const hintStyle = {
    height: `calc(0.3 * ${height})`,
    width: rc_style.width,
  };

  return (
    <div className="d-flex flex-column flex-nowrap edit-input-container" style={{ width, height }} key={key}>
      <div className="d-flex mx-auto justify-content-center align-items-center" style={rc_style}>
        {RControl}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center" style={hintStyle}>
        {error && <span className="error-text">{error}</span>}
        {!error && focused && hint && <span className="hint-text">{hint}</span>}
      </div>
    </div>
  );
}

export function useMaskedInput({
  state,
  changeState,
  error,
  width,
  height,
  mask,
  valueToStr,
  getStateFromChange,
  locateMe,
  coord,
  ...rest
}) {
  const value = valueToStr(state);

  const [focused, setFocused] = useState(false);
  const [hint, setHint] = useState();
  let inputRef = useRef();

  // мягкая проброска значения в компонент
  useEffect(() => {
    if (inputRef.current) simulateOnChange(inputRef.current, value);
  }, [value]);

  function onChange(arr) {
    const { value, err } = getStateFromChange(arr);
    setHint(err);
    const actualError = err && !_.isEmpty(value) ? err : null;
    changeState(value, actualError);
  }

  return renderInput(
    <div
      id={locateMe ? 'cord-locate-container' : 'cord-container'}
      className="d-flex flex-row flex-nowrap w-100 h-100 align-items-center justify-content-center bg-transparent"
    >
      <MaskedInput
        {..._.omit(rest || {}, omitCellKeys)}
        name={locateMe ? 'cord-locate' : 'cord'}
        inputClasses="m-0 w-100 h-100"
        className="w-100 h-100"
        defaultValue={value}
        mask={mask}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={onChange}
        inputRef={inputRef}
        coord={coord}
      />
      {/* {locateMe && (
        <button
          id="locateme"
          className="custom-button-edit my-auto locate-me-button"
          onClick={() => locateMe?.(inputRef.current)}
        >
          <i className="fa-solid fa-location-dot" />
        </button>
      )} */}
    </div>,
    {
      error: error && hint,
      hint: !error && hint,
      width,
      height,
      focused,
    }
  );
}

export const useCordEdit = ({ hint = 'Формат: DDD.DDDDDD', ...args }) => {
  /*** @param input {HTMLInputElement}*/
  function locateMe(input) {
    if (!window.navigator.geolocation) return console.log('Geolocation is switched off');

    window.navigator.geolocation.getCurrentPosition((pos) => {
      const value = { lat: pos.coords.latitude, lon: pos.coords.longitude };
      const strValue = cordsProps.valueToStr(value);
      simulateOnChange(input, strValue);
    });
  }

  const cordsProps = {
    mask: '999.999999999999999 999.999999999999999',
    hint,
    valueToStr: (cord) => `${(cord?.lat + '').padEnd(18, ' ')} ${(cord?.lon + '').padEnd(18, ' ')}`,
    getStateFromChange: (arr) => {
      // Исключаем пустое значение когда контрол только загрузился
      if (args?.hasOwnProperty('value') && !args?.value && arr.every((x) => !x.trim())) return { value: args.value };

      arr = arr
        .map((x) => x.trim())
        .filter(Boolean)
        .map((x) => +x)
        .filter(Number.isFinite);
      const result = { value: {}, err: null };
      if (arr.length == 4) {
        result.value = {
          lat: +`${arr[0]}.${arr[1]}`,
          lon: +`${arr[2]}.${arr[3]}`,
        };
      } else {
        result.err = hint;
        if (arr.length) result.value = arr;
      }
      return result;
    },
    locateMe,
  };
  return useMaskedInput(Object.assign(cordsProps, args, { coord: true }));
};
export const useTrackEdit = (args) =>
  useTextAreaEdit(
    Object.assign(
      {
        valueToStr: (s) => {
          return s?.map(({ lat, lon }) => lat + ',\n' + lon).join(';\n\n');
        },
        getStateFromChange: (text) => {
          const err = 'Формат: широта, долгота; ...',
            value = [];
          for (let rawCord of ('' + text)
            .split(';')
            .map((x) => x.trim())
            .filter(Boolean)) {
            const arr = rawCord
              .split(',')
              .map((x) => x.trim())
              .filter(Boolean)
              .map((x) => +x)
              .filter(Number.isFinite);
            if (arr.length != 2) return { err };
            value.push({ lat: arr[0], lon: arr[1] });
          }
          if (!value.length && text.trim()) {
            return { err };
          }
          return { value };
        },
      },
      args
    )
  );

export function useNumberEdit({ validate, state, changeState, error, width, height, hint, isDisabled, ...rest }) {
  const [focused, setFocused] = useState(false);
  let inputRef = useRef();

  useEffect(() => {
    if (!rest?.hasOwnProperty('value')) return;

    if (inputRef.current) {
      // мягкая проброска значения в компонент
      simulateOnChange(inputRef.current, rest.value);
    } else {
      // Изначальная валидация данных
      let _error = validate?.(state);
      if (_error) changeState(state, _error);
    }
  }, [rest?.value]);

  function onChange(e) {
    const val = e.target.value ? +e.target.value : undefined;
    const err = validate?.(val);
    changeState(val, err);
  }

  // return renderInput(
  //   <Input
  //     {..._.omit(rest || {}, omitCellKeys)}
  //     _ref={inputRef}
  //     type="number"
  //     width="100%"
  //     height="100%"
  //     margin={0}
  //     defaultValue={state}
  //     onBlur={(e) => {
  //       setFocused(false);
  //     }}
  //     onFocus={() => setFocused(true)}
  //     onChange={onChange}
  //     isDisabled={isDisabled}
  //   />,
  //   { error, hint, focused, width, height }
  // );

  return <Input
    {..._.omit(rest || {}, omitCellKeys)}
    _ref={inputRef}
    type="number"
    width="100%"
    height="100%"
    margin={0}
    defaultValue={state}
    onBlur={(e) => {
      setFocused(false);
    }}
    onFocus={() => setFocused(true)}
    onChange={onChange}
    isDisabled={isDisabled}
    messageError={error}
  />
}

export function useTextEdit({ validate, state, changeState, error, width, height, hint, ...rest }) {
  const [focused, setFocused] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (!rest?.hasOwnProperty('value')) return;

    if (ref.current) {
      // мягкая проброска значения в компонент
      simulateOnChange(ref.current, rest.value);
    } else {
      // Изначальная валидация данных
      let _error = validate?.(state);
      if (_error) changeState(state, _error);
    }
  }, [rest?.value]);

  function onChange(e) {
    const val = e.target.value;
    const err = validate?.(val);
    changeState(val, err);
  }

  // return renderInput(
  //   <Input
  //     {..._.omit(rest || {}, omitCellKeys)}
  //     _ref={ref}
  //     type="text"
  //     width="100%"
  //     height="100%"
  //     margin={0}
  //     defaultValue={state}
  //     onBlur={() => setFocused(false)}
  //     onFocus={() => setFocused(true)}
  //     onChange={onChange}
  //   />,
  //   { error, hint, focused, width, height }

  return <Input
    {..._.omit(rest || {}, omitCellKeys)}
    _ref={ref}
    type="text"
    width="100%"
    height="100%"
    margin={0}
    defaultValue={state}
    onBlur={() => setFocused(false)}
    onFocus={() => setFocused(true)}
    onChange={onChange}
    messageError={error}
  />;
}

export function useTextAreaEdit({ state, changeState, error, valueToStr, width, height, getStateFromChange, ...rest }) {
  const value = valueToStr(state);

  const [focused, setFocused] = useState(false);
  const [hint, setHint] = useState();

  function onChange(e) {
    const { value, err } = getStateFromChange(e.target.value);
    setHint(err);
    changeState(value, err && !_.isEmpty(value) ? err : null);
  }

  return renderInput(
    // Нужно чтобы убрать отступ у textarea
    <div id="custom-area-edit-container" className="w-100 h-100">
      <textarea
        {..._.omit(rest || {}, omitCellKeys)}
        className={`custom-textarea m-0 w-100 h-100 input-padding`}
        defaultValue={value}
        onChange={onChange}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        name="custom-area-edit"
        id="custom-area-edit"
      />
    </div>,
    { error, hint, width, height, focused, key: rest?.key }
  );
}

export function useLoader() {
  // Получаем данные о процессе
  let loader = useSelector((state) => state.loader);

  return {
    isLoading: loader?.isLoading,
    isSuccess: !loader?.isLoading && loader?.isSuccess,
    isFailed: !_.isEmpty(loader) && !loader.isSuccess,
  };
}

/**
 * Возвращает название рельсовой цепи. Нужно указать станцию и ID РЦ. Сервер подгрузит значения по станциям, если ранее не сделал этого
 * @return {function(string, string): {isLoading: boolean, name: string}}
 */
export function useRailChainPrettyName() {
  const dispatch = useDispatch();
  const { railChainsPerStation } = useSelector((state) => state?.options);
  const [requested, setRequested] = useState(new Set());
  const asyncActions = useMemo(
    () => Array.from(requested).map((x) => () => {
      if (Number.isFinite(+x)) {
        dispatch(initOptionsRailChainsFromServer(x))
      }
    }),
    [requested]
  );
  useAsyncFetch(asyncActions, [requested]);
  const getPrettyName = useCallback(
    (station, id) => {
      if (railChainsPerStation?.hasOwnProperty(station))
        return { name: railChainsPerStation[station].find((x) => x.value == id)?.label };

      if (!requested.has(station)) {
        requested.add(station);
        setRequested(_.cloneDeep(requested));
      }
      return { isLoading: true };
    },
    [railChainsPerStation, requested]
  );
  return getPrettyName;
}

/**
 * @typedef {Object} RailChain
 * @property {number} id - ID рельсовой цепи
 * @property {number} road - ID дороги
 * @property {number} staion - ID станции / перегона
 * @property {[number, number]} alertLengths - Длина участка оповещения [ПЧ, ЭЧ]
 * @property {string} object - Имя объекта
 * @property {string} rail_chain_name - Имя рельсовой цепи
 * @property {Coordinate} coordinate - координаты концов РЦ
 * @property {Location[]} track - возможный трэк РЦ (кривая)
 */

export function useIsEditing() {
  let loc = useLocation();
  let navigate = useUrlNavigate();
  const isEditing = useMemo(() => {
    let url = new URL(window.location.href);
    return url.searchParams.get('editing') == 'true';
  }, [loc.search]);
  const setEditing = useCallback(
    (editing = true) => {
      navigate((u) => {
        if (editing) u.searchParams.set('editing', 'true');
        else u.searchParams.delete('editing');
      });
    },
    [navigate]
  );
  // При размонтировании компонента убираем статус редактирования
  useEffect(() => () => setEditing(false), []);
  return { isEditing, setEditing };
}

export const renderCords =
  (getLinkFromLoc) =>
    ({ cell: { value: loc } }) => {
      const locations = Array.isArray(loc) ? loc : [loc];
      const first = locations[0];
      if (_.isEmpty(locations) || (locations.length == 1 && _.isEmpty(locations[0]))) return <span className="number-cell">-</span>;
      return !first?.lat || !first?.lon ? (
        <span className="number-cell">-</span>
      ) : (
        <a href={getLinkFromLoc(loc).toString()} target="_blank" rel="noreferrer">
          <div className="d-flex flex-column flex-nowrap align-items-start container mx-auto">
            <span className="number-cell">{first.lat}</span>
            <span className="number-cell">
              {first?.lon}
              {locations.length > 1 ? '...' : ''}
            </span>
          </div>
        </a>
      );
    };

/**
 * Возвращает текущую инфокарту
 * @return {Infocard}
 */
export const useInfocard = () => {
  const params = useParams();
  const source = useSelector((s) => s.infocards.items);
  return useMemo(() => {
    let result = {};
    if (params?.station && source?.length) {
      Object.assign(
        result,
        source.find((x) => x.station == params.station)
      );
    }
    return result;
  }, [params?.station, source]);
};

/** @return {AdjustmentObj & {railChainName: string, stationName: string}}*/
export const useAdjustment = () => {
  let getRCPrettyName = useRailChainPrettyName();
  let getStationName = useGetStationName();
  const { adjustment } = useParams() || {};
  /** @type {AdjustmentObj[]}*/
  const source = useSelector((s) => s.infocards.adjustments);
  const adjustmentObj = useMemo(() => source?.find((x) => x.rail_chain == adjustment), [source, adjustment]);
  const prettyName = useMemo(() => {
    if (!adjustmentObj) return;
    const { name, isLoading } = getRCPrettyName(adjustmentObj.station, adjustmentObj.rail_chain);
    return isLoading ? 'Загрузка...' : name;
  }, [adjustmentObj, getRCPrettyName]);

  return useMemo(() => {
    if (!adjustmentObj) return {};
    return Object.assign(
      {
        railChainName: prettyName,
        stationName: getStationName(adjustmentObj.station),
      },
      adjustmentObj
    );
  }, [prettyName, adjustmentObj]);
};

function useGetPrettyName(key) {
  const opts = useSelector((state) => state?.options);
  const desiredOptions = useMemo(() => opts?.[key] || [], [opts, key]);
  return useCallback(
    (id) => {
      const opt = desiredOptions.find((x) => x.value == id);
      return opt ? opt.label : id;
    },
    [desiredOptions]
  );
}

/**
 * Возвращает красивое имя станции
 * @return {function(number | string): string}
 */
export const useGetStationName = () => useGetPrettyName('stations');
/**
 * Возвращает красивое имя дороги
 * @return {function(number | string): string}
 */
export const useGetRoadName = () => useGetPrettyName('roads');
/**
 * Возвращает красивое имя станции / перегона
 * @return {function(number | string): string}
 */
export const useGetDepartmentName = () => useGetPrettyName('departments');

import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../styles/BrigadeAddDevices.css';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import CustomButton from '../CustomElements/CustomButton';
import Input from '../CustomElements/Input';
import Table from '../Table/Table';
import {
  emitBrigadeCompositionAddDevice,
  emitBrigadeCompositionRemoveDevice,
  emitBrigadeComposition,
} from '../../redux/actions/brigades.action';
import {
  listenBrigadeCompositionAddDevice,
  removeErrors,
  listenBrigadeCompositionRemoveDevice,
} from '../../redux/actions/brigades.action';

/**
 * Компонент для добавления новых устройств в бригаду.
 */
function BrigadeAddDevices({ currentBrigade, closeModal, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение данных о бригадах из Redux store.
  const { brigadeComposition, errors } = useSelector((state) => state.brigadesState);

  // Состояния для управления формой и таблицей
  const [id, setId] = useState('');
  const [error, setError] = useState();
  const [disabledBtnAdd, setDisabledBtnAdd] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [successAdd, setSuccessAdd] = useState(false);

  //Обработка изменений в поле ввода.
  const onChange = (event) => {
    setId(event.target.value);
    setError('');
    dispatch(removeErrors());
  };

  const handleCloseForm = () => {
    // Сбрасываем состояния
    setError();
    setId('');
    dispatch(removeErrors());
    setDataTable([]);
    setSuccessAdd(false);
    // Закрываем модальное окно
    closeModal();
  };

  //Обработка добавления устройства в таблицу.
  const handleButtonClickAdd = () => {
    const payload = { brigadeId: currentBrigade.id, deviceId: id };
    dispatch(emitBrigadeCompositionAddDevice(payload));
    setSuccessAdd(true);
  };

  useEffect(() => {
    // Проверка устройства, если пришла ошибка записываем ее в стейт для отображения
    if (errors?.result) {
      setError(errors?.reason);
      setSuccessAdd(false);
      return;
    }
  }, [errors]);

  useEffect(() => {
    const device = brigadeComposition.find((device) => device.id === id);
    // Добавление устройства в таблицу
    if (device && successAdd) {
      setDataTable((prev) => [...prev, device]);
      setSuccessAdd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brigadeComposition]);

  // обработчик подтверждения
  const handleButtonClickConfirm = () => {
    handleCloseForm()
    //отправляем событие чтобы получить состав бригады
    dispatch(emitBrigadeComposition({ brigadeId: currentBrigade.id }));
  };

  //Удаление устройства из таблицы.
  const handleButtonClickRemove = (id, dataTable) => {
    //отправляем событие чтобы исключить ИУ из  бригады
    const payload = { brigadeId: currentBrigade.id, deviceId: id };
    dispatch(emitBrigadeCompositionRemoveDevice(payload));

    const newData = dataTable.filter((item) => item.id !== id);
    setDataTable(newData);
  };

  // Активация кнопки добавления при вводе ID
  useEffect(() => {
    setDisabledBtnAdd(!id);
  }, [id]);

  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: t?.tableHeaderType,
        accessor: 'type',
      },
      {
        Header: t?.tableHeaderIdDevise,
        accessor: 'id',
      },
      {
        Header: t?.tableHeaderAction,
        Cell: ({ row }) => (
          <div className="">
            <CustomButtonDelete
              margin="0  var(--sadr-font-size-5) "
              onClick={() => {
                handleButtonClickRemove(row?.original?.id, dataTable);
              }}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTable, t]
  );

  // Подготовка элемента для отображения ошибки
  const labelBottom = (
    <div className={`${error ? 'visible-brigade-add-device-error' : 'hidden-brigade-add-device-error'}`}>
      <img className="brigade-add-device_img" src="/image/error.svg" alt="Иконка" />
      {error}
    </div>
  );

  useEffect(() => {
    //слушаем событие 'brigade_add_reply'
    dispatch(listenBrigadeCompositionAddDevice());

    //слушаем событие 'brigade_remove_reply'
    dispatch(listenBrigadeCompositionRemoveDevice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return ()=> {
      handleCloseForm()
    }
  }, []);

  return (
    <div className="brigade-add-device">
      <CustomTitle titleText={t?.addDeviceTitle} />
      <div className="brigade-add-device_content">
        <div>
          <div className="brigade-add-device_input-container">
            <Input
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              placeholder={t?.inputPlaceholder}
              onChange={onChange}
              value={id}
              label={t?.inputLabel}
              name="id"
              labelBottom={labelBottom}
              margin="0 var(--sadr-font-size-20) 0 0"
              mask="999999"
            />
            <CustomButton
              text={t?.addButton}
              colorText="#FFFFF"
              onClick={() => handleButtonClickAdd()}
              color={disabledBtnAdd ? '#ADADAD' : ''}
              disabled={disabledBtnAdd}
              width="var(--sadr-font-size-280)"
              height="var(--sadr-font-size-60)"
              margin="0"
            />
          </div>
        </div>
        {dataTable.length > 0 && <Table data={dataTable} columns={columns} />}
      </div>
      <div className="brigade-add-device_btn-container">
        <CustomButton
          text={t?.confirmButton}
          colorText="#FFFFF"
          onClick={() => handleButtonClickConfirm()}
          color={dataTable.length > 0 ? '' : '#ADADAD'}
          disabled={dataTable.length > 0 ? false : true}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
        />
      </div>
    </div>
  );
}

export default BrigadeAddDevices;

import '../../styles/CustomSwitch.css';


const CustomSwitch = ({label,isActive, onToggle, disabled, activeText, inactiveText }) => {

  const handleClick = () => {
    if (!disabled) {
      onToggle();
    }
  };
  return (
    <div>
      <div className="switch-container">
        <label className="switch-label">{label}</label>
        <div onClick={handleClick} className={`switch ${isActive ? '' : 'active'}${disabled ? 'disabled' : ''}`}>
          <div className={`switch-circle ${isActive ? '' : 'active'}`}>{isActive ? activeText : inactiveText}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomSwitch;

/**
 * Проверяет валидность ID устройства.
 * Валидный ID устройства должен содержать ровно 6 символов, где первый символ соответствует типу устройства.
 * 
 * @param {string} id - ID устройства для проверки.
 * @param {string} typeId - Ожидаемый тип устройства, представленный первым символом ID.
 * @returns {boolean} Возвращает true, если ID устройства валиден, иначе false.
 */
export function isValidDeviceID(id, typeId) {
    // Преобразуем typeId в строку для сравнения
    const typeIdString = String(typeId);
  // Проверяем, совпадает ли первый символ id с типом устройства
  if (id.charAt(0) !== typeIdString || id.length !== 6) {
    console.error("Указан невалидный ID устройства");
    return false;
  }
  
  return true;
}

/**
 * Преобразует массив объектов координат УИППС в массив объектов маркеров.
 * 
 * @param {Array<{lat: number, lon: number, id: number}>} input Массив объектов координат.
 * @param {boolean} kuobz Флаг, определяющий тип маркера и содержимое всплывающего окна.
 * @returns {Array<{position: [number, number], type: string, popupContent: string}>} Массив объектов маркеров.
 */
export const transformsUippsCoords = (input, kuobz) => {
  // Проверяем, является ли входной параметр массивом
  if (!Array.isArray(input)) {
    return [];
  }

  return input.reduce((acc, { lat, lon, id }) => {
    // Проверяем, являются ли lat и lon числами
    if (typeof lat === 'number' && typeof lon === 'number') {
      acc.push({
        position: [lat, lon],
        type: kuobz ? 'blue' : 'red', // Определяем тип маркера в зависимости от значения kuobz
        popupContent: kuobz ? `КУОБЗ № ${id}` : `УИППС № ${id}`, // Определяем текст всплывающего окна в зависимости от значения kuobz
      });
    }
    return acc;
  }, []);
};

/**
 * Создает и запускает процесс скачивания файла.
 * @param {Blob} blob - Бинарные данные файла, которые нужно скачать.
 * @param {string} filename - Имя файла, под которым он будет сохранен на устройстве пользователя.
 */
export function downloadFile(blob, filename) {
  // Создает URL, который указывает на объект данных (Blob), созданный из бинарных данных файла
  const downloadUrl = window.URL.createObjectURL(blob);

  // Создает новый элемент <a> (ссылку) в документе
  const link = document.createElement('a');

  // Устанавливает атрибут href ссылки на созданный URL, по которому можно скачать файл
  link.href = downloadUrl;

  // Устанавливает атрибут download для ссылки, задавая имя файла, которое будет использоваться при сохранении файла
  link.setAttribute('download', filename);

  // Добавляет созданный элемент ссылки в тело документа, чтобы можно было по нему кликнуть
  document.body.appendChild(link);

  // Имитирует клик по ссылке, что приводит к началу загрузки файла
  link.click();

  // Удаляет элемент ссылки из тела документа после клика, тем самым очищая интерфейс
  link.parentNode.removeChild(link);

  // Освобождает созданный URL-адрес, чтобы браузер мог освободить ресурсы, связанные с ним
  window.URL.revokeObjectURL(downloadUrl);
}

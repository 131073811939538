import '../../styles/Select.css';
import React from 'react';
import Select, { components } from 'react-select';

const CustomOption = (props) => {
  return (
    <components.Option {...props} title={props.label}>
      {props.label}
    </components.Option>
  );
};

// Компонент для опции
const OptionСircle = (props) => {
  const radiobutton = '/image/radiobutton.svg';
  const radiobuttonSelected = '/image/radiobuttonSelected.svg';
  return (
    <components.Option {...props}>
      <img className="option-image" src={props.isSelected ? radiobuttonSelected : radiobutton} alt="icon" />
      {props.label}
    </components.Option>
  );
};

// Компонент для опции
const OptionSquare = (props) => {
  const check = '/image/check.svg';
  const checkSelected = '/image/checkSelected.svg';
  return (
    <components.Option {...props}>
      <img className="option-image" src={props.isSelected ? checkSelected : check} alt="icon" />
      {props.label}
    </components.Option>
  );
};

const CustomMenuList = (props) => (
  <components.MenuList {...props}>
    <div className="custom-menu-list">{props.children}</div>
  </components.MenuList>
);

function Selector({
  options,
  onChange,
  customClick = () => {},
  placeholder = 'Выберите',
  width = 'var(--sadr-font-size-320)',
  height = 'var(--sadr-font-size-60)',
  isMulti = true,
  isDisabled = false,
  backlight,
  isClearable = true,
  value,
  label,
  name = '',
  isVisible = true,
  isView,
  optionSquare,
  portalWidth = undefined,
  portalHeightRem = undefined,
  optionText,
  isLoading,
  marginBottom = 'var(--sadr-font-size-20)',
  inputRef,
  message,
  bottom,
}) {
  const handleChange = (choice) => {
    if (choice === null) {
      onChange(choice);
    } else {
      // Обычная обработка выбора
      onChange(choice);
    }
  };

  const styles = {
    // Стили для контрола (главного элемента)
    control: (provided) => ({
      ...provided,
      width, // Ширина контрола
      height, // Высота контрола
      backgroundColor: 'var(--sadr-background)', // Фоновый цвет
      borderRadius: 'var(--sadr-font-size-12)', // Скругление углов
      borderColor: 'var(--sadr-background-secondary)', // Цвет рамки
      boxShadow: '#005695', // Тень
      fontFamily: 'var(--sadr-font-family)', // Шрифт
      fontStyle: 'normal', // Стиль шрифта
      fontWeight: 500, // Начертание шрифта
      fontSize: 'var(--sadr-font-size)', // Размер шрифта
      lineHeight: 'var(--sadr-font-size-20)', // Межстрочный интервал
      color: 'var(--sadr-label-color)',
      '&:hover': {
        border: backlight
          ? 'var(--sadr-font-size-2) solid rgba(210,6,48)'
          : ' var(--sadr-font-size-1)  solid var(--sadr-success)', // Стиль рамки при наведении
      },
      border: backlight ? 'var(--sadr-font-size-2) solid rgba(210,6,48)' : provided.border, // Условный стиль рамки
    }),
    // Стили плейсхолдера
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--sadr-label-color);',
      fontFamily: 'var(--sadr-font-family)',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'var(--sadr-font-size)',
      lineHeight: 'var(--sadr-font-size-20)',
      textAlign: 'left',
      paddingLeft: 'var(--sadr-font-size-10)',
    }),
    // Стили для индикатора выпадающего списка
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--sadr-label-color)', // Цвет индикатора
    }),

    // Стили для кнопки очистки значения
    clearIndicator: (provided, state) => ({
      ...provided,
      display: isView ? 'none' : 'block', // Скрывать кнопку, если isView
      color: '#000', // Цвет кнопки
      '&:hover': {
        color: '#D30630', // Цвет кнопки при наведении
      },
    }),

    // Стили для множественных значений
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--sadr-background)', // Фон множественных значений
      minWidth: 'none', // Минимальная ширина
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#424242',
    }),

    // Стили для одиночной выбранной опции
    singleValue: (provided) => ({
      ...provided,
      color: '#424242', // Установите цвет текста
      textAlign: 'left',
      paddingLeft: 'var(--sadr-font-size-10)',
    }),
    // Стили для контейнера
    container: (provided) => ({
      ...provided,
      width, // Ширина контейнера
      height, // Высота контейнера
      marginBottom, // Отступы контейнера
    }),

    // Стили для заголовка группы (если используется группировка)
    groupHeading: (provided) => ({
      ...provided,
      color: '#9B9B9B', // Цвет заголовка группы
      // color: '#005695', // Цвет заголовка группы
      fontSize: 'var(--sadr-font-size-12)', // Размер шрифта заголовка
    }),

    // Стили для опций в выпадающем списке
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#33a74d' : state.isDisabled ? '#9a9a9a' : '#000', // Условный цвет текста опции
      backgroundColor: state.isSelected ? '' : '', // Условный фон опции
      fontSize: 'var(--sadr-font-size)', // Размер шрифта
      fontWeight: 400, // Начертание шрифта
      textAlign: 'left',
      paddingLeft: state.isSelected && !isView ? 'var(--sadr-font-size-12)' : 'var(--sadr-font-size-24)', // Отступ слева

      // проверить
      overflow: 'hidden', // Добавляем, чтобы текст не выходил за границы
      textOverflow: 'ellipsis', // Добавляем многоточие, если текст не помещается
      whiteSpace: 'nowrap', // Делаем текст в одну строку
      // конец

      '&:hover': {
        backgroundColor: state.isSelected ? '#c8e6fb' : state.isDisabled ? '' : '#ffff', // Фон при наведении
        color: '#33a74d', // Цвет при наведении
      },
      '&:before': {
        content: state.isSelected && !isView ? 'url("/image/checkmark.svg")' : '""', // Иконка выбора
        paddingRight: state.isSelected && !isView ? 'var(--sadr-font-size-5)' : '""', // Отступ справа от иконки
      },
    }),

    // Стили для меню
    menu: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      zIndex: 100001, // Z-индекс меню
      maxHeight: '28.125rem',
    }),

    // Стили для списка опций в меню
    menuList: (provided) => {
      const s = {
        ...provided,
        backgroundColor: 'var(--sadr-background-secondary)',
        whiteSpace: 'nowrap',
        zIndex: 10000, // Z-индекс, чтобы меню отображалось поверх других элементов
        overflowY: 'auto',
      };
      if (portalWidth) s.width = portalWidth;
      return s;
    },

    // Стили для контейнера значения
    valueContainer: (provided, state) => ({
      ...provided,
      flexWrap: 'none', // Запрет переноса текста
      height: '3 var(--sadr-font-size-1) ', // Высота контейнера значения
      overflow: 'hidden', // Скрытие лишнего контента
    }),

    // Стили для разделителя индикатора
    indicatorSeparator: (provided, state) => (state.hasValue && !isView ? { ...provided } : null), // Условное отображение разделителя
  };

  // Условное применение кастомного компонента Option
  const option = optionSquare ? { Option: OptionSquare } : { Option: OptionСircle };
  const optionComponents = isView ? option : { Option: CustomOption };
  const menuList = optionSquare ? { MenuList: CustomMenuList } : {};

  const selectComponents = {
    ...optionComponents,
    ...menuList,
  };
  const actualPortalHeight = Number.isFinite(portalHeightRem)
    ? parseFloat(getComputedStyle(document.documentElement).fontSize) * portalHeightRem
    : undefined;

  return (
    <div
      className={`container-select ${isVisible ? 'visible-select' : 'hidden-select'}`}
      onClick={isDisabled ? () => customClick(name) : undefined}
    >
      {label && (
        <div className="selector-label" htmlFor={name}>
          {label}
        </div>
      )}
      <Select
        ref={inputRef}
        className=""
        id={name}
        inputId={name}
        name={name}
        instanceId={1}
        options={options}
        noOptionsMessage={() => (optionText ? optionText : 'Нет опций')}
        placeholder={placeholder}
        isMulti={isMulti}
        width={width}
        height={height}
        isClearable={isClearable}
        captureMenuScroll={true}
        closeMenuOnSelect={!isMulti}
        closeMenuOnScroll={true}
        isSearchable={true}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        isOptionDisabled={(option) => option.disabled}
        value={value}
        onChange={(choice) => handleChange(choice)}
        components={selectComponents}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        menuPosition="fixed"
        minMenuHeight={actualPortalHeight}
        isLoading={isLoading}
        styles={{
          ...styles, // Распространите уже определенные стили
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
      <span className={`message-text-select ${message ? 'visible' : ''}`} style={{ bottom: bottom || '' }}>
        {message}
      </span>
    </div>
  );
}

export default Selector;

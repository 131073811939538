// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archive-brigade-signals_select-container{
  padding: var(--sadr-font-size-20) var(--sadr-font-size-10) 0;
  position: sticky;
  top: 0;
  /* z-index: 10001; */
  /* padding-left: 20px; */
}
`, "",{"version":3,"sources":["webpack://./src/styles/ArchiveBrigadeSignals.css"],"names":[],"mappings":"AAAA;EACE,4DAA4D;EAC5D,gBAAgB;EAChB,MAAM;EACN,oBAAoB;EACpB,wBAAwB;AAC1B","sourcesContent":[".archive-brigade-signals_select-container{\n  padding: var(--sadr-font-size-20) var(--sadr-font-size-10) 0;\n  position: sticky;\n  top: 0;\n  /* z-index: 10001; */\n  /* padding-left: 20px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBorderCoords,
  clearMapData,
  clearMapIgnores,
  initMapDataFromServer,
} from '../../redux/actions/mapData.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import 'leaflet/dist/leaflet.css';

// Компонент для рендеринга маркеров
const Markers = ({ getIcon, filterInput, formData }) => {
  const map = useMap();
  const dispatch = useDispatch();
  // Состояние для хранения видимых маркеров
  const { mapArray } = useSelector((state) => state.mapData);
  // Локальное состояние для хранения видимых маркеров
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  // Локальное состояние для хранения поискового запроса
  const [searchRequest, setSearchRequest] = useState(filterInput);

  useEffect(() => {
    const markers = mapArray.reduce((acc, { id, lat, lon, type }) => {
      // Проверяем, что объект online и имеет действительные координаты
      if (lat != null && lon != null && lat !== 'nan' && lon !== 'nan' && !isNaN(lat) && !isNaN(lon)) {
        // Добавляем новый маркер в аккумулятор
        acc.push({
          position: [lat, lon],
          type: 'green', // Тип маркера 'green', т.к. online === true
          popupContent: `${type || 'Бригада'} № ${id || 5}`,
        });
      }
      return acc;
    }, []); // Инициализируем аккумулятор пустым массивом
    setVisibleMarkers(markers);
  }, [mapArray]);

  // Локальное состояние для хранения поискового запроса
  useEffect(() => {
    setSearchRequest(filterInput);
  }, [filterInput]);

  // получаем видимые маркеры при поиске или фильтрации или при смене масштаба-координат
  useEffect(() => {
    const bounds = map.getBounds(); // координаты области видимости
    // очистка необходимых стейтов
    dispatch(clearMapIgnores());
    dispatch(clearMapData);
    dispatch(clearFilterConfig());
    // формирование нового запроса
    dispatch(addBorderCoords(bounds));
    dispatch(initMapDataFromServer(formData, bounds, searchRequest));
  }, [searchRequest, map]);

  // Колбек-функция для обновления видимых маркеров
  const updateVisibleMarkers = useCallback(() => {
    const bounds = map.getBounds();
    // Проверка введён ли id длинной 6 или не введён
    const isIdValid = formData?.id?.length === 6 || !formData?.id;
    const isValidType = formData?.typeDevice?.length === 6 || !formData?.typeDevice
    if (isIdValid && isValidType) {
      // Обработка дат
      if (formData.start) {
        formData.start = new Date(formData.start).getTime();
      }
      if (formData.finish) {
        formData.finish = new Date(formData.finish).getTime();
      }

      // Очистка игнорируемых маркеров
      dispatch(clearMapIgnores());
      // Очистка маркеров и области видимости
      dispatch(clearMapData);
      // Добавление в стейт координат области видимости
      dispatch(addBorderCoords(bounds));
      dispatch(clearFilterConfig());
      setSearchRequest('');
      dispatch(initMapDataFromServer(formData, bounds));
    }
  }, [map, formData]); // Зависимости функции

  // Обновление видимых маркеров
  useEffect(() => {
    updateVisibleMarkers();
    map.on('moveend', updateVisibleMarkers);
    return () => {
      map.off('moveend', updateVisibleMarkers);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVisibleMarkers]);

  // Рендеринг видимых маркеров
  return (
    <>
      {visibleMarkers.map((marker, index) => (
        <Marker key={index} position={marker.position} icon={getIcon(marker.type)}>
          <Tooltip>{marker.popupContent}</Tooltip>
        </Marker>
      ))}
    </>
  );
};

export default Markers;

import { INIT_FILES, ADD_FILES, REMOVE_FILES, FILTER_FILES } from "../type";
import { filterStateWithLodash } from "../../utils/filterStateWithLodash";

export function filesReducer(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case INIT_FILES: {
      return payload
    }
    case ADD_FILES: {
      return payload
    }

    case REMOVE_FILES: {
      return payload
    }

    case FILTER_FILES: {
      return filterStateWithLodash(state, payload)
    }

    default:
      return state
  }
}

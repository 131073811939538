import React from 'react';
import '../../styles/FormContainer.css';

function FormContainer({ onSubmit, children, id, ...props }) {
  const childrenArray = React.Children.toArray(children);

  // Элементы для левой и правой колонок

  const leftColumnChildren = childrenArray.filter((child) => child.props.column === 'left');
  const rightColumnChildren = childrenArray.filter((child) => child.props.column === 'right');
  const centerColumnChildren = childrenArray.filter((child) => child.props.column === 'center');

  // Элементы, которые не в колонках и не кнопки, идут перед кнопкой
  const otherChildren = childrenArray.filter((child) => !child.props.column && !child.props.isButton);

  // Кнопка отправки формы
  const buttons = childrenArray.filter(
    (child) => child.props.isButton || child.type === 'submit' || child.props.type === 'submit'
  );

  return (
    <form id={id} className="form-container_form" onSubmit={onSubmit} {...props}>
      <div className="form-container_wrapper">
        <div className="form-container_wrapper_container">{leftColumnChildren}</div>
        {centerColumnChildren && <div className="form-container_wrapper_container">{centerColumnChildren}</div>}
        <div className="form-container_wrapper_container">{rightColumnChildren}</div>
      </div>

      {/* Рендер элементов, которые должны идти перед кнопкой */}
      <div className="form-container_other-children">{otherChildren}</div>

      {/* Рендер кнопки, если она была передана */}
      <div className="form-container_submit-button">{buttons}</div>
    </form>
  );
}

export default FormContainer;

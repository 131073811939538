import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/Archiv.css';
import FilterForArchive from '../Filter/FilterForArchive';
import TableForArchive from '../Table/TableForArchive';

import { initArchiveFilterParams } from '../../redux/actions/archive.action';

function Archiv(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем параметры для фильтрации
  const { archiveFilterParams } = useSelector((state) => state.archive);

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    brigadeId: '',
    deviceId: '',
    roadId: '',
    stationId: '',
    start: '',
    finish: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  //сохраняем параметры фильтра чтобы при переходе на другие страницы они сохранились
  useEffect(() => {
    // при изменении форм даты обновляем состояние в редаксе
    dispatch(initArchiveFilterParams(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  //при открытии страницы обновляем фильтры из редакса если они там были
  useEffect(() => {
    setFormData(archiveFilterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="archive">
      <FilterForArchive initialFormData={initialFormData} formData={formData} setFormData={setFormData} />
      <div className="documentation_content">
        <TableForArchive formData={formData} />
      </div>
    </div>
  );
}

export default Archiv;

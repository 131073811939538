import { React, useMemo } from 'react';
import '../../styles/TableForFiles.css';
import { useSelector, useDispatch } from 'react-redux';
import CustomButtonUpload from '../CustomElements/CustomButtonUpload';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { dateToString } from '../../utils/convertToDate';
import Table from '../Table/Table';
import { downloadFileFromServer } from '../../redux/actions/files.action';
import { newFileFromServer } from '../../redux/actions/files.action';

function TableForFiles(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем все отчеты из стора
  const files = useSelector((state) => state.files);

  const handleButtonClickAction = (file) => {
    const payload = { fileId: file?.id, fileName: file?.name };
    dispatch(downloadFileFromServer(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: 'Название файла',
        accessor: 'name',
        Cell: ({ value }) => <div style={{ textAlign: 'left', paddingLeft: '10px' }}>{defaultCellRender(value)}</div>,
      },
      {
        Header: 'Дата загрузки',
        accessor: 'date',
        Cell: ({ value }) => defaultCellRender(dateToString(value)),
      },
      {
        Header: 'Загрузка',
        accessor: 'link',
        Cell: ({ row }) => (
          <button
            className="custom-button-edit"
            onClick={() => {
              handleButtonClickAction(row.original);
            }}
          >
            <img src="/image/download.svg" alt="Скачать" />
          </button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  return (
    <div className="table-for-files-container">
      <Table data={files || []} columns={columns} showGlobalSearch={true} />
      <div className="table-for-documentation_btn-container">
        <CustomButtonUpload
          className="device-storage-status_button-action"
          text="Добавить новый файл"
          colorText="#FFFFF"
          fileName="file"
          uploadFileFunc={newFileFromServer}
          name="name"
        />
      </div>
    </div>
  );
}

export default TableForFiles;

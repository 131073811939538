import { INIT_ARCHIVE, INIT_ARCHIVE_SESSION, INIT_ARCHIVE_FILTERPARAMS, REMOVE_ARCHIVE_FILTERPARAMS, FILTER_SIGNALS } from "../type";
import { filterSignals } from '../../utils/filterSignals'
export function archiveReducer(state = {}, action) {
  const { type, payload } = action

  switch (type) {
   
    case INIT_ARCHIVE: {
      return {...state, archiveSession: payload}
    }

    case INIT_ARCHIVE_SESSION: {
      return {...state,  archiveBrigadeSession: payload}
    }

    case INIT_ARCHIVE_FILTERPARAMS: {
      return {...state,  archiveFilterParams: payload}
    }

    case REMOVE_ARCHIVE_FILTERPARAMS: {
      return {...state,  archiveFilterParams: payload}
    }

    case FILTER_SIGNALS: {
      const signals = filterSignals(state.archiveBrigadeSession.signals,payload)
       return {...state, archiveBrigadeSession: {...state.archiveBrigadeSession, signals: signals}}
    }


    default:
      return state
  }
}

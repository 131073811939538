import {
  ADD_IGNORE_ID,
  ADD_MAP_COORDS,
  CLEAR_MAP_ARRAY,
  CLEAR_MAP_DATA,
  CLEAR_MAP_IGNORES,
  EDIT_MAP_ARRAY,
  INIT_MAP_ARRAY,
} from '../type';
import axiosInstance from '../../config/axios.config';
import setFilteredData from './filterConfig.action';
import setRequest from '../../utils/createSearchRequest';
import { checkIfItemInFilter, checkVisibleCoords } from '../../utils/mapUtils';

export const initMapData = (mapData) => ({
  type: INIT_MAP_ARRAY,
  payload: mapData,
});

export const addBorderCoords = (coords) => ({ 
  type: ADD_MAP_COORDS,
  payload: coords,
});

export const editMapData = (mapData) => ({
  type: EDIT_MAP_ARRAY,
  payload: mapData,
});

export const addNewIgnoredId = (newId) => ({
  type: ADD_IGNORE_ID,
  payload: newId,
});

export const clearMapData = () => ({
  type: CLEAR_MAP_DATA,
  payload: {
    mapBorders: {},
    mapArray: [],
  },
});

export const clearMapIgnores = () => ({
  type: CLEAR_MAP_IGNORES,
  payload: [],
});

export const clearMapArray = () => ({
  type: CLEAR_MAP_ARRAY,
  payload: [],
});

// получение всех устройств
export const initMapDataFromServer = (formData, visibleCoords, searchString) => async (dispatch, getState) => {
  const uippses = getState().uipps;
  const searchFlag = getState().filterConfig.searchFlag; // флаг если произведён поиск
  const searchData = getState().filterConfig.searchData; // текущее значение поискового запроса

  const searchReaquest = setRequest(formData, searchString, 0, searchFlag, searchData, visibleCoords);
  try {
    if (
      visibleCoords._northEast.lat &&
      visibleCoords._southWest.lat &&
      visibleCoords._northEast.lng &&
      visibleCoords._southWest.lng
    ) {
      const requestTo = uippses.length > 0 ? 'devices/uipps' : 'brigades';
      const result = await axiosInstance(`/api/${requestTo}${searchReaquest}${searchReaquest ? '&' : '?'}`);
      if (result.data && result.status === 200) {
        dispatch(initMapData(result.data));
        // формируется стейт вспомогательных данных для бесконечной подгрузки и перезапросов
        await dispatch(setFilteredData(result.data, searchString, searchReaquest, searchFlag, searchData));
      }
    }
  } catch (error) {}
};

// Проверка координат устройства и добавление новых, если появилось новое устройство
export const lookAfterMapArray = (data, type) => async (dispatch, getState) => {
  const lastRequest = getState().filterConfig.apiRequest;
  const ignoredIds = getState().mapData.ignoredIds;
  const mapBorders = getState().mapData.mapBorders;
  const mapArray = getState().mapData.mapArray;

  if (data && Object.entries(data).length && type) {
    // Проход по всем устройствам/бригадам с сокета и проверка для каждого на видимость и фильтрацию
    Object.entries(data).map(async ([socketId, coords]) => {
      const visible = checkVisibleCoords(mapBorders, coords.lat, coords.lon);
      if (visible) {
        // Если устройство/бригада есть в списке отображаемых, то его обновляем
        if (mapArray.find((item) => Number(item.id) === Number(socketId))
        ) {
          dispatch(
            editMapData({
              id: socketId,
              lat: coords.lat,
              lon: coords.lon,
              type: type,
            })
          );
          return null;
        } else {
          // Если устройство/бригада нет в списке отображаемых, то проверяем на фильтрацию
          const inFilter = await checkIfItemInFilter(socketId, type, lastRequest, ignoredIds);
          if (inFilter) {
            return {
              id: socketId,
              lat: coords.lat,
              lon: coords.lon,
              type: type,
            };
          } else {
            // Если устройство/бригада не в фильтре, то добавляем в список игнорируемых
            dispatch(addNewIgnoredId(socketId));
            return null;
          }
        }
      } else {
        return null;
      }
    });
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-container {
  width: 100%;
  height: var(--sadr-font-size-40);
  position: relative;
  box-shadow: 0 var(--sadr-font-size-5) var(--sadr-font-size-20) var(--sadr-border-shadow);
  border-radius: var(--sadr-font-size-12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
}

.moving-div-time {
  width: var(--sadr-font-size-150);
  height: var(--sadr-font-size-40);
  position: absolute;
  cursor: pointer;
  border-radius: var(--sadr-font-size-12);
  border: 1px solid #7b817c;
  z-index: 2;
  background-color: var(--sadr-background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-12);
  color: #141b15;
  -webkit-user-select: none;
          user-select: none;
}

.time-label {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/DraggableDiv.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gCAAgC;EAChC,kBAAkB;EAClB,wFAAwF;EACxF,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;EAChC,kBAAkB;EAClB,eAAe;EACf,uCAAuC;EACvC,yBAAyB;EACzB,UAAU;EACV,kDAAkD;EAClD,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,cAAc;EACd,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".draggable-container {\n  width: 100%;\n  height: var(--sadr-font-size-40);\n  position: relative;\n  box-shadow: 0 var(--sadr-font-size-5) var(--sadr-font-size-20) var(--sadr-border-shadow);\n  border-radius: var(--sadr-font-size-12);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  user-select: none;\n}\n\n.moving-div-time {\n  width: var(--sadr-font-size-150);\n  height: var(--sadr-font-size-40);\n  position: absolute;\n  cursor: pointer;\n  border-radius: var(--sadr-font-size-12);\n  border: 1px solid #7b817c;\n  z-index: 2;\n  background-color: var(--sadr-background-secondary);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-12);\n  color: #141b15;\n  user-select: none;\n}\n\n.time-label {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

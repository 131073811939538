import { SET_FILTER_STATE } from '../type';

export function filterStateReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTER_STATE:
      return payload

    default:
      return state;
  }
}

import { useState, useEffect } from 'react';
import '../../styles/ResetMesh.css';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { useSelector, useDispatch } from 'react-redux';
import { resetMesh } from '../../redux/actions/brigades.action';

// страница подтверждения сброса Mesh открываеться по клику на кнопку в таблице

function ResetMesh({ handlerConfirm, closeModal, device, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение состояния сброса Mesh из Redux store
  const confirmReset = useSelector((state) => state?.brigadesState);

  // Локальное состояние для управления отображением подтверждения
  const [confirm, setConfirm] = useState(false);
  // Локальное состояние для текста уведомления
  const [text, setText] = useState(t?.processingRequestText);

  const handlerCloseModal = () => {
    closeModal();
    dispatch(resetMesh({}));
  };

  // Эффект, который срабатывает при изменении confirmReset
  useEffect(() => {
    if (confirmReset?.resetMesh === 'ok') {
      setText(t?.resetMeshSuccessText); // Установка текста при успешном сбросе
    }
    if (confirmReset?.resetMesh === 'error') {
      setText(t?.resetMeshErrorText); // Текст при ошибке сброса
    }
  }, [confirmReset, t]);

  // очищаем стейт при размонтировании resetMesh
  useEffect(() => {
    // Функция, которая будет вызвана при размонтировании компонента
    return () => {
      dispatch(resetMesh({}));
    };
  }, [dispatch]);

  return (
    <CustomContainer>
      <CustomTitle titleText={t?.resetMeshTitle} />
      {!confirm && (
        <ConfirmationPage
          confirmText={t?.resetMeshConfirmText?.replace('{device.id}', device.id)}
          confirmButtonText={t?.resetMeshButton}
          cancelButtonText={t?.cancelButtonText}
          onConfirm={() => {
            setConfirm(true);
            handlerConfirm(device.id);
          }} // Функция, которая должна быть вызвана при подтверждении
          onCancel={handlerCloseModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {confirm && (
        <div className="centered-container">
          <h5 className="confirmation-title">{text}</h5>
        </div>
      )}
    </CustomContainer>
  );
}

export default ResetMesh;

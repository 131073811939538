/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../../styles/Modal.css';
import useWindowWidth from '../../customHook/useWindowWidth';

function Modal({ isOpen, onClose, containerSelector, children, width: propWidth, height: propHeight }) {
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  let { width } = useWindowWidth();
  useEffect(() => {
    if (isOpen) {
      const container = document.querySelector(containerSelector);
      if (container) {
        const width = container.offsetWidth;
        setContainerWidth(propWidth || width);
        setContainerHeight(propHeight || null);
      } else {
        setContainerWidth(propWidth || '90%');
        setContainerHeight(propHeight || null);
      }
    }
  }, [isOpen, containerSelector, width, propWidth, propHeight]);

  const modalStyle = {
    width: containerWidth,
    height: containerHeight,
    ...(propWidth && propHeight && {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }),
  };

  useEffect(() => {
    if (isOpen) {
      // При открытии модального окна блокируем скроллинг на заднем фоне
      document.body.classList.add('modal-open');
    } else {
      // При закрытии модального окна разблокируем скроллинг
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  return (
    <div className="modal-overlay" style={{ display: isOpen ? 'block' : 'none' }}>
      <div className="modal-content" style={modalStyle}>
        {onClose && (
          <button
            id="close_modal_button"
            className="close-button"
            onClick={onClose}
            style={{ display: isOpen ? 'block' : 'none' }}
          >
            <span className="close-icon" aria-hidden="true">
              &times;
            </span>
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

export function ModalEscListener({ children }) {
  useEffect(() => {
    function handler(ev) {
      if (ev.key != 'Escape') return;

      const _buttons = document.querySelectorAll('#close_modal_button');
      const buttons = Array.from(_buttons).reverse();
      for (let button of buttons) {
        if (button.checkVisibility({ checkOpacity: true, checkVisibilityCSS: true })) {
          button.click();
          break;
        }
      }
    }

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);

  return children;
}

export default Modal;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomButton from '../CustomElements/CustomButton';
import CustomCheckbox from '../CustomElements/CustomCheckbox';
import '../../styles/BrigadeTransfer.css';
import CustomContainer from '../CustomElements/CustomContainer';
import { listenGetTransferObjectsReply, emittGetTansferObjects } from '../../redux/actions/brigades.action';

function BrigadeTransfer({ handlerConfirm, brigadeId, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получение данных о бригадах из Redux store.
  const { transfer } = useSelector((state) => state.brigadesState);

  // Состояние для текущего выбранного места работы
  const [selectedCheckbox, setSelectedCheckbox] = useState([transfer?.currentRailId]);

  useEffect(() => {}, [selectedCheckbox]);

  const handlerSubmit = () => {
    const payload = { rails: selectedCheckbox, brigadeId: brigadeId };
    handlerConfirm(payload);
  };

  // Обработчик изменения состояния чекбокса
  const handleCheckboxChange = (state) => {
    setSelectedCheckbox((prev) => {
      // Если элемент уже есть в массиве, удаляем его
      if (prev.includes(state)) {
        return prev.filter((item) => item !== state);
      }
      // Иначе добавляем элемент в массив
      else {
        return [...prev, state];
      }
    });
  };

  useEffect(() => {
    dispatch(emittGetTansferObjects({ brigadeId: brigadeId }));
    //слушаем событие 'set_work_mode_reply'
    dispatch(listenGetTransferObjectsReply());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Обновляем состояние selectedCheckbox при изменении transfer?.currentRailId
    setSelectedCheckbox([transfer?.currentRailId]);
  }, [transfer?.currentRailId]);

  return (
    <CustomContainer>
      <CustomTitle titleText={t?.transferTitle} />
      {transfer?.rails?.length === 0 && <span className="brigade-transfer-text alert">{t?.transferNoRails}</span>}
      {transfer?.rails?.length > 0 && (
        <>
          <div className="choose-place-work-container">
            <span className="variable-text">{transfer?.loc_name}</span>
            <span className="variable-text">({transfer?.ord_name})</span>
          </div>
          <span className="brigade-transfer-text">{t?.transferSelectObjects}</span>
          <div className="brigade-transfer-content">
            {/* transfer?.rails? */}
            {transfer?.rails?.map((rail) => (
              <CustomCheckbox
                key={rail?.rail_id}
                label={rail?.rail_name}
                // eslint-disable-next-line eqeqeq
                checked={selectedCheckbox.includes(rail?.rail_id) || rail?.rail_id == transfer?.currentRailId}
                onChange={() => handleCheckboxChange(rail?.rail_id)}
                width="100%"
                height="var(--sadr-font-size-32)"
              />
            ))}
          </div>
        </>
      )}
      <div className="brigade-transfer-container-btn">
        <CustomButton
          text={t?.confirmButtonText}
          onClick={handlerSubmit}
          margin="var(--sadr-font-size-40) 0 var(--sadr-font-size-10)"
          disabled={!selectedCheckbox || transfer?.rails?.length === 0}
        />
      </div>
    </CustomContainer>
  );
}
export default BrigadeTransfer;

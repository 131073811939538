// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  font-size: var(--sadr-font-size-24);
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CloseButton.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb","sourcesContent":[".close-btn {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.close-icon {\n  font-size: var(--sadr-font-size-24);\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Компоненты пользовательского интерфейса
import '../../styles/DeviceDeployment.css';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomTextarea from '../CustomElements/CustomTextarea';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
// Кастомные хуки
import { useButtonValidation } from '../../customHook/useButtonValidation';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import useFilesOptions from '../../customHook/useFilesOptions';
// Утилиты
import { convertToDate } from '../../utils/convertToDate';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
// Действия Redux
import { transferDeviceForOperation } from '../../redux/actions/devices.action';
import { initOptionsFromServer } from '../../redux/actions/options.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsBrigadesFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsEmployeesFromServer,
} from '../../redux/actions/options.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import FileSelector from '../CustomElements/FileSelector';

//компонент формы выдачи устройства в эксплуатацию

function DeviceDeployment({ currentDevice, closeModal, currentStatus, title, isEdit }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const { roads, departments, employees, workers, brigadeNames, deviceStatuses, directorates } = useSelector(
    (state) => state.options
  );

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () =>
      currentStatus
        ? {
          id: currentStatus?.id,
          deviceId: currentDevice.id,
          date: convertToDate(currentStatus.date),
          sectionId: currentStatus.section,
          acceptanceCert: currentStatus?.acceptance_cert,
          acceptanceCertName: currentStatus?.acceptance_cert_name,
          senderId: currentStatus.sender,
          responsibleContact: currentStatus.responsible_contact,
          responsibleId: currentStatus.responsible_id,
          departmentId: currentStatus.department_id,
          road: currentStatus.road,
          receiverEmployeeNumber: currentStatus.responsible_id,
        }
        : {
          deviceId: currentDevice.id,
          date: '',
          brigadeNameId: '',
          acceptanceCert: null,
          senderId: '',
          responsibleId: '',
          responsibleContact: '',
          road: '',
          departmentId: '',
          receiverEmployeeNumber: '',
          directorateId: '',
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const [formData, setFormData] = useState(initialFormData);

  //проверяем были ли изменения в форме (true-да, false-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  //хук для контрола выбора файла
  const acceptanceCertOptions = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId', 'responsibleId', 'brigadeNameId'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsibleId', 'departmentId', 'brigadeNameId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //хук для запроса опций для ФИО ответственного и контроля селекта
  const messageForSelectWorkers = 'Для выбора ФИО выберите подразделение';
  const workersControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsibleId'],
    'departmentId',
    initOptionsEmployeesFromServer,
    messageForSelectWorkers
  );

  //хук для запроса опций для наименования бригад и контроля селекта
  const messageForSelectBrigades = 'Для выбора бригады выберите подразделение';
  const brigadesControl = useOptionsLoader(
    formData,
    setFormData,
    ['brigadeNameId'],
    'departmentId',
    initOptionsBrigadesFromServer,
    messageForSelectBrigades
  );

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, [
    'responsibleContact',
    'receiverEmployeeNumber',
    'departmentId',
    'brigadeNameId',
    'responsibleId',
  ]);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(transferDeviceForOperation(payload, deviceStatuses));
  };
  useSuccessListener(setFormData, initialFormData, closeModal)

  useEffect(() => {
    return () => {
      dispatch(initOptionsFromServer());
    };
  }, []);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'ВЫДАЧА В ЭКСПЛУАТАЦИЮ УСТРОЙСТВА'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      <FormContainer>
        <DateSelector
          column="left"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          label="Дата выдачи в эксплуатацию *"
          selectedDate={formData.date}
          setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
          name="date"
          placeholder="Выберите дату"
          minDate={convertToDate(currentDevice?.last_action_date)}
          maxDate={new Date()}
        />
        <Selector
          column="left"
          options={employees}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select');
          }}
          placeholder="Выберите ФИО"
          label="ФИО выдавшего в эксплуатацию *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={employees?.find((option) => option.value === formData.senderId)}
          isMulti={false}
          name="senderId"
        />
        <FileSelector
          attachmentOptions={acceptanceCertOptions}
          formData={formData}
          setFormData={setFormData}
          column={'left'}
          file={{
            field: 'acceptanceCert',
            name: 'acceptanceCertName',
            label: 'Акт приема-передачи *'
          }}
        />
        <Selector
          column="right"
          options={roads}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'road', selectedOption, 'select');
          }}
          placeholder="Выберите дорогу"
          label="Дорога *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={
            formData.road
              ? roads?.find((option) => option.value === formData.road)
              : roads?.find((option) => option.label === formData.road)
          }
          isMulti={false}
          name="road"
        />

        <Selector
          column="right"
          options={directorates}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
          }}
          placeholder="Выберите дирекцию"
          label="Дирекция *"
          value={
            formData.directorateId ? directorates?.find((option) => option.value === formData.directorateId) : null
          }
          isMulti={false}
          name="directorateId"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          isDisabled={directoratesControl?.isSelectorDisabled}
          message={directoratesControl?.message}
          customClick={directoratesControl?.onSelectorChange}
        />
        <Selector
          column="right"
          options={departments}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
          }}
          placeholder="Выберите подразделение"
          label="Подразделение"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={
            formData.departmentId && departments
              ? departments?.find((option) => option.value === formData.departmentId)
              : null
          }
          isMulti={false}
          name="departmentId"
          isDisabled={departmentsControl?.isSelectorDisabled}
          message={departmentsControl?.message}
          customClick={departmentsControl?.onSelectorChange}
        />
        {/* <Input
          column="right"
          name="receiverEmployeeNumber"
          label="Табельный № ответственного лица"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          isDisabled={true}
          value={formData.receiverEmployeeNumber}
        /> */}
        <Selector
          column="right"
          options={brigadeNames}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'brigadeNameId', selectedOption, 'select');
          }}
          placeholder="Выберите наименование бригады"
          label="Наименование бригады"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={
            formData.brigadeNameId ? brigadeNames?.find((option) => option.value === formData?.brigadeNameId) : null
          }
          isMulti={false}
          name="brigadeNameId"
          isDisabled={brigadesControl?.isSelectorDisabled}
          message={brigadesControl?.message}
          customClick={brigadesControl?.onSelectorChange}
        />
        <Selector
          column="right"
          options={workers}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'responsibleId', selectedOption, 'select');
          }}
          placeholder="Выберите ФИО"
          label="ФИО ответственного лица"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={formData.responsibleId ? workers?.find((option) => option.value === formData.responsibleId) : null}
          isMulti={false}
          name="responsibleId"
          isDisabled={workersControl?.isSelectorDisabled}
          message={workersControl?.message}
          customClick={workersControl?.onSelectorChange}
        />
        <CustomTextarea
          column="right"
          placeholder="Введите контакты ответственного лица"
          type="textArea"
          onChange={(event) => handleUniversalChange(formData, setFormData, event)}
          name="responsibleContact"
          label="Контакты ответственного лица"
          width="100%"
          height="var(--sadr-font-size-150)"
          value={formData.responsibleContact}
        />
        <CustomButton
          onClick={(event) => {
            handlerSubmit(event);
          }}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          text="Выдать устройство"
          disabled={isButtonDisabled || !isFormEdit}
          isButton
        />
      </FormContainer>
    </CustomContainer>
  );
}

export default DeviceDeployment;

import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initFilesFromServer } from '../redux/actions/files.action';

/**
 * Пользовательский хук для загрузки опций файлов и управления состоянием формы в зависимости от чекбокса.
 * @param {function} setFormData - Функция для обновления состояния данных формы.
 * @param {string} certNameField - Имя поля формы для названия файла.
 * @param {string} certField - Имя поля формы для файла.
 * @returns {{isFile: boolean, optionsFiles: [], handleChangeCheckboxFile: function, handleSelectFile: function}} Объект, содержащий состояние чекбокса, опции для селектора, функцию обработчика и функцию установки данных.
 */
function useFilesOptions(setFormData, certNameField, certField) {
  const dispatch = useDispatch(); // Использование функции dispatch из Redux.
  const files = useSelector(state => state.files); // Получение списка файлов из Redux store.

  // Состояние для управления активностью чекбокса.
  const [isFile, setIsFile] = useState(false);

  // Эффект для загрузки файлов с сервера при монтировании компонента.
  useEffect(() => {
    dispatch(initFilesFromServer());
  }, [dispatch]);

  // Функция для изменения состояния чекбокса и сброса данных формы.
  const handleChangeCheckboxFile = () => {
    setIsFile(prev => !prev); // Переключение состояния чекбокса.
    setFormData(prev => {
      const { [certNameField]: _, ...rest } = prev; // Деструктуризация для исключения поля certNameField.
      return {
        ...rest,
        [certField]: '', // Сброс значения поля certField.
      };
    });
  };

  // Функция для установки значения названия файла из выбранного опциона.
  const handleSelectFile = (selectedOption) => {
    setFormData(prev => ({
      ...prev,
      [certNameField]: selectedOption?.label || '',
    }));
  };

  // Создание массива опций для селекта на основе списка файлов.
  const optionsFiles = useMemo(() => files.map(file => ({
    value: file.id,
    label: file.name
  })), [files]);

  // Возвращение объекта с состоянием чекбокса, опциями для селекта, функцией обработчика и функцией установки значения.
  return { isFile, optionsFiles, handleChangeCheckboxFile, handleSelectFile };
}

export default useFilesOptions;

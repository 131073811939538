/* eslint-disable eqeqeq */
import { useMemo } from 'react';
import InputMask from 'react-input-mask';
import Input from '../CustomElements/Input';
import { coordinateFormat, isCoordinateAllowed } from '../../utils/coordinateFormat';

const defaultFormatChars = {
  9: '[0-9]',
  0: '[0-9]+',

  a: '[A-Za-z]',
  z: '[A-Za-z]+',

  '?': '[A-Za-z0-9]',
  '*': '[A-Za-z0-9]+',
};

/**
 *
 * @param inputClasses {string} Классы для стилизации <input />
 * @param name {string} input ID
 * @param mask {string} Маска инпута. 9 - любое число, a - любая буква, * - любое число/буква.
 * @param placeholder {string}
 * @param value {string}
 * @param onChange {(arg: string | string[])=>void} Изменение значения input.
 * В случае маски будет возвращен массив по группам.
 * К примеру 99 - 99 км/ч выдаст 2 элемента массива: первое и второе число.
 * @param label {string} заголовок input
 * @param error {string | boolean | undefined | null} возможная ошибка инпута
 * @return {Element}
 * @constructor
 */
export default function MaskedInput({
  inputClasses,
  name,
  mask = '',
  placeholder = '',
  value,
  onChange,
  label,
  error,
  inputRef,
  coord,
  ...rest
}) {
  /** @type {{length: number, from: number}[]}*/
  const userGroups = useMemo(() => {
    if (!mask || coord) return [{ from: 0, length: mask.length }];

    const regExp = new RegExp(`([${Object.keys(defaultFormatChars).join('')}]+)`, 'g');
    const matches = mask.match(regExp);
    const result = [];
    let index = 0;
    for (let match of matches) {
      let otherIndex = mask.indexOf(match, index);
      result.push({ from: otherIndex, length: match.length });
      index = otherIndex + match.length;
    }
    return result;
  }, [mask]);

  function handleMaskChange(e) {
    if (!mask) return onChange?.(e.target.value);

    const userStr = '' + e.target.value;
    const userInput = userGroups.map(({ from, length }) => userStr.substring(from, from + length));
    return onChange?.(userInput);
    // return onChange?.([e.target.value]);
  }

  function onTabPress(e) {
    if (mask.length && e.key == 'Tab') {
      const cursorAt = e.target.selectionStart;
      const index = userGroups.findIndex((x) => x.from <= cursorAt && cursorAt <= x.from + x.length);
      if (index < 0 || index >= userGroups.length - 1) return;

      let start = userGroups[index + 1].from;
      e.target.selectionStart = start;

      e.preventDefault();
      e.stopPropagation();
    }
  }

  return coord ? (
    <div className="coord-input-container flex-nowrap align-items-start h-100 w-100">
      <Input
        value={value}
        onChange={handleMaskChange}
        name={name}
        width="var(--sadr-font-size-150)"
        height="var(--sadr-font-size-40)"
        isCoordinate={true} // Устанавливаем, что это координатный ввод
        format={coordinateFormat} // Передаем кастомный формат
        isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
      />
      <Input
        value={value}
        onChange={handleMaskChange}
        name={name}
        width="var(--sadr-font-size-150)"
        height="var(--sadr-font-size-40)"
        isCoordinate={true} // Устанавливаем, что это координатный ввод
        format={coordinateFormat} // Передаем кастомный формат
        isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
      />
    </div>
  ) : (
    <div className="d-flex flex-column flex-nowrap align-items-start h-100 w-100">
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <InputMask
        {...rest}
        mask={mask}
        maskChar=" "
        placeholder={placeholder}
        value={value}
        onChange={handleMaskChange}
        onKeyDown={onTabPress}
        formatChars={defaultFormatChars}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            ref={inputRef}
            name={name}
            id={name}
            type="text"
            placeholder={placeholder}
            className={`custom-input ${inputClasses} ${inputProps?.className} ${
              error && value ? 'custom-input-error' : ''
            }`}
            autoComplete="off"
            {...(!mask && { onChange: handleMaskChange, value })}
          />
        )}
      </InputMask>
    </div>
  );
}

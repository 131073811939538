/**
 * Проверяет длину значения.
 * @param {string|number} value - Значение для проверки.
 * @param {number} length - Длина, которую необходимо проверить.
 * @returns {boolean} Возвращает true, если длина значения меньше или равна указанной длине, и false в противном случае.
 */
export function validateValueLength(value, length) {
  // Преобразуем значение в строку, чтобы обработать случай, если значение передано в виде числа
  const strValue = String(value);

  // Проверяем длину строки
  return strValue.length <= length;
}
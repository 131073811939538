import { React, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/AddDevice.css';
import CustomButton from '../CustomElements/CustomButton';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import Selector from '../CustomElements/Selector';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { editStorageStatusFromServer } from '../../redux/actions/statuses.action';
import { convertToDate } from '../../utils/convertToDate';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { getCurrentStatusId } from '../../utils/getCurrentStatusId';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import { load } from '../../redux/actions/loader.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import FileSelector from '../CustomElements/FileSelector';
import useFilesOptions from '../../customHook/useFilesOptions';

// компонент изменения статуса хранение

function EditOperationStatus({ closeModal, currentStatus, currentDevice }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      id: currentStatus?.id,
      deviceId: currentStatus?.device_id,
      fromStatusId: currentStatus?.from_status_id || '',
      note: currentStatus?.note || '',
      isBroken: currentStatus?.broken,
      typeDevice: currentDevice?.type_id,
      date: convertToDate(currentStatus?.date) || '',
      storageId: currentStatus?.storage_id || '',
      receiverId: getCurrentStatusId(currentStatus?.receiver, optionsForSelect?.employees) || '',
      attachment: currentStatus?.attachment || '',
      attachmentName: currentStatus?.attachment_name || '',
      acceptanceCert: currentStatus?.acceptance_cert || '',
      acceptanceCertName: currentStatus?.acceptance_cert_name || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  // Состояние для формы
  const [formData, setFormData] = useState(initialFormData);

  const acceptanceCertOptions = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  //проверяем были ли изменения в форме (true-да, false-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, true);

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
    // Установить флаг открытия формы в true
  }, [initialFormData]);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, ['attachmentName', 'acceptanceCertName', 'attachment']);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editStorageStatusFromServer(payload, formData.deviceId, 'storage'));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = () => {
    setAddSuccess(true);
  };


  return (
    <CustomContainer>
      <CustomTitle titleText={`ИЗМЕНЕНИЕ ДАННЫХ СТАТУСА ${currentDevice?.type} ${currentDevice?.id}`} />
      {addSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите изменить данные статуса?"
          confirmButtonText="Изменить"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата приемки *"
            selectedDate={formData?.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату приемки"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <Selector
            column="right"
            options={optionsForSelect.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            placeholder="Выберите место хранения"
            label="Место хранения *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.storages.find((option) => option.value === formData.storageId)}
            isMulti={false}
            name="storageId"
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            placeholder="ФИО принявшего"
            label="Выберите ФИО принявшего *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.employees.find((option) => option.value === formData.receiverId)}
            isMulti={false}
            name="receiverId"
          />
          <Selector
            column="right"
            options={optionsForSelect?.сondition}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            pl
            placeholder="Не определено"
            label="Состояние *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.isBroken === undefined
                ? optionsForSelect.сondition?.find((option) => option.label === 'Не определено')
                : formData.isBroken === ''
                  ? null
                  : optionsForSelect.сondition?.find((option) => option.value === formData.isBroken)
            }
            isMulti={false}
            name="isBroken"
          />
          <FileSelector
            attachmentOptions={acceptanceCertOptions}
            formData={formData}
            setFormData={setFormData}
            column={'left'}
            file={{ field: 'acceptanceCert', name: 'acceptanceCertName', label: 'Акт приема-передачи *' }}
          />
          <CustomTextarea
            column="right"
            placeholder="Оставьте комментарий"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            label="Комментарий *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-160)"
            value={formData.note}
          />
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сохранить изменения"
            disabled={isButtonDisabled || !isFormEdit}
            onClick={handlerOpenModal}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default EditOperationStatus;

import { convertDateToMs } from './convertToDate'
/**
 * Генерирует строку запроса на основе критериев фильтрации, преобразуя объекты даты в миллисекунды.
 *
 * @param {Object} filterCriteria Объект с критериями фильтрации.
 * @returns {string} Строка запроса.
 */
export function generateQueryString(filterCriteria) {
  const queryParams = Object.entries(filterCriteria)
    // Отфильтровываем пустые значения
    .filter(([, value]) => value)
    // Преобразуем значения дат в миллисекунды и кодируем ключи и значения
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(convertDateToMs(value));
      return `${encodedKey}=${encodedValue}`;
    })
    // Соединяем все пары ключ=значение через &
    .join('&');

  return queryParams;
}


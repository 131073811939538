import { useMemo } from 'react';
import '../../styles/ArciveBrigadeComposition.css';
import Table from '../Table/Table';
import { defaultCellRender } from '../../utils/formatValueForHTML';

/**
 * Компонент для отображения состава бригады на вкладке Архив.
 */

function ArciveBrigadeComposition({ currentSession }) {
  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Тип',
        accessor: 'type',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'ID устройства',
        accessor: 'id',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Статус',
        accessor: 'online',
        Cell: ({ value }) => defaultCellRender(value ? 'Активно' : 'Неактивно'),
      },
      {
        Header: 'Место',
        accessor: 'inZone',
        Cell: ({ value, row }) => defaultCellRender(row.original.type === 'ИУ' ? (value ? 'В зоне' : 'Вне зоны') : '-'),
      },
      {
        Header: 'Состояние',
        accessor: 'viable',
        Cell: ({ value }) => defaultCellRender(value ? 'Исправно' : 'Неисправно'),
      },
      {
        Header: 'Заряд',
        accessor: 'battery',
        Cell: ({ value }) => defaultCellRender(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSession]
  );

  return (
    <div className="arhive-brigade-composition">
      <div className="arhive-brigade-composition_table-container">
        <Table data={currentSession?.composition || []} columns={columns} />
      </div>
    </div>
  );
}

export default ArciveBrigadeComposition;

import React from 'react';
import '../../styles/ViewModifiedRailChains.css';

// Компонент для отображения измененных цепочек рельсов
const ViewModifiedRailChains = ({ data }) => {
  // Функция для отрисовки строк таблицы
  const renderRows = (fields) => {
    // Фильтруем ключи объекта полей, исключая поля с пустыми новыми значениями для 'РЦ'
    return Object.keys(fields)
      .filter((key) => !(key === 'РЦ' && (fields[key].new === '' || fields[key].new === null) ))
      .map((key) => {
        const { old, new: newValue } = fields[key]; // Деструктуризация старого и нового значений для текущего поля
        return (
          <tr key={key} className="table__row">
            <td className="table__cell ">{key}</td>
            <td className="table__cell">
              {key === 'Трек'
                ? old
                  ? `${old.substring(0, 20)}...`
                  : '-'
                : old !== null && old !== undefined && old !== ''
                ? old
                : '-'}
            </td>
            <td className="table__cell">{key === 'Трек' ? 'данные об изменении трека в таблице ниже' : newValue}</td>
          </tr>
        );
      });
  };

  // Функция для отрисовки секций таблицы
  const renderSections = () => {
    // Фильтруем идентификаторы, чтобы исключить пустые секции
    return Object.keys(data)
      .filter((id) => Object.keys(data[id]).length > 0)
      .map((id) => {
        const railChainName = data[id]['РЦ']; // Получаем имя РЦ
        return (
          <React.Fragment key={id}>
            <tr>
              <td colSpan="3" className="table__section-id">
                {id}
                {railChainName &&
                  (railChainName.new ? `, ${railChainName.old} → ${railChainName.new}` : `, ${railChainName.old}`)}
              </td>
            </tr>
            {renderRows(data[id])}
          </React.Fragment>
        );
      });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="table__header">Название поля</th>
          <th className="table__header">Старое значение</th>
          <th className="table__header">Новое значение</th>
        </tr>
      </thead>
      <tbody>{renderSections()}</tbody>
    </table>
  );
};

export default ViewModifiedRailChains;

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeCheckDevices({ closeModal, handlerConfirm, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.checkDevicesTitle} />
      <ConfirmationPage
        // confirmText={`В течение 10 секунд будет подан тестовый сигнал.
        // Проверьте состояние сигнализации и подтвердите исправность.
        // Для завершения проверки закройте модальное окно.`} {t?.checkDevicesConfirmText}
        confirmButtonText={t.checkDevicesConfirmButton}
        cancelButtonText={t.checkDevicesCancelButton}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}
export default BrigadeCheckDevices;

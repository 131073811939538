import Accordeon from '../CustomElements/Accordeon';
import { handleUniversalChange } from '../../utils/formUtils';
import Selector from '../CustomElements/Selector';
import { useFilterControl } from '../../customHook/useFilterControl';
import { useSelector } from 'react-redux';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsSectionFromServer,
} from '../../redux/actions/options.action';
import useOptionsLoader from '../../customHook/useOptionsLoader';

export default function FilterForInfocards({ formData, setFormData, initialFormData }) {
  const optionsForSelect = useSelector((s) => s.options);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate', 'department', 'station'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department'],
    'directorate',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //хук для запроса опций для станций и контроля селекта
  const messageForSelectStation = 'Для выбора станции выберите дорогу';
  const stationControl = useOptionsLoader(
    formData,
    setFormData,
    ['station'],
    'road',
    initOptionsSectionFromServer,
    messageForSelectStation
  );

  return (
    <Accordeon header="ИНФОКАРТЫ" hideAbove={1200} qParamName="infocards_acc" formData={formData} resetFilters={resetFilters}>
      <button
        type="button"
        onClick={resetFilters}
        className={`clear-filter-btn ${showBtnReset ? 'visible' : 'hidden'}`}
      >
        <span className="text-center flex-grow-1">Сбросить все фильтры</span>
        <img className="icon" src="/image/close.svg" alt=""></img>
      </button>
      <div className=" white-background overflow-x-hidden overflow-y-auto padding-sides-15">
        <div className="d-flex flex-column align-items-center">
          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            isMulti={false}
            label="Дорога"
            value={formData.road ? optionsForSelect?.roads?.find((option) => option.value === formData.road) : null}
            name="road"
          />
          <Selector
            options={[]}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'region', selectedOption, 'select');
            }}
            placeholder="Выберите регион"
            label="Регион"
            value={
              formData.region
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.region)
                : null
            }
            isMulti={false}
            name="region"
          />
          <Selector
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция"
            value={
              formData.directorate
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate)
                : null
            }
            isMulti={false}
            name="directorate"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department', selectedOption, 'select');
            }}
            placeholder="Выберите № подразделения"
            isMulti={false}
            label="Подразделение"
            value={
              formData.department
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department)
                : null
            }
            name="department"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.stations}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'station', selectedOption, 'select');
            }}
            value={
              formData.station ? optionsForSelect.stations?.find((option) => option.value === formData.station) : null
            }
            isMulti={false}
            placeholder="Выберите станцию/перегон"
            name="station"
            label="Станция/перегон"
            isDisabled={stationControl?.isSelectorDisabled}
            message={stationControl?.message}
            customClick={stationControl?.onSelectorChange}
          />
        </div>
      </div>
    </Accordeon>
  );
}

// {useSelectEdit({
//   ...baseSize,
//   options: optionsForSelect?.roads,
//   state: road,
//   changeState: (r) => setRoad(r),
//   placeholder: 'Выберите дорогу',
//   name: 'road',
//   label: 'Дорога',
// })}
// {useSelectEdit({
//   ...baseSize,
//   options: optionsForSelect?.regions,
//   state: region,
//   changeState: (r) => setRegion(r),
//   placeholder: 'Выберите регион',
//   name: 'region',
//   label: 'Регион',
// })}
// {useSelectEdit({
//   ...baseSize,
//   options: optionsForSelect?.departments,
//   state: department,
//   changeState: (r) => setDepartment(r),
//   placeholder: 'Выберите № подразделения',
//   name: 'department',
//   label: 'Подразделение',
// })}
// {useSelectEdit({
//   ...baseSize,
//   options: optionsForSelect?.stations,
//   state: station,
//   changeState: (r) => setStation(r),
//   placeholder: 'Выберите № подразделения',
//   name: 'station',
//   label: 'Станция/перегон',
// })}

import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceDeployment.css';
// Компоненты пользовательского интерфейса
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Checkbox from '../CustomElements/Checkbox';
// Утилиты и хуки
import { convertToDate } from '../../utils/convertToDate';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import useFilesOptions from '../../customHook/useFilesOptions';
// Действия Redux
import { load } from '../../redux/actions/loader.action';
import { editOperationStatusFromServer } from '../../redux/actions/statuses.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';
import useSuccessListener from '../../customHook/useSuccessListener';

// компонент  изменения статуса уиппс 'эксплуатация выдача'

function EditOperationSendUippsStatus({ currentStatus, currentDevice, closeModal, title }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      id: currentStatus?.id,
      deviceId: currentStatus?.device_id,
      typeId: currentDevice?.type_id,
      date: convertToDate(currentStatus?.date),
      roadId: currentStatus?.road_id,
      acceptanceCert: currentStatus?.acceptance_cert,
      acceptanceCertName: currentStatus?.acceptance_cert_name,
      directorateId: currentStatus?.directorate_id,
      departmentId: currentStatus?.department_id,
      trainTypeId: currentStatus?.train_type_id,
      trainNumber: currentStatus?.train_number,
      responsibleId: currentStatus?.driver_id,
      responsibleContact: currentStatus?.responsible_contact,
      //
      placement: currentStatus?.placement,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const [formData, setFormData] = useState(initialFormData);

  //хук для контрола выбора файла
  const { isFile, optionsFiles, handleChangeCheckboxFile, handleSelectFile } = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // Состояние для массива обязяательных полей
  const [optionalFields] = useState(['responsibleId', 'responsibleContact', 'trainTypeId', 'trainNumber', 'placement']);

  // Функция для отмены подтверждения и закрытия модального окна
  const closeStatusEditModal = () => setAddSuccess(false);

  // Функция для открытия модального окна подтверждения
  const handlerOpenModal = () => setAddSuccess(true);

  //проверяем были ли изменения в форме
  const isFormEdit = useFormDataChanged(initialFormData, formData, true);

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик клика
  const handlerSubmit = (event) => {
    event.preventDefault();
    handlerOpenModal();
  };

  // отправка данных на сервер при изменении статуса
  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editOperationStatusFromServer(payload, formData.deviceId, 'usage'));
  };

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'roadId',
    initOptionsDirectoratesUippsFromServer,
    messageForSelectDirectorates
  );
  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'ВЫДАЧА В ЭКСПЛУАТАЦИЮ УИППС'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите изменить данные статуса?"
          confirmButtonText="Изменить"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <>
          <FormContainer>
            <DateSelector
              column="left"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              label="Дата выдачи в эксплуатацию *"
              selectedDate={formData.date}
              setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
              name="date"
              placeholder="Выберите дату"
              minDate={convertToDate(currentDevice?.last_action_date)}
              maxDate={new Date()}
            />
            {!isFile && (
              <Input
                column="left"
                placeholder="Выберите файл акта"
                onChange={(event) =>
                  handleUniversalChange(formData, setFormData, event, null, null, null, 'acceptanceCertName')
                }
                onRemove={() =>
                  handleUniversalChange(
                    formData,
                    setFormData,
                    null,
                    'isRemove',
                    null,
                    null,
                    'acceptanceCert',
                    'acceptanceCertName'
                  )
                }
                type="file"
                name="acceptanceCert"
                label="Акт приема-передачи *"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                fileName={currentStatus?.acceptance_cert_name}
                isDisabled={false}
                maxFileSize={20}
              />
            )}
            {isFile && (
              <Selector
                column="left"
                options={optionsFiles}
                onChange={(selectedOption) => {
                  handleUniversalChange(formData, setFormData, null, 'acceptanceCert', selectedOption, 'select');
                  handleSelectFile(selectedOption);
                }}
                placeholder="Выберите файл"
                label="Акт приема-передачи *"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                value={optionsFiles?.find((option) => option.value === formData.acceptanceCert)}
                isMulti={false}
                name="acceptanceCert"
              />
            )}
            <Checkbox
              column="left"
              height={`var(--sadr-font-size-100)`}
              margin={`0px 0px var(--sadr-font-size-5)`}
              width="100%"
              label="Выбрать существующий файл"
              onChange={(newValue) => handleChangeCheckboxFile('isFile', newValue)}
              checked={isFile}
            />
            {!currentStatus?.placement && (
              <Input
                column="left"
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                placeholder="Введите табельный номер"
                label="Табельный № машиниста"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                value={formData?.responsibleId || ''}
                name="responsibleId"
              />
            )}
            {currentStatus?.placement && (
              <Selector
                column="left"
                options={optionsForSelect?.trainTypes}
                onChange={(selectedOption) => {
                  handleUniversalChange(formData, setFormData, null, 'trainTypeId', selectedOption, 'select');
                }}
                placeholder="Выберите тип подвижного состава"
                label="Тип подвижного состава"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                value={
                  formData.trainTypeId
                    ? optionsForSelect?.trainTypes.find((option) => option.value === formData.trainTypeId)
                    : null
                }
                isMulti={false}
                name="trainTypeId"
              />
            )}
            <Selector
              column="right"
              options={optionsForSelect?.roads}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
              }}
              placeholder="Выберите дорогу"
              label="Дорога *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              value={
                formData.roadId ? optionsForSelect?.roads?.find((option) => option.value === formData.roadId) : null
              }
              isMulti={false}
              name="roadId"
            />
            <Selector
              column="right"
              options={optionsForSelect?.directoratesUipps}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
              }}
              placeholder="Выберите дирекцию"
              label="Дирекция *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              value={
                formData.directorateId
                  ? optionsForSelect?.directoratesUipps?.find((option) => option.value === formData.directorateId)
                  : null
              }
              isMulti={false}
              name="directorateId"
              isDisabled={directoratesControl?.isSelectorDisabled}
              message={directoratesControl?.message}
              customClick={directoratesControl?.onSelectorChange}
            />
            <Selector
              column="right"
              options={optionsForSelect?.departments}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
              }}
              placeholder="Выберите подразделение"
              label="Подразделение *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              value={
                formData.departmentId
                  ? optionsForSelect?.departments?.find((option) => option.value === formData?.departmentId)
                  : null
              }
              isMulti={false}
              name="departmentId"
              isDisabled={departmentsControl?.isSelectorDisabled}
              message={departmentsControl?.message}
              customClick={departmentsControl?.onSelectorChange}
            />
            {!currentStatus?.placement && (
              <Input
                column="right"
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                placeholder="Введите контакты"
                label="Контакты машиниста"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                value={formData?.responsibleContact || ''}
                name="responsibleContact"
              />
            )}
            {currentStatus?.placement && (
              <Input
                column="right"
                onChange={(event) => handleUniversalChange(formData, setFormData, event)}
                label="Номер  подвижного состава"
                placeholder="Введите № подвижного состава"
                width="var(--sadr-font-size-420)"
                height="var(--sadr-font-size-60)"
                value={formData?.trainNumber || ''}
                name="trainNumber"
              />
            )}
          </FormContainer>
          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сохранить изменения"
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </>
      )}
    </CustomContainer>
  );
}

export default EditOperationSendUippsStatus;

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeEditDeviceMode({ handlerConfirm, onClose, isKUOBZ, t }) {
  // Явное преобразование isKUOBZ к булевому типу
  const hasKUOBZ = !!isKUOBZ;
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.workModeChangeTitle} />
      {!hasKUOBZ && (
        <ConfirmationPage
          onConfirm={onClose}
          addNew={t?.closeButtonText}
          confirmText={t?.kuobzRequiredText}
        />
      )}
      {hasKUOBZ && (
        <ConfirmationPage
          onConfirm={handlerConfirm}
          confirmText={t?.confirmWorkModeChangeToZoneText}
          onCancel={onClose}
          confirmButtonText={t?.confirmButtonText}
          cancelButtonText={t?.cancelButtonText}
        />
      )}
    </CustomContainer>
  );
}
export default BrigadeEditDeviceMode;

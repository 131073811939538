import AdminDataPannelCheck from './AdminDataPannelCheck';
import AddingDataBlock from './AddingDataBlock';
import '../../styles/AdminDataPanel.css';

function AdminDataPanel() {
  return (
    <section className="admin-data-panel">
      <AdminDataPannelCheck />
      <AddingDataBlock />
    </section>
  );
}
export default AdminDataPanel;

/**
 * Обновляет объект в массиве по ID, заменяя указанные поля новыми значениями.
 * 
 * @param {Array<Object>} state Массив объектов для обновления.
 * @param {Object} newData Объект с новыми данными и ID для поиска.
 * @returns {Array<Object>} Новый массив с обновленными объектами.
 */
export function updateStateBrigade(state, newData) {
  // Проверка на валидность входных данных
  if (!Array.isArray(state)) {
    console.error('Первый параметр должен быть массивом');
    return [];
  }
  if (typeof newData !== 'object' || newData === null) {
    console.error('Второй параметр должен быть объектом');
    return [];
  }

  // Создаем копию исходного массива, чтобы избежать мутации
  const updatedState = [...state];

  // Находим индекс объекта с совпадающим ID
  const itemIndex = updatedState.findIndex(item => item.id === newData.id);

  // Если объект с таким ID найден - обновляем его поля
  if (itemIndex !== -1) {
    // Обновляем только указанные поля в объекте
    updatedState[itemIndex] = { ...updatedState[itemIndex], ...newData };
  }

  return updatedState;
}

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeFinishWork({ closeModal, handlerConfirm, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.finishWorkTitle} />
      <ConfirmationPage
        confirmText={t?.finishWorkConfirmText}
        confirmButtonText={t?.finishWorkConfirmButtonText}
        cancelButtonText={t?.finishWorkCancelButtonText}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}

export default BrigadeFinishWork;

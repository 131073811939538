/**
 * Преобразует массив объектов или строк в формат, подходящий для использования в селекторах.
 * Для объектов: если объект содержит поля 'id' и 'value', 'value' и 'label' в результирующем объекте соответствуют этим полям.
 * В противном случае, 'label' формируется из полей 'surname', 'name', 'patronymic', а 'value' - из поля 'id'.
 * Для строк: и 'value', и 'label' в результирующем объекте соответствуют значению строки.
 *
 * @param {Array<Object|string>} array Массив объектов или строк для преобразования.
 * @returns {Array<{value: string, label: string}>} Преобразованный массив объектов, каждый из которых имеет поля 'value' и 'label'.
 */
export function transformOptionsArray(array) {
  if (!Array.isArray(array)) {
    return [];
  }

  return array.map(item => {
    if (typeof item === 'string') {
      return {
        value: item,
        label: item,
      };
    } else if (item.id && item.value) {
      return {
        value: item.id,
        label: item.value,
      };
    } else {
      return {
        value: item.id,
        label: `${item.surname} ${item.name} ${item.patronymic}`,
        number: item.id, //табельный номер
      };
    }
  });
}


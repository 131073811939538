import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../styles/DepartmentReport.css';
import Table from '../Table/Table';
import CustomButton from '../CustomElements/CustomButton';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeReport(props) {
  const { report } = useSelector((state) => state.documentation);

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'index',
      },
      {
        Header: 'Время начала работ',
        accessor: 'start',
      },
      {
        Header: 'Время окончания работ',
        accessor: 'finish',
      },
      {
        Header: 'Продолжительность работ, ч',
        accessor: 'duration',
      },
      {
        Header: 'Станция/перегон',
        accessor: 'station',
      },
      {
        Header: 'ID виртуальной бригады',
        accessor: 'brigadeId',
      },
    ],
    []
  );

  const handleDownloadClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <CustomContainer id={'report-div'}>
        <CustomTitle titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО БРИГАДЕ'} />{' '}
        <div className="report-header">
          <div className="report-header-div1">
            <span>
              <b>Наименование дирекции:</b> {report?.brigade?.directorateName}
            </span>
            <span>
              <b>Наименование подразделения:</b> {report?.brigade?.departmentName}
            </span>
            <span>
              <b>ФИО/табельный номер ответственного лица:</b> {report?.brigade?.type}
            </span>
          </div>
          <div className="report-header-div1">
            <span>
              <b>Тип бригады:</b> {report?.brigade?.type}
            </span>
            <span>
              <b>Период оказания услуги:</b> {report?.period}
            </span>
          </div>
        </div>
        <Table columns={columns} data={report?.brigade?.data || []} className={'report-table'} hiddenDiv />
        <div className="report-summary">
          <span>
            <b>Объем оказанных услуг:</b> {report?.brigade?.totalHours} часов
          </span>
        </div>
        <div className="report-footer-container">
          <div className="report-footer">
            <span>
              <b>Заказчик: </b> ____________
            </span>
            <span>«______» __________________ 20_____ г.</span>
          </div>
          <div className="report-footer">
            <span className="report-footer-span">
              <b>Исполнитель: </b> ____________
            </span>
            <span>«______» __________________ 20_____ г.</span>
          </div>
        </div>
        <div className="report-btn-container">
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Скачать отчет"
            href={`${process.env.REACT_APP_DOCUMENT}${report?._id}`}
            onClick={handleDownloadClick}
            download
            isButton
          />
        </div>
      </CustomContainer>
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-documentation_container'}>
        <CustomContainer>
          <CustomTitle titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО БРИГАДЕ'} />
          <ConfirmationPage
            addNew="К тексту отчета"
            confirmText="Отчет сформирован!"
            onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
          />
        </CustomContainer>
      </Modal>
    </>
  );
}

export default BrigadeReport;

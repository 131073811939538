import { useState } from 'react';
import { useSelector } from 'react-redux';

import '../../styles/DepartmentReport.css';
import CustomButton from '../CustomElements/CustomButton';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function ReportServises(props) {
  const { report } = useSelector((state) => state.documentation);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const mockData = [
    {
      department: 'ПЧ-2',
      diagnosticsBrigade: 1280,
      maintenanceBrigade: 3100,
    },
    {
      department: 'ПЧ-5',
      diagnosticsBrigade: 1350,
      maintenanceBrigade: 3300,
    },
    {
      department: 'ПЧ-6',
      diagnosticsBrigade: 1220,
      maintenanceBrigade: 3902,
    },
  ];

  const data = {
    brigade: {
      data: [],
      totalHours: 60, // Объем оказанных услуг
    },
    department: {
      data: [],
      totalHoursDiagnostics: 100, // Объем оказанных услуг по ограждению бригад диагностики
      totalHoursRailsMaintenance: 200, // Объем оказанных услуг по ограждению бригад по текущему содержанию пути
    },

    period: '01.01.2023 - 31.01.2023', // Период оказания услуги
    directorateName: 'Куйбышевская дирекция инфраструктуры', // Наименование дирекции
    departmentName: 'ПЧ-11 Самарская дистанция пути', // Наименование подразделения
    number: 87436593476,
  };

  const handleDownloadClick = () => {
    setTimeout(() => setIsOpen(true), 3000);
  };
  return (
    <>
      <CustomContainer id={'report-div'}>
        <CustomTitle
          titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО ДОГОВОРУ №908/ОКЭ-ЦДИ/23/1/1 ОТ 15 ИЮНЯ 2023 ГОДА'}
        />{' '}
        <div className="report-header">
          <div className="report-header-div1">
            <span>
              <b>Наименование дирекции:</b> {data?.directorateName}
            </span>
            <span>
              <b>Наименование службы:</b> {data?.departmentName}
            </span>
          </div>
          <div className="report-header-div1">
            <span>
              <b>Период оказания услуги:</b> {data?.period}
            </span>
          </div>
        </div>
        {/* таблица start */}
        <div className="report-custom-table-container">
          <table className="report-custom-table">
            <thead>
              <tr>
                <th rowSpan={2}>Подразделение</th>
                <th colSpan={2}>Объем оказанных услуг, ч</th>
              </tr>
              <tr>
                <th>Бригады диагностики</th>
                <th>Бригады по текущему содержанию пути</th>
              </tr>
            </thead>
            <tbody>
              {mockData.map((row, index) => (
                <tr key={index}>
                  <td>{row?.department}</td>
                  <td>{row?.diagnosticsBrigade}</td>
                  <td>{row?.maintenanceBrigade}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* таблица end */}
        <div className="report-summary">
          <span>
            <b>Объем оказанных услуг по ограждению бригад диагностики:</b> {data?.brigade?.totalHours} часов
          </span>
          <span>
            <b>Объем оказанных услуг по ограждению бригад по текущему содержанию пути:</b> {data?.brigade?.totalHours}{' '}
            часов
          </span>
        </div>
        <div className="report-btn-container">
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Скачать отчет"
            href={`${process.env.REACT_APP_DOCUMENT}${report?._id}`}
            onClick={handleDownloadClick}
            download
            isButton
          />
        </div>
      </CustomContainer>
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-documentation_container'}>
        <CustomContainer>
          <CustomTitle
            titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО ДОГОВОРУ №908/ОКЭ-ЦДИ/23/1/1 ОТ 15 ИЮНЯ 2023 ГОДА'}
          />
          <ConfirmationPage
            addNew="К тексту отчета"
            confirmText="Отчет сформирован!"
            onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
          />
        </CustomContainer>
      </Modal>
    </>
  );
}

export default ReportServises;

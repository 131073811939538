import React, { useMemo } from 'react';
import CustomButton from './CustomButton';
import { Navigate, Route, Routes, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

// Компонент Tab используется для определения каждой вкладки.
// Он принимает `children`, `path` и `name` как пропсы.
export function Tab({ children }) {
  return children;
}

// Компонент Tabs управляет набором вкладок и маршрутов, связанных с ними.
export function Tabs({ children }) {
  const location = useLocation(); // Получаем текущий URL.
  const navigate = useNavigate(); // Функция для навигации по маршрутам.
  const parentRouterPath = useResolvedPath('.').pathname; // Получаем базовый путь.

  // Фильтруем детей, чтобы убедиться, что они все типа `Tab`.
  const tabs = React.Children.toArray(children).filter((child) => {
    if (child.type !== Tab) {
      console.error('Only `Tab` components should be used as children of `Tabs`.');
      return false;
    }
    return true;
  });

  // Используем useMemo для создания маршрутов на основе вкладок.
  const routes = useMemo(() => {
    return tabs.map((tab) => {
      const { path, children } = tab.props;
      return <Route element={children} path={`${path}/*`} key={path} />;
    });
  }, [tabs]);

  // Определяем дефолтный путь для редиректа при отсутствии конкретного маршрута.
  const defaultPath = tabs[0]?.props?.path;

  return (
    <>
      {/* Заголовок вкладок с кнопками */}
      <div className="border-shadow-padding">
        <div id="tab_header" className="d-flex flex-row flex-nowrap border-shadow justify-content-between position-relative">
          {/* Отображение кнопок для каждой вкладки */}
          {tabs.map(({ props: { name, path } }) => {
            const isActive = location.pathname.includes(`${parentRouterPath}${path}`); // Определяем, активна ли вкладка.

            return (
              <CustomButton
                id={path}
                key={path}
                text={name}
                className={`brigade_button ${isActive ? 'brigade_button--active' : ''}`}
                colorText={isActive ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
                onClick={() => navigate(`${parentRouterPath}${path}`)} // Навигация при клике на кнопку.
              />
            );
          })}
        </div>
      </div>

      {/* Маршрутизация по вкладкам */}
      <Routes>
        {routes}
        {/* Редирект на дефолтный путь, если путь не найден */}
        {defaultPath && <Route path="*" element={<Navigate to={`${parentRouterPath}${defaultPath}`} replace />} />}
      </Routes>
    </>
  );
}
// /* eslint-disable eqeqeq */
// import React, { useMemo } from 'react';
// import CustomButton from './CustomButton';
// import { Navigate, Route, Routes, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

// export function Tab({ children, path, name }) {}

// /**
//  *
//  * @param children {JSX.Element | JSX.Element[]}
//  * @param selectedName
//  * @constructor
//  */
// export function Tabs({ children }) {
//   const loc = useLocation();
//   const navigate = useNavigate();
//   const parentRouterPath = useResolvedPath('.').pathname;

//   React.Children.forEach(children, (child) => {
//     if (child.type.name != 'Tab') throw new Error('You must use "Tab" as children only');
//   });

//   const routes = useMemo(() => {
//     const result = [];
//     const tabs = React.Children.toArray(children);

//     for (let tab of tabs) {
//       const { name, path, children } = tab.props;
//       result.push(<Route element={children} path={path + '/*'} key={path} />);
//     }

//     if (tabs.length) {
//       let pathname = parentRouterPath + tabs[0].props.path;
//       result.push(<Route path="*" key="*" element={<Navigate to={{ pathname }} replace />} />);
//     }

//     return result;
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [children]);

//   return (
//     <>
//       <div className="border-shadow-padding">
//         <div
//           id="tab_header"
//           className="d-flex flex-row flex-nowrap border-shadow justify-content-between position-relative"
//         >
//           {children.map((x) => {
//             const { name, path } = x.props;
//             const isActive = loc.pathname.includes(parentRouterPath + path);

//             function handleClick() {
//               navigate({
//                 pathname: parentRouterPath + path,
//               });
//             }

//             return (
//               <CustomButton
//                 id={path}
//                 key={path}
//                 text={name}
//                 className={`brigade_button ${isActive ? 'brigade_button--active' : ''}`}
//                 colorText={isActive ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
//                 onClick={handleClick}
//               />
//             );
//           })}
//         </div>
//       </div>
//       <Routes>{routes}</Routes>
//     </>
//   );
// }

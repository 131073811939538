import { useState } from 'react';
import '../../styles/Files.css';
import FilterForFiles from '../Filter/FilterForFiles';
import TableForFiles from '../Table/TableForFiles';

function Files(props) {
  const initialFormData = {
    name: '',
    date: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  return (
    <div className="files">
      <FilterForFiles initialFormData={initialFormData} formData={formData} setFormData={setFormData} />
      <div className="files-content">
        <TableForFiles formData={formData} />
      </div>
    </div>
  );
}

export default Files;

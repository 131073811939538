import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
/**
 * Хук для управления состоянием модального окна и текущим статусом устройства.
 * @param {Array} devicesStatus Массив статусов устройства.
 * @param {Object} currentDevice Текущее устройство.
 * @returns {Object} Объект с состояниями и функциями для управления модальным окном.
 */
const useModalWithStatus = (devicesStatuses, currentDevice, setStatusId) => {
  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Состояние, указывающее открыто ли модальное окно
  const [isOpen, setIsOpen] = useState(false);
  // Состояние для хранения текущего статуса устройства
  const [currentStatus, setCurrentStatus] = useState('');
  // Состояние для хранения текущего  устройства
  const [device, setDevice] = useState('');
  // Состояние для управления активным модальным контентом
  const [activeModal, setActiveModal] = useState(null);

  // const dispatch = useDispatch();
  const active = useRef(null);
  // Функция для закрытия модального окна и сброса состояний.
  const closeModal = () => {
    if (loader?.isLoading) return;
    setIsOpen(false);
    active.current = null;
  };

  useEffect(() => {
    setActiveModal(active.current);
  }, []);

  /**
   * Обработчик нажатия кнопки для открытия модального окна и установки текущего статуса.
   * @param {string} buttonName Название кнопки, соответствующее ключу в contentData.
   * @param {number|string} id Идентификатор статуса устройства.
   */
  const handleButtonClick = (buttonName, id, device) => {
    if (device) {
      setDevice(device);
    }
    if (id) {
      if (setStatusId) setStatusId(id);
      // Находим текущий статус устройства по ID
      const status = devicesStatuses?.find((item) => item.id === id);
      if (status) {
        // Добавляем тип устройства к статусу и устанавливаем в состояние
        status.typeDevice = currentDevice?.type;
        setCurrentStatus(status);
      }
    }
    // Устанавливаем активное модальное окно и открываем его
    active.current = buttonName;
    setActiveModal(buttonName);
    setIsOpen(true);
  };

  // Возвращаем состояния и функции из хука
  return {
    isOpen,
    device,
    currentStatus,
    activeModal,
    closeModal,
    handleButtonClick,
  };
};

export default useModalWithStatus;

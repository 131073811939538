import { useState } from 'react';
import FilterForDocumentation from '../Filter/FilterForDocumentation';
import TableForDocumentation from '../Table/TableForDocumentation';
import '../../styles/Documentation.css';

function Documentation(props) {
  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    type: '',
    createDt: '',
    road_id: '',
    directorate_id: '',
    department_id: '',
    section_id: '',
    startDate: '',
    endDate: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  return (
    <>
      <div className="documentation">
        <FilterForDocumentation initialFormData={initialFormData} formData={formData} setFormData={setFormData} />
        <div className="documentation_content">
          <TableForDocumentation formData={formData} />
        </div>
      </div>
    </>
  );
}

export default Documentation;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button-remove {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin: 0;
}

.custom-button-remove:hover {
  transform: scale(1.1);
}

.feather {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.custom-button-remove.disabled {
  pointer-events: none; /* Отключаем взаимодействие */
  transform: none !important; /* Отменяем трансформацию */
}

.custom-button-remove:hover:not(.disabled) {
  transform: scale(1.1); /* Анимация только если кнопка не отключена */
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomButtonRemove.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,eAAe;EACf,sCAAsC;EACtC,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB,EAAE,6BAA6B;EACnD,0BAA0B,EAAE,2BAA2B;AACzD;;AAEA;EACE,qBAAqB,EAAE,6CAA6C;AACtE","sourcesContent":[".custom-button-remove {\n  background-color: transparent;\n  border: none;\n  padding: 0;\n  cursor: pointer;\n  transition: transform 0.2s ease-in-out;\n  margin: 0;\n}\n\n.custom-button-remove:hover {\n  transform: scale(1.1);\n}\n\n.feather {\n  width: 1em;\n  height: 1em;\n  vertical-align: -0.125em;\n}\n\n.custom-button-remove.disabled {\n  pointer-events: none; /* Отключаем взаимодействие */\n  transform: none !important; /* Отменяем трансформацию */\n}\n\n.custom-button-remove:hover:not(.disabled) {\n  transform: scale(1.1); /* Анимация только если кнопка не отключена */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

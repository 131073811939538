import { useMemo, useState } from 'react';
import axios from '../../config/axios.config';
import { useAsyncEffect } from '../../customHook/useAsyncEffect';
import { transformOptionsArray } from '../../utils/transformOptionsArray';
import Selector from './Selector';

function ServerSelector({ url, value, set_value, ...rest }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selected_option = useMemo(() => {
    return value == null || value === ''
      ? null
      : options.find(x => x.value == value);
  }, [value, options]);

  useAsyncEffect(async function load_stations(controller) {
    setLoading(true);
    try {
      if (!url)
        return;

      const resp = await axios.get(url, {
        signal: controller.signal,
        responseType: 'json'
      });
      setOptions(transformOptionsArray(resp.data));
    } catch (e) {
      console.error(`Error during GET ${url}:`, e);
    } finally {
      setLoading(false);
    }

  }, [url]);

  return <Selector width="100%"
                   height="100%"
                   isMulti={false}
                   marginBottom={"var(--sadr-font-size-20)"}
                   value={selected_option}
                   options={options}
                   isLoading={loading}
                   isDisabled={!url}
                   onChange={opt => {
                     const updated = opt?.value;
                     set_value(updated);
                   }}
                   {...rest}
  />;
}

export function StationSelector({ road, station, set_station, ...rest }) {
  return <ServerSelector url={Number.isInteger(road) ? `/api/data/roads/${road}/stations` : null}
                         value={station}
                         set_value={set_station}
                         name="station_selector"
                         placeholder="Выберите станцию"
                         {...rest}
  />;
}

export function RailChainSelector({ station, rc, set_rc, ...rest }) {
  return <ServerSelector url={Number.isInteger(station) ? `/api/data/stations/${station}/rail_chains` : null}
                         value={rc}
                         set_value={set_rc}
                         name="rail_chain_selector"
                         placeholder="Выберите РЦ"
                         {...rest}
  />;
}
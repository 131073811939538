/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Popup, GeoJSON, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import axiosInstance from '../../config/axios.config';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [23.5, 47],
});

L.Marker.prototype.options.icon = DefaultIcon;

function Map() {
  const selectOptions = [
    { id: 1, name: 'Кряж' },
    { id: 2, name: 'Липяги-Кряж' },
    { id: 3, name: 'Кряж-Самара' },
    { id: 4, name: 'Самара' },
    { id: 5, name: 'Безенчук-Звезда' },
    { id: 6, name: 'Безенчук' },
    { id: 7, name: 'Безымянка-Смышляевка' },
    { id: 8, name: 'Безымянка' },
    { id: 9, name: 'Жигули-Новокуйбышевск' },
    { id: 10, name: 'Жигули' },
    { id: 11, name: 'Звезда-Чапаевск' },
    { id: 12, name: 'Звезда' },
    { id: 13, name: 'Мыльная-Безенчук' },
    { id: 14, name: 'Новокуйбышевск-Липяги' },
    { id: 15, name: 'Новокуйбышевск' },
    { id: 16, name: 'Самара-Безымянка' },
    { id: 17, name: 'Толевый' },
    { id: 18, name: 'Томылово-Жигули' },
    { id: 19, name: 'Томылово' },
    { id: 20, name: 'Чапаевск-Томылово' },
    { id: 21, name: 'Чапаевск' },
    { id: 22, name: 'Липяги' },
    { id: 23, name: 'Аша' },
    { id: 24, name: 'Аша-Миньяр' },
    { id: 25, name: 'Миньяр' },
    { id: 26, name: 'Ерал' },
    { id: 27, name: 'Симская' },
    { id: 28, name: 'Симская-Ерал' },
  ];

  const [selectedStation, setSelectedStation] = useState(null);
  const [position, setPosition] = useState([53.185682, 50.118524]);
  const [rails, setRails] = useState([]);
  const [polylineElements, setPolylineElements] = useState([]); // Кривые рельсов
  const [markers, setMarkers] = useState([]); // Маркеры точек
  const [point, setPoint] = useState('');
  const [pointCoords, setPointCoords] = useState('');
  const [railId, setRailId] = useState('');
  const [location, setLocation] = useState(null);
  const [points, setPoints] = useState([]);
  const [mapKey, setMapKey] = useState(0); // key to force map re-render

  /** Получаем с сервера все РЦ по выбранной станции, затем отображаем их на карте */
  function handleSelectChange(e) {
    const station = e.target.value;
    setSelectedStation(station);
    axiosInstance
      .post('/api/data/rails', { stationId: station })
      .then((response) => {
        setRails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /** Добавление маркера на карту */
  function handleAddPointToMap() {
    const dataWithoutSpaces = pointCoords.replace(/\s/g, '');
    const coordsStr = dataWithoutSpaces.split(',');
    if (coordsStr.length == 2) {
      const lat = parseFloat(coordsStr[0]);
      const lon = parseFloat(coordsStr[1]);
      if (lat && lon) {
        setLocation({ lat: lat, lon: lon });
        points.push([lat, lon]);
      } else {
        alert('Невалидные координаты');
      }
    } else {
      console.log('Invalid data:', pointCoords);
    }
  }

  /** Расчет расстояния от последней введенной координаты до заданной РЦ */
  function handleCalcDist() {
    if (!railId || !pointCoords) {
      alert('Не указана координата и/или ID РЦ');
      return;
    }
    axiosInstance
      .post('/api/data/distance', { railId: railId, lat: location?.lat, lon: location?.lon })
      .then((response) => {
        console.log('dist:', response.data.dist);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /** Запрос данных с сервера по указанной РЦ и отображение ее на карте */
  function handleAddRailToMap() {
    if (!railId) {
      alert('Не указан ID РЦ');
      return;
    }
    axiosInstance
      .get(`/api/data/rails/${railId}`)
      .then((response) => {
        setRails([...rails, ...response.data]);
        // setRails(rails.concat(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /** Очистка РЦ и маркеров с карты */
  function handleClearMap() {
    setRails([]);
    setPoints([]);
  }

  function turfLineToGeoJSON(turfLine, color, name) {
    return {
      type: 'Feature',
      properties: { name },
      geometry: {
        type: 'LineString',
        coordinates: turfLine.geometry.coordinates,
      },
      options: { color },
    };
  }

  useEffect(() => {
    const createPolylines = () => {
      return rails.map((rail, index) => {
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        const turfLine = rail.turfLine;
        const geoJSONLine = turfLineToGeoJSON(turfLine, color, rail.name);

        return (
          <GeoJSON key={index} data={geoJSONLine} color={color}>
            <Popup>{`РЦ: ${rail.name} (${rail.id}), start ord: ${rail.ord?.start}, end ord: ${rail.ord?.end}`}</Popup>
          </GeoJSON>
        );
      });
    };

    const oneOfTheRailEnds = rails[0]?.startCoord;
    if (oneOfTheRailEnds) {
      setPosition([oneOfTheRailEnds.lat, oneOfTheRailEnds.lon]);
    }
    setMapKey((prevKey) => prevKey + 1); // force re-render

    const polylines = createPolylines();
    setPolylineElements(polylines);

    const createMarkers = () => {
      return points.map((point, index) => {
        return (
          <Marker position={point}>
            <Popup>{point.toString()}</Popup>
          </Marker>
        );
      });
    };

    const pointsMarkers = createMarkers();
    setMarkers(pointsMarkers);

    if (location) {
      const marker = (
        <Marker position={[location.lat, location.lon]}>
          <Popup>
            {location.lat}, {location.lon}
          </Popup>
        </Marker>
      );
      setPoint(marker);
    }
  }, [rails, location, points]);

  return (
    <div className="p-3" style={{ width: '100%' }}>
      <div className="d-flex align-items-center">
        <select className="form-select w-25 me-2" onChange={handleSelectChange}>
          <option disabled selected value="">
            Станция/перегон
          </option>
          {selectOptions.map((option, index) => (
            <option id="select" key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div className="w-25">
          <input
            className="mb-0 form-control input-heigh"
            type="text"
            id="railId"
            name="railId"
            placeholder="Введите ID РЦ"
            onChange={(event) => setRailId(event.target.value)}
          />
        </div>
        <button className="btn btn-primary m-2" onClick={handleAddRailToMap}>
          Добавить на карту
        </button>
        <button className="btn btn-primary" onClick={handleClearMap}>
          Очистить
        </button>
      </div>

      <div className="d-flex align-items-center" style={{ textAlign: 'left' }}>
        <div className="w-25">
          <input
            className="mb-0 form-control input-heigh"
            type="text"
            id="point"
            name="point"
            placeholder="Введите коориданты"
            onChange={(event) => setPointCoords(event.target.value)}
          />
        </div>
        <button className="btn btn-primary m-2" onClick={handleAddPointToMap}>
          Добавить на карту
        </button>
        <button className="btn btn-primary m-2" onClick={handleCalcDist}>
          Расчитать расстояние
        </button>
      </div>
      <MapContainer
        key={mapKey}
        center={position}
        zoom={15}
        style={{ height: '90%', width: '100%' }}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {polylineElements}
        {/* {point} */}
        {markers}
      </MapContainer>
    </div>
  );
}

export default Map;

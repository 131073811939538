import React, { useState, useEffect } from 'react';
import '../../styles/Checkbox.css';

const Checkbox = ({ label, onChange, height, width, checked, margin }) => {
  const [isChecked, setChecked] = useState(false);

  // Обновляем isChecked, если проп checked изменился
  useEffect(() => {
    if (checked !== undefined) {
      setChecked(checked);
    }
  }, [checked]);

  const toggleCheckbox = () => {
    setChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  const containerStyle = isChecked ? 'checkbox-container checked' : 'checkbox-container';
  const checkboxStyle = isChecked ? 'checkbox-input checked' : 'checkbox-input';

  return (
    <div
      className={containerStyle}
      style={{
        width: width ? width : `var(--sadr-font-size-420)`,
        height: height ? height : `var(--sadr-font-size-100)`,
        margin: margin,
      }}
    >
      <input
        type="checkbox"
        className={checkboxStyle}
        id="customCheckbox"
        checked={isChecked}
        onChange={toggleCheckbox}
      />
      <label htmlFor="customCheckbox" className="checkbox-label">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

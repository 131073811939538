/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceDeployment.css';
// Компоненты пользовательского интерфейса
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import Checkbox from '../CustomElements/Checkbox';
// Утилиты и хуки
import { convertToDate } from '../../utils/convertToDate';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import useFilesOptions from '../../customHook/useFilesOptions';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
// Действия Redux
import { load, remove } from '../../redux/actions/loader.action';
import { transferDeviceForOperation } from '../../redux/actions/devices.action';
import { initOptionsFromServer } from '../../redux/actions/options.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';
import useSuccessListener from '../../customHook/useSuccessListener';

// компонент выдачи уиппс в эксплуатацию

function UippsDeployment({ currentStatus, currentDevice, closeModal, title, isEdit }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);
  const { roads, departments, deviceStatuses } = useSelector((state) => state.options);

  // Состояние для формы
  // Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      deviceId: currentDevice?.id,
      typeId: currentDevice?.type_id,
      date: '',
      acceptanceCert: '',
      roadId: '',
      directorateId: '',
      departmentId: '',
      trainTypeId: '',
      trainNumber: '',
      responsibleId: '',
      responsibleContact: '',
    }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus, currentDevice]
  );

  const [isVisible, setIsVisible] = useState(true);
  const [formData, setFormData] = useState(initialFormData);
  const [optionalFields] = useState(['responsibleId', 'responsibleContact', 'trainTypeId', 'trainNumber']);

  // Функция для обработки события изменения checkbox
  const handleChangeCheckbox = () => setIsVisible((prev) => !prev);

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    setFormData((prev) => {
      return { ...prev, placement: !isVisible };
    });
    const payload = createFormData({ ...formData, placement: !isVisible });
    //переводим в состояние загрузки
    dispatch(load());
    // Отправляем данные на сервер через Redux
    dispatch(transferDeviceForOperation(payload, deviceStatuses, true));
  };

  useEffect(() => {
    if (loader?.isSuccess && !loader?.isLoading) {
      dispatch(remove());
    }
  }, [loader]);
  
  //хук для контрола выбора файла
  const { isFile, optionsFiles, handleChangeCheckboxFile, handleSelectFile } = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'roadId',
    initOptionsDirectoratesUippsFromServer,
    messageForSelectDirectorates
  );
  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal)

  // запрашиваем файлы для создания массива опций
  useEffect(() => {
    return () => {
      dispatch(initOptionsFromServer());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'ВЫДАЧА В ЭКСПЛУАТАЦИЮ'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      <FormContainer>
        <DateSelector
          column="left"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          label="Дата выдачи в эксплуатацию *"
          selectedDate={formData.date}
          setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
          name="date"
          placeholder="Выберите дату"
          minDate={convertToDate(currentDevice?.last_action_date)}
          maxDate={new Date()}
        />

        {!isFile && (
          <Input
            column="left"
            placeholder="Выберите файл акта"
            onChange={(event) =>
              handleUniversalChange(formData, setFormData, event, null, null, null, 'acceptanceCertName')
            }
            onRemove={() =>
              handleUniversalChange(
                formData,
                setFormData,
                null,
                'isRemove',
                null,
                null,
                'acceptanceCert',
                'acceptanceCertName'
              )
            }
            type="file"
            name="acceptanceCert"
            label="Акт приема-передачи *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            fileName={currentStatus?.acceptance_cert_name}
            isDisabled={false}
            maxFileSize={20}
          />
        )}
        {isFile && (
          <Selector
            column="left"
            options={optionsFiles}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'acceptanceCert', selectedOption, 'select');
              handleSelectFile(selectedOption);
            }}
            placeholder="Выберите файл"
            label="Акт приема-передачи *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsFiles?.find((option) => option.value === formData.acceptanceCert)}
            isMulti={false}
            name="acceptanceCert"
          />
        )}
        <Checkbox
          column="left"
          height={`var(--sadr-font-size-40)`}
          width="100%"
          label="Выбрать существующий файл"
          onChange={(newValue) => handleChangeCheckboxFile('isFile', newValue)}
          checked={isFile}
        />
        {!isEdit && (
          <Checkbox
            column="left"
            label="Стационарное размещение"
            onChange={handleChangeCheckbox}
            height={`var(--sadr-font-size-50)`}
            margin="var(--sadr-font-size-20) 0px var(--sadr-font-size-5)"
          />
        )}
        {isVisible && (
          <Input
            column="left"
            isVisible={isVisible}
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            placeholder="Введите табельный номер"
            label="Табельный № машиниста"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={formData.responsibleId}
            name="responsibleId"
          />
        )}
        {!isVisible && (
          <Selector
            column="left"
            isVisible={!isVisible}
            options={optionsForSelect?.trainTypes}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'trainTypeId', selectedOption, 'select');
            }}
            placeholder="Выберите тип подвижного состава"
            label="Тип подвижного состава"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.trainTypeId
                ? optionsForSelect?.trainTypes.find((option) => option.value === formData.trainTypeId)
                : null
            }
            isMulti={false}
            name="trainTypeId"
          />
        )}
        <Selector
          column="right"
          options={roads}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
          }}
          placeholder="Выберите дорогу"
          label="Дорога *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={formData.roadId ? roads?.find((option) => option.label === formData.roadId) : null}
          isMulti={false}
          name="roadId"
        />
        <Selector
          column="right"
          options={optionsForSelect?.directoratesUipps}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
          }}
          placeholder="Выберите дирекцию"
          label="Дирекция *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={
            formData.directorateId
              ? optionsForSelect?.directoratesUipps?.find((option) => option.value === formData.directorateId)
              : null
          }
          isMulti={false}
          name="directorateId"
          isDisabled={directoratesControl?.isSelectorDisabled}
          message={directoratesControl?.message}
          customClick={directoratesControl?.onSelectorChange}
        />
        <Selector
          column="right"
          options={departments}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
          }}
          placeholder="Выберите подразделение"
          label="Подразделение *"
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={formData.departmentId ? departments?.find((option) => option.value === formData.departmentId) : null}
          isMulti={false}
          name="departmentId"
          isDisabled={departmentsControl?.isSelectorDisabled}
          message={departmentsControl?.message}
          customClick={departmentsControl?.onSelectorChange}
        />
        {isVisible && (
          <Input
            column="right"
            isVisible={isVisible}
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            placeholder="Введите контакты"
            label="Контакты машиниста"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={formData.responsibleContact}
            name="responsibleContact"
          />
        )}
        {!isVisible && (
          <Input
            column="right"
            isVisible={!isVisible}
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            label="Номер  подвижного состава"
            placeholder="Введите № подвижного состава"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={formData.trainNumber}
            name="trainNumber"
          />
        )}
      </FormContainer>
      <CustomButton
        onClick={(event) => {
          handlerSubmit(event);
        }}
        width="var(--sadr-font-size-420)"
        height="var(--sadr-font-size-60)"
        text={'Выдать устройство'}
        disabled={isButtonDisabled}
        isButton
      />
    </CustomContainer>
  );
}

export default UippsDeployment;

import { React, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table/Table';
import '../../styles/TableForDevices.css';
import AddDevice from '../Devices/AddDevice';
import Modal from '../Modal/Modal';
import ResetMesh from '../Brigades/ResetMesh';
import CustomButton from '../CustomElements/CustomButton';
import Device from '../Devices/Device';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import { emitResetMesh } from '../../redux/actions/brigades.action';
import { listenResetMesh } from '../../redux/actions/brigades.action';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';
import { initDevicesFromServer } from '../../redux/actions/devices.action';

function TableForDevices({ searchInput, formData, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение данных устройств из Redux store
  const devices = useSelector((state) => state.devices);
  const [currentDevice, setCurrentDevice] = useState();

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [newDeviceModal, setNewDeviceModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [resetMeshModal, setResetMeshModal] = useState(false);

  //Создаём бесконечный скролл с помощью кастгомного хука
  const observer = useRef();
  const bottomBoundaryRef = useRef(null);
  useInfiniteScroll(formData, devices, observer, bottomBoundaryRef, initDevicesFromServer);

  // Открыть модалку для добавления нового устройства
  const addNewDevice = () => {
    setNewDeviceModal(true);
    setIsOpen(true);
  };

  // Открыть модалку для просмотра устройства
  const openDevice = (id) => {
    setCurrentDevice(devices.find((device) => device.id === id));
    setOpenDeviceModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setNewDeviceModal(false);
    setOpenDeviceModal(false);
    setResetMeshModal(false);
  };
  // открытие модалки для сброса mersh
  const handleButtonClick = (event, device) => {
    event.stopPropagation();
    setCurrentDevice(devices.find((elem) => elem.id === device.id));
    setResetMeshModal(true);
    setIsOpen(true);
  };

  // сброс mesh
  const handlerConfirmResetMesh = (id) => {
    const payload = { deviceId: id };
    dispatch(emitResetMesh(payload));
  };

  useEffect(() => {
    //слушаем событие 'reset_mesh_reply'
    dispatch(listenResetMesh());
  }, [dispatch]);

  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'ID устройства',
        accessor: 'id',
        Cell: ({ cell: { value } }) => (
          <div className="table-for-devices_cell_id number-cell">
            <img className="table-for-devices_cell_id_img" src="/image/case.svg" alt="Иконка" style={{ opacity: 1 }} />{' '}
            <span style={{ verticalAlign: 'middle' }}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Тип',
        accessor: 'type',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Состояние',
        accessor: 'broken',
        Cell: ({ value }) => {
          switch (value) {
            case true:
              return 'Неисправен';
            case false:
              return 'Исправен';
            default:
              return 'Не определено';
          }
        },
      },
      {
        Header: 'Дорога',
        accessor: 'road',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Дирекция',
        accessor: 'directorate',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Подразделение',
        accessor: 'department',
        Cell: ({ value }) => defaultCellRender(value),
      },
      // сейчас не требуеться, но пока оставил на всякий случай
      // {
      //   Header: 'Участок',
      //   accessor: 'section',
      //   Cell: ({ value }) => defaultCellRender(value),
      // },
      {
        Header: 'Ответственный',
        accessor: 'responsible',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Сбросить Mesh',
        Cell: ({ row }) => (
          <div className="">
            {row.original.type === 'КУОБЗ' ? (
              <CustomButtonDelete
                margin="0  var(--sadr-font-size-5) "
                disabled={false}
                onClick={(event) => {
                  handleButtonClick(event, row?.original);
                }}
              />
            ) : (
              <span>-</span> // Отображать прочерк, если тип не "КУОБЗ"
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devices]
  );

  return (
    <div className="table-for-devices_or_uipps_container">
      {/* Компонент таблицы */}
      <Table
        data={devices}
        columns={columns}
        // searchInput={searchInput}
        open={openDevice}
        // showGlobalSearch={true}
        bottomBoundaryRef={bottomBoundaryRef}
        arrLength={devices.length}
      />

      <div className="table-for-devices_btn-container">
        {/* Кнопка для добавления нового устройства */}
        <CustomButton text="+ Добавить новое устройство" onClick={addNewDevice} />
      </div>

      {/* Модальное окно */}
      {/* передать что бы ширина модалки была как ширина таблицы containerSelector={'.table-for-devices_or_uipps_container'} */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {/* Компонент для добавления нового устройства */}
        {newDeviceModal && <AddDevice closeModal={closeModal} />}
        {/* Компонент для просмотра устройства */}
        {openDeviceModal && (
          <Device
            closeModal={closeModal}
            currentDevice={currentDevice}
            setCurrentDevice={setCurrentDevice}
            formData={formData}
          />
        )}
        {/* компонент для сброса mesh */}
        {resetMeshModal && (
          <ResetMesh
            closeModal={closeModal}
            confirmReset={false}
            handlerConfirm={handlerConfirmResetMesh}
            device={currentDevice}
            t={t}
          />
        )}
      </Modal>
    </div>
  );
}

export default TableForDevices;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, } from 'react';

/**
 * Останавливает работающий промис и начинает заново при смене зависимостей
 * @param asyncFn {function(AbortController) : Promise} - асинхронная функция
 * @param dependencies {[]}
 */
export function useAsyncEffect(asyncFn, dependencies) {
  const acRef = useRef(new AbortController());
  useEffect(() => {
    acRef.current.abort('cancelled');
    acRef.current = new AbortController();
    asyncFn(acRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

/**
 * Делает текущий промис отменяемым.
 * Не будет менять уже существующие операции, такие как fetch, применять с осторожностью
 * @param fn {function():Promise | Promise}
 * @param ac {AbortController}
 */
export function makePromiseCancellable(fn, ac) {
  return new Promise(async (resolve) => {
    ac.signal.addEventListener('abort', resolve);

    let promise = typeof fn == 'function' ? fn() : fn;
    resolve(promise);
  });
}

/**
 * Асинхронно опрашиваем сервер.
 * @param getFetches {Array<function(): Promise> | function(): Promise} - коллекция запросов на сервер
 * @param dependencies {[]} - зависимости
 */
export function useAsyncFetch(getFetches, dependencies) {
  const acRef = useRef(new AbortController());

  useEffect(() => {
    acRef.current.abort('cancelled');
    acRef.current = new AbortController();
    let all_promisesFn = Array.isArray(getFetches) ? getFetches : [getFetches];

    // Запускаем промисы, нам не важно когда они закончатся
    all_promisesFn.map(asyncAction => new Promise(async (resolve, reject) => {
      acRef.current.signal.addEventListener('abort', resolve);

      for (let i = 0; true; i++) {
        try {
          await makePromiseCancellable(asyncAction(), acRef.current);
          return resolve(true);
        } catch (e) {
          // С каждой итерацией ошибки время ожидания увеличивается
          let toWait = Math.pow(1.5, i) * 500;
          await new Promise(r => setTimeout(r, toWait));
        }
      }
    }));

    // при unmount нужно отменить все запросы
    return () => acRef.current.abort('cancelled');

  }, [getFetches, ...dependencies]);
}

/**
 * Вызываем OnChange событие из кода
 * @param input {HTMLElement}
 * @param newVal {string}
 */
export function simulateOnChange(input, newVal) {
  const setter = Object.getOwnPropertyDescriptor(input.__proto__, 'value')?.set;
  if (setter)
  {
    const event = new Event('input', { bubbles: true });
    setter.call(input, newVal);
    input.dispatchEvent(event);
  }
}
import React from 'react';
import '../../styles/CloseButton.css';

function CloseButton({ onClose }) {
  return (
    <button id="close_button" className="close-btn" onClick={onClose}>
      <span className="close-icon" aria-hidden="true">
        &times;
      </span>
    </button>
  );
}

export default CloseButton;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 60%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin-bottom: 30px;
}

.table__header {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-15);
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
}

.table__section-id {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sadr-font-size);
  color: #424242;
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.table__row {
  border: 1px solid #ddd;
}

.table__cell {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sadr-font-size);
  color: #424242;
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: middle;
}

.table__cell--left {
  text-align: left;
  vertical-align: middle;
}

.highlight {
  background-color: #c1ffcf !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ViewModifiedRailChains.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,cAAc;EACd,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,gDAAgD;AAClD;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,cAAc;EACd,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".table {\n  width: 60%;\n  border-collapse: collapse;\n  border: 1px solid #ddd;\n  margin-bottom: 30px;\n}\n\n.table__header {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-15);\n  text-align: center;\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.table__section-id {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 500;\n  font-size: var(--sadr-font-size);\n  color: #424242;\n  text-align: center;\n  border: 1px solid #ddd;\n  padding: 8px;\n  background-color: rgba(0, 0, 0, 0.07) !important;\n}\n\n.table__row {\n  border: 1px solid #ddd;\n}\n\n.table__cell {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 500;\n  font-size: var(--sadr-font-size);\n  color: #424242;\n  text-align: center;\n  border: 1px solid #ddd;\n  padding: 8px;\n  vertical-align: middle;\n}\n\n.table__cell--left {\n  text-align: left;\n  vertical-align: middle;\n}\n\n.highlight {\n  background-color: #c1ffcf !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import axios from 'axios';
import { store } from '../redux/store';
import { logoutUser } from '../redux/actions/user.action';
import { addError } from '../redux/actions/error.action';
import { getTranslations } from '../utils/getTranslations';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  withCredentials: true,
});

instance.interceptors.response.use(
  response => response, // Если все в порядке, просто вернет ответ
  error => {
    const t = getTranslations(); // получение словаря для статических данных в зависимости от выбраного языка
     // Проверка на наличие специального флага в заголовке запроса
    if (error.config.headers['Skip-Interceptor'] === 'true') {
          return Promise.reject(error);
    }
    // Проверка статуса ошибки
    if (error.response) {
      const meesageError = error?.response?.data?.error? error?.response?.data?.error : t?.unexpectedError;

      const { status } = error.response;

      // Обработка неавторизованного доступа
      if (status === 401) {
        // Вызов действия Redux для выхода из системы
        store.dispatch(logoutUser({logout:true}));
      }
      else if (status >= 400 && status !== 401 && status < 500) {
        console.error("Ошибка клиента.");
        store.dispatch(addError({ error: meesageError }))
      }
      // Добавьте сюда обработку других HTTP статусов, например:
      else if (status >= 500) {
        console.error("Серверная ошибка.");
        store.dispatch(addError({ error: meesageError }))
      }
    }
    // Возвращаем Promise.reject для дальнейшей обработки ошибки
    return Promise.reject(error);
  })

export default instance;

// добавить в запрос при отправке файлов 
// {headers: {'Content-Type': 'multipart/form-data'}}
// instance.post('/api'/devices`, payload, {headers: {'Content-Type': 'multipart/form-data'}})

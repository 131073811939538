import '../../styles/FilterForArchive.css';
import Input from '../CustomElements/Input';
import { handleUniversalChange } from '../../utils/formUtils';
import Selector from '../CustomElements/Selector';

function FilterForArchiveKUOBZ({ formData, setFormData }) {
  // опции для селектов
  const options = {
    safeZoneType: [
      { value: 0, label: 'круг' },
      { value: 1, label: 'полигон' },
    ],
    kuobzGSM: [
      { value: 1, label: '-60 - 0 (dBm)' },
      { value: 2, label: '-70 - -60 (dBm)' },
      { value: 3, label: '-80 - -70 (dBm)' },
      { value: 4, label: ' -90 - -80 (dBm)' },
      { value: 5, label: '< -90 (dBm)' },
    ],
    kuobzConn: [
      { value: false, label: 'Нет' },
      { value: true, label: 'Да' },
    ],
    kuobzAlertType: [
      { value: 1, label: 'ОК' },
      { value: 5, label: 'Тревога' },
      { value: 2, label: 'Оповещение 1' },
      { value: 3, label: 'Оповещение 2' },
      { value: 0, label: 'Проследование РЦ' },
      { value: 6, label: 'Ожидание' },
      { value: 4, label: 'Потеря связи' }
    ],
  };

  return (
    <div className="filter-for-archive_select">
      <Input
        placeholder="Введите ID КУОБЗ"
        onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
        value={formData?.mainKuobzId}
        name="mainKuobzId"
        label="ID ведущего КУОБЗ"
        // type="number"
      />
      <Selector
        options={options?.kuobzAlertType}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'kuobzAlertType', selectedOption, 'select');
        }}
        placeholder="Выберите сигнал"
        value={options?.kuobzAlertType?.find((option) => option.value === formData.kuobzAlertType)}
        name="kuobzAlertType"
        isMulti={false}
        label="Сигнал на КУОБЗ"
      />
      <Selector
        options={options.safeZoneType}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'safeZoneType', selectedOption, 'select');
        }}
        placeholder="Выберите форму"
        value={options.safeZoneType?.find((option) => option.value === formData?.safeZoneType)}
        name="safeZoneType"
        isMulti={false}
        label="Форма безопасной зоны"
      />
      <Input
        placeholder="Введите ширину в метрах"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData.safeZoneWidth}
        name="safeZoneWidth"
        label="Ширина безопасной зоны"
        // type="number"
      />
      <Input
        placeholder="Введите количество спутников"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData?.kuobzGPS}
        name="kuobzGPS"
        label="Количество спутников"
      />
      <Selector
        options={options?.kuobzGSM}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'kuobzGSM', selectedOption, 'select');
        }}
        placeholder="Выберите уровень сигнала"
        value={formData.kuobzGSM ? options?.kuobzGSM?.find((option) => option.value === formData.kuobzGSM) : null}
        name="kuobzGSM"
        isMulti={false}
        label="Уровень сигнала сотовой связи"
      />
      <Selector
        options={options?.kuobzConn}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'kuobzConn', selectedOption, 'select');
        }}
        placeholder="Выберите наличие связи"
        value={options?.kuobzConn?.find((option) => option.value === formData.kuobzConn)}
        name="kuobzConn"
        isMulti={false}
        label="Связь с сервером"
      />
    </div>
  );
}

export default FilterForArchiveKUOBZ;

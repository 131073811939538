/**
 * Проверяет, соответствует ли введенное значение формату координат
 * @param {string} inputValue - Введенное значение для проверки.
 * @returns {string} Возвращает введенное значение, если оно соответствует формату, иначе пустую строку.
 */
export const coordinateFormat = (inputValue) => {
  // Регулярное выражение для поддержки отрицательных и положительных значений,
  // с 1-3 цифрами перед точкой и до 15 цифр после точки
  const regex = /^-?\d{0,3}(\.\d{0,15})?$/;
  return regex.test(inputValue) ? inputValue : '';
};

/**
 * Проверяет, является ли значение допустимым форматом координат
 * @param {Object} param0 - Объект с форматированным значением и числовым значением.
 * @param {string} param0.formattedValue - Форматированное значение для проверки.
 * @param {number} [param0.floatValue] - Числовое значение (необязательное).
 * @returns {boolean} Возвращает true, если значение допустимо, иначе false.
 */
export const isCoordinateAllowed = ({ formattedValue, floatValue }) => {
  // Разрешить отрицательные и положительные значения,
  // с 1-3 цифрами перед точкой и до 15 цифр после точки
  return /^-?\d{0,3}(\.\d{0,15})?$/.test(formattedValue);
};        


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* width: 100%; */
  /* max-width: 80vw; */
  padding: var(--sadr-font-size-20);
}

.form-container_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: calc((100vw - 120rem) * 0.2);
}
.form-container_wrapper_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  padding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);
}

.form-container_other-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Занимает всю ширину */
  padding: 0 var(--sadr-font-size-10); /* Отступы для выравнивания с краями других колонок */
}

.form-container_submit-button {
  width: 100%; /* Занимает всю ширину */
  height: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center; /* Центрирование кнопки */
  align-items: flex-end;
  padding-top: var(--sadr-font-size-20); /* Отступ сверху для разделения от других элементов */
  gap: var(--sadr-font-size-20);
}
`, "",{"version":3,"sources":["webpack://./src/styles/FormContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,8DAA8D;AAChE;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW,EAAE,wBAAwB;EACrC,mCAAmC,EAAE,qDAAqD;AAC5F;;AAEA;EACE,WAAW,EAAE,wBAAwB;EACrC,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB,EAAE,yBAAyB;EAClD,qBAAqB;EACrB,qCAAqC,EAAE,qDAAqD;EAC5F,6BAA6B;AAC/B","sourcesContent":[".form-container_form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  /* width: 100%; */\n  /* max-width: 80vw; */\n  padding: var(--sadr-font-size-20);\n}\n\n.form-container_wrapper {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  gap: calc((100vw - 120rem) * 0.2);\n}\n.form-container_wrapper_container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  /* width: 100%; */\n  padding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);\n}\n\n.form-container_other-children {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%; /* Занимает всю ширину */\n  padding: 0 var(--sadr-font-size-10); /* Отступы для выравнивания с краями других колонок */\n}\n\n.form-container_submit-button {\n  width: 100%; /* Занимает всю ширину */\n  height: 100%;\n  margin-top: auto;\n  display: flex;\n  justify-content: center; /* Центрирование кнопки */\n  align-items: flex-end;\n  padding-top: var(--sadr-font-size-20); /* Отступ сверху для разделения от других элементов */\n  gap: var(--sadr-font-size-20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from 'react';

import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import { Tab, Tabs } from '../CustomElements/Tabs';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import RailChains from './RailChains';
import Adjacency from './Adjacency';
import { useInfocard } from './Common';
import Semaphores from './Semaphores';
import { clearAdjacencyState, clearRailChainsState, clearSemaphoresState } from '../../redux/actions/infocards.action';
import { useDispatch } from 'react-redux';

export default function InfocardView() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // хук для навигации
  const changeURL = useUrlNavigate();

  // получаем данные для отображения инфокарты
  const { name, road_name, department_name, max_speed, min_dist, alert_dist } = useInfocard();
  const noDataAlias = 'н/д';
  const roadName = road_name || noDataAlias;
  const departmentName = department_name || noDataAlias;
  const maxSpeed = max_speed ? `${max_speed} км/ч` : noDataAlias;
  const minDistance = min_dist ? `${min_dist} м` : noDataAlias;
  const alertDistance = alert_dist ? `${alert_dist} м` : noDataAlias;

  // при закрытии инфокарты сбрасываем стейт со смежностями, рц и семафорами обновляем
  useEffect(() => {
    return () => {
      dispatch(clearAdjacencyState());
      dispatch(clearRailChainsState());
      dispatch(clearSemaphoresState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="d-flex flex-column flex-nowrap w-100 overflow-hidden h-100">
      <div
        id="card"
        className="border-shadow infocard-card d-flex flex-row flex-wrap align-items-start sadr-gap-40 align-items-center"
      >
        <h4 className="infocard-card-title">{name}</h4>
        <div className="d-flex flex-column flex-nowrap align-items-start infocard-text w-33 text-left">
          <span>
            <b>Дорога: </b>
            {roadName}
          </span>
          <span>
            <b>Подразделение: </b>
            {departmentName}
          </span>
          <span>
            <b>Разрешенная макс. скорость: </b>
            {maxSpeed}
          </span>
        </div>
        <div className="d-flex flex-column flex-nowrap align-items-start infocard-text text-left">
          <span>
            <b>Минимальное расстояние оповещения: </b>
            {minDistance}
          </span>
          <span>
            <b>Расст. тревоги при приближ. ПС с исправным УИППС: </b>
            {alertDistance}
          </span>
        </div>
        <CustomButtonEdit
          className="device-edit-btn ms-auto mb-auto w-auto"
          onClick={() =>
            changeURL((u) => {
              u.pathname = u.pathname.replace('/view', '/edit');
            })
          }
        />
      </div>
      <Tabs>
        <Tab name="Рельсовые цепи" path="/rail_chain">
          <RailChains />
        </Tab>
        <Tab name="Смежность" path="/adjustment">
          <Adjacency />
        </Tab>
        <Tab name="Светофоры" path="/semaphores">
          <Semaphores />
        </Tab>
      </Tabs>
    </div>
  );
}

import React, { useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const redIcon = L.icon({
  iconUrl: '/image/redIcon.svg',
  iconSize: [15, 15],
});

const greenIcon = L.icon({
  iconUrl: '/image/greenIcon.svg',
  iconSize: [15, 15],
});

const blueIcon = L.icon({
  iconUrl: '/image/blueIcon.svg',
  iconSize: [15, 15],
});

function MyMap({ markers }) {
  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  // Функция для проверки валидности координат
  const isValidCoordinate = (coordinate) => {
    return Array.isArray(coordinate) && coordinate.length === 2 && !isNaN(coordinate[0]) && !isNaN(coordinate[1]);
  };

  // Вычисляем позицию на основе первого элемента markers
  const position = useMemo(() => {
    if (Array.isArray(markers) && markers.length > 0 && isValidCoordinate(markers[0].position)) {
      return markers[0].position;
    }
    return [53.18442135, 50.12827981]; // Значение по умолчанию
  }, [markers]);

  // Функция для получения иконки
  const getIcon = (type) => {
    switch (type) {
      case 'green':
        return greenIcon;
      case 'red':
        return redIcon;
      case 'blue':
        return blueIcon;
      default:
        return redIcon;
    }
  };

  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: '100%', width: '100%', borderRadius: 'var(--sadr-font-size-10)' }}
      attributionControl={false}
    >
      <ChangeView center={position} />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markers?.map(
        (marker, index) =>
          isValidCoordinate(marker.position) && (
            <Marker key={index} position={marker.position} icon={getIcon(marker.type)}>
              <Tooltip>{marker.popupContent}</Tooltip>
            </Marker>
          )
      )}
    </MapContainer>
  );
}

export default MyMap;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { select_recursive } from '../utils/arrUtils';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import useUrlNavigate from './useUrlNavigate';

/**
 * Кастомный хук для управления отображением столбцов в таблице.
 * Позволяет выбирать, какие столбцы должны быть отображены.
 *
 * @param {Array} columns Массив столбцов таблицы.
 * @param resolveHeaders {(parents: string[])=>string | JSX.Element}
 * @param q_name {string | null} - имя ключа для сохранения настройки
 * @returns {[Array, Function, Array]} Массив с тремя элементами:
 * текущими выбранными столбцами, функцией для установки этих столбцов и массивом отфильтрованных столбцов.
 */
function useTableColumnSelect(columns, { resolveHeaders = null, q_name = null } = {}) {
  function getId(col) {
    return col.id || col.accessor;
  }

  function prepare(cols) {
    const parents = new Map();
    const flat = select_recursive(cols, c => c.columns);
    const actual_parents = flat.filter(x => x.columns);
    flat.forEach(col => parents.set(col, actual_parents.find(x => x.columns.includes(col)) || { columns: cols }));
    return { parents, flat, original: cols };
  }

  let loc = useLocation();
  let navigate = useUrlNavigate();
  const [hidden, setHidden] = useState([]);
  const visibleColumns = useMemo(() => {
    const { flat, original, parents } = prepare(_.cloneDeep(columns));
    flat.filter(x => hidden.includes(getId(x))).forEach(del => {
      const { columns: src } = parents.get(del);
      const index = src.indexOf(del);
      src.splice(index, 1);
    });
    return original;
  }, [columns, hidden]);

  const columnOptions = useMemo(() => {
    const { flat, parents } = prepare(columns);
    const leafs = flat.filter(x => !x.columns);

    function resolveHeader(col) {
      const _parents = [];
      let pointer = col;
      while (pointer?.Header) {
        _parents.unshift(pointer);
        pointer = parents.get(pointer);
      }
      let hdrs = _parents.map(x => x.Header);
      return resolveHeaders?.(hdrs, col) || hdrs.map(x => <span key={x}>{x}</span>);
    }

    return leafs.map(x => ({
      label: resolveHeader(x),
      value: getId(x)
    }));
  }, [columns]);

  const selected = useMemo(() => columnOptions.filter(x => !hidden.includes(x.value)),
    [hidden, columnOptions]);
  const handleColumnSelect = useCallback((allOpts) => {
    const allIds = columnOptions.map(x => x.value);
    const currentIds = allOpts.map(x => x.value);
    let _hidden = _.without(allIds, ...currentIds);
    setHidden(_hidden);

    if (q_name) {
      let indexes = _hidden.map(key => columnOptions.findIndex(co => co.value == key)).filter(x => x >= 0);
      navigate(u => {
        if (indexes.length)
          u.searchParams.set(q_name, indexes.join(' '));
        else
          u.searchParams.delete(q_name);
      });
    }
  }, [columnOptions]);

  useEffect(() => {
    if (!q_name)
      return;

    const hiddenColumnIndexes = new URL(window.location.href).searchParams.get(q_name)?.split(' ') || [];
    const _hidden = hiddenColumnIndexes.map(x => columnOptions[+x]?.value).filter(Boolean);
    setHidden(_hidden);
  }, [loc.search, q_name]);

  return [visibleColumns, hidden, columnOptions, selected, handleColumnSelect, q_name];
}

export default useTableColumnSelect;


/* 
const [selectedColumns, handleColumnSelect, visibleColumns] = useTableColumnSelect(columns) 
<Table columns={visibleColumns} data={data} />
*/

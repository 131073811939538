import { React, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../styles/TableForDocumentation.css';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';
import CustomButton from '../CustomElements/CustomButton';
import ReportForm from '../Documentation/ReportForm';
import 'react-datepicker/dist/react-datepicker.css';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import { initReportFromServer } from '../../redux/actions/documentation.action';
import DownloadReport from '../Documentation/DownloadReport';
import { dateToString } from '../../utils/convertToDate';
import ContractReport from '../Documentation/ContractReport';

function TableForDocumentation({ formData }) {
  // получаем все отчеты из стора
  const documentation = useSelector((state) => state.documentation);

  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus();
  const contentData = {
    'Новый отчет по объему услуг': <ReportForm closeModalForm={closeModal} type={'works'} />,
    // 'Новый отчет по работе бригад': <ReportForm closeModalForm={closeModal} type={'brigade'} />,
    'Новый отчет по работе бригад': <DownloadReport closeModal={closeModal} type={'brigade'} />,
    'Новый отчет о нарушениях': <ReportForm closeModalForm={closeModal} type={'violations'} />,
    'Новый отчет по договору': <ContractReport closeModalForm={closeModal} type={'violations'} />,
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Тип',
        accessor: 'type',
        Cell: ({ value }) => {
          switch (value) {
            case 'works':
              return 'Объем услуг';
            case 'brigade':
              return 'Работа бригад';
            case 'violations':
              return 'Нарушения';
            default:
              return '-';
          }
        },
      },
      {
        Header: 'Дата',
        accessor: 'createDt',
        Cell: ({ value }) => defaultCellRender(dateToString(value)),
      },
      {
        Header: 'Дорога',
        accessor: 'road',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Дирекция',
        accessor: 'directorate',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Подразделение',
        accessor: 'department',
        Cell: ({ value }) => defaultCellRender(value),
      },
      // {
      //   Header: 'Участок',
      //   accessor: 'section',
      //   Cell: ({ value }) => defaultCellRender(value),
      // },
      {
        Header: 'Бригада',
        accessor: 'brigade',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Период',
        accessor: 'period',
        // отформатировать дату!!!!!
        // Cell: ({ row }) => {
        //   const startDate = defaultCellRender(dateToString(row.original.start_date));
        //   const endDate = defaultCellRender(dateToString(row.original.end_date));
        //   return `${startDate} - ${endDate}`;
        // },
      },
      {
        Header: 'Загрузка',
        accessor: 'link',
        Cell: ({ row }) => (
          <a
            className="table-for-documentation_cell_download_btn"
            href={`${process.env.REACT_APP_DOCUMENT}${row?.original?._id}`}
            download
            aria-label="Скачать документ"
          >
            <img src="/image/download.svg" alt="Скачать" />
          </a>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentation?.reports]
  );

  // запрашиваем список всех отчетов на сервере при открытии страницы https://dev.ctrlplus.team:8443/api/documentation/660d3ce90531f4b01f7882e7
  useEffect(() => {
    // dispatch(initReportFromServer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="table-for-documentation_container">
      <Table data={documentation?.reports} columns={columns} showGlobalSearch={true} />
      {/* Блок с кнопками */}

      <div className="table-for-documentation_btn-container">
        {Object.keys(contentData).map((buttonName) => (
          <CustomButton
            className="device-storage-status_button-action"
            key={buttonName}
            text={buttonName}
            colorText="#FFFFF"
            onClick={() => handleButtonClick(buttonName)}
            disabled={!(buttonName === 'Новый отчет по работе бригад' || buttonName === 'Новый отчет по договору')}
            // disabled={!(buttonName === 'Новый отчет по работе бригад' || buttonName === 'Новый отчет по объему услуг' || buttonName === 'Новый отчет по договору')}
          />
        ))}
      </div>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-documentation_container'}>
          {contentData[activeModal]}
        </Modal>
      )}
    </div>
  );
}

export default TableForDocumentation;

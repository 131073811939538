import moment from 'moment';

/**
 * Фильтрует массив отчетов по заданным критериям.
 * 
 * @param {Array} reports - Массив объектов отчетов, каждый из которых может содержать данные, такие как startDate, endDate и другие атрибуты.
 * @param {Object} filterCriteria - Объект с критериями для фильтрации, включая возможные даты начала и конца отчетного периода и другие поля для точечной фильтрации.
 * @returns {Array} Возвращает отфильтрованный массив отчетов, которые соответствуют всем указанным критериям.
 */
export function filterReports(reports, filterCriteria) {
  const filteredReports = reports?.filter((report) => {
    // Проверяем, соответствует ли отчет заданному диапазону дат, если обе даты заданы
    const inDateRange =
      filterCriteria.startDate && filterCriteria.endDate
        ? moment(report.startDate).isBetween(
            moment(filterCriteria.startDate),
            moment(filterCriteria.endDate),
            'day',
            '[]'
          ) &&
          moment(report.endDate).isBetween(
            moment(filterCriteria.startDate),
            moment(filterCriteria.endDate),
            'day',
            '[]'
          )
        : true;

    if (!inDateRange) return false; // Если отчет не попадает в диапазон, исключаем его из результатов

    // Проверяем соответствие отчета остальным критериям
    return Object.entries(filterCriteria).every(([key, value]) => {
      if (value === '' || value == null || key === 'startDate' || key === 'endDate') return true;
      if (key === 'createDt') {
        const dateReport = moment(report[key]); // Конвертируем миллисекунды в объект moment
        const dateValue = moment(value); // Value уже является объектом даты
        return dateReport.isSame(dateValue, 'day'); // Проверяем равенство дат без учёта времени
      }

      return report[key] === value; // Стандартное сравнение значений
    });
  });

  return filteredReports;
}

/**
 * Проверяет, что все поля объекта являются пустыми строками.
 * Для целей фильтрации, если все поля пусты, фильтровать не нужно.
 *
 * @param {Object} obj - Объект с параметрами для фильтрации.
 * @returns {boolean} - `true`, если хотя бы одно поле не является пустой строкой, иначе `false`.
 */
export function shouldFilter(obj) {
  // Проверяем, что obj является объектом
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  // Перебираем все поля объекта
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Проверяем, что поле не является пустой строкой
      if (obj[key] !== null && obj[key] !== undefined && obj[key].toString().trim() !== '') {
        return true; // Найдено непустое поле, нужно фильтровать
      }
    }
  }

  // Если все поля пусты или объект пуст, не нужно фильтровать
  return false;
}

import axiosInstance from '../../config/axios.config';
import { FETCH_DATA_REQUEST, SELECT_PARAMETER } from '../type';

const setSelected = (payload) => ({
  type: SELECT_PARAMETER,
  payload,
});

const fetchDataRequest = (payload) => ({
  type: FETCH_DATA_REQUEST,
  payload,
});

const getData = (parameter) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/${parameter}`);
    if (data) {
      dispatch(fetchDataRequest({ data, parameter }));
    }
  } catch (error) {
    console.log(error);
  }
};

export { getData, setSelected };

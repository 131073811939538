/* eslint-disable eqeqeq */
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResolvedPath } from 'react-router';

/**
 * Функция мягкого управления изменением URL
 * В функции изменения можно изменить существующий инстанс, все изменения будут применены для URL
 * @return {(function(function(URL): (void|URL)): void)|*}
 */
export default function useUrlNavigate() {
  const navigate = useNavigate();
  const parentRouterPath = useResolvedPath('.').pathname;
  /** @type {(function(function(URL, string): void | URL): void)|*}*/
  const changeURL = useCallback((fn) => {
    const saved = new URL(window.location.href);
    let url = new URL(window.location.href);
    let other = fn(url, parentRouterPath) || url;
    if (other.toString() != saved.toString()) {
      navigate({
        hash: other.hash,
        search: other.search,
        pathname: other.pathname
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, navigate]);
  return changeURL;
}

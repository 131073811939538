import {
  INIT_UIPPS,
  EDIT_UIPPS_STATUS,
  ADD_UIPPS_COORDS,
  CLEAR_UIPPS,
  UPDATE_SINGLE_UIPPS,
  ADD_NEW_UIPPS,
  INIT_UPDATE_UIPPS,
} from '../type';
import { mergeData } from '../../utils/mergeData';
import { convertFormDataToObject } from '../../utils/formUtils';

export function uippsReducer(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_UIPPS: {
      //Проверяем, есть ли в payload уже существующие id. Как показала практика, это возможно
      // Создаем Set с существующими id
      const existingIds = new Set(state.map((item) => item.id));

      // Фильтруем payload, оставляя только уникальные элементы
      const newPayload = payload.filter((item) => !existingIds.has(item.id));

      // Добавляем уникальные элементы к существующему состоянию
      return [...state, ...newPayload];
    }

    case CLEAR_UIPPS: {
      return payload;
    }

    case INIT_UPDATE_UIPPS: {
      return payload;
    }

    case UPDATE_SINGLE_UIPPS: {
      const updatedState = state.map((uipps) => {
        if (uipps.id === Number(payload.id)) {
          return { ...uipps, ...payload };
        }
        return uipps;
      });
      return updatedState;
    }

    case ADD_NEW_UIPPS: {
      const newDevice = convertFormDataToObject(payload);
      // Возвращаем новый массив с добавленным устройством
      return [newDevice[0], ...state];
    }

    case ADD_UIPPS_COORDS: {
      const updatedState = mergeData(state, payload);
      return updatedState;
    }

    case EDIT_UIPPS_STATUS: {
      const updatedUipps = state.map((uipps) => {
        if (uipps.id === Number(payload.deviceId)) {
          return { ...uipps, ...payload };
        }
        return uipps;
      });
      return updatedUipps;
    }

    default:
      return state;
  }
}

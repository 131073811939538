import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Компоненты пользовательского интерфейса
import '../../styles/Reports.css';
import UniversalFormForReports from './UniversalFormForReports';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomContainer from '../CustomElements/CustomContainer';
import Preloader from '../CustomElements/Preloader';

import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/user.action';
import { getContractReportFromServer } from '../../redux/actions/documentation.action';
import { remove } from '../../redux/actions/loader.action';
import { useRole } from '../../customHook/useRole';

function Reports(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    // setReportForm(initialReportForm)
    dispatch(remove());
  };

  const isReports = useRole('reports');
  const isCustomer = useRole('customer');
  // Состояние для формы contractReport
  const initialReportForm = {
    roadId: '',
    departmentId: '',
    directorateId: '',
    startDate: '',
    endDate: '',
  };

  const [uippsSummaryReport, setUippsSummaryReport] = useState({
    ...initialReportForm,
    reportType: 'summary',
    urlPath: 'uipps'
  });
  const [uippsDetailedReport, setUippsDetaileReport] = useState({
    ...initialReportForm,
    reportType: 'detailed',
    urlPath: 'uipps'
  });
  const [contractReport, setContractReport] = useState({
    ...initialReportForm,
    reportType: 'onOff',
  });

  const navigate = useNavigate();
  const signOut = useSignOut();
  function logout() {
    dispatch(logoutUser({ logout: true }));
    signOut();
    navigate('/login');
  }

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, открываем модальное окно отчета
    if (!loader?.isLoading && loader?.isSuccess) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);
  return (
    <div className="reports">
      {/* тайтл и кнопка выхода */}
      <div className="reports-header">
        <div className="reports-title">ОТЧЕТНОСТЬ</div>
        <button type="button" className="btn btn-dark header-for-object_btn-logout" href="#" onClick={logout}>
          Выход
          <img className="header-for-object_icon" src="/image/exit.svg" alt=""></img>
        </button>
      </div>

      {/* формы */}
      <div className="reports-form">
        {/* отчет 1 */}
        {isReports && (
          <>
            <UniversalFormForReports
              title="Сводный отчет по использованию УИППС"
              action={getContractReportFromServer}
              optionalFields={['departmentId']}ч
              reportForm={uippsSummaryReport}
              initialReportForm={initialReportForm}
              setReportForm={setUippsSummaryReport}
            />

            {/* отчет 2 */}
            <UniversalFormForReports
              title="Детализированный отчет по использованию УИППС"
              action={getContractReportFromServer}
              optionalFields={['departmentId']}
              reportForm={uippsDetailedReport}
              initialReportForm={initialReportForm}
              setReportForm={setUippsDetaileReport}
            />
          </>
        )}
        {/* отчет 3 contractReport */}

        {isCustomer && (
          <UniversalFormForReports
            title="Отчет по использованию САДР"
            action={getContractReportFromServer}
            reportForm={contractReport}
            initialReportForm={initialReportForm}
            setReportForm={setContractReport}
          />
        )}
      </div>
      <Preloader isLoading={loader?.isLoading} />
      <Modal isOpen={isOpen} onClose={closeModal} width="800px" height="500px">
        <CustomContainer>
          <ConfirmationPage
            addNew="К форме отчета"
            confirmText="Отчет сформирован!"
            onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
          />
        </CustomContainer>
      </Modal>
    </div>
  );
}

export default Reports;

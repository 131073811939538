// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devices {
  display: flex; 
  background-color: var(--sadr-background);
  width: 100%;
  height: 100vh;
}

.devices_content{
  display: flex;
  flex-direction: column;
  margin-right: var(--sadr-font-size-10);
}
/* .devices {
  display: grid;
  grid-template-columns: auto 1fr; 
  background-color: var(--sadr-background);
  width: 100%;
  height: 100vh;
}

.devices_content {
  display: grid;
  grid-template-rows: auto; 
  width: 100%;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/Devices.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wCAAwC;EACxC,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sCAAsC;AACxC;AACA;;;;;;;;;;;;GAYG","sourcesContent":[".devices {\n  display: flex; \n  background-color: var(--sadr-background);\n  width: 100%;\n  height: 100vh;\n}\n\n.devices_content{\n  display: flex;\n  flex-direction: column;\n  margin-right: var(--sadr-font-size-10);\n}\n/* .devices {\n  display: grid;\n  grid-template-columns: auto 1fr; \n  background-color: var(--sadr-background);\n  width: 100%;\n  height: 100vh;\n}\n\n.devices_content {\n  display: grid;\n  grid-template-rows: auto; \n  width: 100%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

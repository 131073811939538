// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-for-archive_cell_id {
  display: flex;
  align-items: center;
}

.table-for-archive_cell_id_img {
  margin-right: var(--sadr-font-size-10);
}

.table-for-archive_container{
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  height: 100vh;
  overflow: auto;
  border-radius: 0 0 var(--sadr-font-size-12)  var(--sadr-font-size-12) ;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TableForArchive.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;EAC9B,aAAa;EACb,cAAc;EACd,sEAAsE;AACxE","sourcesContent":[".table-for-archive_cell_id {\n  display: flex;\n  align-items: center;\n}\n\n.table-for-archive_cell_id_img {\n  margin-right: var(--sadr-font-size-10);\n}\n\n.table-for-archive_container{\n  display: flex;\n  flex-direction: column;\n  align-content: space-between;\n  justify-content: space-between;\n  height: 100vh;\n  overflow: auto;\n  border-radius: 0 0 var(--sadr-font-size-12)  var(--sadr-font-size-12) ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

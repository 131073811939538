import React from 'react';
import '../../styles/CustomButtonRemove.css';

function CustomButtonRemove({ onClick, isDisabled }) {
  // Обработчик клика, который проверяет, активна ли кнопка
  const handleRemoveClick = (event) => {
    if (!isDisabled) {
      onClick(event);
    }
    return;
  };
  const buttonClass = `custom-button-remove ${isDisabled ? 'disabled' : ''}`;

  return (
    <div className={buttonClass} onClick={handleRemoveClick}>
      <img src="/image/delete.svg" alt="Remove" className="remove-icon" />
    </div>
  );
}

export default CustomButtonRemove;

/**
 * Обертка для socket.emit, которая автоматически включает токены аутентификации в данные события.
 *
 * @param {Socket} socket - Экземпляр сокета для отправки события.
 * @param {string} event - Название события, которое нужно отправить.
 * @param {Object} data - Объект данных, который будет отправлен с событием.
 */
export const emitWithTokens = (socket, event, data = {}) => {
  // Извлекаем токены из локального хранилища
  // const accessToken = localStorage.getItem('accessToken');
  // const refreshToken = localStorage.getItem('refreshToken');
  // Добавляем токены к передаваемым данным
  const dataWithTokens = {
    ...data,
    // accessToken,
    // refreshToken,
  };
  // Отправляем событие с добавленными токенами

  socket?.emit(event, dataWithTokens);
};

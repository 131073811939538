// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100004; /* Убедитесь, что модальное окно находится над другими элементами */
}

.error-modal-content {
  background-color: white;
  padding: var(--sadr-font-size-20);
  border-radius:  var(--sadr-font-size-5) ;
  box-shadow: 0 0 var(--sadr-font-size-10)var(--sadr-border-shadow);
  position: relative; /* Для позиционирования кнопки закрытия */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error-modal-close-button {
  position: absolute;
  top: var(--sadr-font-size-10);
  right: var(--sadr-font-size-10);
  border: none;
  background: none;
  font-size: var(--sadr-font-size-20);
  cursor: pointer;
}

.error-modal-text{
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  text-align: center;
  color: var(--sadr-text-color);
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ErrorModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAE,mEAAmE;AACtF;;AAEA;EACE,uBAAuB;EACvB,iCAAiC;EACjC,wCAAwC;EACxC,iEAAiE;EACjE,kBAAkB,EAAE,yCAAyC;EAC7D,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,+BAA+B;EAC/B,YAAY;EACZ,gBAAgB;EAChB,mCAAmC;EACnC,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".error-modal-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100004; /* Убедитесь, что модальное окно находится над другими элементами */\n}\n\n.error-modal-content {\n  background-color: white;\n  padding: var(--sadr-font-size-20);\n  border-radius:  var(--sadr-font-size-5) ;\n  box-shadow: 0 0 var(--sadr-font-size-10)var(--sadr-border-shadow);\n  position: relative; /* Для позиционирования кнопки закрытия */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.error-modal-close-button {\n  position: absolute;\n  top: var(--sadr-font-size-10);\n  right: var(--sadr-font-size-10);\n  border: none;\n  background: none;\n  font-size: var(--sadr-font-size-20);\n  cursor: pointer;\n}\n\n.error-modal-text{\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 400;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  text-align: center;\n  color: var(--sadr-text-color);\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/**
 * Функция для добавления данных о координатах и статусе онлайн из `coordsData` к объектам в массиве `data`.
 * Если для элемента массива `data` в `coordsData` нет соответствующих данных, `lat` и `lon` устанавливаются как `null`, а `online` как `false`.
 * 
 * @param {Array<Object>} data Массив объектов, которые будут обогащены новыми данными. Каждый объект должен содержать свойство `id`.
 * @param {Object} coordsData Объект с дополнительными данными, где ключ - это `id` устройства, а значение - объект с полями `lat`, `lon`, и `online`.
 * @returns {Array<Object>} Новый массив объектов с объединенными данными.
 */
export function mergeData(data, coordsData) {
  if (!data || !data.length) {
    return [];
  }
  return data?.map(item => {
    const info = coordsData[item.id.toString()];
    if (info) {
      // Если есть информация для текущего id, добавляем к объекту
      return {
        ...item,
        lat: info.lat || null,
        lon: info.lon || null,
        online: info.online
      };
    } else {
      // Если информации нет, устанавливаем lat и lon как null, online как false
      return {
        ...item,
        lat: null,
        lon: null,
        online: false
      };
    }
  });
}

import React from 'react';
import '../../styles/CustomContainer.css';

function CustomContainer({ children, width, height, margin, className, ...props }) {
  // Создаем объект стилей на основе пропсов
  const containerStyle = {
    width: width,
    height: height,
    margin: margin,
  };

  return (
    <div className={`custom-container ${className ? className : ''}`} style={containerStyle} {...props}>
      {children}
    </div>
  );
}

export default CustomContainer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kuobzes {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  margin: 0 var(--sadr-font-size-20) var(--sadr-font-size-20) var(--sadr-font-size-20);
}

.kuobze_btn-container{
  display: flex;
  justify-content: center;
  margin-bottom: var(--sadr-font-size-20);
  padding: var(--sadr-font-size-20);
  background-color: var(--sadr-background-secondary);
  border-bottom-left-radius: var(--sadr-font-size-12) ;
  border-bottom-right-radius: var(--sadr-font-size-12) ;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Kuobzes.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;EAC9B,YAAY;EACZ,aAAa;EACb,cAAc;EACd,oFAAoF;AACtF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uCAAuC;EACvC,iCAAiC;EACjC,kDAAkD;EAClD,oDAAoD;EACpD,qDAAqD;AACvD","sourcesContent":[".kuobzes {\n  display: flex;\n  flex-direction: column;\n  align-content: space-between;\n  justify-content: space-between;\n  width: 100vw;\n  height: 100vh;\n  overflow: auto;\n  margin: 0 var(--sadr-font-size-20) var(--sadr-font-size-20) var(--sadr-font-size-20);\n}\n\n.kuobze_btn-container{\n  display: flex;\n  justify-content: center;\n  margin-bottom: var(--sadr-font-size-20);\n  padding: var(--sadr-font-size-20);\n  background-color: var(--sadr-background-secondary);\n  border-bottom-left-radius: var(--sadr-font-size-12) ;\n  border-bottom-right-radius: var(--sadr-font-size-12) ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

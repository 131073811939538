import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/CustomButtonEdit.css';

function CustomButtonEdit({ onClick, margin, disabled, className }) {
  const buttonStyle = {
    margin: margin,
  };
  return (
    <button
      className={'custom-button-edit ' + (className ?? '')}
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      <img src="/image/pen.svg" alt="Edit" className="edit-icon" />
    </button>
  );
}

CustomButtonEdit.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomButtonEdit;

import { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/AdjacencyEdit.css';
import Table from '../Table/Table';
import Selector from '../CustomElements/Selector';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import CustomButton from '../CustomElements/CustomButton';
import Preloader from '../CustomElements/Preloader';

import { useLoader } from './Common';
import { initOptionsRailChainsFromServer } from '../../redux/actions/options.action';
import { saveAdjacencyFromServer } from '../../redux/actions/infocards.action';
import { load, remove } from '../../redux/actions/loader.action';

function AdjacencyEdit({ closeModal }) {
  const dispatch = useDispatch();

  //получаем id текущей смежности
  const { adjacency } = useParams();

  // хук для отслеживания процесса запросов
  const { isLoading, isSuccess } = useLoader();

  // получаем опции из стейта
  const selectOptions = useSelector((state) => state?.options);

  // получаем все смежности из стейта
  const adjacencys = useSelector((s) => s.infocards.adjacency);

  // Функция для поиска объекта по rail_chain
  const findObjectByRailChain = (railChain) => {
    return adjacencys?.find((item) => item.rail_chain === Number(railChain));
  };

  //стейт для текущей смежность
  const [currentAdjacency, setCurrentAdjacency] = useState();

  // заменяем нулы на объекты, для отображения в таблице
  const replaceNullsInAdjacency = (currentAdjacency) => {
    if (!currentAdjacency?.adjacency?.length) {
      return [{ station: currentAdjacency?.station, rail_chain: '' }];
    }
    return currentAdjacency.adjacency;
  };

  // состояние для  сета из rail_chain в adjacency
  const [excludedRailChains, setExcludedRailChains] = useState(new Set());

  //добавление смежности
  const handleAdd = () => {
    setCurrentAdjacency((prev) => ({
      ...prev,
      adjacency: [
        ...prev.adjacency,
        {
          rail_chain: '',
          name: '',
          station: prev?.station,
          station_name: prev?.station_name,
        },
      ],
    }));
  };

  // Удаление смежности по индексу
  const removeAdjacencyByIndex = (index) => {
    setCurrentAdjacency((prevState) => {
      const updatedAdjacency = [...prevState.adjacency]; // Создаем копию массива
      updatedAdjacency.splice(index, 1); // Удаляем элемент по индексу

      return {
        ...prevState,
        adjacency: updatedAdjacency,
      };
    });
  };

  // Проверяем, были ли изменения в смежностях
  const isAdjacencyChanged = useMemo(() => {
    const oldAdjacency = findObjectByRailChain(adjacency);
    // Сравниваем текущее значение с исходным
    return JSON.stringify(oldAdjacency?.adjacency) !== JSON.stringify(currentAdjacency?.adjacency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdjacency, adjacency]);

  // Проверяем, что все rail_chain в adjacency заполнены
  const areAllRailChainsFilled = useMemo(() => {
    return currentAdjacency?.adjacency?.every((adj) => Boolean(adj.rail_chain));
  }, [currentAdjacency]);

  // Если изменений нет, нет смежностей или не все rail_chain заполнены, блокируем кнопку сохранить
  const isDisabled = !currentAdjacency?.adjacency?.length || !isAdjacencyChanged || !areAllRailChainsFilled;

  // сохранение изменений для смежности
  const handleSave = () => {
    // Переводим в состояние загрузки
    dispatch(load());
    const payload = {
      rail_chain: currentAdjacency.rail_chain,
      adjacency: Array.from(excludedRailChains),
    };
    dispatch(saveAdjacencyFromServer(payload, currentAdjacency?.station));
  };

  // Функция для получения всех опций и отфильтрованных опций
  const getOptionsAndFilteredOptionsForRC = (selectOptions, originalStation, excludedRailChains) => {
    const options = selectOptions?.railChainsPerStation?.[originalStation] || [];
    const filteredOptionsForRC = options.filter((option) => !excludedRailChains.has(option.value));
    return { options, filteredOptionsForRC };
  };

  // Обработчик изменения рельсовой цепи
  const handleRailChainChange = (selectedOption, index, value) => {
    const newRailChain = selectedOption?.value || '';
    const newName = selectedOption?.label || '';

    setExcludedRailChains((prev) => {
      const newSet = new Set(prev);
      newSet.delete(value); // Удаляем предыдущее значение из сета excludedRailChains
      if (selectedOption?.value) {
        newSet.add(selectedOption?.value); // Добавляем выбранное значение в excludedRailChains
      }
      return newSet;
    });

    setCurrentAdjacency((prev) => {
      const updatedAdjacency = [...prev.adjacency]; // Создаем копию массива
      updatedAdjacency[index] = {
        ...updatedAdjacency[index], // Сохраняем остальные свойства элемента
        rail_chain: newRailChain,
        name: newName,
      };
      return { ...prev, adjacency: updatedAdjacency };
    });
  };

  // Обработчик изменения станции
  const handleStationChange = (selectedOption, index) => {
    const newStation = selectedOption?.value || '';
    const newStationName = selectedOption?.label || '';

    setCurrentAdjacency((prev) => {
      const updatedAdjacency = [...prev.adjacency]; // Создаем копию массива
      updatedAdjacency[index] = {
        rail_chain: '',
        name: '',
        station: newStation,
        station_name: newStationName,
      };

      return { ...prev, adjacency: updatedAdjacency };
    });
  };

  // колонки для таблицы смежности
  const columns = useMemo(() => {
    return [
      {
        Header: 'Станция',
        accessor: 'station',
        Cell: ({ cell: { value }, row: { index } }) => {
          return (
            <Selector
              options={selectOptions?.stations}
              onChange={(selectedOption) => {
                handleStationChange(selectedOption, index);
              }}
              placeholder={'Выберите станцию'}
              value={selectOptions?.stations?.find((option) => option.value === value) || ''}
              isMulti={false}
            />
          );
        },
      },
      {
        Header: 'Смежная РЦ',
        accessor: 'rail_chain',
        Cell: ({ cell: { value }, row: { original, index } }) => {
          // получаем опции
          const { options, filteredOptionsForRC } = useMemo(() => {
            return getOptionsAndFilteredOptionsForRC(selectOptions, original?.station, excludedRailChains);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectOptions, original?.station, excludedRailChains]);
          return (
            <Selector
              options={filteredOptionsForRC}
              onChange={(selectedOption) => handleRailChainChange(selectedOption, index, value)}
              placeholder={'Выберите рельсовую цепь'}
              value={options?.find((option) => option.value === value) || ''}
              isMulti={false}
            />
          );
        },
      },
      {
        Header: 'Действия',
        accessor: '.',
        Cell: ({ row: { index } }) => {
          return (
            <div>
              <CustomButtonDelete
                onClick={() => {
                  removeAdjacencyByIndex(index);
                }}
              />
            </div>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdjacency, selectOptions, excludedRailChains]);

  //сохраняем в стейт текущую смежность
  useEffect(() => {
    // Находим текущую смежность
    const foundAdjacency = findObjectByRailChain(adjacency);

    // Заменяем null значения перед записью в стейт
    const processedAdjacency = {
      ...foundAdjacency,
      adjacency: replaceNullsInAdjacency(foundAdjacency),
    };

    // Записываем в стейт текущую смежность
    setCurrentAdjacency(processedAdjacency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjacencys]);

  // сохраняем в стейт сет для контроля опций
  useEffect(() => {
    const trueValues = currentAdjacency?.adjacency?.map((adj) => adj.rail_chain).filter(Boolean) || [];
    setExcludedRailChains(new Set(trueValues));
  }, [currentAdjacency]);

  //запрашиваем опции рц для всех станций смежности
  useEffect(() => {
    if (currentAdjacency?.adjacency) {
      currentAdjacency.adjacency.forEach((adj) => {
        if (adj.station) {
          dispatch(initOptionsRailChainsFromServer(adj.station));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdjacency]);

  useEffect(() => {
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
  }, [dispatch]);

  return (
    <div className="d-flex container-fluid flex-column justify-content-start align-items-center h-100 adjacency-container">
      <h3 className="modal-title">{`РЕДАКТИРОВАНИЕ СМЕЖНОСТИ ${currentAdjacency?.name}`}</h3>
      <h5 className="mb-3">
        {currentAdjacency?.road_name}, {currentAdjacency?.station_name}
      </h5>
      {isLoading && (
        <div className="place-in-middle">
          <Preloader isLoading />
        </div>
      )}
      {!isLoading && isSuccess && (
        <div className="place-in-middle">
          <h5 className="confirmation-title">Редактирование смежности завершено успешно</h5>
          <CustomButton
            className="confirmation-btn confirm"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={'К списку смежности'}
            onClick={closeModal}
          />
        </div>
      )}
      {!isLoading && !isSuccess && (
        <>
          <Table columns={columns} data={currentAdjacency?.adjacency || []} />

          <div className="d-flex modal-footer pt-4">
            <CustomButton
              className="confirmation-btn align-self-center"
              text="+ Добавить"
              onClick={handleAdd}
              disabled={!areAllRailChainsFilled}
            />
            <CustomButton
              className="confirmation-btn confirm"
              text="Сохранить"
              onClick={handleSave}
              disabled={isDisabled}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AdjacencyEdit;

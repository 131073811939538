// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brigade-place-work-btn-container {
  display: flex;
  justify-content: center;
  margin: var(--sadr-font-size-20);
}

.brigade-place-work-table-container {
  padding: var(--sadr-font-size-10);
}

.brigade-place-work-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--sadr-font-size-30);
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  text-transform: uppercase;
  white-space: nowrap;
}

.brigade-place-work-text {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  text-align: center;
  text-transform: uppercase;
  color: var(--sadr-text-color);
  margin-left: var(--sadr-font-size-10);
}

.brigade-place-work-map-container {
  width: 100%;
  height: var(--sadr-font-size-600);
  padding: var(--sadr-font-size-30);
}

.edit-place .cancel {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/BrigadePlaceWork.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gCAAgC;AAClC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;EACvC,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,kBAAkB;EAClB,yBAAyB;EACzB,6BAA6B;EAC7B,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".brigade-place-work-btn-container {\n  display: flex;\n  justify-content: center;\n  margin: var(--sadr-font-size-20);\n}\n\n.brigade-place-work-table-container {\n  padding: var(--sadr-font-size-10);\n}\n\n.brigade-place-work-info {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: var(--sadr-font-size-30);\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  text-transform: uppercase;\n  white-space: nowrap;\n}\n\n.brigade-place-work-text {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  text-align: center;\n  text-transform: uppercase;\n  color: var(--sadr-text-color);\n  margin-left: var(--sadr-font-size-10);\n}\n\n.brigade-place-work-map-container {\n  width: 100%;\n  height: var(--sadr-font-size-600);\n  padding: var(--sadr-font-size-30);\n}\n\n.edit-place .cancel {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

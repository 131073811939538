import React from 'react';
import '../../styles/DeviceHeader.css';
import { dateToString } from '../../utils/convertToDate';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import { formatValue } from '../../utils/formatValue';

/**
 *
 * @param device - текущий девайс
 * @param onEdit {()->void} - Обработчик редактирования
 * @param onSubmit {()=>void} - Обработчик "Выполнить ТО"
 * @return {Element}
 * @constructor
 */
export function DeviceHeader({ device, onEdit, onService }) {
  return (
    <div className="main-device-container container-fluid row sadr-gap-40">
      {/* Колонка 1  устройство и статус */}
      <div className="d-flex flex-column col-auto">
        <div className="device-header">
          {device?.type} №{device?.id}
        </div>
        <div className="device-status-container d-flex flex-row justify-content-center align-items-center">
          <div className="circle"></div>
          <span className="device-status">{device?.status}</span>
        </div>
      </div>

      {/*/!* Колонка 2 с информацией о SIM-картах *!/*/}
      <div className="device-text d-flex flex-column col-auto">
        <span>
          <span className="device-text-title">SIM1: </span>
          <span className="number-cell">{formatValue(device?.sim1) ?? ''}</span>
        </span>
        <span>
          <span className="device-text-title">SIM2: </span>
          <span className="number-cell">{formatValue(device?.sim2) ?? ''}</span>
        </span>
        <span>
          <span className="device-text-title">Дата производства: </span>
          <span className="number-cell"> {dateToString(device?.manufacture_date)}</span>
        </span>
      </div>

      {/* Колонка 3 с датами ТО */}
      <div className="device-text d-flex flex-column col-auto">
        <span>
          <span className="device-text-title">Дата последнего ТО: </span>
          <span className="number-cell"> {dateToString(device?.last_maintanance_date)}</span>
        </span>
        <span>
          <span className="device-text-title">Дата следующего ТО: </span>
          <span className="number-cell"> {dateToString(device?.next_maintanance_date)}</span>
        </span>
        {device.status === 'Списан' && (
          <>
            <span>
              <span className="device-text-title">Дата списания: </span>
              <span className="number-cell"> {dateToString(device?.write_off_date)}</span>
            </span>
            <span className="writeoff-text">
              <span className="device-text-title">Причина списания: </span>
              <span className="number-cell">{device?.write_off_reason}</span>
            </span>
          </>
        )}
      </div>

      {/* Кнопки */}
      <CustomButtonEdit className="device-edit-btn ms-auto mb-auto w-auto" onClick={onEdit} />

      {device.status !== 'Списан' && (
        <CustomButton className="device-bottom-btn col-auto ms-auto mt-auto" text="Выполнить ТО" onClick={onService} />
      )}
    </div>
  );
}

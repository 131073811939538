import { LOAD, SUCCESS, REMOVE } from "../type";

export const load = (error) => ({
  type: LOAD 
})

export const success = () => ({
  type: SUCCESS 
})

export const remove = () => ({
  type: REMOVE 
})

// Функция для проверки попадания значения GSM в заданный диапазон
function isGSMInRange(value, gsm) {
  switch (value) {
    case 1:
      return gsm <= 0 && gsm >= -60;
    case 2:
      return gsm < -60 && gsm >= -70;
    case 3:
      return gsm < -70 && gsm >= -80;
    case 4:
      return gsm < -80 && gsm >= -90;
    case 5:
      return gsm < -90;
    default:
      return false;
  }
}

export function filterSignals(signals, filterCriteria) {
  const signalsFiltered = signals?.filter((item) => {
    return Object.keys(filterCriteria).every((key) => {
      const value = filterCriteria[key];

       //проверка для поля distance
      if(key === 'distance' && value !== ''){
        return item[key] <= value
      }
       //проверка для поля preciseDistance
      if(key === 'preciseDistance' && value !== ''){
        return item[key] <= value
      }
 
      //проверка для поля safeZoneWidth (ширина БЗ)
      if(key === 'safeZoneWidth' && value !== ''){
        return item[key] >= value
      }
      // Проверка для полей iurGPS kuobzGPS
      if ((key === 'iurGPS' || key === 'kuobzGPS') && value !== '') {
        return item[key] >= value
      }

      // Проверка для полей iurGSM и kuobzGSM 
      if ((key === 'iurGSM' || key === 'kuobzGSM') && value !== '') {
        // Преобразуем item[key] к числу для сравнения
        const gsmValue = Number(item[key]);
        return isGSMInRange(value, gsmValue);
      }

      // Особая обработка для полей btn_alert, btn_clear, fencingBtnServer
      if ((key === 'btn_alert' || key === 'btn_clear' || key === 'fencingBtnServer') && value === true) {
        return Boolean(item[key]) === value;
      }

      // Проверка для поля transferRails
      if (key === 'transferRails') {
        if (value === true) {
          // Возвращает true, если transferRails существует и не является пустой строкой
          return item[key] !== undefined && item[key] !== '';
        } else {
          // Возвращает true для всех объектов, когда value === false, 
          // т.е. фильтрация не применяется
          return true;
        }
      }
      
      // Для поля 'index' преобразуем значения к числу перед сравнением, если они не пустые
      if ((key === 'index' || key ===  'iurGPS')&& value !== '') {
        return Number(item[key]) === Number(value);
      }
      
      // Пропускаем фильтрацию по полю, если значение в критериях пустая строка
      return value === '' || item[key] === value;
    });
  });

  return signalsFiltered;
}

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../Types/type.js';
// Компоненты пользовательского интерфейса
import FormContainer from '../CustomElements/FormContainer.jsx';
import Input from '../CustomElements/Input.jsx';
import CustomButton from '../CustomElements/CustomButton.jsx';
import CustomContainer from '../CustomElements/CustomContainer.jsx';
import CustomTitle from '../CustomElements/CustomTitle.jsx';
import ConfirmationPage from '../CustomElements/ConfirmationPage.jsx';
import CustomTextarea from '../CustomElements/CustomTextarea.jsx';

// Кастомные хуки
import { useButtonValidation } from '../../customHook/useButtonValidation.js';
import useOptionalFieldsForAddRC from '../../customHook/useOptionalFieldsForAddRC';

// Утилиты
import { handleUniversalChange } from '../../utils/formUtils.js';
import { createPayloadForNewRC } from '../../utils/createPayloadForNewRC.js';
import { coordinateFormat, isCoordinateAllowed } from '../../utils/coordinateFormat.js';
import { useInfocard } from './Common.jsx';
import { parseCoordinatesString } from '../../utils/railChainsTableInputChangeHandlers';

// Действия Redux
import { addRailChainsFromServer } from '../../redux/actions/infocards.action.js';
import { load, remove } from '../../redux/actions/loader.action.js';

// компонент для добавления РЦ
function AddRailChains({ closeModal }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // получаем id дориги и станции
  const { station, road } = useInfocard();

  // состояние для сообщения обошибке
  const [messageError, setMessageError] = useState(false);

  // состояние для отображения успешного добавления
  const [addSuccess, setAddSuccess] = useState(false);

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    closeModal();
    setAddSuccess(false);
  };

  // Состояние для формы
  /**
   * @type {FormDataAddRailCains}
   */
  const [formData, setFormData] = useState({
    id: '',
    road: road,
    station: station,
    object: '',
    railChainName: '',
    //Длина участка оповещения
    alertLengthsPCh: '',
    alertLengthsECh: '',
    // Кординаты концов РЦ
    coordinateEndLat: '',
    coordinateEndLon: '',
    ordEnd: '',
    //Кординаты контролируемое ответвление
    coordinateControlledLatA: '',
    coordinateControlledLonA: '',
    controlledOrdA: '',
    coordinateControlledLatB: '',
    coordinateControlledLonB: '',
    controlledOrdB: '',
    coordinateControlledLatV: '',
    coordinateControlledLonV: '',
    controlledOrdV: '',
    coordinateControlledLatG: '',
    coordinateControlledLonG: '',
    controlledOrdG: '',
    //Кординаты неконтролируемое ответвление
    coordinateUncontrolledLatN1: '',
    coordinateUncontrolledLonN1: '',
    uncontrolledOrdN1: '',
    coordinateUncontrolledLatN2: '',
    coordinateUncontrolledLonN2: '',
    uncontrolledOrdN2: '',
    coordinateUncontrolledLatN3: '',
    coordinateUncontrolledLonN3: '',
    uncontrolledOrdN3: '',
    //Кординаты трек
    track: '',
  });

  // состояние для необязательных полей
  const optionalFields = useOptionalFieldsForAddRC(formData);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  const onApply = () => {
    // проверяем строку трек, если есть невалидные значения записываем в стейт наличие ощибки
    const { invalidCoordinatesIds } = parseCoordinatesString(formData?.track, formData?.id);
    if (invalidCoordinatesIds?.length) {
      setMessageError(true);
      return;
    }
    const payload = createPayloadForNewRC(formData);
    dispatch(load());
    dispatch(addRailChainsFromServer(station, payload));
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      setAddSuccess(true);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  // отслеживаем поле трек при изменении  стираем сообщение об ошибке
  useEffect(() => {
    setMessageError('');
  }, [formData?.track]);

  // проверка если поле обязателное ставим звездочку
  const getLabel = (fieldName, label) => {
    const isRequired = !optionalFields.includes(fieldName);
    return isRequired ? `${label} *` : label;
  };

  return (
    <CustomContainer>
      <CustomTitle titleText={'ДОБАВЛЕНИЕ НОВОЙ РЕЛЬСОВОЙ ЦЕПИ'} />
      {addSuccess && (
        <ConfirmationPage
          confirmText={'Рельсовая цепь успешно добавлена'}
          onConfirm={handlerCloseModal}
          addNew={'К списку РЦ'}
        />
      )}
      {!addSuccess && (
        <>
          <FormContainer id={467535}>
            <Input
              column="left"
              placeholder="Введите ID РЦ"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="id"
              label="ID рельсовой цепи *"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              // type="number"
            />
            <Input
              column="center"
              placeholder="Введите название РЦ"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="railChainName"
              label="Рельсовая цепь *"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
            />
            <Input
              column="right"
              placeholder="Введите название объекта"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="object"
              label="Объект *"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
            />
          </FormContainer>
          <h4 className="add-rail-chain-title mb-3">Длина участка оповещения</h4>
          <FormContainer id={46758935}>
            <Input
              column="left"
              placeholder="Введите ПЧ"
              onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 4)}
              name="alertLengthsPCh"
              label="ПЧ"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="9999"
            />
            <Input
              column="center"
              placeholder="Введите ЭЧ"
              onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 4)}
              name="alertLengthsECh"
              label="ЭЧ"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="9999"
            />
          </FormContainer>
          <h4 className="add-rail-chain-title mb-3">Питающий конец</h4>
          <FormContainer id={46997535}>
            <Input
              column="left"
              label={getLabel('coordinateEndLat', 'Питающий конец (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateEndLat"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateEndLon', 'Питающий конец (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateEndLon"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату питающего конца"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="ordEnd"
              label="Питающий конец (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
          </FormContainer>
          <h4 className="add-rail-chain-title mb-3">Контролируемое ответвление</h4>
          <FormContainer id={46535}>
            {/* "А" релейный конец */}
            <Input
              column="left"
              label={getLabel('coordinateControlledLatA', '«А» релейный конец (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLatA"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateControlledLonA', '«А» релейный конец (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLonA"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="controlledOrdA"
              label="«А» релейный конец  (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* "Б"  */}
            <Input
              column="left"
              label={getLabel('coordinateControlledLatB', '«Б»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLatB"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateControlledLonB', '«Б»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLonB"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="controlledOrdB"
              label="«Б» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* "В"  */}
            <Input
              column="left"
              label={getLabel('coordinateControlledLatV', '«В»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLatV"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateControlledLonV', '«В»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLonV"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="controlledOrdV"
              label="«В» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* "Г"  */}
            <Input
              column="left"
              label={getLabel('coordinateControlledLatG', '«Г»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLatG"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateControlledLonG', '«Г»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateControlledLonG"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="controlledOrdG"
              label="«Г» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
          </FormContainer>
          <h4 className="add-rail-chain-title mb-3">Неконтролируемое ответвление</h4>
          <FormContainer id={46757735}>
            {/* "H1"  */}
            <Input
              column="left"
              label={getLabel('coordinateUncontrolledLatN1', '«H1»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLatN1"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateUncontrolledLonN1', '«H1»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLonN1"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="uncontrolledOrdN1"
              label="«H1» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* "H2"  */}
            <Input
              column="left"
              label={getLabel('coordinateUncontrolledLatN2', '«H2»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLatN2"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateUncontrolledLonN2', '«H2»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLonN2"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="uncontrolledOrdN2"
              label="«H2» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* "H3"  */}
            <Input
              column="left"
              label={getLabel('coordinateUncontrolledLatN3', '«H3»  (широта)')}
              placeholder="Введите широту"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLatN3"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="center"
              label={getLabel('coordinateUncontrolledLonN3', '«H3»  (долгота)')}
              placeholder="Введите долготу"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="coordinateUncontrolledLonN3"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              isCoordinate={true} // Устанавливаем, что это координатный ввод
              format={coordinateFormat} // Передаем кастомный формат
              isAllowed={isCoordinateAllowed} // Передаем кастомную валидацию
              messageInfo="Формат: DD.DDDDDD"
            />
            <Input
              column="right"
              placeholder="Введите ординату"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="uncontrolledOrdN3"
              label="«H1» (ордината)"
              width="var(--sadr-font-size-320)"
              height="var(--sadr-font-size-60)"
              mask="99999999"
            />
            {/* ТРЕК */}
            <h4 className="add-rail-chain-title mb-3">Трек</h4>
            <CustomTextarea
              label="«Трек»  (координаты) "
              placeholder="Введите координаты в формате: широта, долгота; широта, долгота;  . . . ;"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="track"
              width="100%"
              height="var(--sadr-font-size-200)"
              message={messageError ? 'Координаты в поле трек введены некорректно' : ''}
              restrictTextInput
            />
            <CustomButton
              key="add_rc"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              text="Добавить РЦ"
              onClick={onApply}
              disabled={isButtonDisabled}
              isButton
            />
          </FormContainer>
        </>
      )}
    </CustomContainer>
  );
}

export default AddRailChains;

import { useSelector } from 'react-redux';
import Selector from '../CustomElements/Selector';
import useOptionsLoader from '../../customHook/useOptionsLoader';

function CustomSelect({
  width,
  height,
  formData,
  setFormData,
  keyField,
  resetFieldsArray,
  fetchOptions,
  name,
  optionsName,
  messageForSelect,
  column,
  label,
  placeholder,
  onChange,
}) {
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  //хук для запроса опций и контроля селекта
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    resetFieldsArray,
    keyField,
    fetchOptions,
    messageForSelect
  );
  return (
    <Selector
      column={column}
      options={optionsForSelect?.[optionsName]}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      value={
        formData?.[name] ? optionsForSelect?.[optionsName]?.find((option) => option.value === formData?.[name]) : null
      }
      isMulti={false}
      name={name}
      isDisabled={directoratesControl?.isSelectorDisabled}
      message={directoratesControl?.message}
      customClick={directoratesControl?.onSelectorChange}
      width={width}
      height={height}
    />
  );
}

export default CustomSelect;

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Preloader from '../CustomElements/Preloader';

function BrigadeSafetyFenceAllowed({ handlerConfirm, onClose, text, loading, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.fenceTitle} />
      <Preloader isLoading={loading} />
      <ConfirmationPage
        confirmText={t?.fenceConfirmText}
        confirmButtonText={t?.fenceConfirmButtonText}
        cancelButtonText={t?.cancelButtonText}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={onClose} // Функция для закрытия модального окна или отмены действия
        isDisabledConfirmBtn={loading}
      />
    </CustomContainer>
  );
}

export default BrigadeSafetyFenceAllowed;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brigade-composition {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--sadr-font-size-10);
}
.brigade-composition_table-container {
  display: flex;
  margin-top: var(--sadr-font-size-20);
}
.brigade-composition_button-container {
  display: flex;
  justify-content: center;
  background: var(--sadr-background-secondary);
  padding: 0  var(--sadr-font-size-5) ;
  border: none;
}
.brigade-composition__button {
  min-width: var(--sadr-font-size-260);
}


/* Закруглить нижний левый угол первой ячейки в шапке таблицы */
.table thead tr:first-child td:first-child {
  border-bottom-left-radius: var(--sadr-font-size-12) ;
}

/* Закруглить нижний правый угол последней ячейки в шапке таблицы */
.table thead tr:first-child td:last-child {
  border-bottom-right-radius: var(--sadr-font-size-12) ;
}
`, "",{"version":3,"sources":["webpack://./src/styles/BrigadeComposition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,oCAAoC;AACtC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,4CAA4C;EAC5C,oCAAoC;EACpC,YAAY;AACd;AACA;EACE,oCAAoC;AACtC;;;AAGA,+DAA+D;AAC/D;EACE,oDAAoD;AACtD;;AAEA,mEAAmE;AACnE;EACE,qDAAqD;AACvD","sourcesContent":[".brigade-composition {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: var(--sadr-font-size-10);\n}\n.brigade-composition_table-container {\n  display: flex;\n  margin-top: var(--sadr-font-size-20);\n}\n.brigade-composition_button-container {\n  display: flex;\n  justify-content: center;\n  background: var(--sadr-background-secondary);\n  padding: 0  var(--sadr-font-size-5) ;\n  border: none;\n}\n.brigade-composition__button {\n  min-width: var(--sadr-font-size-260);\n}\n\n\n/* Закруглить нижний левый угол первой ячейки в шапке таблицы */\n.table thead tr:first-child td:first-child {\n  border-bottom-left-radius: var(--sadr-font-size-12) ;\n}\n\n/* Закруглить нижний правый угол последней ячейки в шапке таблицы */\n.table thead tr:first-child td:last-child {\n  border-bottom-right-radius: var(--sadr-font-size-12) ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/**
 * Находит и возвращает ID статуса устройства на основе его названия из предоставленного массива объектов.
 * 
 * @param {string} status - Статус устройства, для которого нужно найти ID.
 * @param {Array} statusArray - Массив объектов, каждый из которых содержит `id` и `value`, 
 *                              где `value` - это название статуса, а `id` - соответствующий идентификатор.
 * @returns {number|null} Возвращает ID статуса устройства или `null`, если статус не найден в массиве.
 */
export function getCurrentStatusId(status, statusArray) {
    // Используем метод find для поиска объекта в массиве, у которого значение  label совпадает с заданным статусом.
    const statusObject = statusArray?.find(item => item.label === status);
    // Возвращаем 'id' найденного объекта, или 'null', если соответствующий объект не найден.
    return statusObject ? statusObject.value : null;
}



const moment = require('moment');

/**
 * Фильтрует и сортирует массив объектов на основе заданных критериев. 
 * Поддерживает фильтрацию по заголовку (title) и сортировку по дате.
 *
 * @param {Object[]} data - Массив объектов для фильтрации и сортировки.
 * @param {Object} filterValue - Объект, содержащий критерии фильтрации:
 *                                - `value` может быть строкой или массивом строк, указывающими заголовки для фильтрации.
 *                                - `label` используется для отображения и может содержать специальное значение "Все действия",
 *                                  которое указывает на отсутствие необходимости в фильтрации.
 * @param {Object} sortValue - Объект, определяющий направление сортировки:
 *                              - `value` должно быть "Сначала новые" или "Сначала старые" для определения порядка сортировки дат.
 * @returns {Object[]} Отфильтрованный и отсортированный массив объектов.
 */
export function filterAndSortData(data, filterValue, sortValue) {
  // Проверка на валидность входных данных
  if (!Array.isArray(data) || !["Сначала новые", "Сначала старые"].includes(sortValue?.value)) {
    console.error("Неверные входные данные");
    return [];
  }

  let filteredData = data;

  // Фильтрация данных, если filterValue не равно "Все действия"
  if (filterValue?.label !== "Все действия") {
    filteredData = data.filter(item => {
      // Проверка на наличие item.title в массиве filterValue.value или сравнение с единичным значением
      if (Array.isArray(filterValue.value)) {
        return filterValue.value.includes(item.title);
      } else {
        return item.title === filterValue.value;
      }
    });
  }

  // Сортировка данных по дате
  const sortedData = filteredData.sort((a, b) => {
    const dateA = moment(a.create_dt), dateB = moment(b.create_dt);
    return sortValue.value === "Сначала новые" ? dateB.diff(dateA) : dateA.diff(dateB);
  });
  return sortedData;
}

import {
  ADD_DEPARTMENT_OPTIONS,
  ADD_EMPLOYEES_OPTIONS,
  ADD_SECTION_OPTIONS,
  ADD_ANDROID_ID_OPTIONS,
  DELETE_SELECT_OPTIONS,
  INIT_OPTIONS, ADD_RAILCHAIN_OPTIONS, 
  INIT_MRM_IDS,
  ADD_ANDROID_ID_OPTIONS_FOR_IUR,
  ADD_DIRECTORATES_UIPPS_OPTIONS,
  ADD_DIRECTORATES_OPTIONS,
  ADD_BRIGADES_OPTIONS,
  ADD_POSITION,
} from '../type';

import { transformOptionsArray } from '../../utils/transformOptionsArray';
import { transformObjectWithArrays } from '../../utils/transformObjectWithArrays'

export function optionsReducer(state = {}, action) {
  const { type, payload } = action
  
  switch (type) {
    case INIT_OPTIONS : {
      let options = transformObjectWithArrays(payload)
      return {
         ...options, 
         ...(state?.сondition ? { сondition: state.сondition } : {}), 
         ...(state?.optionsActions ? {  optionsActions: state?.optionsActions } : {}), 
         ...(state?.optionsDateSort ? {  optionsDateSort: state?.optionsDateSort } : {}),
         ...(state?.optionsSemaphoreDirection ? {  optionsSemaphoreDirection: state?.optionsSemaphoreDirection } : {}),
        };
    }

    case ADD_DEPARTMENT_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, departments: options}
    }
    
    case ADD_BRIGADES_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, brigadeNames: options}
    }

    case ADD_DIRECTORATES_UIPPS_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, directoratesUipps: options}
    }

    case ADD_DIRECTORATES_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, directorates: options}
    }

    case ADD_ANDROID_ID_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, uippsAndroidId: options}
    }

    case ADD_ANDROID_ID_OPTIONS_FOR_IUR : {
      let options = transformOptionsArray(payload)
      return {...state, iurAndroidId: options}
    }

    case ADD_EMPLOYEES_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, workers: options}
    }

    case ADD_SECTION_OPTIONS : {
      let options = transformOptionsArray(payload)
      return {...state, stations: options}
    }

    case INIT_MRM_IDS : {
      return {...state, mrmIds: payload}
    }

    case DELETE_SELECT_OPTIONS : {

      return {...state, payload: []}
    }

    case ADD_RAILCHAIN_OPTIONS: {
      let options = transformOptionsArray(payload.data);
      let { railChainsPerStation = {} } = state;
      railChainsPerStation[payload.station] = options;
      return {...state, railChainsPerStation: {...railChainsPerStation}};
    }
    
    case ADD_POSITION: {
      return {...state, positions: payload};
    }


    default:
      return state
  }
}

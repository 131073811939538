import React from 'react';
import '../../styles/ViewModifiedRailChains.css';

const DisplayModifiedTrack = ({ data }) => {
  // Функция для отрисовки строк таблицы
  const renderRows = (fields) => {
    let rowIndex = 1;

    return Object.keys(fields)
      .filter((key) => key === 'Трек')
      .flatMap((key) => {
        const { old, new: newValue } = fields[key];
        const oldCoords = typeof old === 'string' ? old.split(';').filter((coord) => coord) : [];
        const newCoords = typeof newValue === 'string' ? newValue.split(';').filter((coord) => coord) : [];

        if (!oldCoords.length && !newCoords.length) {
          return []; // Если нет координат, пропускаем отрисовку строки
        }
        // Максимальное количество строк, которое потребуется отрисовать
        const maxLength = Math.max(oldCoords.length, newCoords.length);

        return Array.from({ length: maxLength }).map((_, idx) => {
          const oldCoord = oldCoords[idx] || '';
          const newCoord = newCoords[idx] || '-';
          const [oldLat, oldLng] = oldCoord ? oldCoord.split(',') : ['-', '-'];
          const [newLat, newLng] = newCoord !== '-' ? newCoord.split(',') : ['-', '-'];

          const isDifferentLat = oldLat !== newLat;
          const isDifferentLng = oldLng !== newLng;

          return (
            <tr key={`${key}-${idx}`} className="table__row">
              <td className="table__cell">{rowIndex++}</td>
              <td className={`table__cell ${isDifferentLat ? 'highlight' : ''}`}>{oldLat}</td>
              <td className={`table__cell ${isDifferentLng ? 'highlight' : ''}`}>{oldLng}</td>
              <td className={`table__cell ${isDifferentLat ? 'highlight' : ''}`}>{newLat}</td>
              <td className={`table__cell ${isDifferentLng ? 'highlight' : ''}`}>{newLng}</td>
            </tr>
          );
        });
      });
  };

  // Функция для отрисовки секций таблицы
  const renderSections = () => {
    return Object.keys(data)
      .filter((id) => {
        const fields = data[id];
        const trackChanged = fields['Трек'] && fields['Трек'].old !== fields['Трек'].new;
        return trackChanged;
      })
      .map((id) => {
        const railChainName = data[id]['РЦ'];
        return (
          <React.Fragment key={id}>
            <tr>
              <td colSpan="5" className="table__section-id">
                {id}
                {railChainName &&
                  (railChainName.new ? `, ${railChainName.old} → ${railChainName.new}` : `, ${railChainName.old}`)}
              </td>
            </tr>
            {renderRows(data[id])}
          </React.Fragment>
        );
      });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="table__header">#</th>
          <th className="table__header" colSpan="2">
            Старое значение
          </th>
          <th className="table__header" colSpan="2">
            Новое значение
          </th>
        </tr>
        <tr>
          <th className="table__header"></th>
          <th className="table__header">Широта</th>
          <th className="table__header">Долгота</th>
          <th className="table__header">Широта</th>
          <th className="table__header">Долгота</th>
        </tr>
      </thead>
      <tbody>{renderSections()}</tbody>
    </table>
  );
};

export default DisplayModifiedTrack;

import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 *
 * @param value {any}
 * @param wasChanged {(cur: any, old: any)=>boolean}
 * @return {boolean}
 */
export function useWasChanged(value, wasChanged = null) {
  const old = usePrevious(value);
  const [changed, setChanged] = useState(0);
  useEffect(() => {
    let _changed = typeof wasChanged == 'function'
      ? wasChanged(value, old)
      : !_.isEqual(value, old);

    // 2 изменения подряд тоже должны срабатывать.
    // Считаем, что любое изменение важно и setState должен измениться
    // Но если использовать только true/false, то useEffect может не отработать правильно
    // Поэтому выставляю рандомные числа больше нуля, чтобы каждое изменение считалось уникальным
    setChanged(_changed ? Math.random() : 0);
  }, [value, old, wasChanged]);
  return changed;
}

import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import '../../styles/Navbar.css';
import { useAuthUser } from 'react-auth-kit';
import { editLanguage } from '../../redux/actions/language.actions';
import _ from 'lodash';

/**
 *
 * @type {{
 * role: string | string[],
 * src: string,
 * link: string,
 * title: string,
 * key: number}[]}
 */
const menu = [
  {
    link: '/devices',
    role: 'admin',
    src: '/image/menu_1.svg',
    title: 'Учет устройств',
    key: 23232,
  },
  {
    link: '/brigades',
    role: ['user', 'admin', 'customer'],
    src: '/image/menu_2.svg',
    title: ' Управление бригадами',
    key: 23234,
  },
  {
    link: '/uipps',
    role: 'admin',
    src: '/image/menu_3.svg',
    title: 'Контроль УИППС',
    key: 23236,
  },
  {
    link: '/documentation',
    role: ['admin', 'customer'],
    src: '/image/menu_4.svg',
    title: 'Отчетная документация',
    key: 23238,
  },
  {
    link: '/archive',
    role: 'admin',
    src: '/image/menu_5.svg',
    title: 'Архив',
    key: 23230,
  },

  {
    link: '/infocards',
    exactRole: ['admin'],
    src: '/image/menu_6.svg',
    title: 'Информационные карты',
    key: 23240,
  },
  {
    link: '/files',
    exactRole: ['admin'],
    src: '/image/file.svg',
    title: 'Файлы',
    key: 23241,
  },
  {
    link: '/kuobz',
    role: ['engineer'],
    src: '/image/kuobz.svg',
    title: 'Редактирование КУОБЗ',
    key: 6,
  },
  {
    link: '/development',
    exactRole: ['engineer', 'admin'],
    src: '/image/seting.svg',
    title: 'Тестовые данные',
    key: 7,
  },
  {
    link: '/rails',
    exactRole: ['engineer', 'admin'],
    src: '/image/map.svg',
    title: 'Карта',
    key: 8,
  },
  {
    link: '/reports',
    exactRole: ['reports'],
    src: '/image/menu_4.svg',
    title: 'Отчеты',
    key: 81,
  },
  {
    link: '/administrate',
    role: ['admin'],
    src: '/image/menu_7.svg',
    title: 'Администрирование данных',
    key: 191,
  },
];

function toArr(arr) {
  return Array.isArray(arr) ? arr : [arr];
}

function Navbar(props) {
  const dispatch = useDispatch();
  const user = useAuthUser()();
  const loc = useLocation();
  const [currentLang, setCurrentLang] = useState('RU');

  const menuItems = useMemo(() => {
    const currentRoles = toArr(user?.role);
    return menu
      .filter((x) => {
        if (x.role) {
          const roles = toArr(x.role);
          // Если среди привилегий пользователя нет ни одной требуемой
          if (!_.intersection(roles, currentRoles).length) return false;
        }
        if (x.exactRole) {
          const roles = toArr(x.exactRole);
          // Если у пользователя нет хотя бы одной из требуемых ролей
          if (roles.some((x) => !currentRoles.includes(x))) return false;
        }
        return true;
      })
      .map((x) => ({
        src: x.src,
        title: x.title,
        key: x.key,
        to: {
          pathname: x.link,
          search: loc.search,
          hash: loc.hash,
        },
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.search, loc.hash, ...toArr(user?.role)]);

  // Нет смысла показывать только одну кнопку навигации
  if (menuItems.length < 2) return <></>;

  return (
    <nav className="nav-container">
      <ul className="navbar__list">
        {menuItems.map((li) => (
          <NavLink to={li.to} key={li.key}>
            <li className="navbar__item">
              <img className="navbar__icon" src={li.src} alt="" />
              <span className="navbar__text">{li.title}</span>
            </li>
          </NavLink>
        ))}
      </ul>
      {process.env.REACT_APP_LANG && (
        <div
          onClick={() => {
            setCurrentLang((prevLang) => {
              const newLanguage = prevLang === 'RU' ? 'RS' : 'RU';
              dispatch(editLanguage(newLanguage));
              return newLanguage;
            });
          }}
          className="switch-lang"
          style={{ marginTop: 'auto' }}
        >
          <div className="switch-circle-lang">{currentLang}</div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;

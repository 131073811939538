/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/Login.css';
import { useNavigate } from 'react-router-dom';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit';
import { login } from '../../redux/actions/user.action';
import { logoutUser } from '../../redux/actions/user.action';
import { useSignOut } from 'react-auth-kit';
import socket from '../../config/socket';

function Login() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  const signOut = useSignOut();

  // Получаем данные о пользователе из Redux store.
  const userData = useSelector((state) => state.user);

  // Локальное состояние для учетных данных пользователя.
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Локальное состояние для отслеживания ошибок входа в систему.
  const [error, setError] = useState('');

  // Hook для программной навигации.
  const navigate = useNavigate();

  // Hooks от react-auth-kit для управления аутентификацией.
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  // Обработчик события для входа в систему.
  const handleLogin = (event) => {
    // Отправка действия входа в Redux.
    dispatch(login({ username: username, password: password }));
  };

  // Побочный эффект для обработки успешного входа в систему.
  useEffect(() => {
    if (userData.user && !isAuthenticated()) {
      // Вход в систему с использованием react-auth-kit.
      signIn({
        token: userData?.accessToken, // Установка токена (пример).
        refreshToken: userData?.refreshToken,
        expiresIn: 10000,
        tokenType: 'Bearer',
        authState: userData, // Установка состояния аутентификации.
      });
      //подключение сокета после успешного входа
      socket.auth = { token: userData?.accessToken }; // Установка токена для сокета
      socket.connect(); // Подключаем сокет
      navigate('/'); // Перенаправление на главную страницу, дальше роутер разберется.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.user, username, navigate, signIn, isAuthenticated]);

  // Побочный эффект для обработки ошибки входа.
  useEffect(() => {
    if (userData.error) {
      setError(true);
      setUsername('');
      setPassword('');
      dispatch(logoutUser({ logout: true }));
      signOut();
    }
  }, [userData.error]);

  // Побочный эффект для сброса ошибок при изменении учетных данных.
  useEffect(() => {
    if (username || password) {
      setError(false);
    }
  }, [username, password]);

  useEffect(() => {
    dispatch(logoutUser({ logout: true }));
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function handleEnter() {
    if (username && password) return handleLogin();

    // После ввода логина Enter переведет фокум на пароль
    if (document.activeElement.id == 'username') document.querySelector('#password').focus();
  }
  // Рендеринг компонента входа в систему.
  return (
    <div
      className="d-flex flex-column flex-nowrap my-auto mx-auto"
      onKeyDown={(e) => e.key == 'Enter' && handleEnter()}
    >
      <div className="card-container d-flex flex-column flex-nowrap align-items-center">
        <h3 className="login-text">Авторизация</h3>
        <div className="login-line"></div>
        <span className={`text-nowrap login-error-text ${error ? 'visible' : 'hidden'}`}>
          <img className="table-for-devices_cell_id_img" src="/image/error.svg" alt="Иконка" />
          Неверный логин или пароль
        </span>
        <div className="h-100 w-75 mb-auto">
          <label className={username ? 'visible' : 'hidden'} htmlFor="username">
            Логин
          </label>
          <input
            id="username"
            type="text"
            className={`login-input w-100 ${error ? 'login-input-error' : ''}`}
            name="username"
            placeholder="Логин"
            value={username || ''}
            onChange={(event) => setUsername(event.target.value)}
            tabIndex={1}
            required
            autoFocus
            autoComplete="username"
          />

          <label htmlFor="password" className={password ? 'visible' : 'hidden'}>
            Пароль
          </label>
          <input
            id="password"
            type="password"
            className={`login-input w-100 ${error ? 'login-input-error' : ''}`}
            name="password"
            placeholder="Пароль"
            value={password || ''}
            tabIndex={2}
            onChange={(event) => setPassword(event.target.value)}
            required
            autoComplete="current-password"
          />

          <button
            className={`add-button w-100 ${username && password ? 'btn-success' : ''}`}
            onClick={handleLogin}
            tabIndex={3}
            disabled={!(username && password)}
          >
            Войти
          </button>
        </div>
      </div>
      <div className="d-flex flex-nowrap flex-column align-items-center">
        <img className="login-info_svg" src="/image/Infosignal.svg" alt="" />
        <span className="login-info_text">
          Система для учета устройств, управления <br /> бригадами, контроля и отчетности
        </span>
      </div>
    </div>
  );
}

export default Login;

import QRCode from 'react-qr-code';
import React, { useState } from 'react';

function Android() {
  const [value] = useState('http://dev.ctrlplus.team:8081/apk');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div className="m-5">Download the latest apk:</div>
      <QRCode title="SADR app" value={value} bgColor="var(--sadr-background-secondary)" fgColor="#000000" size={256} />
    </div>
  );
}

export default Android;

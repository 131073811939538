import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeSafetyFence({ handlerConfirm, confirmText, t }) {
  const text = confirmText
    ? confirmText
    : t?.fenceUnavailableText;
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.fenceTitle} />
      <ConfirmationPage onConfirm={handlerConfirm} addNew={t?.closeButtonText} confirmText={text} />
    </CustomContainer>
  );
}
export default BrigadeSafetyFence;

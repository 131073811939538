import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function SignalModeOff({ handlerConfirm, onClose, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.workModeChangeTitle} />
      <ConfirmationPage
        onConfirm={handlerConfirm}
        onCancel={onClose}
        confirmButtonText={t?.confirmButtonText}
        cancelButtonText={t?.cancelButtonText}
        confirmText={t?.confirmWorkModeChangeText}
      />
    </CustomContainer>
  );
}

export default SignalModeOff;

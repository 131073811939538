// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .device-deployment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .device-deployment_form{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between; 
   width: 100%;
   height: 100%;
   padding: var(--sadr-font-size-20);
  }
  
  .device-deployment_wrapper{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  
  }
  .device-deployment_wrapper_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);
  }
              
  
  `, "",{"version":3,"sources":["webpack://./src/styles/DeviceDeployment.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;EACd;;EAEA;GACC,aAAa;GACb,sBAAsB;GACtB,mBAAmB;GACnB,8BAA8B;GAC9B,WAAW;GACX,YAAY;GACZ,iCAAiC;EAClC;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,WAAW;;EAEb;EACA;EACA,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,8DAA8D;EAC9D","sourcesContent":["\n  .device-deployment-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: 100%;\n  }\n\n  .device-deployment_form{\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: space-between; \n   width: 100%;\n   height: 100%;\n   padding: var(--sadr-font-size-20);\n  }\n  \n  .device-deployment_wrapper{\n    display: flex;\n    justify-content: space-evenly;\n    width: 100%;\n  \n  }\n  .device-deployment_wrapper_container{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  padding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);\n  }\n              \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archive {
  display: flex; 
  width: 100%;
  background-color: var(--sadr-background);
}


`, "",{"version":3,"sources":["webpack://./src/styles/Archiv.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,wCAAwC;AAC1C","sourcesContent":[".archive {\n  display: flex; \n  width: 100%;\n  background-color: var(--sadr-background);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeExceptionDevise({ exceptionDevice, closeModal, handlerConfirm, t }) {
  const confirmText = t?.excludeDeviceConfirmText
    .replace('{deviceType}', exceptionDevice.type)
    .replace('{deviceId}', exceptionDevice.id);
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.excludeDeviceTitle} />
      <ConfirmationPage
        confirmText={confirmText}
        confirmButtonText={t.excludeDeviceConfirmButton}
        cancelButtonText={t.excludeDeviceCancelButton}
        onConfirm={() => {
          handlerConfirm(exceptionDevice.id);
        }} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}

export default BrigadeExceptionDevise;

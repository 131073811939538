/* eslint-disable eqeqeq */
import moment from 'moment';
// тоже работает без дат,  оставил пока на лодаше
//export function filterStateWithJavaScript(state, filterCriteria) {
  export function filterStateWithLodash(state, filterCriteria) {
    return state?.filter(item => {
      return Object.keys(filterCriteria).every(key => {
        const value = filterCriteria[key];        
        if (value === '') {
          return true; // Игнорирование пустых строк
        } else if (value === undefined && key === 'broken') {
          // Если broken равно undefined, проверяем, равно ли значение в объекте null
          return item[key] === null;
        } else if (key === 'id' && String(value).length >= 6) {
          return String(item[key]) === String(value); // Сравнение по id
        } else if (key === 'receive_date' || key === 'create_date' ||  key === 'date') {
          // Специальная обработка для receive_date и create_date'
          const itemDate = item[key];
          if (!itemDate) return false; // Если дата в элементе state не определена, фильтр не проходит
          const formattedItemDate = moment.isMoment(itemDate) ? itemDate : moment(itemDate);
          const formattedValue = moment(value);
          return formattedItemDate.isSame(formattedValue, 'day'); // Сравнение дат по дням
        } 
          // Отдельная обработка для ключей 'start_date' и 'end_date'.
          else if (key === 'start_date' || key === 'end_date') {
        
            // Конвертируем 'start_date' из filterCriteria в объект moment. Если 'start_date' не задан, используем null.
            const startDate = filterCriteria['start_date'] ? moment(filterCriteria['start_date']) : null;
            
            // Конвертируем 'end_date' из filterCriteria в объект moment. Если 'end_date' не задан, используем null.
            const endDate = filterCriteria['end_date'] ? moment(filterCriteria['end_date']) : null;
            
            // Конвертируем значение текущего ключа из элемента массива в объект moment.
            const itemDate = moment(item[key]);
        
            // Если обрабатываем 'start_date' и startDate определен, проверяем, что дата элемента не раньше startDate.
            if (key === 'start_date' && startDate) {
              return itemDate.isSameOrAfter(startDate);
            }
            // Если обрабатываем 'end_date' и endDate определен, проверяем, что дата элемента не позже endDate.
            else if (key === 'end_date' && endDate) {
              return itemDate.isSameOrBefore(endDate);
            }
            // Если диапазон дат не задан в filterCriteria, пропускаем эту проверку и возвращаем true.
            return true;
        }
        // Отдельная обработка для ключей 'start' и 'finish'.
        else if (key === 'start' || key === 'finish') {
        
          // Конвертируем 'start' из filterCriteria в объект moment. Если 'start' не задан, используем null.
          const startDate = filterCriteria['start'] ? moment(filterCriteria['start']) : null;
                    
          // Конвертируем 'finish' из filterCriteria в объект moment. Если 'finish' не задан, используем null.
          const endDate = filterCriteria['finish'] ? moment(filterCriteria['finish']) : null;
                    
           // Конвертируем значение текущего ключа из элемента массива в объект moment и устанавливаем начало дня.
          const itemDate = moment(item[key]).startOf('day');
                
          // Если обрабатываем 'start_date' и startDate определен, проверяем, что дата элемента не раньше startDate.
          if (key === 'start' && startDate) {
            return itemDate.isSame(startDate);
           }
          // Если обрабатываем 'end_date' и endDate определен, проверяем, что дата элемента не позже endDate.
          else if (key === 'finish' && endDate) {
            return itemDate.isSame(endDate);
          }
          // Если диапазон дат не задан в filterCriteria, пропускаем эту проверку и возвращаем true.
          return true;
       }else {
          return item[key] == value; // Стандартное сравнение для остальных ключей
        }
      });
    });
  }
  

//при фильтрации бригад учесть как будет называться свойство с датой 
// export function filterStateWithLodash(state, filterCriteria) {
//   return _.filter(state, item => {
//     return _.every(filterCriteria, (value, key) => {
//       if (value === '') {
//         return true; // Игнорирование пустых строк
//       } else if (value === undefined && key === 'broken') {
//         return _.get(item, key) === null; // Если broken равно undefined, проверяем, равно ли значение в объекте null
//       } else if (key === 'id' && String(value).length >= 6) {
//         return _.toString(_.get(item, key)) === _.toString(value); // Сравнение по id
//       } else if (key === 'receive_date' && value) {
//         // Обработка для receive_date, если значение определено
//         const itemDate = _.get(item, key);
//         const formattedItemDate = moment(itemDate);
//         const formattedValue = moment(value);
//         return formattedItemDate.isSame(formattedValue, 'day'); // Сравнение дат по дням
//       } else {
//         return _.isEqual(_.get(item, key), value); // Стандартное сравнение для остальных ключей
//       }
//     });
//   });
// }



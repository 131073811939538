/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { React, useCallback, useEffect, useMemo, useState } from 'react';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import CustomButton from '../CustomElements/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfocardFromServer, addInfocardFromServer } from '../../redux/actions/infocards.action';
import { addDepartmenOptions } from '../../redux/actions/options.action';
import { useInfocard, useLoader, useMaskedInput, useSelectEdit } from './Common';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router-dom';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import { load, remove } from '../../redux/actions/loader.action.js';
import { initDepartmenOptionsFromServer, initOptionsDirectoratesFromServer } from '../../redux/actions/options.action';

const defVal = {
  directorate: '',
  station: '',
  max_speed: '',
  road: '',
  min_dist: '',
  department: '',
  alert_dist: '',
};

export default function InfocardCreationOrEditing({ closeModal }) {
  // Состояние для формы
  const infocard = useInfocard();
  let loc = useLocation();
  const isEditing = useMemo(() => loc.pathname?.includes('infocards/edit'), [loc.pathname]);
  const [formData, setFormData] = useState(infocard || defVal);
  const [formErrors, setFormErrors] = useState(defVal);
  const dispatch = useDispatch();
  const optionsForSelect = useSelector((state) => state.options);
  const { isLoading, isSuccess } = useLoader();

  useEffect(() => {
    setFormData({ ...(infocard || {}) });
  }, [infocard]);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate', 'department'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department'],
    'directorate',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  // Подразделения зависят от дороги
  useEffect(() => {
    if (formData.road) {
      // очищаем старые данные
      dispatch(addDepartmenOptions([]));
    }
  }, [formData.road]);

  const canApply = useMemo(() => {
    const props = Object.keys(defVal);

    // Что-то не заполнено до конца
    if (props.some((key) => !formData[key])) {
      return false;
    }

    // Ошибка валидации
    if (Object.values(formErrors).some((x) => !!x)) {
      // TODO: а может не втихую выходить, а выводить какие-то ошибки...
      return false;
    }

    const hasSmthChanged = !isEqual(formData, infocard);

    return hasSmthChanged
  }, [formData, formErrors, infocard]);

  const onApply = useCallback(() => {
    dispatch(load());
    if (isEditing) {
      dispatch(updateInfocardFromServer(formData));
    } else {
      dispatch(addInfocardFromServer(formData));
    } 
  }, [dispatch, formData, isEditing, infocard]);

  function useDefaultValues(key) {
    return {
      name: key,
      state: formData[key],
      error: formErrors[key],
      changeState: (_state, _error) => {
        setFormErrors((prev) => ({
          ...prev,
          [key]: _error,
        }));
        if (!_error) {
          setFormData((prev) => ({
            ...prev,
            [key]: _state,
          }));
        }
      },
      width: 'var(--sadr-font-size-420)',
      height: 'var(--sadr-font-size-120)',
    };
  }

  let _editControls = [
    useSelectEdit({
      ...useDefaultValues('road'),
      placeholder: 'Выберите дорогу',
      label: 'Дорога *',
      options: optionsForSelect?.roads,
    }),
    useMaskedInput({
      ...useDefaultValues('max_speed'),
      placeholder: 'Введите максимальную скорость в км/ч',
      label: 'Разрешенная макс. скорость движения*',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите скорость в км/ч';
        return { err, value };
      },
      mask: '999 км/ч',
    }),
    useSelectEdit({
      ...useDefaultValues('directorate'),
      placeholder: 'Выберите дирекцию',
      label: 'Дирекция *',
      options: optionsForSelect?.directorates,
      isDisabled: directoratesControl?.isSelectorDisabled,
      message: directoratesControl?.message,
      customClick: directoratesControl?.onSelectorChange,
      bottom: '-15px',
    }),
    useMaskedInput({
      ...useDefaultValues('min_dist'),
      placeholder: 'Введите мин. расстояние оповещения в метрах',
      label: 'Минимальное расстояние оповещения*',
      mask: '99999 м.',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите расстояние в метрах';
        return { err, value };
      },
    }),
    useSelectEdit({
      ...useDefaultValues('department'),
      placeholder: 'Выберите подразделение',
      label: 'Подразделение *',
      options: optionsForSelect?.departments,
      isDisabled: departmentsControl?.isSelectorDisabled,
      message: departmentsControl?.message,
      customClick: departmentsControl?.onSelectorChange,
      bottom: '-15px',
    }),
    useMaskedInput({
      ...useDefaultValues('alert_dist'),
      placeholder: 'Введите расстояние тревоги в метрах',
      label: 'Тревога при приближ. ПС с исправным УИППС*',
      mask: '9999 м.',
      valueToStr: (v) => v,
      getStateFromChange: (arr) => {
        const str = arr[0],
          value = +str;
        let err = null;
        if (!str || !Number.isFinite(value) || value < 1) err = 'Введите расстояние в метрах';
        return { err, value };
      },
    }),
    <Input
      placeholder="Введите станцию"
      onChange={(event) =>
        setFormData((prev) => ({
          ...prev,
          [isEditing ? 'name' : 'station']: event.target.value,
        }))
      }
      name={isEditing ? 'name' : 'station'}
      label="Станция/перегон *"
      width="var(--sadr-font-size-420)"
      height="var(--sadr-font-size-60)"
      value={isEditing ? formData?.name : formData?.station}
    />,
  ];

  const editControls = _editControls.map((control, index) => (
    <div key={index} column={index % 2 == 0 ? 'left' : 'right'}>
      {control}
    </div>
  ));

  return (
    <CustomContainer>
      <CustomTitle
        titleText={isEditing ? 'РЕДАКТИРОВАНИЕ ИНФОРМАЦИОННОЙ КАРТЫ' : 'СОЗДАНИЕ НОВОЙ ИНФОРМАЦИОННОЙ КАРТЫ'}
      />
      {(!isLoading || isLoading) && !isSuccess && (
        <CustomContainer>
          <FormContainer className="form-container_form w-100">
            {editControls}
            <CustomButton
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              text={isEditing ? 'Изменить информационную карту' : 'Добавить информационную карту'}
              onClick={onApply}
              disabled={!canApply}
              isButton
            />
          </FormContainer>
        </CustomContainer>
      )}
      {!isLoading && isSuccess && (
        <div className="d-flex flex-column flex-nowrap mx-auto my-auto">
          <span className="label-success">
            {isEditing ? 'Информационная карта изменена' : 'Новая информационная добавлена'}
          </span>
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="К информационной карте"
            onClick={() => {
              closeModal(true);
              dispatch(remove());
            }}
            isButton
          />
        </div>
      )}
    </CustomContainer>
  );
}

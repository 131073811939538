import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import '../../styles/CustomButton.css';

function CustomButtonUpload({
  text,
  color,
  colorText,
  className,
  SvgIcon,
  styleSvg,
  fileName,
  name,
  uploadFileFunc,
  ...rest
}) {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const buttonStyle = {
    backgroundColor: color,
    color: colorText,
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
  };

  // Функция для отправки файла на сервер
  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;

    // формируем данные для отправки на сервер
    const formData = new FormData();
    formData.append(fileName, file);
    formData.append(name, file.name);

    dispatch(uploadFileFunc(formData, file.name));
  };

  // Функция для открытия окна выбора файла
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <button
        {...rest}
        className={`custom-btn ${className ? className : ''}`}
        style={buttonStyle}
        onClick={handleButtonClick}
      >
        {SvgIcon && <SvgIcon style={{ verticalAlign: 'middle', marginRight: '8px', ...styleSvg }} />}
        {text}
      </button>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
    </div>
  );
}

export default CustomButtonUpload;

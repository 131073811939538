/**
 * Маппер для координат.
 * @param {string} lat - Широта.
 * @param {string} lon - Долгота.
 * @param {string} ord - Порядок.
 * @returns {Object} Объект с координатами.
 */
const mapCoordinate = (lat, lon, ord) => ({
  lat: lat || undefined,
  lon: lon || undefined,
  ord: ord || undefined,
});

/**
 * Парсит строку координат трека в массив объектов.
 * @param {string} trackString - Строка координат трека.
 * @returns {Array} Массив объектов с координатами трека.
 */
const parseTrack = (trackString) => {
  if (!trackString) return [];
  return trackString.split(';').map(pair => {
    const [lat, lon] = pair.split(',').map(coord => parseFloat(coord.trim()));
    return { lat, lon };
  });
};
/**
 * Создает пейлоад из данных формы.
 * @param {Object} formData - Данные формы.
 * @param {string} formData.id - Идентификатор.
 * @param {string} formData.road - Дорога.
 * @param {string} formData.station - Станция.
 * @param {string} formData.object - Объект.
 * @param {string} formData.railChainName - Название цепи.
 * @param {string} formData.alertLengthsPCh - Длина участка оповещения PCh.
 * @param {string} formData.alertLengthsECh - Длина участка оповещения ECh.
 * @param {string} formData.coordinateEndLat - Координаты конца РЦ (широта).
 * @param {string} formData.coordinateEndLon - Координаты конца РЦ (долгота).
 * @param {string} formData.ordEnd - Порядок конца.
 * @param {string} formData.coordinateControlledLatA - Координаты контролируемого ответвления A (широта).
 * @param {string} formData.coordinateControlledLonA - Координаты контролируемого ответвления A (долгота).
 * @param {string} formData.controlledOrdA - Порядок контролируемого ответвления A.
 * @param {string} formData.coordinateControlledLatB - Координаты контролируемого ответвления B (широта).
 * @param {string} formData.coordinateControlledLonB - Координаты контролируемого ответвления B (долгота).
 * @param {string} formData.controlledOrdB - Порядок контролируемого ответвления B.
 * @param {string} formData.coordinateControlledLatV - Координаты контролируемого ответвления V (широта).
 * @param {string} formData.coordinateControlledLonV - Координаты контролируемого ответвления V (долгота).
 * @param {string} formData.controlledOrdV - Порядок контролируемого ответвления V.
 * @param {string} formData.coordinateControlledLatG - Координаты контролируемого ответвления G (широта).
 * @param {string} formData.coordinateControlledLonG - Координаты контролируемого ответвления G (долгота).
 * @param {string} formData.controlledOrdG - Порядок контролируемого ответвления G.
 * @param {string} formData.coordinateUncontrolledLatN1 - Координаты неконтролируемого ответвления N1 (широта).
 * @param {string} formData.coordinateUncontrolledLonN1 - Координаты неконтролируемого ответвления N1 (долгота).
 * @param {string} formData.uncontrolledOrdN1 - Порядок неконтролируемого ответвления N1.
 * @param {string} formData.coordinateUncontrolledLatN2 - Координаты неконтролируемого ответвления N2 (широта).
 * @param {string} formData.coordinateUncontrolledLonN2 - Координаты неконтролируемого ответвления N2 (долгота).
 * @param {string} formData.uncontrolledOrdN2 - Порядок неконтролируемого ответвления N2.
 * @param {string} formData.coordinateUncontrolledLatN3 - Координаты неконтролируемого ответвления N3 (широта).
 * @param {string} formData.coordinateUncontrolledLonN3 - Координаты неконтролируемого ответвления N3 (долгота).
 * @param {string} formData.uncontrolledOrdN3 - Порядок неконтролируемого ответвления N3.
 * @param {string} formData.track - Координаты трека.
 * @returns {Object} Пейлоад с данными формы, включая массив объектов треков.
 */
  export const createPayloadForNewRC = (formData) => {
    // Создание пейлоада
    const payload = {
      id: formData?.id,
      road: formData?.road,
      station: formData?.station,
      rail_chain_name: formData?.railChainName,
      object: formData?.object,
      // рельсовая цепь 1-й элемент - ПЧ, 2-й - ЭЧ
      alertLengths: [formData?.alertLengthsPCh, formData?.alertLengthsECh],
      coordinate: {
        // питающий конец: широта, долгота, ордината
        end: {
          lat: formData?.coordinateEndLat,
          lon: formData?.coordinateEndLon,
          ord: formData?.ordEnd,
        },
        // контролируемое ответвление: широта, долгота, ордината [А, Б, В, Г]
        controlled: [
          mapCoordinate(formData?.coordinateControlledLatA, formData?.coordinateControlledLonA, formData?.controlledOrdA),
          mapCoordinate(formData?.coordinateControlledLatB, formData?.coordinateControlledLonB, formData?.controlledOrdB),
          mapCoordinate(formData?.coordinateControlledLatV, formData?.coordinateControlledLonV, formData?.controlledOrdV),
          mapCoordinate(formData?.coordinateControlledLatG, formData?.coordinateControlledLonG, formData?.controlledOrdG),
        ],
        //некотролируемое ответвление: широта, долгота, ордината [Н1, Н2, Н3]
        uncontrolled: [
          mapCoordinate(formData?.coordinateUncontrolledLatN1, formData?.coordinateUncontrolledLonN1, formData?.uncontrolledOrdN1),
          mapCoordinate(formData?.coordinateUncontrolledLatN2, formData?.coordinateUncontrolledLonN2, formData?.uncontrolledOrdN2),
          mapCoordinate(formData?.coordinateUncontrolledLatN3, formData?.coordinateUncontrolledLonN3, formData?.uncontrolledOrdN3),
        ],
      },
      // трек, массив точек {широта, долгота}

      track: parseTrack(formData?.track),
    };
  
    return payload;
  };

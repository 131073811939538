import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import BrigadeAddWorkPlace from './BrigadeAddWorkPlace';
import Modal from '../Modal/Modal';
import { useState } from 'react';
import translations from '../../content/PagesContent/brigadeAddContent/brigadeAddContent.json';
import useTranslations from '../../customHook/useTranslations';

function WarnEditPlaceWork({ handlerConfirm, brigadeId }) {
  const [modal, setModal] = useState(false); //---- State for modal window

  // получение словаря для статических данных в зависимости от выбраного языка
  const inputsInfo = useTranslations(translations);

  const enterOrdinate = inputsInfo.buttonsLabels.enterOrdinate;
  const updateLabel = inputsInfo.buttonsLabels.update;
  const warnMessage = inputsInfo.noPlaceNotification;
  const openDataModal = (chosenId) => {
    setModal(true);
  };
  const closeDataModal = () => {
    setModal(false);
  };
  return (
    <CustomContainer className="edit-place">
      <CustomTitle titleText={inputsInfo.changePlaceTitle} />
      {/* Modal window for editing/adding data */}
      {modal && (
        //---- Modal window with form for handling brigades work places.
        <Modal isOpen={modal} onClose={closeDataModal} width="50%" height="80%">
          <BrigadeAddWorkPlace brigadeId={brigadeId} setModal={setModal} inputsData={inputsInfo}/>
        </Modal>
      )}
      <ConfirmationPage
        confirmText={warnMessage}
        confirmButtonText={updateLabel}
        cancelButtonText={enterOrdinate}
        setColorCancelBtn="var(--sadr-success)"
        onCancel={openDataModal}
        onConfirm={handlerConfirm}
      />
    </CustomContainer>
  );
}

export default WarnEditPlaceWork;

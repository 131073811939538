// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox-container {
  display: flex;
  align-items: flex-start;
  cursor: none;
  margin-bottom: var(--sadr-font-size-10);
  width: 100%;
  height: 100%;
}


`, "",{"version":3,"sources":["webpack://./src/styles/CustomCheckbox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,uCAAuC;EACvC,WAAW;EACX,YAAY;AACd","sourcesContent":[".custom-checkbox-container {\n  display: flex;\n  align-items: flex-start;\n  cursor: none;\n  margin-bottom: var(--sadr-font-size-10);\n  width: 100%;\n  height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

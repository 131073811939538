import { useState, useEffect } from 'react';

/**
 * Кастомный хук для обработки колонок таблицы и возвращения их для селекта.
 * @param {Array} columns - Массив колонок таблицы.
 * @returns {Object} - Объект с массивами selectOptions, filteredColumns, selectValue, а также функцией для изменения селекта.
 */
const useSelectableColumns = (columns) => {
  // Состояние для хранения выбранных колонок
  const [selectedColumns, setSelectedColumns] = useState([]);
  // Состояние для хранения опций селекта
  const [selectOptions, setSelectOptions] = useState([]);
  // Состояние для хранения отфильтрованных колонок таблицы
  const [filteredColumns, setFilteredColumns] = useState([]);
  // Состояние для хранения значений селекта
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => {
    /**
     * Функция для получения опций из колонок таблицы.
     * @param {Array} cols - Массив колонок таблицы.
     * @returns {Array} - Массив опций для селекта.
     */
    const getColumnOptions = (cols) => {
      // Колонки, для которых не нужно добавлять префиксы
      const excludePrefixColumns = [
        'alertLengths[0]', 
        'alertLengths[1]', 
        'object', 
        'rail_chain_name', 
        'track'
      ];

      let options = [];
      cols?.forEach((col) => {
        if (col?.columns) {
          // Если колонка содержит дочерние колонки, рекурсивно вызываем функцию для дочерних колонок
          options.push(...getColumnOptions(col?.columns));
          // options = options?.concat(getColumnOptions(col?.columns));
        } else if (col?.accessor !== 'id') {
          let labelPrefix = '';
          let headerText = col?.Header;
          
          // Добавляем префикс "ордината" или "координаты" в зависимости от значения accessor
          if (col?.accessor && col?.accessor?.endsWith('ord')) {
            labelPrefix = 'ордината ';
          } else if (!excludePrefixColumns?.includes(col?.accessor)) {
            labelPrefix = 'координаты ';
          }

          // Добавляем опцию в массив опций
          options?.push({ value: col?.accessor || col?.id, label: `${labelPrefix}${headerText}` });
        }
      });
      return options;
    };

    /**
     * Функция для получения отфильтрованных колонок таблицы.
     * @param {Array} cols - Массив колонок таблицы.
     * @param {Array} selected - Массив выбранных колонок.
     * @returns {Array} - Массив отфильтрованных колонок.
     */
    const getFilteredColumns = (cols, selected) => {
      return cols?.reduce((acc, col) => {
        if (col?.columns) {
          // Если колонка содержит дочерние колонки, рекурсивно вызываем функцию для дочерних колонок
          const filteredSubColumns = getFilteredColumns(col?.columns, selected);
          if (filteredSubColumns?.length > 0) {
            acc?.push({ ...col, columns: filteredSubColumns });
          }
        } else if (col?.accessor === 'id' || selected?.includes(col?.accessor || col?.id)) {
          // Если колонка выбрана или её accessor равен 'id', добавляем её в массив отфильтрованных колонок
          acc?.push(col);
        }
        return acc;
      }, []);
    };

    // Получаем все опции из колонок таблицы
    const allOptions = getColumnOptions(columns);

    // Добавляем isSelected к каждой опции
    const optionsWithSelection = allOptions.map(option => ({
      ...option,
      isSelected: selectedColumns.length === 0 || selectedColumns.includes(option.value),
    }));

    // Получаем новые отфильтрованные колонки
    const newFilteredColumns = getFilteredColumns(columns, selectedColumns.length === 0 ? allOptions.map(option => option.value) : selectedColumns);

    // Обновляем состояние опций селекта
    setSelectOptions(optionsWithSelection);
    // Обновляем состояние отфильтрованных колонок
    setFilteredColumns(newFilteredColumns);
    // Обновляем состояние значений селекта
    setSelectValue(optionsWithSelection.filter(option => option.isSelected));
  }, [columns, selectedColumns]);

  /**
   * Функция для изменения выбранных колонок.
   * @param {Array} selectedOptions - Массив выбранных опций.
   */
  const handleSelectChange = (selectedOptions) => {
    // Обновляем состояние выбранных колонок
    setSelectedColumns(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  // Возвращаем опции, отфильтрованные колонки, функцию изменения селекта и значения селекта
  return { selectOptions, filteredColumns, handleSelectChange, selectValue };
};

export default useSelectableColumns;

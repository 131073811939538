/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FilterForDevices from '../Filter/FilterForDevices';
import TableForDevices from '../Table/TableForDevices';
import HeaderForObject from '../CustomElements/HeaderForObject';
import { clearDevices, initDevicesFromServer } from '../../redux/actions/devices.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import '../../styles/Devices.css';
import translations from '../../content/PagesContent/DevicePages/devicePageTranslations.json';
import useTranslations from '../../customHook/useTranslations';

function Devices(props) {
  const [type, setType] = useState();

  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);

  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    type_id: '',
    road_id: '',
    section_id: '',
    status_id: '',
    department_id: '',
    receive_date: '',
    responsible: '',
    broken: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const runDispathes = () => {
    dispatch(clearDevices());
    dispatch(clearFilterConfig());
    dispatch(initDevicesFromServer(formData, searchInput));
  };
  const onFilterSubmit = (event) => {
    event.preventDefault();
    runDispathes();
  };
  useEffect(() => {
    runDispathes();
  }, [searchInput]);

  return (
    <div className="devices">
      <FilterForDevices
        type={type}
        setType={setType}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
      />
      <div className="devices_content container-fluid">
        <HeaderForObject
          searchInput={searchInput}
          onFilterChange={(e) => {
            setSearchInput(e?.target?.value);
          }}
          onFilterSubmit={onFilterSubmit}
        />
        <TableForDevices type={type} searchInput={searchInput} formData={formData} t={t}/>
      </div>
    </div>
  );
}

export default Devices;

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeWayOut({ closeModal, handlerConfirm, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={ t?.wayOutTitle} />
      <ConfirmationPage
        confirmText={t?.wayOutConfirmText}
        confirmButtonText={t?.exitWayButton}
        cancelButtonText={t?.cancelButtonText}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}
export default BrigadeWayOut;

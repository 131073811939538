import React, { useState, useEffect } from 'react';
import '../../styles/Feature.css';
import socket from '../../config/socket';

function Feature() {
  const [iurOptions, setIurOptions] = useState([])

  useEffect(() => {
    socket.emit('get_mrm_data', { skipTokenVerification: true });

    const onMrmIds = (data) => {
      setIurOptions(data);
    };

    socket.on('mrm_ids', onMrmIds);

    return () => {
      socket.off('mrm_ids', onMrmIds);
    };
  }, []);

  const [selectedAndroidId, setSelectedAndroidId] = useState(null);

  function handleSelectChange(e) {
    setSelectedAndroidId(e.target.value);
  }

  const handleErrSending = (event, type) => {
    if (!selectedAndroidId) {
      alert('А можно указать устройство?');
      return;
    }
    event.preventDefault();
    socket.emit('err_test', { androidId: selectedAndroidId, type: type })
  };

  return (
    <>
      <div className="p-3" style={{ textAlign: 'start', width: '40%' }}>
        <div className="p-2" style={{ width: '50vw' }}>
          <label style={{ padding: 0 }}>Устройство</label>
          <select className="form-select w-25 mb-2" onChange={handleSelectChange}>
            <option disabled selected value="">
              Выберите ИУР
            </option>
            {iurOptions.map((option, index) => (
              <option key={index} value={option.androidId}>
                {option.mrmId} ({option.androidId})
              </option>
            ))}
          </select>
          <div className='d-flex'>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 1)}>
              Полноэкранная ошибка
            </button>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 2)}>
              Текстовая ошибка
            </button>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 3)}>
              Нет ошибок
            </button>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 4)}>
              Текстовая ошибка с параметров
            </button>
          </div>
          <div className='d-flex'>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 5)}>
              isNextBtnActive=true
            </button>
            <button className="btn btn-primary m-1" onClick={(event) => handleErrSending(event, 6)}>
              isNextBtnActive=false
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;

import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceStorageFromRepair.css';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { getCurrentStatusId } from '../../utils/getCurrentStatusId';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { convertToDate } from '../../utils/convertToDate';
import { transferDeviceForStorage } from '../../redux/actions/devices.action';
import { editStorageStatusFromServer } from '../../redux/actions/statuses.action';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import { load } from '../../redux/actions/loader.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import useFilesOptions from '../../customHook/useFilesOptions';
import FileSelector from '../CustomElements/FileSelector';

// компонент для изменения статуса возврат из ремонта и передачи устройства на хранение

function DeviceStorageFromRepair({ currentDevice, closeModal, currentStatus, title, isEdit }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = () => {
    setAddSuccess(true);
  };

  // Состояние для формы
  // Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () =>
      currentStatus
        ? {
          id: currentStatus?.id,
          deviceId: currentStatus?.device_id,
          fromStatusId: currentStatus?.from_status_id,
          note: currentStatus?.note || '',
          isBroken: currentStatus?.broken,
          typeDevice: currentDevice?.type_id || '',
          date: convertToDate(currentStatus?.date) || '',
          storageId: currentStatus?.storage_id || '',
          receiverId: getCurrentStatusId(currentStatus?.receiver, optionsForSelect?.employees) || '',
          acceptanceCert: currentStatus?.acceptance_cert || '',
          acceptanceCertName: currentStatus?.acceptance_cert_name || '',
          attachment: currentStatus?.attachment || '',
          attachmentName: currentStatus?.attachment_name || '',
        }
        : {
          deviceId: currentDevice.id,
          fromStatusId: getCurrentStatusId(currentDevice?.status, optionsForSelect.deviceStatuses),
          date: '',
          acceptanceCert: '',
          receiverId: '',
          storageId: '',
          isBroken: '',
          attachment: '',
          note: '',
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );
  const [formData, setFormData] = useState(initialFormData);

  const acceptanceCertOptions = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );


  const attachmentOptions = useFilesOptions(
    setFormData,
    'attachmentName',
    'attachment'
  );

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
    // Установить флаг открытия формы в true
  }, [initialFormData]);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, []);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    //если передача на хранение
    if (!isEdit) {
      const payload = createFormData(formData);
      // Отправляем данные на сервер через Redux
      dispatch(transferDeviceForStorage(payload, optionsForSelect.deviceStatuses, currentDevice?.type_id, 'repair'));
    }
    // если изменение статуса
    if (isEdit) {
      handlerOpenModal();
    }
  };

  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editStorageStatusFromServer(payload, formData.deviceId, 'repair'));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  // при размонтировании отправляем запрос чтобы получить обновленный стейт
  useEffect(() => {
    return () => {
      dispatch(initStatusesFromServer(currentDevice.id, 'repair'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'ПРИНЯТИЕ НА ХРАНЕНИЕ УСТРОЙСТВА'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите изменить данные статуса?"
          confirmButtonText="Изменить"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата приемки из ремонта *"
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            placeholder="Выберите ФИО"
            label="ФИО принявшего из ремонта *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.employees.find((option) => option.value === formData.receiverId)}
            isMulti={false}
            name="receiverId"
          />
          <FileSelector
            formData={formData}
            setFormData={setFormData}
            attachmentOptions={acceptanceCertOptions}
            column="left"
            file={{
              field: 'acceptanceCert',
              name: 'acceptanceCertName',
              label: 'Акт приема-передачи *'
            }}
          />
          <Selector
            column="right"
            options={optionsForSelect.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            placeholder="Выберите место хранения"
            label="Место хранения *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.storages.find((option) => option.value === formData.storageId)}
            isMulti={false}
            name="storageId"
          />
          <Selector
            column="right"
            options={optionsForSelect.сondition.filter((item) => item.value !== undefined)}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select');
            }}
            placeholder="Не определено"
            label="Состояние *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.сondition.find((option) => option.value === formData.isBroken)}
            isMulti={false}
            name="isBroken"
          />
          <FileSelector
            formData={formData}
            setFormData={setFormData}
            attachmentOptions={attachmentOptions}
            column="right"
            file={{
              field: 'attachment',
              name: 'attachmentName',
              label: 'Акт выполненных работ *'
            }}
          />
          <CustomTextarea
            placeholder="Оставьте комментарий"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            label="Комментарий *"
            width="100%"
            height="var(--sadr-font-size-200)"
            value={formData.note}
          />
          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={isEdit ? 'Сохранить изменения' : 'Принять устройство'}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default DeviceStorageFromRepair;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-for-documentation_container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  overflow: auto;
}

.table-for-documentation_table {
  flex: 1 1;
  overflow: auto;
}

.table-for-documentation_btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: var(--sadr-font-size-20);
  padding: var(--sadr-font-size-20);
  padding-top: var(--sadr-font-size-40);
  background-color: var(--sadr-background-secondary);
  border-bottom-left-radius: var(--sadr-font-size-12) ;
  border-bottom-right-radius: var(--sadr-font-size-12) ;
}

.table-for-documentation_btn {
  width: var(--sadr-font-size-420);
  height: var(--sadr-font-size-60);
  border-radius: var(--sadr-font-size-12) ;
  background: var(--sadr-success);
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-30);
  text-align: center;
  color: var(--sadr-background-secondary);
  border: solid var(--sadr-font-size-2);
}

.table-for-documentation_cell_id {
  display: flex;
}

.table-for-documentation_cell_id_img {
  margin-right: var(--sadr-font-size-10);
}

.table-for-documentation_cell_download_btn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TableForDocumentation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uCAAuC;EACvC,iCAAiC;EACjC,qCAAqC;EACrC,kDAAkD;EAClD,oDAAoD;EACpD,qDAAqD;AACvD;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;EAChC,wCAAwC;EACxC,+BAA+B;EAC/B,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,kBAAkB;EAClB,uCAAuC;EACvC,qCAAqC;AACvC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".table-for-documentation_container {\n  display: flex;\n  flex-direction: column;\n  align-content: space-between;\n  justify-content: space-between;\n  overflow: auto;\n}\n\n.table-for-documentation_table {\n  flex: 1;\n  overflow: auto;\n}\n\n.table-for-documentation_btn-container {\n  display: flex;\n  justify-content: center;\n  margin-bottom: var(--sadr-font-size-20);\n  padding: var(--sadr-font-size-20);\n  padding-top: var(--sadr-font-size-40);\n  background-color: var(--sadr-background-secondary);\n  border-bottom-left-radius: var(--sadr-font-size-12) ;\n  border-bottom-right-radius: var(--sadr-font-size-12) ;\n}\n\n.table-for-documentation_btn {\n  width: var(--sadr-font-size-420);\n  height: var(--sadr-font-size-60);\n  border-radius: var(--sadr-font-size-12) ;\n  background: var(--sadr-success);\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 600;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-30);\n  text-align: center;\n  color: var(--sadr-background-secondary);\n  border: solid var(--sadr-font-size-2);\n}\n\n.table-for-documentation_cell_id {\n  display: flex;\n}\n\n.table-for-documentation_cell_id_img {\n  margin-right: var(--sadr-font-size-10);\n}\n\n.table-for-documentation_cell_download_btn {\n  border: none;\n  background: none;\n  outline: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

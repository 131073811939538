// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-transfer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-transfer_form{
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 100%;
}

.device-transfer_wrapper{
  display: flex;
  justify-content: space-evenly;
  width: 100%;

}
.device-transfer_wrapper_container{
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);
}
            

`, "",{"version":3,"sources":["webpack://./src/styles/DeviceTransfer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,WAAW;AACZ;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,WAAW;;AAEb;AACA;AACA,aAAa;AACb,sBAAsB;AACtB,mBAAmB;AACnB,WAAW;AACX,8DAA8D;AAC9D","sourcesContent":[".device-transfer-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.device-transfer_form{\n display: flex;\n flex-direction: column;\n align-items: center;\n width: 100%;\n}\n\n.device-transfer_wrapper{\n  display: flex;\n  justify-content: space-evenly;\n  width: 100%;\n\n}\n.device-transfer_wrapper_container{\ndisplay: flex;\nflex-direction: column;\nalign-items: center;\nwidth: 100%;\npadding: 0 var(--sadr-font-size-10) 0 var(--sadr-font-size-10);\n}\n            \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import CustomButton from './CustomButton';
import '../../styles/ConfirmationPage.css';

function ConfirmationPage({
  onConfirm,
  onCancel,
  confirmText,
  confirmButtonText,
  cancelButtonText,
  addNew,
  isDisabledConfirmBtn,
  isDisabledCancelBtn,
  btnWidth,
  btnHeigth,
  setColorCancelBtn
}) {
  return (
    <div className="confirmation-container">
      <h5 className="confirmation-title">{confirmText}</h5>
      <div className="confirmation-btn-container">
        {!addNew && (
          <>
            <CustomButton
              className="confirmation-btn confirm"
              width={btnWidth}
              height={btnHeigth}
              text={confirmButtonText}
              onClick={(event) => {
                onConfirm(event);
              }}
              disabled={isDisabledConfirmBtn}
            />
            <CustomButton
              className="confirmation-btn cancel"
              width={btnWidth}
              height={btnHeigth}
              color={setColorCancelBtn || 'var(--sadr-background-secondary)'}
              colorText={setColorCancelBtn ? 'var(--sadr-background-secondary)' : 'var(--sadr-border)'}
              // border = {setColorCancelBtn ? 'none' : 'var(--sadr-background-secondary)'}
              text={cancelButtonText}
              onClick={onCancel}
              disabled={isDisabledCancelBtn}
            />
          </>
        )}
        {addNew && (
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={addNew}
            onClick={onConfirm}
          />
        )}
      </div>
    </div>
  );
}

export default ConfirmationPage;

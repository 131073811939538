import React from 'react';
import '../../styles/CustomCheckbox.css';

const CustomCheckbox = ({
  label,
  width = 'var(--sadr-font-size-420)',
  height = 'var(--sadr-font-size-34)',
  onChange,
  checked,
  checkedIcon = 'image/checkSelected.svg',
  uncheckedIcon = 'image/check.svg',
}) => {
  const svgPath = checked ? checkedIcon : uncheckedIcon;

  return (
    <div
      className="custom-checkbox-container"
      style={{ width, height, cursor: 'pointer' }}
      onClick={() => onChange(label)}
    >
      <img src={svgPath} alt="Checkbox Icon" style={{ marginRight: 'var(--sadr-font-size-10)' }} id="customCheckbox" />
      <label className="checkbox-label" htmlFor="customCheckbox">
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;

import { useSelector } from 'react-redux';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeTransferFencingAllowed({ handlerConfirm, closeModal, text, t }) {
  // Получение данных о бригадах из Redux store.
  const { currentLocationsWork } = useSelector((state) => state.brigadesState);
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.transferTitle} />
      <ConfirmationPage
        confirmText={t.transferConfirmText.replace('{{object}}', currentLocationsWork.obj)}
        confirmButtonText={t?.transferStartButton}
        cancelButtonText={t?.cancelButtonText}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}

export default BrigadeTransferFencingAllowed;

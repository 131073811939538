import React from 'react';
import Input from './Input'; // Assuming you have these components
import Selector from './Selector';
import Checkbox from './Checkbox';
import { handleUniversalChange } from '../../utils/formUtils';

const FileSelector = ({
  formData,
  setFormData,
  attachmentOptions,
  column, // left/right
  file // field - field name (acceptanceCert), name - file name field name (acceptanceCertName), label
}) => {
  return (
    <div>
      {!attachmentOptions.isFile ? (
        <Input
          column={column}
          placeholder="Выберите файл"
          onChange={(event) =>
            handleUniversalChange(formData, setFormData, event, null, null, null, file?.name)
          }
          onRemove={() =>
            handleUniversalChange(
              formData,
              setFormData,
              null,
              'isRemove',
              null,
              null,
              file?.field,
              file?.name
            )
          }
          type="file"
          name={file?.field}
          label={file?.label}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          isDisabled={false}
          maxFileSize={20}
          fileName={formData?.[file?.name]}
        />
      ) : (
        <Selector
          column={column}
          options={attachmentOptions.optionsFiles}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, file?.field, selectedOption, 'select');
            attachmentOptions.handleSelectFile(selectedOption);
          }}
          placeholder="Выберите файл"
          label={file?.label}
          width="var(--sadr-font-size-420)"
          height="var(--sadr-font-size-60)"
          value={attachmentOptions.optionsFiles?.find((option) => option.value === formData?.[file?.field])}
          isMulti={false}
          name="attachment"
        />
      )}
      <Checkbox
        column={column}
        height={`var(--sadr-font-size-60)`}
        width="100%"
        label="Выбрать существующий файл"
        onChange={() => attachmentOptions.handleChangeCheckboxFile()}
        checked={attachmentOptions.isFile}
      />
    </div>
  );
};

export default FileSelector;
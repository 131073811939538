/**
 * Форматирует значение в виде строки с пробелами между цифрами.
 * @param {string|number} value - Значение для форматирования.
 * @returns {string} Возвращает значение с пробелами между цифрами, если значение прошло валидацию, или исходное значение, если оно невалидно.
 */
export function formatValue(value) {
    // Проверяем, является ли значение null или undefined и возвращаем пустую строку
    if (value === null || value === undefined) {
      return '';
    }
  
  // Преобразуем значение в строку для удобства обработки
  const strValue = String(value);

  // Проверяем валидность значения
  if (!/^\d+$/.test(strValue)) {
    // Если значение невалидно, возвращаем исходное значение
    return strValue;
  }

  // Форматируем значение в соответствии с заданным форматом
  const formattedValue = strValue.replace(
    /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/,
    '$1 $2 $3 $4 $5'
  );

  return formattedValue;
}

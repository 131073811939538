import {INIT_KUOBZES } from '../type'
import axiosInstance from '../../config/axios.config';
import { addError } from './error.action';
import { success } from './loader.action'

export const initKuobzes = (kuobzes) => ({
  type: INIT_KUOBZES,
  payload: kuobzes
})

// получение всех устройств
export const initKuobzesFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/kuobz')
    if (data) {
      dispatch(initKuobzes(data))
    }
  } catch (error) {
    console.log(error);
  }
}

// добавление нового устройства
export const newKuobzFromServer = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/kuobz', payload)
    if(response.status === 201) {
       // записываем в стейт сообщение об успешном выполнении
       dispatch(success())
       dispatch(initKuobzesFromServer())
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.'
    dispatch(addError(message))
    console.log(error);
  }
}

// изменение устройства
export const editKuobzFromServer = (payload, kuobzId) => async (dispatch) => {
  try {

    const response = await axiosInstance.put(`/api/kuobz/${kuobzId}`, payload)
    if(response.status === 200){
      // записываем в стейт сообщение об успешном выполнении
      dispatch(success())
      dispatch(initKuobzesFromServer())
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.'
    dispatch(addError(message))
    console.log(error);
  }
}

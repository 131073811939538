import React from 'react';
import InputMask from 'react-input-mask';
import '../../styles/PhoneInput.css';

function PhoneInput({ label, name, onChange, value }) {
  return (
    <div className="input-container">
      {label && (
        <label className="phone-input-label" htmlFor={name}>
          {label}
        </label>
      )}
      {/* Отображаем метку, если она передана */}
      <InputMask mask="8 999 999 99 99" maskChar="_" placeholder="8 ___ ___ __ __" onChange={onChange} value={value}>
        {(inputProps) => (
          <input
            type="text"
            className="phone-input"
            autoComplete="off"
            name={name}
            placeholder="8 ___ ___ __ __"
            {...inputProps}
          />
        )}
      </InputMask>
    </div>
  );
}

export default PhoneInput;

import {
  ADD_IGNORE_ID,
  ADD_MAP_COORDS,
  CLEAR_MAP_ARRAY,
  CLEAR_MAP_DATA,
  CLEAR_MAP_IGNORES,
  EDIT_MAP_ARRAY,
  INIT_MAP_ARRAY,
} from '../type';

export function mapDataReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_MAP_ARRAY: {
      return { ...state, mapArray: payload };
    }

    case ADD_IGNORE_ID: {
      if (!state.ignoredIds.includes(payload)) {
        return { ...state, ignoredIds: [...state.ignoredIds, payload] };
      } else return state;
    }

    case ADD_MAP_COORDS: {
      return { ...state, mapBorders: payload };
    }


    case CLEAR_MAP_ARRAY: {
      return { ...state, mapArray: payload };
    }

    case CLEAR_MAP_IGNORES: {
      return { ...state, ignoredIds: payload };
    }

    case CLEAR_MAP_DATA: {
      return { ...state, ...payload };
    }

    case EDIT_MAP_ARRAY: {
      const res = state?.mapArray?.map((item) => {
        if (Number(item.id) === Number(payload.id)) {
          return payload;
        }
        return item;
      });
      return { ...state, mapArray: res };
    }
    default:
      return state;
  }
}

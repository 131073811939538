import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomContainer from '../CustomElements/CustomContainer';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import DepartmentReport from '../Documentation/DepartmentReport';
import BrigadeReport from './BrigadeReport';
import ReportServises from './ReportServises';
import CustomTitle from '../CustomElements/CustomTitle';
import DateRangePicker from '../CustomElements/DateRangePicker';
import Modal from '../Modal/Modal';
import Preloader from '../CustomElements/Preloader';

import { useButtonValidation } from '../../customHook/useButtonValidation';
import useModalWithStatus from '../../customHook/useModalWithStatus';

import { convertDateToMs } from '../../utils/convertToDate';
import { handleUniversalChange } from '../../utils/formUtils';

import { getReportFromServer } from '../../redux/actions/documentation.action';
import { load, remove } from '../../redux/actions/loader.action';

function ReportForm({ type, closeModalForm }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const options = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus();

  // Состояние для формы
  const initialFormData = {
    type: type,
    reportType: '',
    roadId: '',
    directorateId: '',
    departmentId: '',
    brigadeId: '',
    startDate: '',
    endDate: '',
  };

  const reportTypeOptions = [
    { value: 'directorate', label: 'по дирекции' },
    { value: 'department', label: 'по подразделению' },
    { value: 'brigade', label: 'по бригаде' },
  ];
  const [formData, setFormData] = useState(initialFormData);
  const [optionalFields, setOptionalFields] = useState([]);

  const contentData = {
    directorate: <ReportServises />,
    brigade: <BrigadeReport />,
    department: <DepartmentReport />,
  };

  useEffect(() => {
    switch (formData.reportType) {
      case 'brigade':
        setOptionalFields([]);
        break;
      case 'directorate':
        setOptionalFields(['departmentId', 'brigadeId']);
        break;
      case 'department':
        setOptionalFields(['brigadeId']);
        break;
      default:
        setOptionalFields([]);
        break;
    }
  }, [formData.reportType]);
  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Переводим в состояние загрузки
    dispatch(load());

    // Создаем новый объект payload, копируя все свойства из formData
    const payload = { ...formData };

    // Если formData содержит startDate, преобразуем его и обновляем в payload
    if (formData?.startDate) {
      payload.startDate = convertDateToMs(formData.startDate);
    }

    // Если formData содержит endDate, преобразуем его и обновляем в payload
    if (formData?.endDate) {
      payload.endDate = convertDateToMs(formData.endDate);
    }
    // Отправляем данные на сервер через Redux
    dispatch(getReportFromServer(payload));
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, открываем модальное окно отчета
    if (!loader?.isLoading && loader?.isSuccess) {
      handleButtonClick(formData.reportType);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  const closeModalFormAndReport = () => {
    closeModalForm();
    closeModal();
  };

  return (
    <>
      <CustomContainer>
        <CustomTitle titleText={'ОТЧЕТ ПО ОБЪЕМУ ОКАЗАННЫХ УСЛУГ'} />
        <FormContainer>
          <Selector
            column="left"
            options={reportTypeOptions}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'reportType', selectedOption, 'select');
            }}
            placeholder="Выберите тип отчета"
            label="Тип отчета *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={reportTypeOptions?.find((option) => option.value === formData.reportType)}
            isMulti={false}
            name="reportType"
          />
          <Selector
            column="left"
            options={options?.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            label="Дорога *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={options?.roads?.find((option) => option.value === formData.roadId)}
            isMulti={false}
            name="roadId"
          />
          <Selector
            column="left"
            options={options?.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
            }}
            placeholder="Выберите подразделение"
            label="Подразделение *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={options?.departments?.find((option) => option.value === formData.departmentId)}
            isMulti={false}
            name="departmentId"
            isVisible={formData.reportType === 'department' || formData.reportType === 'brigade'}
            isDisabled={!['department', 'brigade'].includes(formData.reportType)}
          />

          <DateRangePicker
            column="right"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Период *"
            formData={formData}
            setFormData={setFormData}
            startDateField="startDate" // имя поля для даты начала, с дефолтным значением
            endDateField="endDate" // имя поля для даты окончания, с дефолтным значением
            name="date"
            placeholder="Выберите период"
            topPosition="50%"
            maxDate={new Date()}
          />
          <Selector
            column="right"
            options={options?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={options?.directorates?.find((option) => option.value === formData.directorateId)}
            isMulti={false}
            name="directorateId"
          />
          <Input
            column="right"
            placeholder="Введите ID бригады"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            name="brigadeId"
            label="Бригада *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            // type="number"
            value={formData?.brigadeId}
            isVisible={formData.reportType === 'brigade'}
            isDisabled={!(formData.reportType === 'brigade')}
          />
          <Preloader isLoading={loader?.isLoading} />
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сформировать отчет"
            disabled={isButtonDisabled}
            isButton
            onClick={handlerSubmit}
          />
        </FormContainer>
      </CustomContainer>
      <Modal isOpen={isOpen} onClose={closeModalFormAndReport} containerSelector={'.table-for-documentation_container'}>
        {contentData[activeModal]}
      </Modal>
    </>
  );
}

export default ReportForm;

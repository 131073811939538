// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.switch-label {
  font-size: 14px;
  white-space: nowrap;
}

.switch {
  display: flex;
  position: relative;
  width: 120px;
  height: 40px;
  background: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s;
}

.switch.active {
  background: #0c6dfd;
}

.switch-circle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 60px;
  height: 30px;
  background: #fff;
  border-radius: 40px;
  transition: left 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
}

.switch-circle.active {
  left: 55px;
}

.switch-text {
  font-size: 16px;
  color: #333;
}

.switch.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.switch-circle.disabled {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomSwitch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".switch-container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n}\n\n.switch-label {\n  font-size: 14px;\n  white-space: nowrap;\n}\n\n.switch {\n  display: flex;\n  position: relative;\n  width: 120px;\n  height: 40px;\n  background: #ccc;\n  border-radius: 50px;\n  cursor: pointer;\n  transition: background 0.3s;\n}\n\n.switch.active {\n  background: #0c6dfd;\n}\n\n.switch-circle {\n  position: absolute;\n  top: 5px;\n  left: 5px;\n  width: 60px;\n  height: 30px;\n  background: #fff;\n  border-radius: 40px;\n  transition: left 0.3s;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 16px;\n  color: #333;\n}\n\n.switch-circle.active {\n  left: 55px;\n}\n\n.switch-text {\n  font-size: 16px;\n  color: #333;\n}\n\n.switch.disabled {\n  cursor: not-allowed;\n  opacity: 0.6;\n}\n\n.switch-circle.disabled {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

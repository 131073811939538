import { React, useRef, useState, useEffect } from 'react';
import '../../styles/Input.css';
import CustomButtonRemove from './CustomButtonRemove';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';

function Input({
  id,
  placeholder,
  onChange,
  onRemove,
  value,
  defaultValue,
  label,
  type,
  name,
  width,
  height,
  margin,
  maxFileSize = 50,
  isDisabled,
  fileName,
  labelBottom,
  background,
  textAlign,
  disableHover,
  isVisible = true,
  messageInfo,
  messageError,
  isNotMarginMessage,
  onFocus,
  onBlur,
  handleMouseDown,
  handleMouseUp,
  _ref,
  // пропсы для маски
  mask,
  maskPlaceholder,
  maskChar,
  //пропсы для NumericFormat
  isCoordinate,
  format, // для форматирования значений
  isAllowed, // для валидации значений
  maxLength,
  internalProcessing,
  restrictionRegex,
}) {
  /**
   * Стили для инпута и кнопки
   * @type CSSStyleDeclaration
   */
  const inputStyle = {
    width: width,
    height: height,
    margin: messageInfo || messageError ? '0' : margin ? margin : '0 0 var(--sadr-font-size-20) 0',
    display: type === 'file' ? 'none' : '', // Скрываем инпут, если указан тип
    textAlign: textAlign,
  };
  const baseStyle = {
    width: width,
    height: height,
    display: type === 'file' ? '' : 'none', // Показываем кнопку, если указан тип
    paddingLeft: 'var(--sadr-font-size-20)',
    fontFamily: 'var(--sadr-font-family)',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 'var(--sadr-font-size)',
    lineHeight: 'var(--sadr-font-size-20)',
  };

  // Реф для инпута
  const inputRef = useRef(null);
  if (inputRef?.current && _ref) _ref.current = inputRef.current;

  // Состояние для хранения имени выбранного файла
  const [selectedFileName, setSelectedFileName] = useState(fileName);
  const [message, setMessage] = useState('');

  // состояние для хранения значения инпута внутри
  const [internalValue, setInternalValue] = useState(value || '');

  // Обработчик изменений в инпуте
  const internalHandleChange = (event) => {
    const newValue = event.target.value;

    // Проверка на соответствие регулярному выражению
    if (!newValue.match(restrictionRegex)) {
      return; // Если не соответствует, ничего не делаем
    }
    onChange(event);
    setInternalValue(newValue);
  };

  // Обработчик клика на кнопку выбора файла
  const handleButtonClick = (e) => {
    inputRef.current.value = ''; // Сбрасываем значение инпута
    inputRef.current.click(); // Запускаем клик по инпуту
  };

  // Обработчик изменения значения инпута с типом file
  const handleInputChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > maxFileSize * 1024 * 1024) {
        setMessage(`Размер файла не должен превышать ${maxFileSize} MB`);
        setSelectedFileName('');
        return;
      } else {
        setMessage('');
        setSelectedFileName(file.name); // Обновляем состояние с именем файла
      }
    }
    onChange(e); // Вызываем переданный обработчик изменения
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();
    setSelectedFileName(''); // Очищаем имя файла
    setMessage('');
    onRemove(); // Вызываем обработчик удаления файла
    inputRef.current.value = ''; // Сбрасываем значение инпута
  };

  useEffect(() => {
    setSelectedFileName(fileName);
  }, [fileName]);

  /**
   * Динамически изменяемые стили на основе selectedFileName
   * @type CSSStyleDeclaration
   */
  const buttonStyle = {
    ...baseStyle, // Распространяем базовые стили
    color: selectedFileName ? 'var(--sadr-border)' : 'var(--sadr-label-color)',
    paddingLeft: 'var(--sadr-font-size-20)',
    margin: message ? '0' : '0 0 var(--sadr-font-size-20) 0',
  };

  return (
    <div className={`input-container ${isVisible ? 'visible-input' : 'hidden-input'}`}>
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      {/* Отображаем метку, если она передана */}
      {isCoordinate ? (
        <NumericFormat
          id={id || name}
          name={name}
          className={`custom-input ${disableHover ? 'disable-hover' : ''} ${background ? 'white-bacground' : ''}`}
          style={inputStyle}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onValueChange={(values) => {
            const event = {
              target: {
                name: name,
                value: values?.value,
              },
            };
            onChange(event);
          }}
          autoComplete="off"
          disabled={isDisabled}
          fixedDecimalScale={false} // Гарантирует, что количество знаков после запятой будет фиксированным, даже если пользователь вводит меньшее количество знаков
          allowNegative={true} // Разрешает ввод отрицательных значений
          format={format} // Используем переданный проп для форматирования
          isAllowed={isAllowed} // Используем переданный проп для валидации
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
        />
      ) : mask ? (
        <InputMask
          id={id || name}
          className={`custom-input ${disableHover ? 'disable-hover' : ''} ${background ? 'white-bacground' : ''}`}
          style={inputStyle}
          type={type || 'text'} // Устанавливаем тип инпута или по умолчанию text
          placeholder={placeholder}
          onChange={handleInputChange} // Обработчик изменения значения инпута
          value={value}
          defaultValue={defaultValue}
          name={name}
          autoComplete="off"
          disabled={isDisabled}
          mask={mask}
          maskChar={maskChar || null}
          maskplaceholder={maskPlaceholder}
          onFocus={onFocus}
          onBlur={onBlur}
          alwaysShowMask={false} // Маска не будет отображаться всегда
        />
      ) : (
        <input
          id={id || name}
          className={`custom-input ${disableHover ? 'disable-hover' : ''} ${background ? 'white-bacground' : ''}`}
          style={inputStyle}
          type={type || 'text'} // Устанавливаем тип инпута или по умолчанию text
          placeholder={placeholder}
          onChange={internalProcessing ? internalHandleChange : handleInputChange} // Обработчик изменения значения инпута
          value={internalProcessing ? internalValue : value}
          defaultValue={defaultValue}
          name={name}
          ref={inputRef} // Привязываем реф к инпуту
          autoComplete="off"
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        />
      )}
      {labelBottom && <label className="input-label-bottom">{labelBottom}</label>}
      <button
        className="custom-file"
        type="button"
        style={buttonStyle}
        onClick={handleButtonClick} // Обработчик клика на кнопку
      >
        <span className="file-name">{selectedFileName || placeholder}</span>
        <CustomButtonRemove onClick={handleRemoveClick} isDisabled={isDisabled} />
      </button>
      {(message || messageInfo || messageError) && (
        <span
          className={`message-text-input  ${message || messageInfo || messageError ? 'visible black-text' : ''} ${
            messageError ? 'error_in_cell' : 'color-info-message'
          } ${isNotMarginMessage ? 'not-margin-message' : ''}`}
        >
          {message || messageInfo || messageError}
        </span>
      )}
    </div>
  );
}

export default Input;

import { useMemo } from 'react';
import '../../styles/BrigadePlaceWork.css';
import Table from '../Table/Table';
import MyMap from '../Maps/MyMap';
import { defaultCellRender } from '../../utils/formatValueForHTML';

function BrigadePlaceWork({ currentSession, index }) {
  // Мемоизация списка маркеров
  const markers = useMemo(() => {
    // Проверяем, есть ли сигналы для обработки
    if (![currentSession?.signals?.[index]] || [currentSession?.signals?.[index]].length === 0) {
      return [];
    }

    return [currentSession?.signals?.[index]].flatMap((signal) => {
      const markers = [];

      // Функция для создания маркера
      const createMarker = (coords, type, popupContent) => ({
        position: [coords?.lat, coords.lon],
        type,
        popupContent,
      });

      // Проверка и создание маркеров для каждого типа координат
      if (signal?.iurCoords && signal?.iurCoords.lat && signal?.iurCoords.lon) {
        markers.push(createMarker(signal.iurCoords, 'green', 'ИУР'));
      }

      if (signal?.kuobzCoords && signal?.kuobzCoords.lat && signal?.kuobzCoords?.lon) {
        markers.push(createMarker(signal.kuobzCoords, 'blue', 'КУОБЗ'));
      }

      if (signal?.uippsCoords && signal?.uippsCoords?.lat && signal?.uippsCoords?.lon) {
        markers.push(createMarker(signal.uippsCoords, 'red', 'УИПС'));
      }
      if (signal?.uippsCoords1 && signal?.uippsCoords?.lat && signal?.uippsCoords?.lon) {
        markers.push(createMarker(signal?.uippsCoords, 'red', 'УИПС'));
      }

      return markers;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession?.signals?.[index]]);

  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Дорога',
        accessor: 'road',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Станция/Перегон',
        accessor: 'station',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Ордината места работ',
        accessor: 'ord',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Объект',
        accessor: 'obj',
        Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'РЦ',
        accessor: 'railName',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Смежные РЦ',
        accessor: 'adjacentRails',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Режим',
        accessor: 'workMode',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Расстояние оповещ.',
        accessor: 'warningDistance',
        Cell: ({ value }) => defaultCellRender(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSession]
  );

  const columnsTableCoords = useMemo(
    () => [
      {
        Header: () => <div style={{ color: '#33A74D' }}>Координаты ИУР</div>,
        accessor: 'iurCoords',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#33A74D' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },
      {
        Header: () => <div style={{ color: '#166EF1' }}>Координаты КУОБЗ</div>,
        accessor: 'kuobzCoords',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#166EF1' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },
      {
        Header: () => <div style={{ color: '#CA0000' }}>Координаты УИППС1</div>,
        accessor: 'uippsCoords',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#CA0000' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },
      {
        Header: () => <div style={{ color: '#CA0000' }}>Координаты УИППС2</div>,
        accessor: 'uippsCoords2',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#CA0000' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },
      {
        Header: () => <div style={{ color: '#CA0000' }}>Координаты УИППС3</div>,
        accessor: 'uippsCoords3',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#CA0000' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },

      {
        Header: () => <div style={{ color: '#CA0000' }}>Координаты УИППС4</div>,
        accessor: 'uippsCoords4',
        Cell: ({ value }) => {
          // Проверяем, что значение является объектом с нужными свойствами
          if (value && value.lat && value.lon) {
            return (
              <div style={{ color: '#CA0000' }}>
                <div>{value.lat}</div>
                <div>{value.lon}</div>
              </div>
            );
          }
          return defaultCellRender(value);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSession.signals]
  );

  return (
    <div className="brigade-place-work-table-container">
      {/* Таблица с данными о текущем месте работ */}
      <Table data={currentSession ? [currentSession] : []} columns={columns} viewHeader />
      {/*Таблица сигналов */}
      <Table
        className="customBorderRadius"
        data={currentSession?.signals?.[index] ? [currentSession?.signals?.[index]] : []}
        columns={columnsTableCoords}
        viewHeader
      />
      {/* Карта, требуеться передавать координаты */}
      <div className="brigade-place-work-map-container">
        <MyMap markers={markers} />
      </div>
    </div>
  );
}

export default BrigadePlaceWork;

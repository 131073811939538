// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-kuobz {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.add-kuobz_container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sadr-font-size-518);
  height: var(--sadr-font-size-34) ;
  margin-bottom: var(--sadr-font-size-20);
  margin-top: var(--sadr-font-size-10);
}

.add-kuobz_title-success {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-20);
  line-height: var(--sadr-font-size-20);
  text-align: center;
  color: var(--sadr-text-color);
  margin-bottom: var(--sadr-font-size-30) ;
}

.add-kuobz_container-success {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.add-kuobz_title {
  height: var(--sadr-font-size-34) ;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-28);
  line-height: var(--sadr-font-size-34);
  color: var(--sadr-success);
  white-space: nowrap;
}

`, "",{"version":3,"sources":["webpack://./src/styles/AddKuobz.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,iCAAiC;EACjC,uCAAuC;EACvC,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,qCAAqC;EACrC,kBAAkB;EAClB,6BAA6B;EAC7B,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,iCAAiC;EACjC,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,qCAAqC;EACrC,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".add-kuobz {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n}\n.add-kuobz_container-title {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: var(--sadr-font-size-518);\n  height: var(--sadr-font-size-34) ;\n  margin-bottom: var(--sadr-font-size-20);\n  margin-top: var(--sadr-font-size-10);\n}\n\n.add-kuobz_title-success {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-20);\n  line-height: var(--sadr-font-size-20);\n  text-align: center;\n  color: var(--sadr-text-color);\n  margin-bottom: var(--sadr-font-size-30) ;\n}\n\n.add-kuobz_container-success {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.add-kuobz_title {\n  height: var(--sadr-font-size-34) ;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-28);\n  line-height: var(--sadr-font-size-34);\n  color: var(--sadr-success);\n  white-space: nowrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { actions, ensurePluginOrder, useRowState } from 'react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _, { isArray } from 'lodash';

actions.setEditing = 'setEditing';
actions.setChanges = 'setChanges';

/**
 * @typedef {Object} TableChanges<T>
 * @template T
 * @property {T[]} added - добавленные элементы
 * @property {T[]} deleted - удаленные элементы
 * @property {Map<T, T>} updated - Ключ - старый объект, значение - новый объект
 * @property {Map<T, string>} errors - список элементов которые не прошли валидацию
 */

/**
 * Плагин для редактирования таблицы.<br/>
 * Изменяет несколько сущностей, рассмотрим каждые из них:<br/>
 * РЕДАКТИРОВАНИЕ<br/>
 * <Table isEditing={true | false}/> - состояние редактирования таблицы <br/>
 * В состоянии редактирования таблица будет рендерить EditCell вместе Cell везде где доступно. <br/>
 *
 *
 * Дополнительно в cell добавлены следующие поля:<br/>
 * <b>cell.state</b> - значение ячейки при редактировании. По умолчанию - обычное значение ячейки.
 * Считается, что ячейка была редактирована пользователем, если cell.state != cell.value<br/>
 * <b>cell.setState</b> - записывае состояние ячейки при редактировании. Сбрасывает ошибку<br/>
 * <b>cell.error</b> - возможная ошибка (строка). Блокирует изменение ячейки для невалидных данных.
 * Можно выводить как предупреждение для пользователя. <br/>
 * <b>cell.setError</b> - смена ошибки. <br/>
 *
 * В row добавлены следующие поля: <br/>
 * <b>row.deleteRow </b> - функция удаления строки. Вызов row.deleteRow() удаляет текущую строку из таблицы<br/>
 *
 * Для отслеживания изменений в <Table> можно передать состояние и его изменение changes / setChanges<br/>
 * Добавляя элемент в changes.added либо changes.deleted, мы добавляем/удаляем новый элемент
 */
export default function useTableEdit(hooks) {
  hooks.useOptions.push(useOptions);
  hooks.useInstanceAfterData.push(useInstanceAfterData);
  hooks.prepareRow.push(prepareRow);
  hooks.stateReducers.push(reducer);
}

useTableEdit.pluginName = 'useTableEdit';

function useOptions(instance) {
  const { data, getRowId } = instance;
  // Заменяем дату на копию элементов. Будем использовать их дальше для редактирования
  const [innerData, setInnerData] = useState([]);

  return Object.assign(instance, {
    data: innerData,
    __originalData: data,
    __setData: setInnerData
  });
}

function useInstanceAfterData(instance) {
  const {
    dispatch,
    plugins,
    data,
    __originalData,
    __setData,
    idKey = 'id',
    getRowId,
    setChanges,
    state: {
      rowState = {},
      isEditing,
      changes: changesFromState
    } = {}
  } = instance;

  // используем плагин useRowState
  ensurePluginOrder(plugins,
    [useRowState.pluginName],
    useTableEdit.pluginName);

  // высчитываю изменения для текущей таблицы
  const changes = useMemo(() => {
    if (!isEditing)
      return {};

    const mappedOrigData = new Map(__originalData.map(x => ['' + getRowId(x), x]));
    const mappedData = new Map(data.map(x => ['' + getRowId(x), x]));

    const existingKeys = Array.from(mappedOrigData.keys());
    const actualKeys = Array.from(mappedData.keys());
    const delKeys = _.without(existingKeys, ...actualKeys);
    const addKeys = _.without(actualKeys, ...existingKeys);
    const errors = new Map();
    const updated = new Map();

    for (let [rowId, singleRowState] of _.entries(rowState)) {
      const orig = mappedData.get(rowId);
      const copy = addKeys.includes(rowId)
        // Для добавленных элементов используем тот же инстанс для сравнивания.
        ? orig
        : _.cloneDeep(orig);

      for (let [colId, cellState] of _.entries(singleRowState?.cellState || {})) {
        if (cellState.error)
          errors.set(rowId, cellState.error);
        else if (cellState.hasOwnProperty('value') && cellState.value !== undefined)
          _.set(copy, colId, cellState.value);
      }

      if (!_.isEqual(orig, copy) && !errors.has(rowId) && !addKeys.includes(rowId))
        updated.set(orig, copy);
    }

    /**
     * @param keys {[]}
     * @param map {Map}
     * @return {*}
     */
    function findByKey(keys, map, mapFn = (x, key) => x) {
      return keys.map(x => mapFn(map.get(x), x));
    }

    return {
      added: findByKey(addKeys, mappedData),
      deleted: findByKey(delKeys, mappedOrigData),
      errors: new Map(Array.from(errors.entries()).map(([key, text]) => [mappedData.get(key), text])),
      updated: updated
    };
  }, [data, __originalData, isEditing, getRowId, rowState]);

  const pushChanges = useCallback(() => {
    setChanges?.(changes);
    dispatch({ type: actions.setChanges, payload: changes });
  }, [setChanges, changes, dispatch]);

  // Функция с одинаковыми зависимостями будет запускаться раз в 150 млс.
  // Нужно чтобы избежать лавинообразных изменений
  const clearEditingState = useCallback(_.debounce(() => {
    // сбрасываю историю редактирования
    dispatch({ type: actions.resetRowState });
    dispatch({ type: actions.setChanges, payload: {} });
      __setData([...__originalData]);
  }, 150, { trailing: true }), [dispatch, __originalData, __setData]);

  useEffect(function refreshData() {
    clearEditingState();
  }, [__originalData]);
  useEffect(function _clearEditingState() {
    if (isEditing)
      return;

    clearEditingState();
  }, [isEditing]);
  useEffect(function push2State() {
    pushChanges();
  }, [changes]);
  useEffect(function pullFromState() {
    if (changesFromState?.__add?.length) {
      __setData(p => [...p, ...changesFromState.__add]);
    }
  }, [changesFromState]);

  if (isEditing && instance.getRowId?.name == 'defaultGetRowId')
    throw new Error('You must define getRowId by yourself for table edit mode');

  Object.assign(instance, {
    isEditing,
    setEditing: editing => dispatch({ type: actions.setEditing, payload: editing }),
    deleteRow: ({ original, setState }) => {
      __setData(prev => prev.filter(x => x != original));
      // так же очищаем состояние строки
      setState({});
    },
    addRow: (datarow = {}) => __setData(prev => [...prev, datarow])
  });
}

function reducer(state, action, previousState, instance) {
  switch (action.type) {
    case actions.setEditing:
      return {
        ...state,
        isEditing: !!action.payload,
        rowState: !!action.payload ? state.rowState : {}
      };

    case actions.setChanges:
      return {
        ...state,
        changes: action.payload
      };

    default:
      return state;
  }
}

function prepareRow(row, { instance }) {
  if (!row || !instance.isEditing)
    return;

  row.deleteRow = () => instance.deleteRow?.(row);

  row.cells.forEach(cell => {
    const _render = cell.render.bind(cell);
    const { state = {}, setState, value: original } = cell;
    cell.render = function(name, ...args) {
      if (name == 'Cell' && typeof cell?.column?.EditCell == 'function')
        name = 'EditCell';
      return _render(name, ...args);
    };
    cell.state = state?.hasOwnProperty('value') ? state.value : original;
    cell.error = state.error;
    delete cell.setState;
    cell.changeState = (_state, _error = null) => {
      setState({ value: _state, error: _error });
    };
  });
}

/**
 * Возвращает те же инстансы
 * @template T
 * @param changes {TableChanges<T>}
 * @param arr {T[]}
 */
export function applyChangesToArray(changes, arr) {
  if (_.isEmpty(changes))
    return arr;

  let result = [...arr, ...changes.added].filter(x => !changes.deleted.includes(x));
  for (let [old, current] of changes.updated) {
    let index = result.indexOf(old);
    result.splice(index, 1, current);
  }

  return result;
}

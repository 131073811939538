import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/FilterForDocumentation.css';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import DateSelector from '../CustomElements/DateSelector';
import DateRangePicker from '../CustomElements/DateRangePicker';
import Accordeon from '../CustomElements/Accordeon';

import useOptionsLoader from '../../customHook/useOptionsLoader';
import { handleUniversalChange } from '../../utils/formUtils';
import { useFilterControl } from '../../customHook/useFilterControl';
import { initReportFromServer } from '../../redux/actions/documentation.action';
import { initDepartmenOptionsFromServer, initOptionsDirectoratesFromServer } from '../../redux/actions/options.action';

function FilterForDocumentation({ formData, setFormData, initialFormData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);
  const options = {
    type: [
      { value: 'works', label: 'Объем услуг' },
      { value: 'brigade', label: 'Работа бригад' },
      { value: 'violations', label: 'Нарушения' },
    ],
  };

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  // // запрашиваем документы, передаем параметры для фильтрации
  useEffect(() => {
    // dispatch(initReportFromServer(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id'],
    'road_id',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  return (
    <Accordeon header="ОТЧЕТНОСТЬ" hideAbove={1200} qParamName={'docs_acc'} formData={formData} resetFilters={resetFilters}>
      <button
        type="button"
        className={`filter-for-documentation_btn-reset-filters ${
          showBtnReset ? 'visible-btn-reset' : 'hidden-btn-reset'
        }`}
        onClick={resetFilters}
      >
        <span className="filter-for-documentation_btn-reset-filters_text">Сбросить все фильтры</span>
        <img className="filter-for-documentation_btn-reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="filter-for-documentation_form" id="" method="POST" action="#" autoComplete="off">
        <div className="filter-for-documentation_select" id="">
          <Input
            placeholder="Введите ID отчета"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.id}
            name="id"
            label="ID отчета"
          />
          <Selector
            options={options.type}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'type', selectedOption, 'select');
            }}
            value={formData?.type ? options.type?.find((option) => option.value === formData?.type) : null}
            label="Тип отчета"
            isMulti={false}
            name="type"
          />
          <DateSelector
            label="Дата формирования отчета"
            selectedDate={formData?.createDt}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'createDt', date, 'date')}
            name="createDt"
          />
          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            value={
              formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null
            }
            name="road_id"
            isMulti={false}
            label="Дорога"
          />
          <Selector
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            value={
              formData.directorate_id
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
                : null
            }
            name="directorate_id"
            isMulti={false}
            label="Дирекция"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
            }}
            placeholder="Выберите подразделение"
            value={
              formData?.department_id
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
                : null
            }
            isMulti={false}
            label="Подразделение"
            name="department_id"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          {/* <Selector
            options={optionsForSelect.sections}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'section_id', selectedOption, 'select');
            }}
            placeholder="Выберите участок"
            value={
              formData.section_id
                ? optionsForSelect?.sections?.find((option) => option.value === formData.section_id)
                : null
            }
            isMulti={false}
            name="section_id"
            label="Участок"
          /> */}
          <DateRangePicker
            label="Период"
            formData={formData}
            setFormData={setFormData}
            startDateField="startDate" // имя поля для даты начала, с дефолтным значением
            endDateField="endDate" // имя поля для даты окончания, с дефолтным значением
            placeholder="Выберите период"
            name="date_period"
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForDocumentation;

/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import { useFilterControl } from '../../customHook/useFilterControl';
import { handleUniversalChange } from '../../utils/formUtils';
import Accordeon from '../CustomElements/Accordeon';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import { clearUippss, initUippsFromServer } from '../../redux/actions/uipps.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import { clearMapData } from '../../redux/actions/mapData.action';
import '../../styles/FilterForUips.css';

function FilterForUips({ setIsViewList, isViewList, setIsViewMap, isViewMap, formData, setFormData, initialFormData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  // стейт активности формы
  const formActive = useSelector((state) => state.filterState.filter);
  // блокируем дублирующиеся запросы
  const block = useRef(true);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id'],
    'road_id',
    initOptionsDirectoratesUippsFromServer,
    messageForSelectDirectorates
  );
  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //цвет кнопок, события кнопок
  const [colorBtn, setColorBtn] = useState(false);
  const handleButtonClickList = () => {
    if (!isViewList) {
      setIsViewList((prev) => !prev);
      setIsViewMap((prev) => !prev);
      setColorBtn((prev) => !prev);
      dispatch(clearMapData());
    }
  };
  const handleButtonClickMap = () => {
    if (!isViewMap) {
      setIsViewMap((prev) => !prev);
      setIsViewList((prev) => !prev);
      setColorBtn((prev) => !prev);
      dispatch(clearMapData());
    }
  };
  // получаем все уипсы
  useEffect(() => {
    if (block.current) {
      block.current = false;
    } else {
      if ((formData?.id?.length === 6 || !formData?.id) && !isViewMap && formActive) {
        dispatch(clearUippss());
        dispatch(clearFilterConfig());
        dispatch(initUippsFromServer(formData));
      }
    }
  }, [formData, isViewMap, formActive]);

  return (
    <Accordeon
      header="КОНТРОЛЬ УИППС"
      hideAbove={1200}
      qParamName="uiips_acc"
      formData={formData}
      resetFilters={resetFilters}
    >
      <div className="filter-for-brigades_container_btn">
        <button
          type="button"
          className={`filter-for-brigades_btn-list ${!colorBtn ? 'filter-for-brigades_btn-active' : ''}`}
          onClick={handleButtonClickList}
        >
          <span className="brigades-button-text">Список</span>
        </button>
        <button
          type="button"
          className={`filter-for-brigades_btn-map ${colorBtn ? 'filter-for-brigades_btn-active' : ''}`}
          onClick={handleButtonClickMap}
        >
          <span className="brigades-button-text">Карта</span>
        </button>
      </div>
      <button
        type="button"
        onClick={resetFilters}
        className={`filter-for-uipps_btn-reset-filters ${showBtnReset ? 'visible-btn-reset' : 'hidden-btn-reset'}`}
      >
        <span className="filter-for-uipps_btn-reset-filters_text">Сбросить все фильтры</span>
        <img className="filter-for-uipps_btn-reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="filter-for-uips_wrapper" id="" method="POST" action="#" autoComplete="off">
        <div className="filter-for-uips_select" id="">
          <Input
            placeholder="Введите ID УИППС"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData.id}
            name="id"
            label="ID УИППС"
            // type="number"
          />
          <Selector
            options={optionsForSelect?.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            value={
              formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null
            }
            isMulti={false}
            name="road_id"
            label="Дорога"
          />
          <Selector
            options={optionsForSelect?.directoratesUipps}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            value={
              formData?.directorate_id
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
                : null
            }
            name="directorate_id"
            isMulti={false}
            label="Дирекция"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
            }}
            placeholder="Выберите № подразделения"
            value={
              formData?.department_id
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
                : null
            }
            name="department_id"
            isMulti={false}
            label="Подразделение"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.trainTypes}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'train_type_id', selectedOption, 'select');
            }}
            placeholder="Выберите тип ПС"
            value={
              formData?.train_type_id
                ? optionsForSelect?.trainTypes.find((option) => option.value === formData.train_type_id)
                : null
            }
            name="train_type_id"
            isMulti={false}
            label="Тип подвижного состава"
          />
          <Input
            placeholder="Введите № локомотива"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 15)}
            value={formData?.train_number_id}
            name="train_number_id"
            label="Номер подвижного состава"
            // type="number"
          />
          <Input
            placeholder="Введите  № машиниста"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 15)}
            value={formData?.responsible}
            name="responsible"
            label="Табельный номер машиниста"
            // type="number"
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForUips;

import { useEffect, useState } from 'react';
import {debounce} from 'lodash';

/**
 * Возвращает актуальные рамеры окна
 * @returns {{width: number, height: number}}
 */
export default function useWindowWidth() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const listener = debounce(()=>{
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 150, {maxWait: 5});
    window.addEventListener('resize', listener);
    return ()=> void window.removeEventListener('resize', listener);
  }, []);
  return size;
}
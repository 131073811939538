import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import '../../styles/FilterForArchiveParams.css';
import Accordeon from '../CustomElements/Accordeon';
import ExpandableForm from '../CustomElements/ExpandableForm';
import FilterForArchiveKUOBZ from './FilterForArchiveKUOBZ';
import FilterForArchiveIUR from './FilterForArchiveIUR';
import FilterForArchiveServer from './FilterForArchiveServer';

import { useFilterControl } from '../../customHook/useFilterControl';
import { initArchiveSessionFromServer } from '../../redux/actions/archive.action';

function FilterForArchiveParams({ sessionId }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    //server
    index: '',
    alertType: '',
    routeRailName: '',
    dir: '',
    distance: '',
    warningRails: '',
    alertRails: '',
    uippsId: '',
    uippsAlertType: '',
    preciseDistance: '',
    btn_alert: false,
    btn_clear: false,
    inSafeZone: '',
    fencingBtnServer: false,
    transferRails: '',
    //IUR
    iurAlertType: '',
    iurGPS: '',
    iurGSM: '',
    iurConn: '',
    //KUOBZ
    mainKuobzId: '',
    signalKuobz: '',
    safeZoneType: '',
    safeZoneWidth: '',
    kuobzGPS: '',
    kuobzGSM: '',
    kuobzConn: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData, [
    'kuobzConn',
    'iurConn',
  ]);

  // Функция для обработки события изменения checkbox, принимает имя поля в качестве аргумента
  const handleChangeCheckbox = (fieldName) =>
    setFormData((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));

  // фильтрация сигналов
  useEffect(() => {
    if (sessionId) {
      dispatch(initArchiveSessionFromServer(sessionId, formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Accordeon header="ПАРАМЕТРЫ АРХИВА" hideAbove={1200} qParamName={'docs_acc'} formData={formData} resetFilters={resetFilters}>
      <button
        type="button"
        className={`filter-for-archive-params_btn-reset-filters ${
          showBtnReset ? 'visible-btn-reset' : 'hidden-btn-reset'
        }`}
        onClick={resetFilters}
      >
        <span className="filter-for-archive-params_btn-reset-filters_text">Сбросить все фильтры</span>
        <img className="filter-for-archive-params_btn-reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      {/* <div className='margin-div'></div> */}
      <div className="filter-for-archive-params_form">
        <div className="filter-for-archive-params_select">
          <ExpandableForm title={'Сервер'}>
            <FilterForArchiveServer
              initialFormData={initialFormData}
              formData={formData}
              setFormData={setFormData}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          </ExpandableForm>
          <ExpandableForm title={'ИУР'}>
            <FilterForArchiveIUR
              initialFormData={initialFormData}
              formData={formData}
              setFormData={setFormData}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          </ExpandableForm>
          <ExpandableForm title={'КУОБЗ'}>
            <FilterForArchiveKUOBZ
              initialFormData={initialFormData}
              formData={formData}
              setFormData={setFormData}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          </ExpandableForm>
        </div>
      </div>
    </Accordeon>
  );
}

export default FilterForArchiveParams;

/**
 * Функция для обновления или добавления информации об изменениях в объекте
 * @param {Object} changes - Объект, в который будут добавляться изменения
 * @param {string} id - Идентификатор элемента, который изменяется
 * @param {string} fieldName - Имя поля, которое изменяется
 * @param {*} oldValue - Старое значение поля
 * @param {*} newValue - Новое значение поля
 * @param {string} railChainName - Имя железнодорожной цепи
 * @returns {boolean} - Возвращает true, если изменения были внесены, иначе false
 */
export function trackChange(changes, id, fieldName, oldValue, newValue, railChainName) {
  oldValue = oldValue === '' || oldValue === false ? null : oldValue;
  newValue = newValue === '' || newValue === false ? null : newValue;
  // Проверяем, что старое значение отличается от нового
  if (String(oldValue) !== String(newValue)) {
    if (!changes[id]) {
      changes[id] = {}; // Если объекта с таким ID нет, создаем его
    }

    // Добавляем имя РЦ, если его еще нет в объекте
    if (!changes[id]['РЦ']) {
      changes[id]['РЦ'] = {
        old: railChainName,
        new: null
      };
    }

    // Обновляем значение поля
    changes[id][fieldName] = {
      old: oldValue,
      new: newValue? newValue: null
    };

    return true;
  } else {
    // Если старое значение равно новому, удаляем запись об изменении
    if (changes[id] && changes[id][fieldName]) {
      delete changes[id][fieldName];

      // Если у объекта с данным ID больше нет изменений, удаляем его
      if (Object.keys(changes[id]).length === 1 && changes[id]['rail_chain_name']) {
        delete changes[id];
      }
    }

    return false;
  }
}

import _ from 'lodash';
import axiosInstance from '../config/axios.config';

/**
 * @typedef {Object} Location
 * @property {number} lon - широта
 * @property {number} lat - долгота
 */

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km
  return d;
}

/**
 *
 * @param locations {Location[]}
 * @return {number}
 */
function getYandexZoom(locations) {
  if (locations.length < 2) return 10;

  // const lvl21 = 0.055; // На 21 масштабе видим ~55 кв метр карты.
  // const lvl21linear = 4; // Масштаб линейки на 21 уровне  - 4м

  const linears = [3000, 1000, 700, 400, 200, 90, 50, 20, 10, 6, 3, 1, 0.7, 0.4, 0.2, 0.09, 0.05];

  const minCords = {
    lat: _.min(locations.map((x) => x.lat)),
    lon: _.min(locations.map((x) => x.lon)),
  };
  const maxCords = {
    lat: _.max(locations.map((x) => x.lat)),
    lon: _.max(locations.map((x) => x.lon)),
  };
  let dist = getDistanceFromLatLonInKm(minCords.lat, minCords.lon, maxCords.lat, maxCords.lon);
  let firstBigger = linears.findIndex((x) => dist >= x);
  if (firstBigger < 0) firstBigger = linears.length - 1;
  // Берем предыдущий индекс и добавляем 2 уровня (масштаб начинается с 2) и ещё один (чтобы все комфортно влезло)
  let zoom = firstBigger + 3;
  return zoom;
}

/**
 * Получает ссылку на Яндекс карты. Для отрисовки использует точки на карте
 * @param loc {Location | Location[]}
 * @return URL
 * @see https://yandex.ru/dev/yandex-apps-launch-maps/doc/ru/concepts/yandexmaps-web?ysclid=lu1a7j0elf274076866
 */
export function getYandexPointsLink(loc) {
  loc = Array.isArray(loc) ? loc : [loc];
  loc = loc.filter((x) => Number.isFinite(Number(x?.lat)) && Number.isFinite(Number(x?.lon)));
  const result = new URL('https://yandex.ru/maps');
  result.searchParams.set('l', 'map');
  result.searchParams.set('pt', loc.map(({ lat, lon }) => lat + ',' + lon).join('~'));
  if (loc.length > 2) {
    let middle = {
      lat: _.meanBy(loc, (x) => x.lat),
      lon: _.meanBy(loc, (x) => x.lon),
    };
    result.searchParams.set('ll', middle.lat + ',' + middle.lon);
    result.searchParams.set('z', getYandexZoom(loc));
  }
  return result;
}

/**
 * Получает ссылку на Яндекс карты. Для отрисовки использует линейку
 * @param loc {Location | Location[]}
 * @return URL
 * @see https://yandex.ru/dev/yandex-apps-launch-maps/doc/ru/concepts/yandexmaps-web?ysclid=lu1a7j0elf274076866
 */
export function getYandexLinearMap(loc) {
  const url = getYandexPointsLink(loc);
  let points = url.searchParams
    .get('pt')
    .split('~')
    .map((x) => x.split(',').map((x) => +x));
  let rl = [points[0]];
  for (let i = 1; i < points.length; i++) {
    const current = points[i];
    const orig = points[i - 1];
    const next = [current[0] - orig[0], current[1] - orig[1]];
    rl.push(next);
  }
  url.searchParams.delete('pt');
  url.searchParams.delete('l');
  url.searchParams.set('rl', rl.map((x) => x.join(',')).join('~'));
  return url;
}

/**
 * Checks if a given coordinate is within a visible area.
 *
 * @param {Object} visibleCoords - The visible coordinates with _southWest and _northEast properties.
 * @param {number} itemLat - The latitude of the item to check.
 * @param {number} itemLon - The longitude of the item to check.
 * @return {boolean} True if the item is within the visible area, false otherwise.
 */
export const checkVisibleCoords = (visibleCoords, itemLat, itemLon) => {
  if (
    itemLat > visibleCoords._southWest.lat &&
    itemLat < visibleCoords._northEast.lat &&
    itemLon > visibleCoords._southWest.lng &&
    itemLon < visibleCoords._northEast.lng
  ) {
    return true;
  } else {
    return false;
  }
};
/**
 * Checks if an item with the given ID is not in ignore list
 * is in the filter based on the provided parameters (if parameters are provided).
 *
 * @param {string} id - The ID of the item to check.
 * @param {string} type - The type of the item ('УИППС' or 'brigades').
 * @param {string} lastRequest - The last request made.
 * @param {Array<string>} ignoredIds - The list of IDs to ignore.
 * @return {Promise<boolean>} A promise that resolves to true if the item is in the filter, false otherwise.
 */
export const checkIfItemInFilter = async (id, type, lastRequest, ignoredIds) => {
  // Checking ignore list
  if (ignoredIds?.includes(id)) return false;
  // If alredy filtered by id, then it's there is no other items in the filter
  if(lastRequest.includes("?id=")) return false;
  // Checking last request. If it's a request for visible area, then it's there is no filter
  if(lastRequest.startsWith("?visibleMinLat")) return true;
  // Sending request with provided parameters to backend and checking if item is in the filter
  const requestTo = type === 'УИППС' ? 'devices/uipps' : 'brigades';
  const result = await axiosInstance(`/api/${requestTo}${lastRequest}&id=${id}`);
  if (result.data.length && result.status === 200) {
    return true;
  } else {
    return false;
  }
};

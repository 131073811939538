// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-place-work-container-page{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}
.choose-place-work-content{
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-left: var(--sadr-font-size-100);
}

.choose-place-work-text{
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  color: var(--sadr-text-color);
  margin-bottom: var(--sadr-font-size-20);
  text-align: left;
}

.choose-place-work {
  width: 100%;
  height: 78%;
}

.choose-place-work-container {
  display: flex;
  gap: var(--sadr-font-size-10); /* Расстояние между элементами */
  margin-bottom: var(--sadr-font-size-30) ;
  text-align: left;
}

.variable-text {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  color: var(--sadr-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/styles/%D0%A1hoosePlaceWork.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;EACZ,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,6BAA6B;EAC7B,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,aAAa;EACb,6BAA6B,EAAE,gCAAgC;EAC/D,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,6BAA6B;AAC/B","sourcesContent":[".choose-place-work-container-page{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: auto;\n}\n.choose-place-work-content{\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: 100%;\n  width: 100%;\n  margin-left: var(--sadr-font-size-100);\n}\n\n.choose-place-work-text{\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  color: var(--sadr-text-color);\n  margin-bottom: var(--sadr-font-size-20);\n  text-align: left;\n}\n\n.choose-place-work {\n  width: 100%;\n  height: 78%;\n}\n\n.choose-place-work-container {\n  display: flex;\n  gap: var(--sadr-font-size-10); /* Расстояние между элементами */\n  margin-bottom: var(--sadr-font-size-30) ;\n  text-align: left;\n}\n\n.variable-text {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  color: var(--sadr-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

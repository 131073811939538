/**
 * Обработчик изменений длины участка оповещения в таблице рельсовых цепей.
 *
 * @param {Event} event - Событие изменения.
 * @param {number} index - Индекс строки в массиве copySource.
 * @param {boolean} isFirstElement - Флаг для определения первого элемента alertLengths.
 * @param {function} setCopySource - Функция обновления состояния copySource.
 */
export const handleAlertLengthsChange = (event, index, isFirstElement, setCopySource) => {
  // Получаем новое значение из события изменения
  let newValue = event.target.value;
  if (newValue === '' || newValue === false) {
    newValue = null;
  }

  // Обновляем состояние с использованием функции обновления состояния
  setCopySource((prevCopySource) => {
    // Создаем копию предыдущего состояния массива copySource
    const updatedCopySource = [...prevCopySource];

    // Обновляем элемент массива с указанным индексом
    updatedCopySource[index] = {
      ...updatedCopySource[index], // Копируем остальные свойства из предыдущего состояния
      // Обновляем alertLengths в зависимости от isFirstElement
      alertLengths: isFirstElement
        ? [newValue, updatedCopySource[index].alertLengths[1]] // Если isFirstElement, обновляем первый элемент
        : [updatedCopySource[index].alertLengths[0], newValue], // Иначе обновляем второй элемент
    };

    // Возвращаем обновленный массив copySource
    return updatedCopySource;
  });
};

/**
 * Обрабатывает изменение значения поля ввода и обновляет состояние массива copySource.
 *
 * @param {Object} event - Событие изменения поля ввода.
 * @param {number} index - Индекс элемента в массиве copySource, который необходимо обновить.
 * @param {string} fieldName - Имя поля, которое необходимо обновить.
 * @param {Function} setCopySource - Функция для обновления состояния массива copySource.
 * @param {boolean} restrictTextInput - Флаг, указывающий, следует ли ограничивать ввод текста.
 */
export const handleFieldChange = (event, index, fieldName, setCopySource, restrictTextInput, coordArray) => {
  // Получаем новое значение из события изменения
  let newValue = coordArray? coordArray : event.target.value;
  if (newValue === '' || newValue === false) {
    newValue = null;
  }
  // Проверяем, нужно ли ограничивать ввод текста
  if (restrictTextInput) {
    const regex = /^[0-9.,;\s-]*$/; // Разрешенные символы: цифры, точка, запятая, точка с запятой, пробел и тире
    if (!regex.test(newValue)) {
      event.preventDefault(); // Предотвращаем изменение, если значение не соответствует разрешенным символам
      return;
    }
  }

  // Обновляем состояние с использованием функции обновления состояния
  setCopySource((prevCopySource) => {
    // Создаем копию предыдущего состояния массива copySource
    const updatedCopySource = [...prevCopySource];

    // Обновляем элемент массива с указанным индексом
    updatedCopySource[index] = {
      ...updatedCopySource[index], // Копируем остальные свойства из предыдущего состояния
      // Обновляем указанное поле
      [fieldName]: newValue === '' ? null : newValue, // Обновляем указанное поле,
    };

    // Возвращаем обновленный массив copySource
    return updatedCopySource;
  });
};

/**
 * Обновляет значения координат в состоянии copySource.
 *
 * @param {Event} event - Событие изменения.
 * @param {string} type - Тип координаты, может быть 'coordinate', 'uncontrolled' или 'controlled'.
 * @param {string} name - Имя поля для обновления, например, 'ord', 'lat', 'lon'.
 * @param {number} index - Индекс объекта в массиве copySource.
 * @param {function} setCopySource - Функция обновления состояния copySource.
 * @param {number|null} [arrayIndex=null] - Индекс объекта в массиве 'uncontrolled' или 'controlled', если применимо.
 */
export const handleCoordinateChange = (event, type, name, index, setCopySource, arrayIndex = null) => {
  // Получаем новое значение из события изменения
  const newValue = event.target.value;
  // Обновляем состояние с использованием функции обновления состояния
  setCopySource((prevCopySource) => {
    // Создаем копию предыдущего состояния массива copySource
    const updatedCopySource = [...prevCopySource];

    // Проверяем, обновляем ли мы координаты 'coordinate.end'
    if (type === 'coordinate') {
      // Обновляем поле в объекте coordinate.end
      updatedCopySource[index] = {
        ...updatedCopySource[index], // Копируем остальные свойства из предыдущего состояния
        coordinate: {
          ...updatedCopySource[index].coordinate, // Копируем остальные свойства из coordinate
          end: {
            ...updatedCopySource[index].coordinate.end, // Копируем остальные свойства из coordinate.end
            [name]: newValue === '' || newValue === false  ? null : newValue, // Обновляем указанное поле
          },
        },
      };
    } 
    // Проверяем, обновляем ли мы массивы 'uncontrolled' или 'controlled'
    else if (type === 'uncontrolled' || type === 'controlled') {
      // Создаем копию массива 'uncontrolled' или 'controlled'
      const arrayToUpdate = [...updatedCopySource[index].coordinate[type]];

      // Обновляем указанный объект в массиве
      arrayToUpdate[arrayIndex] = {
        ...arrayToUpdate[arrayIndex], // Копируем остальные свойства объекта в массиве
        [name]: newValue === '' || newValue === false  ? null : newValue, // Обновляем указанное поле
      };

      // Обновляем элемент массива copySource с указанным индексом
      updatedCopySource[index] = {
        ...updatedCopySource[index], // Копируем остальные свойства из предыдущего состояния
        coordinate: {
          ...updatedCopySource[index].coordinate, // Копируем остальные свойства из coordinate
          [type]: arrayToUpdate, // Обновляем массив 'uncontrolled' или 'controlled'
        },
      };
    }

    // Возвращаем обновленный массив copySource
    return updatedCopySource;
  });
};

/**
 * Разбирает строку координат и возвращает массив объектов с координатами lat и lon, а также массив ID строк координат, которые не прошли проверку.
 *
 * @param {string} coordinatesString Строка координат в формате "lat,lon;lat,lon;..."
 * @param {string|number} id ID строки координат для добавления в массив некорректных координат
 * @returns {Object} Объект, содержащий массив объектов с координатами lat и lon и массив ID строк координат, которые не прошли проверку
 */
export function parseCoordinatesString(coordinatesString, id) {
  coordinatesString = coordinatesString.replace(/ /g, '')
  const invalidCoordinatesIds = [];
  const coordinatesArray = [];
  // Регулярное выражение для проверки координат
  const coordinateRegex = /^-?\d{1,3}(\.\d{1,15})?$/;
  // Разделить строку на массив пар координат
  const pairs = coordinatesString?.split(';');

  // Преобразовать каждую пару в объект с свойствами lat и lon
  pairs?.forEach(pair => {
    // Проверяем, что пара не пустая
    if (pair.trim() !== '') { 
    const [lat, lon] = pair?.split(',');
    const parsedLat = parseFloat(lat);
    const parsedLon = parseFloat(lon);
    // Проверить, что удалось корректно преобразовать lat и lon в числа, и что они находятся в допустимом диапазоне
    const isValid = !isNaN(parsedLat) && !isNaN(parsedLon) &&
        coordinateRegex.test(lat) && coordinateRegex.test(lon) &&
        Math.abs(parsedLat) <= 90 && Math.abs(parsedLon) <= 180;

    if (isValid) {
      coordinatesArray.push({ lat: parsedLat, lon: parsedLon, isValid });
    } else {
      // В случае некорректных координат вернуть исходные значения и флаг isValid = false
      invalidCoordinatesIds.push(Number(id));
      coordinatesArray.push({ lat: lat ? parseFloat(lat) : null, lon: lon ? parseFloat(lon) : null, isValid: false });
    }
   }
  });

  return { coordinatesArray, invalidCoordinatesIds };
}


/**
 * Преобразует строковые значения свойства track каждого объекта в массив объектов с координатами lat и lon.
 * @param {Object} obj - Исходный объект, содержащий объекты с свойством track, которое требуется преобразовать.
 * @returns {Array<string|number>|boolean} Массив ID строк координат, которые не прошли проверку, или false, если таких ID нет
 */
export function processTracks(obj) {
  const invalidIds = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const item = obj[key];
      if (typeof item.track === 'string' && item.track.trim() !== '') {
        // Преобразовать строку в массив объектов с координатами lat и lon
        const { coordinatesArray, invalidCoordinatesIds } = parseCoordinatesString(item.track, key);
        item.track = coordinatesArray;
        invalidCoordinatesIds.forEach(id => {
          if (!invalidIds.includes(id)) {
            invalidIds.push(Number(id));
          }
        });
      }
    }
  }
  return invalidIds.length > 0 ? invalidIds : false;
}


/**
 * Валидирует введенное значение координаты.
 * @param {string} value - Введенное значение координаты для проверки.
 * @param {boolean} isLatitude - Флаг, указывающий, проверяем ли мы широту (true) или долготу (false).
 * @returns {boolean} true, если значение прошло проверку; в противном случае false.
 */
export function validateCoordinate(value, isLatitude) {
  const coordinateRegex = /^-?\d{1,3}\.\d{1,15}$/;

  // Проверка, что значение пустое
  if (value === '') {
    return true;
  }

  // Проверка формата ввода с помощью регулярного выражения
  if (!coordinateRegex.test(value)) {
    return false;
  }

  // Преобразование строки в число с плавающей точкой
  const parsedCoord = parseFloat(value);

  // Проверка диапазона значений в зависимости от типа координаты
  if (isLatitude) {
    // Проверка для широты
    return Math.abs(parsedCoord) <= 90;
  } else {
    // Проверка для долготы
    return Math.abs(parsedCoord) <= 180;
  }
}

import React from 'react';
import '../../styles/CustomButton.css';

/**
 * Renders a custom button component based on the provided props.
 *
 * @param {object} margin - The margin for the button.
 * @param {number} minHeight - The minimum height of the button.
 * @param {string} text - The text displayed on the button.
 * @param {string} color - The background color of the button.
 * @param {string} width - The width of the button.
 * @param {string} height - The height of the button.
 * @param {function} onClick - The function to be executed on button click.
 * @param {boolean} disabled - Flag indicating if the button is disabled.
 * @param {string} colorText - The text color of the button.
 * @param {string} className - Additional class name for styling.
 * @param {JSX.Element} SvgIcon - An optional SVG icon component.
 * @param {object} styleSvg - Custom styles for the SVG icon.
 * @param {string} border - The border style of the button.
 * @param {string} type - The type of the button.
 * @param {boolean} isButton - Flag indicating if it's a button element.
 * @param {string} href - The link URL if the button is a link.
 * @param {string} download - The file name to download if the button is a link.
 * @param {object} rest - Additional props spread.
 * @return {JSX.Element} The rendered custom button component.
 */
function CustomButton({
  margin,
  minHeight,
  text,
  color,
  width,
  height,
  onClick,
  disabled,
  colorText,
  className,
  SvgIcon,
  styleSvg,
  border,
  type,
  isButton,
  href,
  download,
  ...rest
}) {
  const buttonStyle = {
    backgroundColor: color,
    color: colorText,
    width: width,
    height: height,
    disabled: disabled ? disabled : false,
    onClick: onClick ? onClick : '',
    minHeight: minHeight,
    margin: margin,
    border: border ? ' var(--sadr-font-size-1)  solid black' : '',
  };

  const linkStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    color: colorText,
    width: width,
    height: height,
    minHeight: minHeight,
    margin: margin,
    border: border ? 'var(--sadr-font-size-1) solid black' : '',
    textDecoration: 'none',
  };

  if (href) {
    return (
      <a
        href={href}
        download={download}
        onClick={(event) => {
          if (disabled) {
            event.preventDefault(); // Предотвратить действие по умолчанию, если ссылка задизейблена
          } else {
            onClick && onClick(event);
          }
        }}
        className={`custom-btn ${className ? className : ''}`}
        aria-label="Скачать документ"
        style={{
          ...linkStyle,
          pointerEvents: disabled ? 'none' : 'auto',
          backgroundColor: disabled ? '#adadad' : linkStyle.backgroundColor,
        }}
      >
        {SvgIcon && (
          <SvgIcon style={{ verticalAlign: 'middle', marginRight: 'var(--sadr-font-size-5)', ...styleSvg }} />
        )}
        <span id="button-text">{text}</span>
      </a>
    );
  }

  return (
    <button
      {...rest}
      className={`custom-btn ${className ? className : ''}`}
      style={buttonStyle}
      onClick={(event) => {
        onClick(event);
      }}
      disabled={disabled}
      type={type ? type : 'button'}
    >
      {SvgIcon && <SvgIcon style={{ verticalAlign: 'middle', marginRight: 'var(--sadr-font-size-5)', ...styleSvg }} />}
      <span id="button-text" style={{ marginTop: '1px'}}>{text}</span>
    </button>
  );
}

export default CustomButton;

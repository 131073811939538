import React from 'react';
import '../../styles/Preloader.css';

const Preloader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="preloader">
      <div className="preloader__wrap">
        <div className="preloader__spinner-loader"></div>
        <div className="preloader__message">Загрузка...</div>
      </div>
    </div>
  );
};

export default Preloader;

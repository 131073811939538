import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/Brigade.css';
import CustomButton from '../CustomElements/CustomButton';
import DoubleButton from '../CustomElements/DobuleButton';
import BrigadeFinishWork from './BrigadeFinishWork';
import BrigadeSignals from './BrigadeSignals';
import Modal from '../Modal/Modal';
import BrigadeComposition from './BrigadeComposition';
import BrigadePlaceWork from './BrigadePlaceWork';
import BrigadeEditDeviceMode from './BrigadeEditDeviceMode';
import BrigadeEditDeviceModeMovement from './BrigadeEditDeviceModeMovement';
import { SvgIconBell, SvgIconNight } from '../CustomElements/CustomIcon';
import BrigadeTransferFencing from '../Brigades/BrigadeTransferFencing';
import SignalModeOff from './SignalModeOff';
import SignalModeOn from './SignalModeOn';
import BrigadeSafetyFenceAllowed from './BrigadeSafetyFenceAllowed';
import BrigadeSafetyFenceRemove from './BrigadeSafetyFenceRemove';
import BrigadeTransferFencingOff from './BrigadeTransferFencingOff';
import BrigadeTransfer from './BrigadeTransfer';
import { determineBackgroundColor } from '../../utils/formatValueForHTML';
import { formatMillisecondsToHoursMinutes, convertMillisecondsToDate } from '../../utils/convertToDate';
import {
  emitSignalModeChange,
  emitBrigadeListenStart,
  emitBrigadeListenStop,
  emitWorkModeChange,
  emitFinishWork,
  emitTransferFencingOff,
  emitRestrictOn,
  emitRestrictOff,
  emitTransferOn,
  initBrigadeAfterUpdate,
  emitSetStandalone,
  emitSetCanEnterOrd,
} from '../../redux/actions/brigades.action';
import { listenBrigadeInfo, listenWorkMode, listenPlaceWorkInfo } from '../../redux/actions/brigades.action';
import {
  removeStateCurrentLocationsWork,
  removeStateBrigadeSignals,
  removeStateAllSignals,
  removeStatebrigadeInfo,
} from '../../redux/actions/brigades.action';
import BrigadeSafetyFence from './BrigadeSafetyFence';
import { useRole } from '../../customHook/useRole';

function Brigade({ currentBrigade, formData, t }) {
  // Получение данных о бригадах из Redux store.
  const { brigadeInfo, brigadeSignals } = useSelector((state) => state.brigadesState);
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Локальные состояния для управления UI компонента.
  const [brigadeFinishWork, setBrigadeFinishWork] = useState(false);

  const [brigadeTransferFencing, setBrigadeTransferFencing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isAdmin = useRole('admin');
  const isCustomer = useRole('customer');

  const timerRef = useRef();

  const [openBrigadeEditDeviceMode, setOpenBrigadeEditDeviceMode] = useState(false);
  const [openBrigadeEditDeviceModeMovement, setOpenBrigadeEditDeviceModeMovement] = useState(false);
  const [openSignalModeOn, setOpenSignalModeOn] = useState(false);
  const [openSignalModeOff, setOpenSignalModeOff] = useState(false);
  const [openSignalRestrictOn, setOpenSignalRestrictOn] = useState(false);
  const [openSignalRestrictOnBan, setOpenSignalRestrictOnBan] = useState(false);
  const [openSignalRestrictOff, setOpenSignalRestrictOff] = useState(false);
  const [openTransferFencingOn, setOpenTransferFencingOn] = useState();
  const [openTransferFencingOff, setOpenTransferFencingOff] = useState();

  // Объект для отображения соответствующего содержимого в зависимости от выбранной вкладки.
  const contentData = useMemo(
    () => ({
      ...((isAdmin || isCustomer) && {
        [t?.brigadeComposition]: <BrigadeComposition currentBrigade={currentBrigade} t={t} />,
      }),
      [t?.workPlace]: <BrigadePlaceWork currentBrigade={currentBrigade} t={t} />,
      [t?.signals]: <BrigadeSignals currentBrigade={currentBrigade} t={t} />,
    }),
    [t, isAdmin, isCustomer, currentBrigade]
  );

  const [activeButton, setActiveButton] = useState(Object.keys(contentData)[0]);

  //Обработчик клика по кнопке для изменения активного содержимого.
  const handleButtonClick = (buttonName) => {
    if (buttonName !== activeButton) {
      setActiveButton(buttonName);
    }
  };
  // Закрытие модального окна. Меняем стейты для отображения на false
  const closeModal = () => {
    setIsOpen(false);
    setBrigadeFinishWork(false);
    setBrigadeTransferFencing(false);
    setOpenBrigadeEditDeviceMode(false);
    setOpenBrigadeEditDeviceModeMovement(false);
    setOpenSignalModeOn(false);
    setOpenSignalModeOff(false);
    setBrigadeTransferFencing(false);
    setOpenTransferFencingOff(false);
    setOpenTransferFencingOn(false);
    setOpenSignalRestrictOn(false);
    setOpenSignalRestrictOff(false);
  };

  // Обработчик для открытия модального окна завершения работы.
  const handleClickFinishWork = () => {
    setIsOpen(true);
    setBrigadeFinishWork(true);
  };

  // Обработчик подтверждения "Завершить работу" в модальном окне.
  const handlerConfirm = () => {
    const payload = { brigadeId: currentBrigade.id };
    dispatch(emitFinishWork(payload));
    closeModal();
  };

  //открытие модального окна для включения режима 'зона'
  const btnWorkModeZona = () => {
    if (brigadeInfo?.workMode !== 2) {
      setOpenBrigadeEditDeviceMode(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о переходе в режим 'зона'
  const handleButtonClickEditDeviceMode = () => {
    if (brigadeInfo?.workMode !== 2) {
      const payload = { brigadeId: currentBrigade?.id, workMode: 2 };
      dispatch(emitWorkModeChange(payload));
      closeModal();
    }
  };

  //открытие модального окна для включения режима'движение'
  const btnWorkModeMovement = () => {
    if (brigadeInfo?.workMode === 2) {
      setOpenBrigadeEditDeviceModeMovement(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о переходе в режим 'движение'
  const handleButtonClickEditDeviceModeMovement = () => {
    const payload = { brigadeId: currentBrigade?.id, workMode: 1 };
    dispatch(emitWorkModeChange(payload));
    closeModal();
  };

  //открытие модального окна для включения режима 'громко'
  const btnSignalModeOn = () => {
    setOpenSignalModeOn(true);
    setIsOpen(true);
  };

  //отправка события на сервер о переходе в режим 'громко'
  const handleButtonClickDeviceSignalOn = () => {
    const payload = { brigadeId: currentBrigade.id, signalMode: 2 };
    dispatch(emitSignalModeChange(payload));
    closeModal();
  };

  //открытие модального окна для включения режима 'тихо'
  const btnSignalModeOff = () => {
    setOpenSignalModeOff(true);
    setIsOpen(true);
  };

  //отправка события на сервер о переходе в режим 'тихо'
  const handleButtonClickDeviceSignalOff = () => {
    const payload = { brigadeId: currentBrigade.id, signalMode: 1 };
    dispatch(emitSignalModeChange(payload));
    closeModal();
  };

  //открытие модального окна для включения режима 'ограждение'
  const btnisRestrictOn = () => {
    if (!brigadeInfo?.isClear) {
      setOpenSignalRestrictOnBan(true);
      setIsOpen(true);
    }
    if (brigadeInfo?.isClear && !brigadeInfo?.isRestrictOn) {
      setOpenSignalRestrictOn(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о включении "ограждения"
  const handleButtonClickRestrictOn = () => {
    const payload = { brigadeId: currentBrigade.id };
    dispatch(emitRestrictOn(payload));
    setLoading(true);

    // Сохранение идентификатора таймера в ref
    timerRef.current = setTimeout(() => {
      setLoading(false);
      closeModal();
    }, 30000);
  };

  // Функция для изменения состояния свитчера 'Работа без КУОБЗ'
  const handleStandaloneChange = (value) => {
    dispatch(emitSetStandalone({ brigadeId: currentBrigade?.id, value: value }));
  };

  // Функция для изменения состояния свитчера 'Ввод ординаты'
  const handleCanEnterOrdChange = (value) => {
    dispatch(emitSetCanEnterOrd({ brigadeId: currentBrigade?.id, value: value }));
  };

  useEffect(() => {
    if (brigadeInfo?.isRestrictOn && loading) {
      // Если isRestrictOn сменился на true до истечения таймера
      clearTimeout(timerRef.current); // Отменить таймер
      setLoading(false);
      closeModal();
    }
    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brigadeInfo?.isRestrictOn]);

  //открытие модального окна для вЫключения режима 'ограждение'
  const btnisRestrictOff = () => {
    if (brigadeInfo?.isRestrictOn) {
      setOpenSignalRestrictOff(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о вЫключении "ограждения"
  const handleButtonClickRestrictOff = () => {
    const payload = { brigadeId: currentBrigade.id };
    dispatch(emitRestrictOff(payload));
    closeModal();
  };

  //открытие модального окна для включения режима 'перенос'
  const btnTransferFencingOn = () => {
    if (!brigadeInfo?.isClear) {
      setBrigadeTransferFencing(true);
      setIsOpen(true);
    }
    if (brigadeInfo?.isClear && !brigadeInfo?.isTransferOn) {
      setOpenTransferFencingOn(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о включении "перенос"
  const handleButtonClickTransferOn = (payload) => {
    dispatch(emitTransferOn(payload));
    if (activeButton !== t?.workPlace) {
      setActiveButton(t?.workPlace);
    }
    closeModal();
  };

  //открытие модального окна для вЫключении режима 'перенос'
  const btnTransferFencingOff = () => {
    if (brigadeInfo?.isTransferOn) {
      setOpenTransferFencingOff(true);
      setIsOpen(true);
    }
  };

  //отправка события на сервер о вЫключении режима "перенос"
  const handleButtonClickTransferOff = () => {
    const payload = { brigadeId: currentBrigade.id };
    dispatch(emitTransferFencingOff(payload));
    closeModal();
  };

  useEffect(() => {
    //отправляем событие чтобы сервер начал слать инфо
    dispatch(emitBrigadeListenStart({ brigadeId: currentBrigade.id }));
    //слушаем событие 'brigade_info'
    dispatch(listenBrigadeInfo());
    //слушаем событие 'set_work_mode_reply'
    dispatch(listenWorkMode());
    //слушаем событие 'info'
    dispatch(listenPlaceWorkInfo());

    // Функция очистки
    return () => {
      // обновляем стейт с бригадами
      dispatch(initBrigadeAfterUpdate(currentBrigade?.id));
      // отправляем событие чтобы сервер закончил слать инфо
      dispatch(emitBrigadeListenStop({ brigadeId: currentBrigade.id }));
      //очищаем с тейт с местом работ
      dispatch(removeStateCurrentLocationsWork());
      //очищаем стейт с сигналами
      dispatch(removeStateAllSignals());
      //очищаем стейт brigadeInfo
      dispatch(removeStatebrigadeInfo());
      //очищаем стейт brigadeSignals
      dispatch(removeStateBrigadeSignals());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-100 overflow-hidden d-flex flex-column flex-nowrap">
      {/* Заголовок и статус */}
      <div
        className={`brigade-card d-flex flex-row flex-wrap justify-content-start ${
          isAdmin ? 'sadr-gap-20' : 'sadr-gap-40'
        }`}
      >
        <div className="d-flex flex-column flex-nowrap">
          <h4 className="brigade_card-title">
            {t?.brigadeTitle} {currentBrigade?.id}
          </h4>
          {(isAdmin || isCustomer) && (
            <div className="brigade_card-status">
              <div
                className="brigade_status-circle"
                style={{
                  backgroundColor: determineBackgroundColor(brigadeInfo?.color),
                  borderRadius: brigadeInfo?.polyType ? 'var(--sadr-font-size-12) ' : '50%',
                }}
              >
                {brigadeInfo?.safeZone}
              </div>
              <div className="brigade_status-details d-flex flex-column flex-nowrap align-items-start">
                <div className="brigade_status-text text-nowrap">{t?.inZoneActive}</div>
                <div className="brigade_status-values text-nowrap">
                  <span className="brigade_status-label">{t?.iuLabel}</span>
                  <span className="brigade_status-value">
                    {brigadeInfo?.iuInZone ? `${brigadeInfo.iuInZone}` : '0'}/
                    {brigadeInfo?.iuOnline ? `${brigadeInfo.iuOnline}` : '0'}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {/*Реижм работы / сигналов */}
        {(isAdmin || isCustomer) && (
          <div className="d-flex flex-column flex-nowrap gap-2">
            <DoubleButton
              onClikBtnFirst={btnWorkModeMovement}
              onClickBtnSec={btnWorkModeZona}
              textBtnFirst={t?.workModeMovement}
              textBtnSec={t?.workModeZone}
              label={t?.workModeLabel}
              color={brigadeInfo?.workMode === 2 ? false : true}
              disabledBtnFirst={isCustomer}
              disabledBtnSec={isCustomer}
            />
            {brigadeInfo?.kuobzCount > 0 && (
              <DoubleButton
                onClikBtnFirst={btnSignalModeOff}
                onClickBtnSec={btnSignalModeOn}
                textBtnFirst={t?.signalModeQuiet}
                textBtnSec={t?.signalModeLoud}
                label={t?.signalModeLabel}
                svgPathBtnFirst={SvgIconNight}
                svgPathBtnSec={SvgIconBell}
                color={brigadeInfo?.signalMode === 1 ? true : false}
                disabledBtnFirst={brigadeInfo?.kuobzCount === 0 || isCustomer}
                disabledBtnSec={brigadeInfo?.kuobzCount === 0 || isCustomer}
              />
            )}
          </div>
        )}
        {/*Ограждение / Перенос */}
        {(isAdmin || isCustomer) && (
          <div className="d-flex flex-column flex-nowrap gap-2 ">
            <DoubleButton
              onClikBtnFirst={btnisRestrictOn}
              onClickBtnSec={btnisRestrictOff}
              textBtnFirst={brigadeInfo?.isRestrictOn ? t?.fenceModeOn : t?.fenceModeActivate}
              textBtnSec={brigadeInfo?.isRestrictOn ? t?.fenceModeDeactivate : t?.fenceModeOff}
              label={t?.fenceModeLabel}
              disabledBtnFirst={!brigadeInfo?.inWork || !brigadeSignals?.isExitRails || isCustomer}
              disabledBtnSec={!brigadeInfo?.inWork || !brigadeSignals?.isExitRails || isCustomer}
              color={brigadeInfo?.isRestrictOn}
            />
            <DoubleButton
              onClikBtnFirst={btnTransferFencingOn}
              onClickBtnSec={btnTransferFencingOff}
              textBtnFirst={brigadeInfo?.isTransferOn ? t?.transferModeOn : t?.transferModeActivate}
              textBtnSec={brigadeInfo?.isTransferOn ? t?.transferModeDeactivate : t?.transferModeOff}
              label={t?.transferModeLabel}
              disabledBtnFirst={!brigadeInfo?.inWork || !brigadeSignals?.isExitRails || isCustomer}
              disabledBtnSec={!brigadeInfo?.inWork || !brigadeSignals?.isExitRails || isCustomer}
              color={brigadeInfo?.isTransferOn}
              state={brigadeInfo?.state}
            />
          </div>
        )}
        {/*  Ввод ординаты / Работа без КУОБЗ */}
        {isAdmin && (
          <div className="d-flex flex-column flex-nowrap gap-2 me-auto">
            <DoubleButton
              onClikBtnFirst={() => {
                handleCanEnterOrdChange(true);
              }}
              onClickBtnSec={() => {
                handleCanEnterOrdChange(false);
              }}
              textBtnFirst={brigadeInfo?.canEnterOrd ? t?.fenceModeOn : t?.fenceModeActivate}
              textBtnSec={brigadeInfo?.canEnterOrd ? t?.fenceModeDeactivate : t?.fenceModeOff}
              label={t?.inputCoordinate}
              disabledBtnFirst={!isAdmin}
              disabledBtnSec={!isAdmin}
              color={brigadeInfo?.canEnterOrd}
            />
            <DoubleButton
              onClikBtnFirst={() => {
                handleStandaloneChange(true);
              }}
              onClickBtnSec={() => {
                handleStandaloneChange(false);
              }}
              textBtnFirst={brigadeInfo?.isStandalone ? t?.transferModeOn : t?.transferModeActivate}
              textBtnSec={brigadeInfo?.isStandalone ? t?.transferModeDeactivate : t?.transferModeOff}
              label={t?.workWithoutKUOBZ}
              disabledBtnFirst={!isAdmin}
              disabledBtnSec={!isAdmin}
              color={brigadeInfo?.isStandalone}
            />
          </div>
        )}
        {/*Необходимо для отступа номером бригады и временем работы */}
        {isAdmin && (
          <>
            <div className="me-auto" />
            {/* Начало / длительность работы */}
            {brigadeInfo?.inWork && (
              <div className="d-flex flex-column flex-nowrap gap-2 align-items-start">
                <span className="brigade_card-text">
                  <strong>{t?.startWork} </strong>{' '}
                  {convertMillisecondsToDate(Number(brigadeInfo?.sessionStartTime), true)}
                </span>
                <span className="brigade_card-text">
                  <strong>{t?.workDuration} </strong> {formatMillisecondsToHoursMinutes(brigadeInfo?.sessionDuration)}
                </span>
                <CustomButton
                  className="brigade_card-work_btn mt-auto white-on-hover"
                  color={'var(--sadr-background-secondary)'}
                  width={'var(--sadr-font-size-250)'}
                  text={t?.finishWork}
                  onClick={handleClickFinishWork}
                  disabled={!brigadeInfo?.inWork}
                />
              </div>
            )}
          </>
        )}
      </div>
      {brigadeInfo?.noDeviceConn && (
        <div className="blinking-div">
          {brigadeInfo?.phoneNumber ? `${t?.noDeviceConnWithPhone} ${brigadeInfo.phoneNumber}.` : t?.noDeviceConn}
        </div>
      )}
      <div className="brigade_button-container">
        {Object.keys(contentData).map((buttonName) => (
          <CustomButton
            className={`brigade_button ${activeButton === buttonName ? 'brigade_button--active' : ''}`}
            key={buttonName}
            text={buttonName}
            colorText={activeButton === buttonName ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
            onClick={() => handleButtonClick(buttonName)}
          />
        ))}
      </div>
      <div className="brigade_content overflow-auto">{contentData[activeButton]}</div>

      {/* Модальное окно */}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.brigades_content'}>
          {/* Компонент завершения работ */}
          {brigadeFinishWork && <BrigadeFinishWork closeModal={closeModal} handlerConfirm={handlerConfirm} t={t} />}

          {/* Компонент подтверждения перехода в режим зона */}
          {openBrigadeEditDeviceMode && (
            <BrigadeEditDeviceMode
              handlerConfirm={handleButtonClickEditDeviceMode}
              onClose={closeModal}
              isKUOBZ={brigadeInfo?.kuobzCount}
              t={t}
            />
          )}

          {/* Компонент подтверждения перехода в режим движение  */}
          {openBrigadeEditDeviceModeMovement && (
            <BrigadeEditDeviceModeMovement
              handlerConfirm={handleButtonClickEditDeviceModeMovement}
              onClose={closeModal}
              t={t}
            />
          )}

          {/* Компонент подтверждения перехода в режим громко  */}
          {openSignalModeOn && (
            <SignalModeOn handlerConfirm={handleButtonClickDeviceSignalOn} onClose={closeModal} t={t} />
          )}

          {/* Компонент подтверждения перехода в режим тихо */}
          {openSignalModeOff && (
            <SignalModeOff handlerConfirm={handleButtonClickDeviceSignalOff} onClose={closeModal} t={t} />
          )}

          {/* Компонент запрета включения "ограждения" */}
          {openSignalRestrictOnBan && <BrigadeSafetyFence handlerConfirm={closeModal} t={t} />}

          {/* Компонент подтверждения включения "ограждения" */}
          {openSignalRestrictOn && (
            <BrigadeSafetyFenceAllowed
              handlerConfirm={handleButtonClickRestrictOn}
              onClose={closeModal}
              loading={loading}
              t={t}
            />
          )}

          {/* Компонент подтверждения выключения "ограждения" */}
          {openSignalRestrictOff && (
            <BrigadeSafetyFenceRemove handlerConfirm={handleButtonClickRestrictOff} onClose={closeModal} t={t} />
          )}

          {/* Компонент запрета включения "переноса" */}
          {brigadeTransferFencing && <BrigadeTransferFencing handlerConfirm={closeModal} t={t} />}

          {/* Компонент  включения "переноса" */}
          {openTransferFencingOn && (
            <BrigadeTransfer
              handlerConfirm={handleButtonClickTransferOn}
              onClose={closeModal}
              brigadeId={currentBrigade.id}
              t={t}
            />
          )}

          {/* Компонент запрета вЫключения "переноса" */}
          {openTransferFencingOff && (
            <BrigadeTransferFencingOff handlerConfirm={handleButtonClickTransferOff} closeModal={closeModal} t={t} />
          )}
        </Modal>
      )}
    </div>
  );
}

export default Brigade;

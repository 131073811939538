// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 0;
  right: 0;
  height: 97%;
  max-width: calc(100% - var(--sadr-font-size-10) * 2);
  background-color: #fff;
  box-shadow: 0 0 var(--sadr-font-size-10)var(--sadr-border-shadow);
  z-index: 1000;
  /* Включаем вертикальную прокрутку, если контент не помещается по высоте */
  overflow-y: auto; 
  margin: var(--sadr-font-size-10);
  background: var(--sadr-background-secondary);
  border-radius: var(--sadr-font-size-12) ;
  padding: var(--sadr-font-size-20);
}


.close-button {
  position: absolute;
  top: var(--sadr-font-size-2);
  right: var(--sadr-font-size-6);
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  font-size: var(--sadr-font-size-24);
  color: #333;
}

.modal-open {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Modal.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;AACf;;AAEA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,WAAW;EACX,oDAAoD;EACpD,sBAAsB;EACtB,iEAAiE;EACjE,aAAa;EACb,0EAA0E;EAC1E,gBAAgB;EAChB,gCAAgC;EAChC,4CAA4C;EAC5C,wCAAwC;EACxC,iCAAiC;AACnC;;;AAGA;EACE,kBAAkB;EAClB,4BAA4B;EAC5B,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n.modal-overlay {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n}\n\n.modal-content {\n  position: fixed;\n  top: 0;\n  right: 0;\n  height: 97%;\n  max-width: calc(100% - var(--sadr-font-size-10) * 2);\n  background-color: #fff;\n  box-shadow: 0 0 var(--sadr-font-size-10)var(--sadr-border-shadow);\n  z-index: 1000;\n  /* Включаем вертикальную прокрутку, если контент не помещается по высоте */\n  overflow-y: auto; \n  margin: var(--sadr-font-size-10);\n  background: var(--sadr-background-secondary);\n  border-radius: var(--sadr-font-size-12) ;\n  padding: var(--sadr-font-size-20);\n}\n\n\n.close-button {\n  position: absolute;\n  top: var(--sadr-font-size-2);\n  right: var(--sadr-font-size-6);\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n.close-icon {\n  font-size: var(--sadr-font-size-24);\n  color: #333;\n}\n\n.modal-open {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

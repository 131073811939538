import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Компоненты пользовательского интерфейса
import '../../styles/DeviceDeployment.css';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import Input from '../CustomElements/Input';
import CustomTextarea from '../CustomElements/CustomTextarea';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Checkbox from '../CustomElements/Checkbox';
// Кастомные хуки
import { useButtonValidation } from '../../customHook/useButtonValidation';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import useFilesOptions from '../../customHook/useFilesOptions';
// Утилиты
import { convertToDate } from '../../utils/convertToDate';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
// Действия Redux
import { editOperationStatusFromServer } from '../../redux/actions/statuses.action';
import { initOptionsFromServer } from '../../redux/actions/options.action';
import { load } from '../../redux/actions/loader.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsBrigadesFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsEmployeesFromServer,
} from '../../redux/actions/options.action';
import useSuccessListener from '../../customHook/useSuccessListener';

//компонент формы изменения статуса выдачи устройства в эксплуатацию

function EditOperationSendStatus({ currentDevice, closeModal, currentStatus, title, isEdit }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // получаем опции из стора
  const { roads, departments, brigadeNames, directorates, workers, employees } = useSelector((state) => state.options);

  // Состояние для формы
  // Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      id: currentStatus?.id,
      deviceId: currentDevice.id,
      date: convertToDate(currentStatus.date) || '',
      brigadeNameId: currentStatus?.brigade_name_id || '',
      acceptanceCert: currentStatus?.acceptance_cert || '',
      acceptanceCertName: currentStatus?.acceptance_cert_name || '',
      senderId: currentStatus?.sender_id || '',
      responsibleContact: currentStatus?.responsible_contact || '',
      responsibleId: currentStatus?.responsible_id || '',
      departmentId: currentStatus?.department_id || '',
      road: currentStatus?.road_id,
      directorateId: currentStatus?.directorate_id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const [formData, setFormData] = useState(initialFormData);

  //хук для контрола выбора файла
  const { isFile, optionsFiles, handleChangeCheckboxFile, handleSelectFile } = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsibleId', 'departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );
  //хук для запроса опций для ФИО ответственного и контроля селекта
  const messageForSelectWorkers = 'Для выбора ФИО выберите подразделение';
  const workersControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsibleId'],
    'departmentId',
    initOptionsEmployeesFromServer,
    messageForSelectWorkers
  );

  //хук для запроса опций для наименования бригад и контроля селекта
  const messageForSelectBrigades = 'Для выбора бригады выберите подразделение';
  const brigadesControl = useOptionsLoader(
    formData,
    setFormData,
    ['brigadeNameId'],
    'departmentId',
    initOptionsBrigadesFromServer,
    messageForSelectBrigades
  );

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
    // Установить флаг открытия формы в true
  }, [initialFormData]);

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, [
    'responsibleContact',
    'responsibleId',
    'brigadeNameId',
    'departmentId',
  ]);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editOperationStatusFromServer(payload, formData.deviceId, 'usage'));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  // после закрытияя обновляем стейт опций с сервера
  useEffect(() => {
    return () => {
      dispatch(initOptionsFromServer());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = () => {
    setAddSuccess(true);
  };

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'ВЫДАЧА В ЭКСПЛУАТАЦИЮ УСТРОЙСТВА'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите изменить данные статуса?"
          confirmButtonText="Изменить"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата выдачи в эксплуатацию *"
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <Selector
            column="left"
            options={employees}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select');
            }}
            placeholder="Выберите ФИО"
            label="ФИО выдавшего в эксплуатацию *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={employees?.find((option) => option.value === formData.senderId)}
            isMulti={false}
            name="senderId"
          />
          {!isFile && (
            <Input
              column="left"
              placeholder="Выберите файл акта"
              onChange={(event) =>
                handleUniversalChange(formData, setFormData, event, null, null, null, 'acceptanceCertName')
              }
              onRemove={() =>
                handleUniversalChange(
                  formData,
                  setFormData,
                  null,
                  'isRemove',
                  null,
                  null,
                  'acceptanceCert',
                  'acceptanceCertName'
                )
              }
              type="file"
              name="acceptanceCert"
              label="Акт приема-передачи *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              isDisabled={false}
              maxFileSize={20}
              fileName={currentStatus?.acceptance_cert_name}
            />
          )}
          {isFile && (
            <Selector
              column="left"
              options={optionsFiles}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'acceptanceCert', selectedOption, 'select');
                handleSelectFile(selectedOption);
              }}
              placeholder="Выберите файл"
              label="Акт приема-передачи *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              value={optionsFiles?.find((option) => option.value === formData.acceptanceCert)}
              isMulti={false}
              name="acceptanceCert"
            />
          )}
          <Checkbox
            column="left"
            height={`var(--sadr-font-size-45)`}
            width="100%"
            label="Выбрать существующий файл"
            onChange={(newValue) => handleChangeCheckboxFile('isFile', newValue)}
            checked={isFile}
          />
          <Selector
            column="right"
            options={roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            label="Дорога *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.road
                ? roads?.find((option) => option.value === formData.road)
                : roads?.find((option) => option.label === formData.road)
            }
            isMulti={false}
            name="road"
          />
          <Selector
            column="right"
            options={directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция *"
            value={
              formData.directorateId ? directorates?.find((option) => option.value === formData.directorateId) : null
            }
            isMulti={false}
            name="directorateId"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            column="right"
            options={departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
            }}
            placeholder="Подразделение"
            label="Выберите подразделение"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.departmentId && departments
                ? departments?.find((option) => option.value === formData.departmentId)
                : null
            }
            isMulti={false}
            name="departmentId"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            column="right"
            options={brigadeNames}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'brigadeNameId', selectedOption, 'select');
            }}
            placeholder="Выберите наименование бригады"
            label="Наименование бригады"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.brigadeNameId ? brigadeNames?.find((option) => option.value === formData?.brigadeNameId) : null
            }
            isMulti={false}
            name="brigadeNameId"
            isDisabled={brigadesControl?.isSelectorDisabled}
            message={brigadesControl?.message}
            customClick={brigadesControl?.onSelectorChange}
          />
          <Selector
            column="right"
            options={workers}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'responsibleId', selectedOption, 'select');
            }}
            placeholder="Выберите ФИО"
            label="ФИО ответственного лица"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={formData.responsibleId ? workers?.find((option) => option.value === formData.responsibleId) : null}
            isMulti={false}
            name="responsibleId"
            isDisabled={workersControl?.isSelectorDisabled}
            message={workersControl?.message}
            customClick={workersControl?.onSelectorChange}
          />
          <CustomTextarea
            column="right"
            placeholder="Введите контакты ответственного лица"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="responsibleContact"
            label="Контакты ответственного лица"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-120)"
            value={formData.responsibleContact}
          />
          <CustomButton
            onClick={handlerOpenModal}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сохранить изменения"
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default EditOperationSendStatus;

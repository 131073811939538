import {transformOptionsArray} from './transformOptionsArray'

/**
 * Преобразует объект, обрабатывая его свойства, которые являются массивами.
 * Для каждого свойства объекта, которое является массивом, вызывает функцию transformOptionsArray
 * Пропускает свойства, которые не являются массивами.
 *
 * @param {Object} inputObject - Объект для преобразования. Ожидается, что некоторые или все его свойства могут быть массивами.
 * @returns {Object} Преобразованный объект с теми же ключами, что и входной, но с преобразованными массивами в качестве значений.
 */
export function transformObjectWithArrays(inputObject) {
  const transformedObject = {};

  for (const key in inputObject) {
    if (Array.isArray(inputObject[key])) {
      transformedObject[key] = transformOptionsArray(inputObject[key]);
    }
  }

  return transformedObject;
}




import { useSelector } from 'react-redux';

/**
 * Кастомный хук для получения переводов на основе текущего языка из хранилища.
 *
 * @param {Object} translations - Объект JSON, содержащий переводы для разных языков.
 * @param {Object} translations.ru - Переводы для русского языка.
 * @param {Object} translations.rs - Переводы для сербского языка.
 * @returns {Object} - Словарь переводов для текущего языка.
 *
 * @example
 * const translations = {
 *   "ru": {
 *     "greeting": "Привет",
 *     "farewell": "До свидания"
 *   },
 *   "rs": {
 *     "greeting": "Здраво",
 *     "farewell": "Довиђења"
 *   }
 * };
 *
 * const MyComponent = () => {
 *   const t = useTranslations(translations);
 *   return (
 *     <div>
 *       <h1>{t?.greeting}</h1>
 *       <p>{t?.farewell}</p>
 *     </div>
 *   );
 * };
 */
const useTranslations = (translations) => {
  const currentLanguage = useSelector(state => state.language);
  return translations[currentLanguage] || translations['ru'];
};

export default useTranslations;

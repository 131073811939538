export const SvgIconBell = ({ style }) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.9375 14.2812C8.6875 14.5312 8.375 14.6562 8 14.6562C7.625 14.6562 7.30208 14.5312 7.03125 14.2812C6.78125 14.0104 6.65625 13.6979 6.65625 13.3438H9.3125C9.3125 13.7188 9.1875 14.0312 8.9375 14.2812ZM12 7.34375V10.6562L13.3438 12V12.6562H2.65625V12L4 10.6562V7.34375C4 6.30208 4.26042 5.39583 4.78125 4.625C5.32292 3.85417 6.0625 3.35417 7 3.125V2.65625C7 2.38542 7.09375 2.15625 7.28125 1.96875C7.46875 1.76042 7.70833 1.65625 8 1.65625C8.29167 1.65625 8.53125 1.76042 8.71875 1.96875C8.90625 2.15625 9 2.38542 9 2.65625V3.125C9.9375 3.35417 10.6667 3.85417 11.1875 4.625C11.7292 5.39583 12 6.30208 12 7.34375ZM13.3125 7C13.2083 5.20833 12.4271 3.78125 10.9688 2.71875L11.9062 1.78125C13.6354 3.11458 14.5521 4.85417 14.6562 7H13.3125ZM5.0625 2.71875C3.58333 3.76042 2.79167 5.1875 2.6875 7H1.34375C1.44792 4.85417 2.36458 3.11458 4.09375 1.78125L5.0625 2.71875Z" />
    </svg>
  );
};

export const SvgIconNight = ({ style }) => (
  <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 1.34375C7.83333 1.34375 9.39583 2 10.6875 3.3125C12 4.60417 12.6562 6.16667 12.6562 8C12.6562 9.83333 12 11.4062 10.6875 12.7188C9.39583 14.0104 7.83333 14.6562 6 14.6562C5.29167 14.6562 4.625 14.5625 4 14.375C5.375 13.9583 6.48958 13.1667 7.34375 12C8.21875 10.8125 8.65625 9.47917 8.65625 8C8.65625 6.52083 8.21875 5.19792 7.34375 4.03125C6.48958 2.84375 5.375 2.04167 4 1.625C4.625 1.4375 5.29167 1.34375 6 1.34375Z" />
  </svg>
);

export const Svg_ = ({ style }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.58744 7.616C7.58744 7.76533 7.53944 7.86667 7.44344 7.92C7.3581 7.96267 7.25144 7.95733 7.12344 7.904L0.563438 5.36C0.478104 5.31733 0.403438 5.26933 0.339438 5.216C0.286104 5.152 0.259438 5.072 0.259438 4.976V3.744C0.259438 3.62667 0.286104 3.54133 0.339438 3.488C0.392771 3.424 0.467438 3.376 0.563438 3.344L7.13944 0.768C7.26744 0.714666 7.3741 0.72 7.45944 0.783999C7.54477 0.847999 7.58744 0.938666 7.58744 1.056V2.096C7.58744 2.30933 7.4221 2.48533 7.09144 2.624L2.57944 4.368L7.09144 6.08C7.2621 6.15467 7.38477 6.22933 7.45944 6.304C7.54477 6.368 7.58744 6.464 7.58744 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

export const Svg__ = ({ style }) => (
  <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.86869 7.616C7.86869 7.76533 7.82069 7.86667 7.72469 7.92C7.63935 7.96267 7.53269 7.95733 7.40469 7.904L0.844688 5.36C0.759354 5.31733 0.684688 5.26933 0.620688 5.216C0.567354 5.152 0.540688 5.072 0.540688 4.976V3.744C0.540688 3.62667 0.567354 3.54133 0.620688 3.488C0.674021 3.424 0.748688 3.376 0.844688 3.344L7.42069 0.768C7.54869 0.714666 7.65535 0.72 7.74069 0.783999C7.82602 0.847999 7.86869 0.938666 7.86869 1.056V2.096C7.86869 2.30933 7.70335 2.48533 7.37269 2.624L2.86069 4.368L7.37269 6.08C7.54335 6.15467 7.66602 6.22933 7.74069 6.304C7.82602 6.368 7.86869 6.464 7.86869 6.592V7.616ZM16.9312 7.616C16.9312 7.76533 16.8832 7.86667 16.7872 7.92C16.7019 7.96267 16.5952 7.95733 16.4672 7.904L9.90719 5.36C9.82185 5.31733 9.74719 5.26933 9.68319 5.216C9.62985 5.152 9.60319 5.072 9.60319 4.976V3.744C9.60319 3.62667 9.62985 3.54133 9.68319 3.488C9.73652 3.424 9.81119 3.376 9.90719 3.344L16.4832 0.768C16.6112 0.714666 16.7179 0.72 16.8032 0.783999C16.8885 0.847999 16.9312 0.938666 16.9312 1.056V2.096C16.9312 2.30933 16.7659 2.48533 16.4352 2.624L11.9232 4.368L16.4352 6.08C16.6059 6.15467 16.7285 6.22933 16.8032 6.304C16.8885 6.368 16.9312 6.464 16.9312 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

export const Svg___ = ({ style }) => (
  <svg width="26" height="8" viewBox="0 0 26 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.47025 7.616C7.47025 7.76533 7.42225 7.86667 7.32625 7.92C7.24092 7.96267 7.13425 7.95733 7.00625 7.904L0.44625 5.36C0.360917 5.31733 0.28625 5.26933 0.22225 5.216C0.168917 5.152 0.14225 5.072 0.14225 4.976V3.744C0.14225 3.62667 0.168917 3.54133 0.22225 3.488C0.275583 3.424 0.35025 3.376 0.44625 3.344L7.02225 0.768C7.15025 0.714666 7.25692 0.72 7.34225 0.783999C7.42758 0.847999 7.47025 0.938666 7.47025 1.056V2.096C7.47025 2.30933 7.30492 2.48533 6.97425 2.624L2.46225 4.368L6.97425 6.08C7.14492 6.15467 7.26758 6.22933 7.34225 6.304C7.42758 6.368 7.47025 6.464 7.47025 6.592V7.616ZM16.5328 7.616C16.5328 7.76533 16.4848 7.86667 16.3888 7.92C16.3034 7.96267 16.1968 7.95733 16.0688 7.904L9.50875 5.36C9.42342 5.31733 9.34875 5.26933 9.28475 5.216C9.23142 5.152 9.20475 5.072 9.20475 4.976V3.744C9.20475 3.62667 9.23142 3.54133 9.28475 3.488C9.33808 3.424 9.41275 3.376 9.50875 3.344L16.0848 0.768C16.2128 0.714666 16.3194 0.72 16.4048 0.783999C16.4901 0.847999 16.5328 0.938666 16.5328 1.056V2.096C16.5328 2.30933 16.3674 2.48533 16.0368 2.624L11.5248 4.368L16.0368 6.08C16.2074 6.15467 16.3301 6.22933 16.4048 6.304C16.4901 6.368 16.5328 6.464 16.5328 6.592V7.616ZM25.5953 7.616C25.5953 7.76533 25.5473 7.86667 25.4513 7.92C25.3659 7.96267 25.2593 7.95733 25.1313 7.904L18.5713 5.36C18.4859 5.31733 18.4113 5.26933 18.3473 5.216C18.2939 5.152 18.2673 5.072 18.2673 4.976V3.744C18.2673 3.62667 18.2939 3.54133 18.3473 3.488C18.4006 3.424 18.4753 3.376 18.5713 3.344L25.1473 0.768C25.2753 0.714666 25.3819 0.72 25.4673 0.783999C25.5526 0.847999 25.5953 0.938666 25.5953 1.056V2.096C25.5953 2.30933 25.4299 2.48533 25.0993 2.624L20.5873 4.368L25.0993 6.08C25.2699 6.15467 25.3926 6.22933 25.4673 6.304C25.5526 6.368 25.5953 6.464 25.5953 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

export const _Svg = ({ style }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.412562 7.616C0.412562 7.76533 0.460562 7.86667 0.556562 7.92C0.641895 7.96267 0.748562 7.95733 0.876562 7.904L7.43656 5.36C7.5219 5.31733 7.59656 5.26933 7.66056 5.216C7.7139 5.152 7.74056 5.072 7.74056 4.976V3.744C7.74056 3.62667 7.7139 3.54133 7.66056 3.488C7.60723 3.424 7.53256 3.376 7.43656 3.344L0.860562 0.768C0.732562 0.714666 0.625895 0.72 0.540562 0.783999C0.455229 0.847999 0.412562 0.938666 0.412562 1.056V2.096C0.412562 2.30933 0.577896 2.48533 0.908562 2.624L5.42056 4.368L0.908562 6.08C0.737895 6.15467 0.615229 6.22933 0.540562 6.304C0.455229 6.368 0.412562 6.464 0.412562 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

export const __Svg = ({ style }) => (
  <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.13131 7.616C9.13131 7.76533 9.17931 7.86667 9.27531 7.92C9.36065 7.96267 9.46731 7.95733 9.59531 7.904L16.1553 5.36C16.2406 5.31733 16.3153 5.26933 16.3793 5.216C16.4326 5.152 16.4593 5.072 16.4593 4.976V3.744C16.4593 3.62667 16.4326 3.54133 16.3793 3.488C16.326 3.424 16.2513 3.376 16.1553 3.344L9.57931 0.768C9.45131 0.714666 9.34465 0.72 9.25931 0.783999C9.17398 0.847999 9.13131 0.938666 9.13131 1.056V2.096C9.13131 2.30933 9.29665 2.48533 9.62731 2.624L14.1393 4.368L9.62731 6.08C9.45665 6.15467 9.33398 6.22933 9.25931 6.304C9.17398 6.368 9.13131 6.464 9.13131 6.592V7.616ZM0.0688124 7.616C0.0688124 7.76533 0.116812 7.86667 0.212812 7.92C0.298145 7.96267 0.404812 7.95733 0.532812 7.904L7.09281 5.36C7.17815 5.31733 7.25281 5.26933 7.31681 5.216C7.37015 5.152 7.39681 5.072 7.39681 4.976V3.744C7.39681 3.62667 7.37015 3.54133 7.31681 3.488C7.26348 3.424 7.18881 3.376 7.09281 3.344L0.516812 0.768C0.388812 0.714666 0.282145 0.72 0.196812 0.783999C0.111479 0.847999 0.0688124 0.938666 0.0688124 1.056V2.096C0.0688124 2.30933 0.234146 2.48533 0.564812 2.624L5.07681 4.368L0.564812 6.08C0.394145 6.15467 0.271479 6.22933 0.196812 6.304C0.111479 6.368 0.0688124 6.464 0.0688124 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

export const ___Svg = ({ style }) => (
  <svg width="26" height="8" viewBox="0 0 26 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5297 7.616C18.5297 7.76533 18.5777 7.86667 18.6737 7.92C18.7591 7.96267 18.8657 7.95733 18.9937 7.904L25.5537 5.36C25.6391 5.31733 25.7137 5.26933 25.7777 5.216C25.8311 5.152 25.8577 5.072 25.8577 4.976V3.744C25.8577 3.62667 25.8311 3.54133 25.7777 3.488C25.7244 3.424 25.6497 3.376 25.5537 3.344L18.9777 0.768C18.8497 0.714666 18.7431 0.72 18.6577 0.783999C18.5724 0.847999 18.5297 0.938666 18.5297 1.056V2.096C18.5297 2.30933 18.6951 2.48533 19.0257 2.624L23.5377 4.368L19.0257 6.08C18.8551 6.15467 18.7324 6.22933 18.6577 6.304C18.5724 6.368 18.5297 6.464 18.5297 6.592V7.616ZM9.46725 7.616C9.46725 7.76533 9.51525 7.86667 9.61125 7.92C9.69658 7.96267 9.80325 7.95733 9.93125 7.904L16.4912 5.36C16.5766 5.31733 16.6512 5.26933 16.7152 5.216C16.7686 5.152 16.7952 5.072 16.7952 4.976V3.744C16.7952 3.62667 16.7686 3.54133 16.7152 3.488C16.6619 3.424 16.5872 3.376 16.4912 3.344L9.91525 0.768C9.78725 0.714666 9.68058 0.72 9.59525 0.783999C9.50992 0.847999 9.46725 0.938666 9.46725 1.056V2.096C9.46725 2.30933 9.63258 2.48533 9.96325 2.624L14.4752 4.368L9.96325 6.08C9.79258 6.15467 9.66992 6.22933 9.59525 6.304C9.50992 6.368 9.46725 6.464 9.46725 6.592V7.616ZM0.40475 7.616C0.40475 7.76533 0.45275 7.86667 0.548749 7.92C0.634083 7.96267 0.74075 7.95733 0.86875 7.904L7.42875 5.36C7.51408 5.31733 7.58875 5.26933 7.65275 5.216C7.70608 5.152 7.73275 5.072 7.73275 4.976V3.744C7.73275 3.62667 7.70608 3.54133 7.65275 3.488C7.59942 3.424 7.52475 3.376 7.42875 3.344L0.85275 0.768C0.72475 0.714666 0.618083 0.72 0.53275 0.783999C0.447416 0.847999 0.40475 0.938666 0.40475 1.056V2.096C0.40475 2.30933 0.570083 2.48533 0.90075 2.624L5.41275 4.368L0.90075 6.08C0.730083 6.15467 0.607416 6.22933 0.53275 6.304C0.447416 6.368 0.40475 6.464 0.40475 6.592V7.616Z"
      fill="#141B15"
    />
  </svg>
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brigade-transfer-container-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}
.brigade-transfer-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Создает два столбца с равной шириной */
  padding: var(--sadr-font-size-20) 0 0 var(--sadr-font-size-20);
  column-gap: var(--sadr-font-size-250);
  height: 80%;
  overflow-y: auto;
}

.brigade-transfer-text {
  margin-bottom: var(--sadr-font-size-20);
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  color: var(--sadr-text-color);
}

.brigade-transfer {
  width: 100%;
  height: 78%;
}

`, "",{"version":3,"sources":["webpack://./src/styles/BrigadeTransfer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,qCAAqC,EAAE,yCAAyC;EAChF,8DAA8D;EAC9D,qCAAqC;EACrC,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,uCAAuC;EACvC,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,WAAW;AACb","sourcesContent":[".brigade-transfer-container-btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: auto;\n}\n.brigade-transfer-content {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr); /* Создает два столбца с равной шириной */\n  padding: var(--sadr-font-size-20) 0 0 var(--sadr-font-size-20);\n  column-gap: var(--sadr-font-size-250);\n  height: 80%;\n  overflow-y: auto;\n}\n\n.brigade-transfer-text {\n  margin-bottom: var(--sadr-font-size-20);\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  color: var(--sadr-text-color);\n}\n\n.brigade-transfer {\n  width: 100%;\n  height: 78%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {
  ADD_INFOCARDS,
  EDIT_INFOCARD,
  EDIT_RAIL_CHAINS,
  INIT_INFOCARDS,
  INIT_RAIL_CHAINS,
  DELETE_RAIL_CHAINS,
  ADD_RAIL_CHAINS,
  INIT_ADJACENCY,
  DELETE_ADJACENCY,
  INIT_SEMAPHORES,
  DELETE_SEMAPHORES, 
  SET_ADJACENCY, 
  CLEAR_ADJACENCY_STATE,
  CLEAR_RAIL_CHAINS_STATE,  
  CLEAR_SEMAPHORES_STATE,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { load, remove, success } from './loader.action';
import { downloadFile } from '../../utils/downloadFile';
import { addError } from './error.action';

export const requestInfocards = (filter) => async (dispatch) => {
  let url = new URL('http://localhost');
  for (let [key, value] of Object.entries(filter)) {
    if (value != null) {
      url.searchParams.set(key, value);
    }
  }
  url = '/api/infocards' + url.search;
  try {
    const resp = await axiosInstance.get(url, { responseType: 'json' });
    dispatch({ type: INIT_INFOCARDS, payload: resp.data });
  } catch (e) {
    console.error('Error during GET ' + url, e);
  }
};

/**
 * @param station {string}
 * @param format {ExportFormat}
 * @return {(function(*): Promise<void>)|*}
 */
export const requestRailChains = (station) => {
  const url = `/api/stations/${station}/rail_chains`;

  return async function (dispatch) {
    try {
      const resp = await axiosInstance.get(url, { responseType: 'json' });
      dispatch({ type: INIT_RAIL_CHAINS, payload: resp.data });
    } catch (e) {
      console.error('Error during GET ' + url, e);
    }
  };
};

// добавление новой инфокарты
export const addInfocardFromServer = (infocard) => async (dispatch) => {
  try {
    const {data} = await axiosInstance.post(`/api/infocards`, infocard);
    if(data){
      dispatch({ type: ADD_INFOCARDS, payload: data });
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
  }
}

// редактирование инфокарты
export const updateInfocardFromServer = (infocard) => async (dispatch) => {
  try {
    if(infocard?.size){
      infocard = Array.from(infocard.entries()).reduce(
        (p, [old, cur]) => Object.assign(p, { [old.station]: cur }),
        {}
      );
    }
    const {data} = await axiosInstance.patch(`/api/infocards`, infocard);
    if(data){
      dispatch({ type: EDIT_INFOCARD, payload: data });
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
  }
}

// добавление новой рельсовой цепи
export const addRailChainsFromServer = (station, railChain) => async (dispatch) => {
  try {
    const {data} = await axiosInstance.post(`/api/stations/${station}/rail_chains`, railChain);
    if (data) {
      dispatch({ type: ADD_RAIL_CHAINS, payload: { station, rail: data } });
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
  }
}

// изменение рельсовых цепей
export const updateRailChainsFromServer = (station, railChains) => async (dispatch) => {
  try {
    const {data} = await axiosInstance.patch(`/api/stations/${station}/rail_chains`, railChains);
    if (data){
      dispatch({ type: EDIT_RAIL_CHAINS, payload: { station, items: data } });
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
  }
}

// удаление рельсовой цепи
export const deleteRailChainsFromServer = (station, id) => async (dispatch) => {
  try {
    const resp = await axiosInstance.delete(`/api/stations/${station}/rail_chains/${id}`);
    if(resp.status === 200){
      dispatch(success())
      dispatch({ type: DELETE_RAIL_CHAINS, payload: { station, items: resp.data } });
    }
  } catch (error) {
    console.log(error);
  }
}

// очистка стейта РЦ
export const clearRailChainsState = (statuses, path) => ({
  type: CLEAR_RAIL_CHAINS_STATE,
  payload: [],
})

// получение всех смежностей по станции
export const initAdjacencyFromServer = (station) => async (dispatch) => {
    try {
      const {data} = await axiosInstance.get(`/api/stations/${station}/adjacency`);
      dispatch({ type: INIT_ADJACENCY, payload: data });
    } catch (error) {
      console.error(error);
    }
  };

// удаление смежности
export const deleteAdjacencyFromServer = (station, id) => async (dispatch) => {
  try {
    const resp = await axiosInstance.delete(`/api/stations/${station}/adjacency/${id}`);
    if (resp.status === 200) {
      dispatch(success());
      dispatch({ type: DELETE_ADJACENCY, payload: resp.data[0] });
    }
  } catch (error) {
    console.log(error);
  }
};

// очистка стейта смежности
export const clearAdjacencyState = (statuses, path) => ({
  type: CLEAR_ADJACENCY_STATE,
  payload: [],
})

//
export const saveAdjacencyFromServer = (payload, station) => async(dispatch) => {
  try {
    const resp = await axiosInstance.post(`/api/stations/${station}/adjacency`, payload);
    if (resp?.status === 200) {
      dispatch({ type: SET_ADJACENCY, payload: resp?.data });
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
  }
}

export const requestSemaphores = (station) => async (dispatch) => {
  const url = `/api/stations/${station}/semaphores`;
  try {
    const resp = await axiosInstance.get(url, { responseType: 'json' });
    dispatch({ type: INIT_SEMAPHORES, payload: resp.data });
  } catch (e) {
    console.error(`Error during GET ${url}:`, e);
  }
};

/**
 *
 * @param station {string}
 * @param added {[]}
 * @param deleted {[]}
 * @param updated {Map<Object, Object>}
 */
export const patchSemaphores = (station, { added, deleted, updated }) => {
  const url = `/api/stations/${station}/semaphores`
  const promises = [];

  if (deleted?.length) {
    const deleteUrl = url + '/delete'
    deleted = deleted.map(x => x.semaphore_id);
    promises.push(async function (dispatch) {
      try {
        const resp = await axiosInstance.post(deleteUrl, deleted, { responseType: 'json' });
        dispatch({ type: DELETE_SEMAPHORES, payload: { station, items: resp.data } });
      } catch (e) {
        console.error(`Error during DELETE ${deleteUrl}:`, e);
      }
    });
  }

  /**
   * Обновление сделано через POST запрос. Измененные данные добавляются в массив added (при изменении была заблокирована возможность менять id),
   * на сервере обновление данных (и добавление новых и обновление существующих) происходит одним запросом, на уровне БД
   * После выполнения POST запроса, заново запрашиваем светофоры по текущей станции с сервера, чтобы не парицца с подменой измененных данных на фронте (да, я ленивая)
   */
  if (added?.length || updated.size) {
    updated.forEach(((value) => {
      added.push(value)
    }))
    promises.push(async function (dispatch) {
      try {
        await axiosInstance.post(url, added, { responseType: 'json' });
        dispatch(requestSemaphores(station));
      } catch (e) {
        console.error(`Error during POST ${url}:`, e);
      }
    });
  }

  return async dispatch => {
    try {
      dispatch(load());
      // Вызваю последовательно чтобы избежать непредсказуемого поведения
      for (let promise of promises) {
        await promise(dispatch);
      }
    } catch (e) {
      // ignored
    } finally {
      dispatch(remove());
    }
  };
};

// очистка стейта семафоров
export const clearSemaphoresState = (statuses, path) => ({
  type: CLEAR_SEMAPHORES_STATE,
  payload: [],
})

export const getXlsxFileFromServer = (stationId, stationName) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`/api/stations/${stationId}/xlsx`, {}, { responseType: 'blob' })
    const data = res.data
    if (data) {
      dispatch(success())
      const fileName = `${stationName || 'Справочник'}.xlsx`
      downloadFile(data, fileName)
    }
  } catch (error) {
    const message = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.'
    dispatch(addError({ error: message }))
    console.log(error)
  }
}

export const getDataCheckResultFromServer = (stationId, stationName) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`/api/stations/${stationId}/check`, {}, { responseType: 'blob' })
    const data = res.data
    if (data) {
      dispatch(success())
      const fileName = `${stationName || 'result'}.xlsx`
      downloadFile(data, fileName)
    }
  } catch (error) {
    const message = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.'
    dispatch(addError({ error: message }))
    console.log(error)
  }
}

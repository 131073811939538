import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET, {
  autoConnect: false,
  transports: ['websocket'],
  withCredentials: true,
});

// Добавление обработчика события 'connect'
socket.on('connect', () => {
  console.log('Socket connected.');
});

socket.on('disconnect', () => {
  console.log('Socket disconnected.');
});


export default socket;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button-edit {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.custom-button-edit:hover {
  transform: scale(1.1);
}

.feather {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomButtonEdit.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,wBAAwB;AAC1B","sourcesContent":[".custom-button-edit {\n  background-color: transparent;\n  border: none;\n  padding: 0;\n  cursor: pointer;\n  transition: transform 0.2s ease-in-out;\n}\n\n.custom-button-edit:hover {\n  transform: scale(1.1);\n}\n\n.feather {\n  width: 1em;\n  height: 1em;\n  vertical-align: -0.125em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

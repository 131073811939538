import { useState, useEffect } from 'react';

const coordinatePairs = [
  ['coordinateEndLat', 'coordinateEndLon'],
  ['coordinateControlledLatA', 'coordinateControlledLonA'],
  ['coordinateControlledLatB', 'coordinateControlledLonB'],
  ['coordinateControlledLatV', 'coordinateControlledLonV'],
  ['coordinateControlledLatG', 'coordinateControlledLonG'],
  ['coordinateUncontrolledLatN1', 'coordinateUncontrolledLonN1'],
  ['coordinateUncontrolledLatN2', 'coordinateUncontrolledLonN2'],
  ['coordinateUncontrolledLatN3', 'coordinateUncontrolledLonN3'],
];

/**
 * Кастомный хук для управления необязательными полями на основе данных формы.
 *
 * @param {Object} formData - Текущее состояние данных формы.
 * @returns {Array<string>} Обновлённый список необязательных полей.
 */
const useOptionalFieldsForAddRC = (formData) => {
  const [optionalFields, setOptionalFields] = useState([
    'alertLengthsPCh',
    'alertLengthsECh',
    'coordinateEndLat',
    'coordinateEndLon',
    'ordEnd',
    'coordinateControlledLatA',
    'coordinateControlledLonA',
    'controlledOrdA',
    'coordinateControlledLatB',
    'coordinateControlledLonB',
    'controlledOrdB',
    'coordinateControlledLatV',
    'coordinateControlledLonV',
    'controlledOrdV',
    'coordinateControlledLatG',
    'coordinateControlledLonG',
    'controlledOrdG',
    'coordinateUncontrolledLatN1',
    'coordinateUncontrolledLonN1',
    'uncontrolledOrdN1',
    'coordinateUncontrolledLatN2',
    'coordinateUncontrolledLonN2',
    'uncontrolledOrdN2',
    'coordinateUncontrolledLatN3',
    'coordinateUncontrolledLonN3',
    'uncontrolledOrdN3',
    'track',
  ]);

  useEffect(() => {
    let updatedOptionalFields = [...optionalFields];

    // Обрабатываем каждую пару координат
    coordinatePairs.forEach(pair => {
      const [lat, lon] = pair;

      // Если хотя бы одно значение в паре существует, удаляем оба поля из необязательных
      if (formData[lat] || formData[lon]) {
        updatedOptionalFields = updatedOptionalFields.filter(field => field !== lat && field !== lon);
      } else {
        // Если оба значения отсутствуют, добавляем оба поля в необязательные
        if (!updatedOptionalFields.includes(lat)) {
          updatedOptionalFields.push(lat);
        }
        if (!updatedOptionalFields.includes(lon)) {
          updatedOptionalFields.push(lon); // Добавляем долготу в необязательные поля, если она отсутствует
        }
      }
    });

    setOptionalFields(updatedOptionalFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return optionalFields;
};

export default useOptionalFieldsForAddRC;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError } from '../../redux/actions/error.action';
import { remove } from '../../redux/actions/loader.action';
import '../../styles/ErrorModal.css';

const ErrorModal = ({ width, height }) => {
  
  // Получение сообщения об ошибке из глобального состояния
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  // Очистка сообщения об ошибке
  const handleClose = () => {
    dispatch(clearError());
    dispatch(remove());
  };
  // Не отображаем модальное окно, если нет ошибки
  if (!error || (typeof error === 'object' && !error.error)) {
    return null;
  }

  return (
    <div className="error-modal-background">
      <div className="error-modal-content" style={{ width, height }}>
        <button className="error-modal-close-button" onClick={handleClose}>
          <span className="close-icon" aria-hidden="true">
            &times;
          </span>
        </button>
        <p className="error-modal-text">{error.error || error}</p>
      </div>
    </div>
  );
};

export default ErrorModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/**
 * Custom React hook to implement infinite scroll functionality.
 * Observes a bottom boundary element to detect when it enters the viewport and triggers data fetching.
 *
 * @param {Object} formData - The form data that needs to be passed to the data fetching function.
 * @param {Array} infiniteData - The current data array, used to trigger re-observation when new data is loaded.
 * @param {Object} observer - A mutable ref object that holds the IntersectionObserver instance.
 * @param {Object} bottomBoundaryRef - A ref object pointing to the bottom boundary element to observe.
 * @param {Function} initFunction - The function to dispatch when the bottom boundary is intersected.
 */
const useInfiniteScroll = (formData, infiniteData, observer, bottomBoundaryRef, initFunction) => {
  // Get a flag that indicates if a request can be sent
  const canSendRequest = useSelector((state) => state.filterConfig.canSendRequest);
  const dispatch = useDispatch();

  /**
   * Callback function for the IntersectionObserver.
   * Dispatches the initFunction if the bottom boundary element is intersecting and requests can be sent.
   *
   * @param {Array} entries - The IntersectionObserver entries.
   */
  const observerCallback = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && canSendRequest) {
        dispatch(initFunction(formData));
      }
    },
    [canSendRequest, dispatch, formData, initFunction]
  );

  /**
   * Sets up the IntersectionObserver to observe the bottom boundary element.
   * Disconnects any existing observer before creating a new one.
   *
   * @param {HTMLElement} node - The DOM node to observe.
   */
  const setObserver = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(observerCallback);
      if (node) observer.current.observe(node);
    },
    [observerCallback, observer]
  );

  /**
   * Effect to set up the observer when the bottom boundary element or infinite data changes.
   * Re-observes the bottom boundary element whenever new data is loaded.
   */
  useEffect(() => {
    if (bottomBoundaryRef.current) {
      setObserver(bottomBoundaryRef.current);
    }
  }, [infiniteData, setObserver, bottomBoundaryRef]);

  /**
   * Effect to handle delayed observation.
   * Observes the bottom boundary element after a short delay when requests can be sent.
   * Clears the timeout if the component re-renders or unmounts before the timeout completes.
   */
  useEffect(() => {
    if (canSendRequest) {
      const timeoutId = setTimeout(() => {
        if (bottomBoundaryRef.current && observer.current) {
          observer.current.observe(bottomBoundaryRef.current);
        }
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [canSendRequest, bottomBoundaryRef, observer]);
};

export default useInfiniteScroll;

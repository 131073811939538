import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/Device.css';
import Modal from '../Modal/Modal';
import EditDevice from './EditDevice';
import ServiceDevice from './ServiceDevice';
import DeviceArchiveStatus from '../Statuses/DeviceArchiveStatus';
import DeviceOperationStatus from '../Statuses/DeviceOperationStatus';
import DeviceRepairStatus from '../Statuses/DeviceRepairStatus';
import DeviceStorageStatus from '../Statuses/DeviceStorageStatus';
import { DeviceHeader } from './DeviceHeader';
import { remove } from '../../redux/actions/loader.action';
import { clearStatuses } from '../../redux/actions/statuses.action';

function Device({ currentDevice, setCurrentDevice, formData }) {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices);
  const [activeButton, setActiveButton] = useState('Эксплуатация');
  const [editDeviceModal, setEditDeviceModal] = useState(false);
  const [deviceService, setDeviceService] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const contentDataStatus = {
    Эксплуатация: (
      <DeviceOperationStatus
        currentDevice={currentDevice}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
      />
    ),
    Хранение: (
      <DeviceStorageStatus
        currentDevice={currentDevice}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
      />
    ),
    Ремонт: (
      <DeviceRepairStatus currentDevice={currentDevice} setActiveButton={setActiveButton} activeButton={activeButton} />
    ),
    Архив: (
      <DeviceArchiveStatus
        currentDevice={currentDevice}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
      />
    ),
  };

  useEffect(() => {
    const foundDevice = devices.find((device) => device.id === currentDevice.id);
    if (foundDevice) {
      setCurrentDevice(foundDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, currentDevice]);

  // Закрыть модальное окно редактирования
  const closeModalDeviceEditAndService = () => {
    setEditDeviceModal(false);
    setDeviceService(false);
    setIsOpen(false);
  };

  // Открыть модалку для изменения устройства
  const handleButtonClickEdit = () => {
    setEditDeviceModal(true);
    setIsOpen(true);
  };
  /** Открыть модалку для проведения ТО */
  const handleButtonClickService = () => {
    setDeviceService(true);
    setIsOpen(true);
  };

  // при закрытии устройства обновляем стейт с устройствами что бы подтянуть измененные данные
  // очищаем стейты статусов
  useEffect(() => {
    return () => {
      dispatch(clearStatuses());
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div className="device-grid-container h-100 w-100">
      <DeviceHeader device={currentDevice} onEdit={handleButtonClickEdit} onService={handleButtonClickService} />

      {/* Отрисовка таблиц Статусов */}
      <> {contentDataStatus[activeButton]}</>

      {/* Модальное окно */}
      <Modal
        isOpen={isOpen}
        onClose={closeModalDeviceEditAndService}
        containerSelector={'.table-for-devices_or_uipps_container'}
      >
        {/* Компонент для изменения устройства */}
        {editDeviceModal && (
          <EditDevice closeModalDevice={closeModalDeviceEditAndService} currentDevice={currentDevice} />
        )}
        {/* Компонент для изменения устройства(выполнение ТО) */}
        {deviceService && (
          <ServiceDevice closeModalDevice={closeModalDeviceEditAndService} currentDevice={currentDevice} />
        )}
      </Modal>
    </div>
  );
}
export default Device;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-20);
  line-height: var(--sadr-font-size-30);
  text-align: center;
  color: var(--sadr-text-color);
  margin-bottom: var(--sadr-font-size-30) ;
  white-space: pre-wrap;
}
.confirmation-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.confirmation-btn,
.cancel,
.confirm {
  margin: var(--sadr-font-size-15) ;
}

.confirmation-btn-container {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ConfirmationPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,qCAAqC;EACrC,kBAAkB;EAClB,6BAA6B;EAC7B,wCAAwC;EACxC,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;AAC7B;AACA;;;EAGE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".confirmation-title {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-20);\n  line-height: var(--sadr-font-size-30);\n  text-align: center;\n  color: var(--sadr-text-color);\n  margin-bottom: var(--sadr-font-size-30) ;\n  white-space: pre-wrap;\n}\n.confirmation-container {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n.confirmation-btn,\n.cancel,\n.confirm {\n  margin: var(--sadr-font-size-15) ;\n}\n\n.confirmation-btn-container {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import moment from 'moment';
import 'moment-timezone';

export function dateToString(date) {
  // Проверяем, что дата предоставлена и является допустимым значением для Moment.js
  if (date && moment(date).isValid()) {
    // Создаем объект moment, добавляем 3 часа и форматируем дату в строку
    return moment(date).add(3, 'hours').format('DD.MM.YYYY');
  } else {
    // Возвращаем null, если дата не предоставлена или некорректна
    return null;
  }
}

export function convertToDate(dateString) {
  // Проверяем, является ли dateString экземпляром Date
  if (dateString instanceof Date) {
    return moment(dateString).add(3, 'hours').toDate();
  }

  // Проверяем, что dateString существует и является строкой
  if (typeof dateString !== 'string' || !dateString) {
    return null;
  }

  // Создаем объект moment и добавляем 3 часа
  let date = dateString.includes('T') ? moment(dateString) : moment(dateString, 'DD.MM.YYYY');

  return date.add(3, 'hours').toDate();
}

/**
 * Преобразует миллисекунды в форматированное время в формате "часы:минуты".
 *
 * @param {number} milliseconds - Количество миллисекунд для преобразования.
 * @returns {string|null} Возвращает строку времени в формате "HH:mm".
 *                        В случае ошибки возвращает null.
 */
export function formatMillisecondsToHoursMinutes(milliseconds) {
  // Проверка на то, что переданное значение является числом
  if (typeof milliseconds !== 'number' || isNaN(milliseconds)) {
    console.error('Переданное значение не является числом');
    return null; // Возвращаем null при ошибке
  }

  // Проверка на то, что число неотрицательное
  if (milliseconds < 0) {
    console.error('Переданное количество миллисекунд не может быть отрицательным');
    return null; // Возвращаем null при ошибке
  }

  // Преобразование миллисекунд в часы и минуты
  const totalMinutes = Math.floor(milliseconds / 60000); // 1 минута = 60000 миллисекунд
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Форматирование строку "HH:mm"
  const formattedHours = String(hours).padStart(2, '0'); // Дополнение до двух цифр с помощью '0'
  const formattedMinutes = String(minutes).padStart(2, '0'); // Дополнение до двух цифр с помощью '0'

  return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Преобразует миллисекунды в дату в формате DD.MM.YYYY [HH:mm:ss] в московском часовом поясе
 *
 * @param {number} milliseconds - количество миллисекунд с начала эпохи Unix.
 * @returns {string|null} строковое представление даты в формате DD.MM.YYYY HH:mm:ss или null в случае ошибки.
 */
export function convertMillisecondsToDate(milliseconds, withTime = true) {
  // Проверка, что входной параметр является числом
  if (typeof milliseconds !== 'number' || isNaN(milliseconds)) {
    // console.error('Переданное значение не является числом');
    return null;
  }

  // Создание объекта даты с учетом временной зоны 'Europe/Moscow'
  const date = moment(milliseconds).tz('Europe/Moscow');

  const format = withTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY';

  // Форматируем дату в московском часовом поясе
  return date && date.isValid() ? date.format(format) : '-';
}

/**
 * Перевод выбранной даты из календаря (полночь выбранной даты в часовом поясе, из которого открывают веб аппку) в миллисекунды полночи этой же даты по мск
 * @param {Date} value дата из дейтпикера
 * @returns {number} миллисекунды по московскому времени
 */
export function convertDateToMs(value) {
  if (value instanceof Date) {
    const offsetMinsToUtc = value.getTimezoneOffset();
    const offsetMinsMoscowToUtc = - moment().tz('Europe/Moscow').utcOffset(); // минус потому что getTimezoneOffset возвращает отрицательное значение, а utcOffset положительное
    // Получаем смещение временной зоны в минутах и переводим его в миллисекунды
    const offset = (offsetMinsToUtc - offsetMinsMoscowToUtc) * 60 * 1000;
    const moscowMidnightMs = moment(value).valueOf() - offset;
    return moscowMidnightMs;
  }
  return value;
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { remove } from '../redux/actions/loader.action';

/**
 * Custom hook that listens for success events and performs actions based on the state of the loader and error.
 *
 * @param {function} setFormData - Function to set the form data.
 * @param {object} initialFormData - Initial form data.
 * @param {function} closeModal - Function to close the modal.
 * @param {function} closeStatusEditModal - Function to close the status edit modal.
 * @return {void}
 */
const useSuccessListener = async (setFormData, initialFormData, closeModal, closeStatusEditModal) => {
  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);
  const error = useSelector((state) => state.error);

  const dispatch = useDispatch();

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно

    if (setFormData && initialFormData && closeModal) {
      if (!loader?.isLoading && loader?.isSuccess) {
        //очищаем форму и стейты
        setFormData(initialFormData);
        closeModal();
      }
      //если есть ошибка то закрываем только окно подтверждения
      if ((error || error?.error) && closeStatusEditModal) {
        closeStatusEditModal();
      }
    }
    // при закрытии компонента чистим стейт загрузки
    if (!loader?.isLoading && loader?.isSuccess) {
      return () => {
        dispatch(remove());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess, error]);
};

export default useSuccessListener;

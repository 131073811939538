import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import '../../styles/ArchiveBrigade.css';
import CustomButton from '../CustomElements/CustomButton';
import ArchiveBrigadeSignals from './ArchiveBrigadeSignals';
import ArchiveBrigadeComposition from './ArchiveBrigadeComposition';
import ArchiveBrigadePlaceWork from './ArchiveBrigadePlaceWork';
import FilterForArchiveParams from '../Filter/FilterForArchiveParams';
import CloseButton from '../CustomElements/CloseButton';
import { initArchiveSessionFromServer } from '../../redux/actions/archive.action';
import DraggableDiv from './DraggableDiv';
/**
 * Отображает информацию и управляет архивом бригад.
 * Позволяет просматривать состав бригады, место работы и сигналы.
 * Включает функционал для перемещения по временной шкале.
 */

function ArchiveBrigade() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Навигация для программного перехода между маршрутами.
  const navigate = useNavigate();
  // Извлекаем ID сессии из URL.
  const { id } = useParams();
  // Получаем данные архива из Redux store.
  const { archiveBrigadeSession } = useSelector((state) => state.archive);

  // стейт для хранения индекса для массива с сигналами
  const [index, setIndex] = useState(0);

  // Сопоставление кнопок с компонентами для отображения контента.
  const contentData = {
    'Состав бригады': <ArchiveBrigadeComposition currentSession={archiveBrigadeSession?.signals?.[index]} />,
    'Место работ': <ArchiveBrigadePlaceWork currentSession={archiveBrigadeSession} index={index} />,
    Сигналы: <ArchiveBrigadeSignals currentSession={archiveBrigadeSession} index={index} />,
  };

  // Текущая активная вкладка интерфейса, управляет отображаемым контентом.
  const [activeButton, setActiveButton] = useState(Object.keys(contentData)[0]);

  // Начальное время интервала архива.
  const startTime = moment(archiveBrigadeSession.start).tz('Europe/Moscow');
  // Конечное время интервала архива.
  const endTime = moment(archiveBrigadeSession.finish).tz('Europe/Moscow');
  // Длительность интервала архива, вычисляется как разница между конечным и начальным временем.

  // Функция для обработки закрытия архива и возврата к предыдущему маршруту.
  const onClose = () => {
    navigate(`/archive`);
  };

  const timeSignalArray = useMemo(() => {
    if (!archiveBrigadeSession?.signals?.length) {
      return [];
    }
    return archiveBrigadeSession.signals.map((signal) => {
      // получаем временную метку из массива и приводим к московскому времени
      return moment(signal.time).tz('Europe/Moscow').valueOf();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveBrigadeSession?.signals]);

  // отправляем запрос на получение данных о сессии
  useEffect(() => {
    dispatch(initArchiveSessionFromServer(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="gray-backgound d-flex flex-row main-container overflow-hidden">
        <FilterForArchiveParams sessionId={id} />
        {/* <TestForm/> */}
        <div className="d-flex flex-column flex-nowrap overflow-hidden flex-grow-1 flex-grow-1 brigade-archive">
          <div className="brigade-archive-btn-container">
            <CloseButton onClose={onClose} />
          </div>
          <DraggableDiv
            index={index}
            setIndex={setIndex}
            timeSignalArray={timeSignalArray}
            start={startTime}
            end={endTime}
            brigadeId={archiveBrigadeSession?.brigadeId}
          />
          <div className="brigade-archive_button-container d-flex justify-content-between">
            {Object.keys(contentData).map((buttonName) => (
              <CustomButton
                className={`brigade-archive_button ${
                  activeButton === buttonName ? 'brigade-archive_button--active' : ''
                }`}
                key={buttonName}
                text={buttonName}
                colorText={activeButton === buttonName ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
                onClick={() => setActiveButton(buttonName)}
              />
            ))}
          </div>
          {/* overflow-auto */}
          <div className="brigade-archive-content overflow-auto">{contentData[activeButton]}</div>
        </div>
      </div>
    </>
  );
}

export default ArchiveBrigade;

import QRCode from 'react-qr-code';
import React, { useState } from 'react';

function MirShQR() {
  const [value] = useState('http://dev.ctrlplus.team:8081/apk_mirsh');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div className="m-5">Актуальная версия МИР-Ш:</div>
      <QRCode title="MIR-SH" value={value} bgColor="var(--sadr-background-secondary)" fgColor="#000000" size={256} />
    </div>
  );
}

export default MirShQR;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.brigade-add-device_img {
  margin-right:  var(--sadr-font-size-5) ;
}

.brigade-add-device_input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brigade-add-device {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.brigade-add-device_content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.brigade-add-device_btn-container{
  margin-top: auto;
}
.visible-brigade-add-device-error {
  visibility: visible;
  opacity: 1;
}
.hidden-brigade-add-device-error {
  visibility: hidden;
  opacity: 0;
}

.brigade-add-device-table{
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/BrigadeAddDevices.css"],"names":[],"mappings":";AACA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":["\n.brigade-add-device_img {\n  margin-right:  var(--sadr-font-size-5) ;\n}\n\n.brigade-add-device_input-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.brigade-add-device {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.brigade-add-device_content {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.brigade-add-device_btn-container{\n  margin-top: auto;\n}\n.visible-brigade-add-device-error {\n  visibility: visible;\n  opacity: 1;\n}\n.hidden-brigade-add-device-error {\n  visibility: hidden;\n  opacity: 0;\n}\n\n.brigade-add-device-table{\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';
import '../../styles/DepartmentReport.css';
import CustomButton from '../CustomElements/CustomButton';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

const DepartmentReport = () => {
  const { report } = useSelector((state) => state.documentation);

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  const data = {
    brigade: {
      data: [
        {
          index: 1, // №
          start: '09.01.2023 09:30', // Время начала работ (объединено с датой)
          finish: '09.01.2023 10:30', // // Время окончания работ (объединено с датой)
          duration: 1, // Продолжительность работ, ч
          station: 'Самара', // Станция/перегон
          brigadeId: 100101, // ID виртуальной бригады
        },
      ],
      totalHours: 60, // Объем оказанных услуг
    },
    department: {
      data: [
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
        {
          index: 1, // №
          sectionNumber: 1, // Номер участка
          responsible: 'Карпов Дмитрий Исакович 0192434', // ФИО / ТАБЕЛЬНЫЙ НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          brigadeType: 'Бригада по текущему содержанию пути', // Бригада
          brigadeId: 100101, // ID виртуальной бригады
          hours: 65, // Объем оказанных услуг, ч
        },
      ],
      totalHoursDiagnostics: 100, // Объем оказанных услуг по ограждению бригад диагностики
      totalHoursRailsMaintenance: 200, // Объем оказанных услуг по ограждению бригад по текущему содержанию пути
    },

    period: '01.01.2023 - 31.01.2023', // Период оказания услуги
    directorateName: 'Куйбышевская дирекция инфраструктуры', // Наименование дирекции
    departmentName: 'ПЧ-11 Самарская дистанция пути', // Наименование подразделения
  };
  const columns = useMemo(
    () => [
      {
        Header: '№',
        accessor: 'index', // Ключ в данных для номера строки
      },
      {
        Header: 'Номер участка',
        accessor: 'sectionNumber', // Ключ в данных для номера участка
      },
      {
        Header: (
          <>
            ФИО / ТАБЕЛЬНЫЙ
            <br />
            НОМЕР ОТВЕТСТВЕННОГО ЛИЦА
          </>
        ),
        accessor: 'responsible', // Ключ в данных для ФИО и табельного номера
      },
      {
        Header: 'Бригада',
        accessor: 'brigadeType', // Ключ в данных для типа бригады
      },
      {
        Header: (
          <>
            ID виртуальной
            <br /> бригады (ИУР)
          </>
        ),
        accessor: 'brigadeId', // Ключ в данных для ID бригады
      },
      {
        Header: (
          <>
            Объем оказанных <br />
            услуг, ч
          </>
        ),
        accessor: 'hours', // Ключ в данных для объема оказанных услуг
      },
    ],
    []
  );

  const handleDownloadClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <CustomContainer id={'report-div'}>
        <CustomTitle titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО ПОДРАЗДЕЛЕНИЮ'} />
        <div className="report-header">
          <div className="report-header-div1">
            <span>
              <b>Наименование дирекции:</b> {data?.directorateName}
            </span>
            <span>
              <b>Наименование подразделения:</b> {data?.departmentName}
            </span>
          </div>
          <div>
            <span>
              <b>Период оказания услуги:</b> {data?.period}
            </span>
          </div>
        </div>
        <Table columns={columns} data={data.department.data} className={'report-table'} />
        <div className="report-summary">
          <span>
            <b>Объем оказанных услуг по ограждению бригад диагностики:</b> {data?.department?.totalHoursDiagnostics}{' '}
            часов
          </span>
          <span>
            <b>Объем оказанных услуг по ограждению бригад по текущему содержанию пути:</b>{' '}
            {data?.department?.totalHoursRailsMaintenance} часов
          </span>
        </div>
        <div className="report-footer-container">
          {' '}
          <div className="report-footer">
            <span>
              <b>Заказчик: </b> ____________
            </span>
            <span>«______» __________________ 20_____ г.</span>
          </div>
          <div className="report-footer">
            <span className="report-footer-span">
              <b>Исполнитель: </b> ____________
            </span>
            <span>«______» __________________ 20_____ г.</span>
          </div>
        </div>

        <div className="report-btn-container">
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Скачать отчет"
            href={`${process.env.REACT_APP_DOCUMENT}${report?._id}`}
            onClick={handleDownloadClick}
            download
            isButton
          />
        </div>
      </CustomContainer>
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-documentation_container'}>
        <CustomContainer>
          <CustomTitle titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО ПОДРАЗДЕЛЕНИЮ'} />
          <ConfirmationPage
            addNew="К тексту отчета"
            confirmText="Отчет сформирован!"
            onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
          />
        </CustomContainer>
      </Modal>
    </>
  );
};

export default DepartmentReport;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Компоненты пользовательского интерфейса
import '../../styles/AddUipps.css';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import CustomButton from '../CustomElements/CustomButton';
import Input from '../CustomElements/Input';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import PhoneInput from '../CustomElements/PhoneInput';
import CustomTitle from '../CustomElements/CustomTitle';
import Checkbox from '../CustomElements/Checkbox';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
// Кастомные хуки
import { useButtonValidation } from '../../customHook/useButtonValidation';
import useFilesOptions from '../../customHook/useFilesOptions';
// Утилиты
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { isValidDeviceID } from '../../utils/isValidDeviceID';
import { isValidAndroidID } from '../../utils/isValidAndroidID';
// Действия Redux
import { addError } from '../../redux/actions//error.action';
import { initUippsAndroidIdFromServer } from '../../redux/actions/uipps.action';
import { initFilesFromServer } from '../../redux/actions/files.action';
import { load, remove } from '../../redux/actions/loader.action';
import { newDeviceFromServer } from '../../redux/actions/devices.action';

function AddUipps({ closeModal, currentUipps }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Состояния уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // состояние для необязательных полей
  const [optionalFields] = useState(['sim1', 'sim2', 'note']);

  // Состояние для формы
  const [formData, setFormData] = useState({
    id: '',
    androidId: '',
    typeId: 4,
    receiveDate: '',
    acceptanceCert: null,
    storageId: '',
    receiverId: '',
    senderId: '',
    manufactureDate: '',
    sim1: '',
    isBroken: '',
    sim2: '',
    note: '',
  });
  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  useEffect(() => {}, [formData]);
  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Проверяем валидность id
    if (!isValidDeviceID(formData.id, formData.typeId)) {
      return dispatch(addError({ error: 'Указан невалидный ID устройства' }));
    }
    // Проверяем валидность androidId, если он предоставлен
    if (formData?.androidId && !isValidAndroidID(formData.androidId)) {
      return dispatch(addError({ error: 'Указан невалидный ANDROID_ID' }));
    }
    //переводим в состояние загрузки
    dispatch(load());
    //формируем данные для отправки на сервер
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(newDeviceFromServer(payload, formData));
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      setAddSuccess(true);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    closeModal();
    setAddSuccess(false);
  };

  // прис бросе даты производства  сбрасываем дату приемки
  useEffect(() => {
    setFormData((prev) => ({ ...prev, receiveDate: '' }));
  }, [formData.manufactureDate]);

  // отправляем запрос на получений списка androidId при открытии формы
  useEffect(() => {
    dispatch(initUippsAndroidIdFromServer());
    dispatch(initFilesFromServer());
  }, [dispatch]);

  //хук для контрола выбора файла
  const { isFile, optionsFiles, handleChangeCheckboxFile, handleSelectFile } = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );

  return (
    <CustomContainer>
      <CustomTitle titleText={'ДОБАВЛЕНИЕ НОВОГО УИППС'} />
      {addSuccess && (
        <ConfirmationPage
          confirmText={'Новый УИППС добавлен!'}
          onConfirm={handlerCloseModal}
          addNew={'К списку устройств'}
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <Input
            column="left"
            placeholder="Введите ID УИППС"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            name="id"
            label="ID УИППС *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            // type="number"
          />
          <DateSelector
            column="left"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата производства *"
            selectedDate={formData.manufactureDate}
            setSelectedDate={(date) =>
              handleUniversalChange(formData, setFormData, null, 'manufactureDate', date, 'date')
            }
            name="manufactureDate"
            placeholder="Выберите дату производства"
            maxDate={new Date()}
          />
          <PhoneInput
            column="left"
            label="SIM1"
            name="sim1"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim1}
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            placeholder="Выберите ФИО принявшего"
            label="ФИО принявшего *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            name="receiverId"
            isMulti={false}
          />
          <Selector
            column="left"
            options={optionsForSelect?.сondition}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            placeholder="Не определено"
            label="Состояние *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="isBroken"
          />
          {!isFile && (
            <Input
              column="left"
              placeholder="Выберите файл акта"
              onChange={(event) =>
                handleUniversalChange(formData, setFormData, event, null, null, null, 'acceptanceCertName')
              }
              onRemove={() =>
                handleUniversalChange(
                  formData,
                  setFormData,
                  null,
                  'isRemove',
                  null,
                  null,
                  'acceptanceCert',
                  'acceptanceCertName'
                )
              }
              type="file"
              name="acceptanceCert"
              label="Акт приема-передачи *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
            />
          )}
          {isFile && (
            <Selector
              column="left"
              options={optionsFiles}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'acceptanceCert', selectedOption, 'select');
                handleSelectFile(selectedOption);
              }}
              placeholder="Выберите файл"
              label="Акт приема-передачи *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              value={optionsFiles?.find((option) => option.value === formData?.acceptanceCert)}
              isMulti={false}
              name="acceptanceCert"
            />
          )}
          <Checkbox
            column="left"
            height={`var(--sadr-font-size-40)`}
            width="100%"
            label="Выбрать существующий файл"
            onChange={(newValue) => handleChangeCheckboxFile('isFile', newValue)}
            checked={isFile}
          />
          <Selector
            column="right"
            options={optionsForSelect?.uippsAndroidId}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'androidId', selectedOption, 'select')
            }
            placeholder="Выберите android_id"
            label="ANDROID_ID *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="androidId"
            optionText="Нет неавторизованных УИППС"
          />
          <DateSelector
            column="right"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата приемки *"
            selectedDate={formData.receiveDate}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'receiveDate', date, 'date')}
            name="receiveDate"
            placeholder="Выберите дату приемки"
            minDate={formData?.manufactureDate}
            maxDate={new Date()}
          />
          <PhoneInput
            column="right"
            label="SIM2"
            name="sim2"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim2}
          />
          <Selector
            column="right"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select')
            }
            placeholder="Выберите ФИО передавшего"
            label="ФИО передавшего *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            name="senderId"
            isMulti={false}
          />
          <Selector
            column="right"
            options={optionsForSelect.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            placeholder="Выберите место хранения"
            label="Место хранения *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            name="storageId"
            isMulti={false}
          />

          <CustomTextarea
            column="right"
            placeholder="Оставьте комментарий"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            label="Комментарий"
            width="100%"
            value={formData.note}
          />

          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Добавить УИППС"
            disabled={isButtonDisabled}
            isButton
            margin={'0 0 var(--sadr-font-size-20) 0'}
            onClick={handlerSubmit}
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default AddUipps;

// types.js

/**
 * @typedef {Object} FormDataAddRailCains
 * @property {string} id - Идентификатор
 * @property {string} road - Дорога
 * @property {string} station - Станция
 * @property {string} object - Объект
 * @property {string} railChainName - Название цепи
 * @property {string} alertLengthsPCh - Длина участка оповещения PCh
 * @property {string} alertLengthsECh - Длина участка оповещения ECh
 * @property {string} coordinateEndLat - Широта конца РЦ
 * @property {string} coordinateEndLon - Долгота конца РЦ
 * @property {string} ordEnd - Ордината конца РЦ
 * @property {string} coordinateControlledLatA - Широта контролируемого ответвления A
 * @property {string} coordinateControlledLonA - Долгота контролируемого ответвления A
 * @property {string} controlledOrdA - Ордината контролируемого ответвления A
 * @property {string} coordinateControlledLatB - Широта контролируемого ответвления B
 * @property {string} coordinateControlledLonB - Долгота контролируемого ответвления B
 * @property {string} controlledOrdB - Ордината контролируемого ответвления B
 * @property {string} coordinateControlledLatV - Широта контролируемого ответвления V
 * @property {string} coordinateControlledLonV - Долгота контролируемого ответвления V
 * @property {string} controlledOrdV - Ордината контролируемого ответвления V
 * @property {string} coordinateControlledLatG - Широта контролируемого ответвления G
 * @property {string} coordinateControlledLonG - Долгота контролируемого ответвления G
 * @property {string} controlledOrdG - Ордината контролируемого ответвления G
 * @property {string} coordinateUncontrolledLatN1 - Широта неконтролируемого ответвления N1
 * @property {string} coordinateUncontrolledLonN1 - Долгота неконтролируемого ответвления N1
 * @property {string} uncontrolledOrdN1 - Ордината неконтролируемого ответвления N1
 * @property {string} coordinateUncontrolledLatN2 - Широта неконтролируемого ответвления N2
 * @property {string} coordinateUncontrolledLonN2 - Долгота неконтролируемого ответвления N2
 * @property {string} uncontrolledOrdN2 - Ордината неконтролируемого ответвления N2
 * @property {string} coordinateUncontrolledLatN3 - Широта неконтролируемого ответвления N3
 * @property {string} coordinateUncontrolledLonN3 - Долгота неконтролируемого ответвления N3
 * @property {string} uncontrolledOrdN3 - Ордината неконтролируемого ответвления N3
 * @property {string} track - Трек
 */

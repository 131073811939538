import moment from "moment";

// функция для формирования запроса
const setRequest = (filterCriteria, searchString, lastId, searchFlag, searchApiRequest, visibleCoords) => {
  const params = [];
  // если был нажат поиск
  if (searchString || (searchApiRequest && searchFlag)) {
    params?.unshift(`like=${searchString ? searchString : searchApiRequest}`);
  } else if (filterCriteria) {
    // набиваем запрос для фильтров
    const filtersRequest = Object.keys(filterCriteria)
      .reduce((acc, key) => {
        if (filterCriteria[key]) {
          if(key !== 'receive_date'){
            acc.push(`${key}=${filterCriteria[key]}`);
          }else{
            acc.push(`${key}=${moment.tz(filterCriteria[key], 'Europe/Moscow').format('YYYY-MM-DD')}`);
          }
        }
        return acc;
      }, [])
      .join('&');
    if (filtersRequest) params?.push(filtersRequest);
  }
  // добавляем id последнего полученного устройства чтобы получить только новые устройства, если это не карта
  if (!visibleCoords && lastId) {
    params.push(`last_id=${lastId}`);
  } else if (visibleCoords) {
    params.push(
      `visibleMinLat=${visibleCoords._southWest.lat}&visibleMaxLat=${visibleCoords._northEast.lat}&visibleMinLon=${visibleCoords._southWest.lng}&visibleMaxLon=${visibleCoords._northEast.lng}`
    );
  }
  return params.length ? `?${params.join('&')}` : '';
};

export default setRequest;

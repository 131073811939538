import { FILTER_CONFIG, UPDATE_REQUEST_BLOCKER } from '../type';

export const createFilterConfig = (lastId, canSendRequest, apiRequest, searchFlag, searchData) => ({
  type: FILTER_CONFIG,
  payload: { lastId, canSendRequest, apiRequest, searchFlag, searchData },
});

export const updateCanSendRequest = (state) =>({
  type: UPDATE_REQUEST_BLOCKER,
  payload: state
})

export const updateRequestBlocker = (state) => (dispatch) => {
  setTimeout(() => {
    dispatch(updateCanSendRequest(state));
  }, 200);
  
}

export const clearFilterConfig = () => ({
  type: FILTER_CONFIG,
  payload: { lastId: 0, canSendRequest: true, apiRequest: '', searchFlag: false, searchData: '' },
});

const setFilteredData = (data, searchString, apiRequest, searchFlag, searchApiRequest) => (dispatch) => {
  if (data && data.length === 50) {
    const lastId = Math.max(...data.map((obj) => obj.id));
    if (!searchFlag && searchString) {
      dispatch(createFilterConfig(lastId, true, apiRequest, true, searchString));
    } else {
      dispatch(createFilterConfig(lastId, true, apiRequest, searchFlag, searchApiRequest));
    }
  } else {
    if (!searchFlag && searchApiRequest) {
      dispatch(createFilterConfig(0, false, apiRequest, true, searchString));
    } else {
      dispatch(createFilterConfig(0, false, apiRequest, searchFlag, searchApiRequest));
    }
  }
};
export default setFilteredData;

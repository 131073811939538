import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceStorageStatus.css';
import Modal from '../Modal/Modal';
import EditOperationStatus from './EditStorageStatus';
import CustomButton from '../CustomElements/CustomButton';
import DeviceRepair from '../Devices/DeviceRepair';
import DeviceDeployment from '../Devices/DeviceDeployment';
import DeviceTransfer from '../Devices/DeviceTransfer';
import DeviceWriteOff from '../Devices/DeviceWriteOff';
import UippsDeployment from '../UIPPS/UippsDeployment';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import useDeviceStatus from '../../customHook/useDeviceStatus';
import TableMap from '../Table/TableMap';

//компонент для отображения вкладки Хранение на станице устройства

function DeviceStorageStatus({ currentDevice, uippsType, setActiveButton, activeButton }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  //получакм массив статаусов Хранение из стора
  const { storageStatuses } = useSelector((state) => state?.statuses);
  const [statusId, setStatusId] = useState('');

  //получаем статус текущего устройства
  const status = currentDevice?.status;
  // состояние для активации кнопки
  const [disabledBtn, setDisabledBtn] = useState(!(status === 'Хранение'));

  // хук для получения текущего статуса по id
  const currentStatus = useDeviceStatus(storageStatuses, statusId);

  // Используем useEffect для обновления disabledBtn при изменении status
  useEffect(() => {
    setDisabledBtn(!(status === 'Хранение'));
  }, [status]); // Зависимость от status

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus(
    storageStatuses,
    currentDevice,
    setStatusId
  );

  // компоненты для отображения
  const contentData = {
    'Переместить на другой склад': <DeviceTransfer currentDevice={currentDevice} closeModal={closeModal} isEdit={false} />,
    'Выдать в эксплуатацию': uippsType ? (
      <UippsDeployment currentDevice={currentDevice} closeModal={closeModal} />
    ) : (
      <DeviceDeployment currentDevice={currentDevice} closeModal={closeModal} />
    ),
    'Направить в ремонт': <DeviceRepair currentDevice={currentDevice} closeModal={closeModal} />,
    Списать: <DeviceWriteOff currentDevice={currentDevice} closeModal={closeModal} isOpen={isOpen} />,
    edit:
      currentStatus?.from_status_id === 9 ? (
        <DeviceTransfer
          currentDevice={currentDevice}
          currentStatus={currentStatus}
          closeModal={closeModal}
          isEdit={true}
        />
      ) : (
        <EditOperationStatus currentStatus={currentStatus} currentDevice={currentDevice} closeModal={closeModal} />
      ),
  };
  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'storage'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Таблица со статусами */}
      {storageStatuses && (
        <TableMap
          data={storageStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
        />
      )}

      {/* Блок с кнопками */}
      <div className="device-storage-status_button-action_container flex-wrap">
        {Object.keys(contentData)
          .slice(0, -1)
          .map((buttonName) => (
            <CustomButton
              className="device-storage-status_button-action"
              key={buttonName}
              text={buttonName}
              colorText="#FFFFF"
              onClick={() => handleButtonClick(buttonName)}
              color={disabledBtn ? '#ADADAD' : ''}
              disabled={disabledBtn || (buttonName === 'Выдать в эксплуатацию' && currentDevice?.broken)}
            />
          ))}
      </div>

      {/* Модальное окно */}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
          {contentData[activeModal]}
        </Modal>
      )}
    </>
  );
}

export default DeviceStorageStatus;

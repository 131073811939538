import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomContainer from '../CustomElements/CustomContainer';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange } from '../../utils/formUtils';
import { getContractReportFromServer } from '../../redux/actions/documentation.action';
import DateRangePicker from '../CustomElements/DateRangePicker';
import { load, remove } from '../../redux/actions/loader.action';
import { convertDateToMs } from '../../utils/convertToDate';
import CustomTitle from '../CustomElements/CustomTitle';
import Modal from '../Modal/Modal';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Preloader from '../CustomElements/Preloader';
import { initDepartmenOptionsFromServer, initOptionsDirectoratesFromServer } from '../../redux/actions/options.action';
import useOptionsLoader from '../../customHook/useOptionsLoader';

function ContractReport(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const options = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  // Состояние для формы
  const initialFormData = {
    roadId: '',
    departmentId: '',
    directorateId: '',
    startDate: '',
    endDate: '',
    reportType: '',
  };

  const reportTypes = [
    { value: 'onOff', label: 'Включение/выключение' },
    { value: 'onOff10', label: 'Включение/выключение - 10 мин ' },
    { value: 'conn', label: 'Подключение/отключение' },
    { value: 'restrict', label: 'Ограждение' },
  ];

  const [formData, setFormData] = useState(initialFormData);
  const [optionalFields] = useState(['departmentId']);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Переводим в состояние загрузки
    dispatch(load());

    // Создаем новый объект payload, копируя все свойства из formData
    const payload = { ...formData };

    // Если formData содержит startDate, преобразуем его и обновляем в payload
    if (formData?.startDate) {
      payload.startDate = convertDateToMs(formData.startDate);
    }

    // Если formData содержит endDate, преобразуем его и обновляем в payload
    if (formData?.endDate) {
      payload.endDate = convertDateToMs(formData.endDate);
    }
    // Отправляем данные на сервер через Redux
    dispatch(getContractReportFromServer(payload));
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, открываем модальное окно отчета
    if (!loader?.isLoading && loader?.isSuccess) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  useEffect(() => {
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorateId', 'departmentId'],
    'roadId',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['departmentId'],
    'directorateId',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  return (
    <>
      <CustomContainer>
        <CustomTitle titleText={'ОТЧЕТ ОБ ОКАЗАННЫХ УСЛУГАХ ПО ДОГОВОРУ'} />
        <FormContainer>
          <Selector
            column="left"
            options={options?.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'roadId', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            label="Дорога *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={options?.roads?.find((option) => option.value === formData.roadId)}
            isMulti={false}
            name="roadId"
            isDisabled={loader?.isLoading}
          />
          <Selector
            column="right"
            options={options?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorateId', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция *"
            value={
              formData.directorateId
                ? options?.directorates?.find((option) => option.value === formData.directorateId)
                : null
            }
            isMulti={false}
            name="directorateId"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />
          <Selector
            column="left"
            options={options?.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'departmentId', selectedOption, 'select');
            }}
            placeholder="Выберите подразделение"
            label="Подразделение"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            value={
              formData.departmentId && options?.departments
                ? options?.departments?.find((option) => option.value === formData.departmentId)
                : null
            }
            isMulti={false}
            name="departmentId"
            isDisabled={departmentsControl?.isSelectorDisabled || loader?.isLoading}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />

          <DateRangePicker
            column="right"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Период *"
            formData={formData}
            setFormData={setFormData}
            startDateField="startDate" // имя поля для даты начала, с дефолтным значением
            endDateField="endDate" // имя поля для даты окончания, с дефолтным значением
            name="date"
            placeholder="Выберите период"
            topPosition="50%"
            maxDate={new Date()}
            disabled={loader?.isLoading}
          />

          <Selector
            column="left"
            options={reportTypes}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'reportType', selectedOption, 'select');
            }}
            placeholder="Выберите тип отчета"
            label="Тип отчета *"
            value={formData.reportType ? reportTypes?.find((option) => option.value === formData.reportType) : null}
            isMulti={false}
            name="reportType"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />

          <Preloader isLoading={loader?.isLoading} />
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сформировать отчет"
            disabled={isButtonDisabled || loader?.isLoading}
            isButton
            onClick={handlerSubmit}
          />
        </FormContainer>
      </CustomContainer>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-documentation_container'}>
          <CustomContainer>
            <CustomTitle titleText={'ОТЧЕТ ОБ ОБЪЕМЕ ОКАЗАННЫХ УСЛУГ ПО ДОГОВОРУ'} />
            <ConfirmationPage
              addNew="К форме отчета"
              confirmText="Отчет сформирован!"
              onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
            />
          </CustomContainer>
        </Modal>
      )}
    </>
  );
}

export default ContractReport;

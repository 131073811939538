import { LOAD_REQUEST } from "../type";

export function requestLoaderReducer(state = false, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_REQUEST: {
      return payload
    }
    default:
      return state
  }
}

/**
 * Проверяет, является ли переданный идентификатор Android действительным.
 * Идентификатор считается действительным, если он является строкой из 16 шестнадцатеричных символов.
 *
 * @param {string} androidId - Идентификатор Android, который необходимо проверить.
 * @returns {boolean} Возвращает true, если идентификатор Android действителен, иначе false.
 */
export function isValidAndroidID(androidId) {
  // Проверяем, является ли androidId строкой и имеет ли длину 16 символов.
  if (typeof androidId !== 'string' || androidId.length !== 16) {
    return false;
  }

  // Регулярное выражение для проверки на соответствие шестнадцатеричным символам.
  const hexPattern = /^[a-fA-F0-9]+$/;

  // Проверяем, соответствует ли идентификатор Android шаблону шестнадцатеричных символов.
  const isValid = hexPattern.test(androidId);

  if (isValid) {
    return true;
  } else {
    console.error("Invalid androidId:", androidId);
    return false;
  }
}

import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

function BrigadeTransferFencingOff({ handlerConfirm, closeModal, t }) {
  return (
    <CustomContainer>
      <CustomTitle titleText={t?.transferTitle} />
      <ConfirmationPage
        confirmText={t?.confirmTransferText}
        confirmButtonText={t?.confirmButtonText}
        cancelButtonText={t?.cancelButtonText}
        onConfirm={handlerConfirm} // Функция, которая должна быть вызвана при подтверждении
        onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
      />
    </CustomContainer>
  );
}

export default BrigadeTransferFencingOff;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arhive-brigade-composition {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--sadr-font-size-10);
}
.arhive-brigade-composition_table-container {
  display: flex;
  margin-top: var(--sadr-font-size-20);
}
`, "",{"version":3,"sources":["webpack://./src/styles/ArciveBrigadeComposition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,oCAAoC;AACtC","sourcesContent":[".arhive-brigade-composition {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: var(--sadr-font-size-10);\n}\n.arhive-brigade-composition_table-container {\n  display: flex;\n  margin-top: var(--sadr-font-size-20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

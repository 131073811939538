import { FILTER_CONFIG, UPDATE_REQUEST_BLOCKER } from '../type';

export function filterConfigReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case FILTER_CONFIG: {
      return {
        ...state,
        lastId: payload.lastId,
        canSendRequest: payload.canSendRequest,
        apiRequest: payload.apiRequest,
        searchFlag: payload.searchFlag,
        searchData: payload.searchData,
      };
    }
    case UPDATE_REQUEST_BLOCKER: {
      return {
        ...state,
        blockRequest: payload,
      }
    }
    default:
      return state;
  }
}

import { validateValueLength } from './validationOfData/validateValueLength';

/**
 * Создает объект FormData из переданного события формы или объекта.
 * Эта функция может быть использована для отправки данных формы, включая файлы, на сервер.
 * @param {Event|Object} dataOrEvent - Событие формы или объект с данными формы.
 * @param {File|null} fileToAdd - Файл для добавления в FormData, по умолчанию null.
 * @returns {FormData} - Объект FormData с данными формы и, при наличии, файлом.
 * @throws {Error} Если первый аргумент не является ни событием формы, ни объектом.
 */
export function createFormData(dataOrEvent, fileToAdd = null) {
  let formData;

  // Проверяем, является ли первый аргумент событием формы
  if (dataOrEvent && dataOrEvent.target && dataOrEvent.target instanceof HTMLFormElement) {
    formData = new FormData(dataOrEvent.target);
  } else if (typeof dataOrEvent === 'object') {
    // Проверяем, является ли аргумент объектом
    formData = new FormData();
    Object.keys(dataOrEvent).forEach((key) => {
      const value = dataOrEvent[key];
      // Добавляем файлы, массивы, объекты или другие типы данных в FormData
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else if (typeof value === 'object' && value !== null) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value === null ? '' : value);
      }
    });
  } else {
    throw new Error('The first argument must be an event or an object');
  }
  // Добавляем дополнительный файл, если он был указан
  if (fileToAdd instanceof File) {
    formData.append('file', fileToAdd);
  }

  return formData;
}

/**
 * Проверяет валидность формы, убеждаясь, что все обязательные поля заполнены.
 * Принимает объект, представляющий данные формы, и массив, содержащий имена необязательных полей.
 * Функция возвращает true, если хотя бы одно обязательное поле пусто, и false, если все обязательные поля заполнены.
 *
 * @param {Object} formData - Объект с данными формы, где ключи - это имена полей, а значения - данные этих полей.
 * @param {string[]} [optionalFields=[]] - Массив, содержащий имена полей, которые являются необязательными для заполнения.
 * @returns {boolean} Возвращает true, если форма содержит пустые обязательные поля, иначе false.
 */
export function formValidation(formData, optionalFields = []) {
  // Перебираем все ключи объекта formData
  return Object.keys(formData).some((key) => {
    // Пропускаем проверку, если поле является необязательным
    if (optionalFields.includes(key)) {
      return false;
    }
    // Особая проверка для поля isBroken
    if (key === 'isBroken') {
      return formData[key] === undefined || formData[key] === ''; // Невалидно, если isBroken равно undefined
    }
    // Особая проверка для поля androidId
    // if (key === 'androidId') {
    // // Проверяем, что androidId имеет ровно 16 символов
    //   return !formData[key] || formData[key].length !== 16;
    // }
    // Если поле обязательно, проверяем его на пустоту или отсутствие значения
    // Возвращает true для пустых значений, что указывает на невалидность формы
    return !formData[key];
  });
}

/**
 * Обрабатывает изменение даты в форме и обновляет состояние формы.
 * Эта функция принимает имя поля даты, выбранную дату, текущее состояние формы и функцию для обновления состояния формы.
 * Она обновляет указанное поле формы новой датой.
 *
 * @param {string} fieldName - Имя поля в форме, которое нужно обновить.
 * @param {Date|string} selectedDate - Выбранная дата, которая будет установлена для поля. Может быть объектом Date или строкой.
 * @param {Object} formData - Текущее состояние данных формы.
 * @param {Function} setFormData - Функция для обновления состояния формы.
 */
function handleDateChange(fieldName, selectedDate, formData, setFormData) {
  setFormData({
    ...formData,
    [fieldName]: selectedDate,
  });
}

/**
 * Обрабатывает изменение выбранного файла в форме и обновляет состояние формы.
 * Эта функция принимает событие, содержащее информацию о выбранном файле, текущее состояние формы и функцию для обновления состояния формы.
 * Она добавляет в formData файл и, если указано, свойство с именем файла в соответствии с переменной fileName.
 *
 * @param {Event} event - Событие, содержащее информацию о выбранном файле. Обычно это событие 'onChange' из элемента input type="file".
 * @param {Object} formData - Текущее состояние данных формы.
 * @param {Function} setFormData - Функция для обновления состояния формы.
 * @param {string} [fileName] - Опциональное имя свойства, в котором будет храниться имя файла.
 */
function handleFileChange(event, formData, setFormData, fileName) {
  const { name, files } = event.target;
  if (files && files.length > 0) {
    const file = files[0];
    const updatedFormData = {
      ...formData,
      [name]: file,
    };

    // Добавляем имя файла, если был передан параметр fileName
    if (fileName) {
      updatedFormData[fileName] = file.name;
    }

    setFormData(updatedFormData);
  }
}

/**
 * Обрабатывает изменения в полях ввода формы и обновляет состояние формы.
 * Если задана максимальная длина (maxLength) для текстового поля, значение фильтруется,
 * чтобы оставить только числа и обрезается до maxLength символов перед записью в состояние.
 * Без указания maxLength значение записывается в состояние напрямую.
 * Для полей ввода файла извлекает файл и его имя, сохраняя их в состояние формы.
 *
 * @param {Event} event - Событие, вызвавшее обработчик.
 * @param {Object} formData - Текущее состояние формы.
 * @param {Function} setFormData - Функция обновления состояния формы.
 * @param {string} [fileName] - Имя поля для файла, если обработка касается загрузки файла.
 * @param {number} [maxLength] - Максимальная длина значения для текстовых полей; при ее наличии фильтруются только числа.
 */
function handleInputChange(event, formData, setFormData, fileName, maxLength, maxStringLength, validatePredicate) {
  const { name, value } = event.target;
  if (value === '') {
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  let newValue = value;

  // Обработка ввода файла
  if (fileName) {
    const file = event.target.files[0];
    const fileNameValue = file ? file.name : '';

    setFormData({
      ...formData,
      [name]: file, // Устанавливаем сам файл
      [fileName]: fileNameValue, // Устанавливаем имя файла
    });
  } else {
    // Фильтрация ввода, оставляя только цифры и обрезая его, если задан maxLength
    if (maxLength) {
      newValue = value.replace(/[^\d]/g, '').slice(0, maxLength);
    }

    // Обновление formData для текстового ввода
    if (
      ((!maxLength && !maxStringLength && !validatePredicate) || (maxLength && newValue.length <= maxLength)) &&
      newValue
    ) {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }

    // Обработка максимальной длины строки
    if (maxStringLength && !maxLength && !validatePredicate) {
      const validationState = validateValueLength(newValue, maxStringLength);
      if (validationState) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }

    // Проверка validatePredicate
    if (validatePredicate && !maxLength && !maxStringLength) {
      newValue = value.replace(/[^\d]/g, ''); // Удаляем все нечисловые символы

      if (newValue) {
        // Проверяем, что newValue не пустое после фильтрации
        newValue = Number(newValue); // Преобразуем в число

        if (newValue >= validatePredicate.min && newValue <= validatePredicate.max) {
          setFormData({
            ...formData,
            [name]: newValue,
          });
        }
      }
    }
  }
}

/**
 * Обрабатывает изменение значения в инпуте и обновляет состояние формы.
 * Эта функция принимает событие, содержащее информацию о изменении в инпуте, текущее состояние формы и функцию для обновления состояния формы.
 * Она обновляет указанное поле формы новым значением из инпута.
 *
 * @param {Event} event - Событие, содержащее информацию об изменении инпута. Обычно это событие 'onChange' из элемента input.
 * @param {Object} formData - Текущее состояние данных формы.
 * @param {Function} setFormData - Функция для обновления состояния формы.
 */
// function handleSelectChange(name, selectedOption, formData, setFormData) {
//   const selectedValue = selectedOption ? selectedOption.value : '';
//   setFormData({
//     ...formData,
//     [name]: selectedValue,
//   });
// }

function handleSelectChange(name, selectedOption, formData, setFormData) {
  // Получаем выбранное значение или пустую строку, если ничего не выбрано
  const selectedValue = selectedOption ? selectedOption.value : '';

  // Обновляем formData с новым значением для указанного поля
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: selectedValue,
  }));
}
/**
 * Удаляет файл из состояния формы, устанавливая его значение в null.
 * Эта функция предназначена для обновления состояния формы при удалении файла, связанного с формой.
 * Она принимает текущее состояние формы и функцию для обновления этого состояния.
 *
 * @param {Object} formData - Текущее состояние данных формы. Ожидается, что в formData есть поле 'file'.
 * @param {Function} setFormData - Функция для обновления состояния формы.
 */
//   function removeFile(formData, setFormData, fileName, castomNameForFile) {
//   // setFormData({
//   //   ...formData,
//   //   fileName: null,
//   // });

//   // Создаем копию formData,
//   const newFormData = { ...formData };

//   // Удаляем файл с указанным именем
//   delete newFormData[fileName];
//   delete newFormData[castomNameForFile]

//   // Обновляем состояние formData
//   setFormData(newFormData);
// }
function removeFile(formData, setFormData, fileName, castomNameForFile) {
  // Создаем копию formData и обновляем значения указанных полей
  const newFormData = {
    ...formData,
    [fileName]: '', // Заменяем значение на пустую строку
    ...(castomNameForFile && { [castomNameForFile]: '' }), // Заменяем значение на пустую строку только если передан castomNameForFile
  };

  // Обновляем состояние formData
  setFormData(newFormData);
}

/**
 * Универсальная функция для обновления состояния формы в зависимости от типа события.
 * Делегирует обработку изменений специализированным функциям в зависимости от типа элемента.
 * @param {Object} formData - текущее состояние формы.
 * @param {Function} setFormData - функция для обновления состояния формы.
 * @param {Event} [event=null] - событие, инициированное элементом формы.
 * @param {string} [name=null] - имя элемента формы, которое будет обновлено.
 * @param {any} [selectValue=null] - значение для селекторов или даты.
 * @param {string} [typeElement=null] - тип элемента формы для специфической обработки, например 'select', 'date' или 'file'.
 */
export function handleUniversalChange(
  formData,
  setFormData,
  event = null,
  name = null,
  selectValue = null,
  typeElement = null,
  fileName,
  castomNameForFile,
  maxLength,
  maxStringLength = null,
  validatePredicate = null
) {
  switch (typeElement) {
    case 'select':
      handleSelectChange(name, selectValue, formData, setFormData);
      break;
    case 'date':
      handleDateChange(name, selectValue, formData, setFormData);
      break;
    case 'file':
      if (event) {
        handleFileChange(event, formData, setFormData, fileName);
      }
      break;
    default:
      if (event) {
        handleInputChange(event, formData, setFormData, fileName, maxLength, maxStringLength, validatePredicate);
      }
      break;
  }

  // Специальная обработка для удаления файла
  if (name === 'isRemove') {
    removeFile(formData, setFormData, fileName, castomNameForFile);
  }
}

/**
 * Выводит данные из объекта FormData в консоль в формате объекта.
 *
 * @param {FormData} formData - Объект FormData, содержащий данные формы.
 */
function logFormData(formData) {
  // Создание пустого объекта для хранения данных из FormData
  const object = {};

  // Итерация по всем парам ключ-значение в объекте formData
  for (const [key, value] of formData.entries()) {
    // Добавление каждой пары ключ-значение в объект
    object[key] = value;
  }
}

/**
 * Преобразует данные из объекта FormData в объект JavaScript.
 *
 * @param {FormData} formData - Объект FormData, содержащий данные формы.
 * @returns {Object} Объект, содержащий данные из FormData.
 */
function convertFormDataToObject(formData) {
  // Создание пустого объекта для хранения данных из FormData
  const object = {};

  // Итерация по всем парам ключ-значение в объекте formData
  for (const [key, value] of formData.entries()) {
    // Добавление каждой пары ключ-значение в объект
    object[key] = value;
  }

  // Возврат объекта с данными
  return object;
}

/**
 * Преобразует массив объектов, заменяя значение 'label' каждого объекта на значение 'value'.
 *
 * @param {Object[]} options - Массив объектов для преобразования. Каждый объект должен содержать ключи 'value' и 'label'.
 * @returns {Object[]} Новый массив объектов с обновленными значениями 'label'.
 */
function transformOptions(options) {
  // Проверяем, является ли options массивом
  if (!Array.isArray(options)) {
    return [];
  }

  // Используем метод map для создания нового массива
  return options.map((option) => {
    return {
      ...option, // Копируем все существующие свойства объекта
      label: option.value, // Заменяем значение 'label' на значение 'value'
    };
  });
}

export {
  handleDateChange,
  handleFileChange,
  handleInputChange,
  handleSelectChange,
  removeFile,
  logFormData,
  convertFormDataToObject,
  transformOptions,
};

// Выше оптимизированная функция но не протестированна поэтому пока оставил эту
// export function handleUniversalChange(formData, setFormData, event = null, name = null, selectValue = null, typeElement = null) {
//   // Если функция вызвана для удаления файла, устанавливаем свойство 'file' в null
//   if (name && name === 'isRemove') {
//     setFormData({
//       ...formData,
//       file: null, // Сброс файла
//     });
//     return;
//   }

//   // Обработка обычного текстового поля
//   if (!typeElement && event && event.target.type !== 'file') {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value, // Обновляем значение поля в состоянии формы
//     });
//     return;
//   }

//   // Обработка поля с файлом
//   if (!typeElement && event && event.target.type === 'file') {
//     const { name, files } = event.target;
//     if (files && files.length > 0) {
//       setFormData({
//         ...formData,
//         [name]: files[0], // Сохраняем первый выбранный файл в состояние
//       });
//     }
//     return;
//   }

//   // Обработка поля выбора даты
//   if (typeElement === 'date') {
//     setFormData({
//       ...formData,
//       [name]: selectValue, // Обновляем значение даты в состоянии формы
//     });

//     return;
//   }

//   // Обработка селектора (выпадающего списка)
//   if (typeElement === 'select') {
//     const selectedValue = selectValue ? selectValue.value : '';
//     setFormData({
//       ...formData,
//       [name]: selectedValue, // Устанавливаем выбранное значение для селектора
//     });
//   }
//   // Возвращаемся без выполнения, если ни одно условие не сработало
//   return;
// }
